import { API } from "aws-amplify";
import { getCanvasItemMap } from '@/libs/canvas.js'

export async function getUser() {

  try {
    return await API.get("api", "/user");
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export async function updateUser(update) {

  try {
    return await API.put("api", "/user", {
      body: update
    });
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export async function getUserInfo(email) {

  try {
    return await API.get("api", "/user/" + email);
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export async function register(email) {
  if ((typeof email !== 'string') || !email.length)
    return null;

  try {
    return await API.post("api", "/register", {
      body: {
        email: email
      }
    });
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export async function inviteToMyAccount(email) {
  if ((typeof email !== 'string') || !email.length)
    return null;

  return await API.post("api", "/invite", {
    body: {
      email: email
    }
  });
}

export async function updateAccount(update) {

  try {
    return await API.put("api", "/account", {
      body: update
    });
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export async function likeInCanvas(cid, user, iid, like) {

  if ((typeof cid !== 'string') || (typeof user !== 'string') || (typeof iid !== 'string') || (typeof like !== 'boolean'))
    return false;

  try {
    await API.post("api", "/canvas/" + cid + "/like/" + iid, {
      body: {
        user: user,
        like: like
      }
    });
  }
  catch (err) {
    console.log(err);
    return false;
  }

  return true;
}

export async function getLikesInCanvas(cid) {

  if (typeof cid !== 'string')
    return [];

  try {
    return await API.get("api", "/canvas/" + cid + "/like");
  }
  catch (err) {
    console.log(err);
    return [];
  }
}

export async function createComment(cid, user, iid, text) {

  if ((typeof cid !== 'string') || (typeof user !== 'string') || (typeof iid !== 'string') || (typeof text !== 'string'))
    return null;

  try {
    return await API.post("api", "/canvas/" + cid + "/comment/" + iid, {
      body: {
        user: user,
        text: text
      }
    });
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export async function getComments(cid) {

  if (typeof cid !== 'string')
    return [];

  try {
    return await API.get("api", "/canvas/" + cid + "/comment");
  }
  catch (err) {
    console.log(err);
    return [];
  }
}

export async function toggleEmojiInCanvas(cid, user, iid, add, emoji) {

  if ((typeof cid !== 'string') || (typeof user !== 'string') || (typeof iid !== 'string') || (typeof add !== 'boolean') || (typeof emoji !== 'string'))
    return false;

  try {
    await API.post("api", "/canvas/" + cid + "/emoji/" + iid, {
      body: {
        user: user,
        add: add,
        emoji: emoji
      }
    });
  }
  catch (err) {
    console.log(err);
    return false;
  }

  return true;
}

export async function getEmojisInCanvas(cid) {

  if (typeof cid !== 'string')
    return [];

  try {
    return await API.get("api", "/canvas/" + cid + "/emoji");
  }
  catch (err) {
    console.log(err);
    return [];
  }
}

///////////////////////////////////////////////
// Private
export async function convertVideo(path, cid, iid) {
  if ((typeof path !== 'string'))
    return null;

  try {
    return await API.post("api", "/private/video", {
      body: {
        path: path,
        cid: cid,
        iid: iid
      }
    });
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

// returns created canvas object
export async function createCanvas(name, config, template) {
  if ((typeof config !== 'object'))
    return null;

  try {
    return await API.post("api", "/canvas", {
      body: {
        name: name,
        config: config,
        template: template
      }
    });
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export async function getCanvas(cid) {
  if ((typeof cid !== 'string'))
    return null;

  return await API.get("api", "/canvas/" + cid);
}

export async function listCanvases() {
  try {
    return await API.get("api", "/canvas");
  }
  catch (err) {
    console.log(err);
    return [];
  }
}

export async function updateCanvas(cid, canvas) {
  if ((typeof cid !== 'string') || (typeof canvas !== 'object'))
    return null;

  try {
    return await API.put("api", "/canvas/" + cid, {
      body: canvas
    });
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export async function removeCanvas(cid) {
  if ((typeof cid !== 'string'))
    return null;

  try {
    return await API.del("api", "/canvas/" + cid, { body: {} });
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export async function createItem(cid, parent, type, config, index, upload) {
  if ((typeof cid !== 'string') || (typeof parent !== 'string') || (typeof type !== 'string') || (typeof config !== 'object'))
    return null;

  try {
    return await API.post("api", "/canvas/" + cid + '/item', {
      body: {
        parent: parent,
        type: type,
        config: config,
        index: index,
        upload: upload
      }
    });
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export async function getItem(cid, iid) {
  if ((typeof cid !== 'string') || (typeof iid !== 'string'))
    return null;
  try {
    return await API.get("api", "/canvas/" + cid + '/item/' + iid);
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export async function updateItem(cid, iid, item) {
  if ((typeof cid !== 'string') || (typeof iid !== 'string') || (typeof item !== 'object'))
    return null;

  try {
    return await API.put("api", "/canvas/" + cid + '/item/' + iid, {
      body: item
    });
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export async function removeItem(cid, parent, iid, recursive = true) {
  if ((typeof cid !== 'string') || (typeof parent !== 'string') || (typeof iid !== 'string'))
    return null;

  try {
    return await API.del("api", "/canvas/" + cid + '/item/' + iid, {
      body: {
        parent: parent,
        recursive: recursive
      }
    });
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export async function copyItem(cidOld, cidNew, iid, parent) {
  if ((typeof cidOld !== 'string') || (typeof cidNew !== 'string') || (typeof iid !== 'string') || (typeof parent !== 'string'))
    return null;

  try {
    return await API.post("api", "/canvas/" + cidOld + '/item/' + iid + '/copy', {
      body: {
        parent: parent,
        cidNew: cidNew
      }
    });
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export async function createSnapshot(cid, iid, name, assignee, assigneeRole) {
  if ((typeof cid !== 'string') || (typeof iid !== 'string') || (typeof name !== 'string'))
    return null;

  try {
    return await API.post("api", "/canvas/" + cid + '/item/' + iid + '/snapshot', {
      body: {
        name: name,
        assignee: assignee,
        assigneeRole: assigneeRole
      }
    });
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export async function getSnapshot(cid, sid) {
  if ((typeof cid !== 'string') || (typeof sid !== 'string'))
    return null;

  return await API.get("api", "/canvas/" + cid + '/snapshot/' + sid);
}

export async function removeSnapshot(cid, sid) {
  if ((typeof cid !== 'string') || (typeof sid !== 'string'))
    return null;

  try {
    return await API.del("api", "/canvas/" + cid + '/snapshot/' + sid);
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export async function addSubSection(item, newSection, store) {
  if (!item || !item.cid || !item.iid || !item.type)
    return null;

  if (!item.items)
    item.items = [];

  try {
    //////////////////////////////////////////////////////////
    // (0.) Get item to have up-to-date data
    // TODO...

    if (item.type !== 'root') {
      //////////////////////////////////////////////////////////
      // 1. Duplicate item, change name to '', add to original
      let newItem = {
        cid: item.cid,
        parent: item.iid, // add to original...
        type: item.type,
        cfg: Object.assign({}, item.cfg),
        items: item.items
      };
      newItem.cfg.name = ''; // remove name

      let iids = [];
      if (newItem.items.length) {
        for (let i of newItem.items)
          iids.push(i.iid);
      }
  
      newItem.iid = await API.post("api", "/canvas/" + item.cid + '/item', { body: {
        parent: newItem.parent,
        type: newItem.type,
        config: newItem.cfg,
        items: iids
      }});

      getCanvasItemMap(store.state, item.cid)[newItem.iid] = newItem;
      
      //////////////////////////////////////////////////////////
      // 2. Update original view to flat, set items to duplicated iid
      item.cfg.flat = true;
      await API.put("api", "/canvas/" + item.cid + '/item/' + item.iid, { body: {
        i: [ newItem.iid ],
        cfg: item.cfg
      }});

      item.items = [ newItem ];
    }

    //////////////////////////////////////////////////////////
    // 3. Create new subsection in original
    newSection.iid = await API.post("api", "/canvas/" + item.cid + '/item', { body: {
      parent: newSection.parent,
      type: newSection.type,
      config: newSection.cfg
    }});

    getCanvasItemMap(store.state, item.cid)[newSection.iid] = newSection;

    item.items.push(newSection);

    return item;
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export async function publishCanvas(cid) {
  if ((typeof cid !== 'string'))
    return null;

  try {
    return await API.post("api", "/canvas/" + cid + '/publish', { body: {} });
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export async function getPublishedCanvas(cid) {
  if ((typeof cid !== 'string'))
    return null;

  try {
    return await API.get("api", "/canvas/" + cid + '/publish');
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export async function createShareToCollect(cid, iid, instructions) {
  if ((typeof cid !== 'string') || (typeof instructions !== 'string'))
    return null;

  try {
    return await API.post("api", "/canvas/" + cid + '/collect', { body: {
      instructions: instructions,
      iid: iid
    } });
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export async function getShareToCollect(cid, ccid) {
  if ((typeof cid !== 'string') || (typeof ccid !== 'string'))
    return null;

  try {
    return await API.get("api", "/canvas/" + cid + '/collect/' + ccid);
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export async function uploadToShareToCollect(cid, ccid, type, config, path, file, uid) {
  if ((typeof cid !== 'string') || 
      (typeof ccid !== 'string') || 
      (typeof type !== 'string') || 
      (typeof config !== 'object') ||
      (typeof path !== 'string') || 
      (typeof file !== 'string') || 
      (typeof uid !== 'string'))
    return null;

  try {
    return await API.put("api", "/canvas/" + cid + '/collect/' + ccid, { body: {
      type: type,
      config: config,
      path: path,
      file: file, 
      uid: uid
    }});
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export async function createKeyValueItem(cid, iid, key, value, type, index, config) {
  if ((typeof cid !== 'string') || (typeof iid !== 'string') || (typeof type !== 'string'))
    return null;

  try {
    return await API.post("api", "/canvas/" + cid + '/item/' + iid + '/kv', {
      body: {
        key: key,
        value: value,
        type: type,
        index: index,
        config: config
      }
    });
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export async function updateKeyValueItem(cid, iid, kvid, item) {
  if ((typeof cid !== 'string') || (typeof iid !== 'string') || (typeof kvid !== 'string') || (typeof item !== 'object'))
    return null;

  try {
    return await API.put("api", "/canvas/" + cid + '/item/' + iid + '/kv/' + kvid, {
      body: item
    });
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export async function removeKeyValueItem(cid, iid, kvid) {
  if ((typeof cid !== 'string') || (typeof kvid !== 'string') || (typeof iid !== 'string'))
    return null;

  try {
    return await API.del("api", "/canvas/" + cid + '/item/' + iid + '/kv/' + kvid, {
      body: {}
    });
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export async function zipItems(items) {
  if (!items || !items.length)
    return null;

  try {
    return await API.post("api", "/zipper", {
      body: {
        items: items
      }
    });
  }
  catch (err) {
    console.log(err);
    return null;
  }
}

export async function shareWithUser(cid, iid, uid, email, rw, link = false, message = null) {
  return await API.post("api", "/ac/" + cid, {
    body: {
      iid: iid,
      uid: uid,
      email: email,
      rw: rw,
      link: link,
      message: message
    }
  });
}

export async function removeShare(cid, acid) {
  if ((typeof cid !== 'string') || (typeof acid !== 'string'))
    return false;

  try {
    await API.del("api", "/ac/" + cid, {
      body: {
        acid: acid
      }
    });

    return true;
  }
  catch (err) {
    console.log(err);
    return false;
  }
}
export async function updateShare(cid, acid, role) {
  if ((typeof cid !== 'string') || (typeof acid !== 'string') || (typeof role !== 'string'))
    return false;

  try {
    return await API.put("api", "/ac/" + cid, {
      body: {
        acid: acid,
        role: role
      }
    });
  }
  catch (err) {
    console.log(err);
    return false;
  }
}

export async function listShares(cid) {
  if ((typeof cid !== 'string'))
    return [];

  try {
    return await API.get("api", "/ac/" + cid);
  }
  catch (err) {
    console.log(err);
    return [];
  }
}

export async function requestAccess(cid, iid) {
  if ((typeof cid !== 'string'))
    return false;

  try {
    await API.post("api", (iid && iid.length > 0) ? `/request-access/${cid}/${iid}` : `/request-access/${cid}`);

    return true;
  }
  catch (err) {
    console.log(err);
    return false; 
  }
}

export async function listImagesOnWebsite(url) {
  if ((typeof url !== 'string'))
    return false;

  try {
    await API.post("api", "/crawler", {
      body: {
        url: url
      }
    });

    return true;
  }
  catch (err) {
    console.log(err);
    return false; 
  }
}

export async function search(query) {
  if ((typeof query !== 'object'))
    return [];

  try {
    return await API.post("api", "/search", {
      body: {
        query: query
      }
    });
  }
  catch (err) {
    console.log(err);
    return [];
  }
}