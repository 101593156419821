/* eslint-disable no-unused-vars */
import * as api from '@/libs/api.js'

export class User {
  #store;
  #updating = false;

  constructor(store) {
    // Singleton...
    if (User._instance)
      return User._instance;

    User._instance = this;
    this.#store = store;
  }

  async #getUser(updateIfNeeded = true) {
    if (this.#updating) {
      // get user just once at a time, but let's have a 20s timeout
      let delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      let i = 0;
      while (this.#updating && ++i < 100) {
        await delay(200);
      }
    }

    if ((!this.#store.user || !this.#store.user.userName) && updateIfNeeded) {
      this.#updating = true;

      let user = await api.getUser();
      this.#store.user.e = user.e;
      this.#store.user.userName = user.userName;
      this.#store.user.account = user.account;
      this.#store.user.accountType = user.accountType;
      this.#store.user.uid = user.uid;
      this.#store.user.profileImage = user.profileImage;
      this.#store.user.userStats = user.userStats;
      this.#store.user.accountStats = user.accountStats;
      this.#store.user.teamMembers = user.teamMembers;
      this.#store.user.statuses = user.statuses;
      this.#store.user.userRoles = user.userRoles;
      this.#store.user.projectFlow = user.projectFlow;
      this.#store.user.templates = user.templates;
      this.#store.user.defaultTemplate = user.defaultTemplate;
      this.#updating = false;
    }

    if (!this.#store.user)
      return { userName: null, account: null};

    return this.#store.user;
  }
  async getUser() {
    return await this.#getUser();
  }
  async updateUser(update) {
    if (update.userName)
      this.#store.user.userName = update.userName;
      
    if (update.profileImage)
      this.#store.user.profileImage = update.profileImage;
  }
}