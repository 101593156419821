<template>
<div v-if="item" @click="click()" style="height: 100%">
  <v-responsive v-if="item.loading" :aspect-ratio="noAspectRatio ? null : (config.aspectRatio / 10)">
    <div class="skeleton-box" style="width: 100%; height: 100%;"></div>
  </v-responsive>
  <v-img v-else-if="item.thumbSrc || item.src && (item.type === 'img' || item.type === 'video')" :aspect-ratio="noAspectRatio ? null : (config.aspectRatio / 10)" :src="item.thumbSrc || item.src" :cover="config.cover" :contain="!config.cover" :transition="false" class="primary--text align-end" style="cursor: pointer; max-height: 92vh; width: 100%">
    <v-avatar :size="small ? 32 : 64" color="rgba(0,0,0,0.33)" v-if="item.type === 'video'" style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)">
      <v-progress-circular v-if="item.cfg.status === 'CONVERTING'" indeterminate color="white" size="48" width="6"></v-progress-circular>
      <v-icon :size="small ? 24: 48" color="white" v-else>mdi-play</v-icon>
    </v-avatar>
  </v-img>
  <v-responsive v-else-if="item.type === 'file'" :aspect-ratio="noAspectRatio ? null : (config.aspectRatio / 10)" class="primary--text align-end" style="cursor: pointer">
    <div class="vcenter hcenter">
      <v-icon class="d-block primary--text text-center mb-2" size="36px">mdi-file-outline</v-icon>
      <span class="text-h5 primary--text" style="text-transform: uppercase">{{(item.cfg && item.cfg.mime) ? (getSubtypeFromMime(item.cfg.mime)) : ''}}</span>
    </div>
  </v-responsive>
  <v-responsive v-else-if="item.type === 'audio'" :aspect-ratio="noAspectRatio ? null : (config.aspectRatio / 10)" class="primary--text align-end" @click.stop.prevent="view" style="cursor: pointer">
    <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)"><v-icon size="96px" color="primary">mdi-play</v-icon></div>
  </v-responsive>
  <canvas-item-thumbnail v-else-if="item.type === 'link' && linkedItem" v-model="linkedItem" :config="config" :single="single" :small="small"></canvas-item-thumbnail>
  <template v-else-if="item.items && item.items.length">
    <v-window v-if="!single" show-arrows dark v-model="item.active">
      <v-window-item v-for="(groupitem, g) in item.items" :key="g">
        <canvas-item-thumbnail v-model="item.items[g]" :config="config" :single="single" :small="small"></canvas-item-thumbnail>
      </v-window-item>
      <div class="hcenter vbottom mb-2"><v-chip color="rgba(0,0,0,0.3)" class="elevation-0">{{item.active + 1}}/{{item.items.length}}</v-chip></div>
      <div style="position: absolute; left: 4px; top: 4px">
        <v-chip v-if="countMimeTypeInGroup(item, 'video.*') > 0" color="primary" class="elevation-1 ma-0">{{countMimeTypeInGroup(item, 'video.*')}} <v-icon small class="ml-1">mdi-play</v-icon></v-chip>
      </div>
    </v-window>
    <canvas-item-thumbnail v-else v-model="item.items[0]" :config="config" :single="single" :small="small"></canvas-item-thumbnail>
    <!--<v-img v-else-if="item.items[0].thumbSrc || item.items[0].src && (item.items[0].type === 'img' || item.type === 'video')" :aspect-ratio="noAspectRatio ? null : (config.aspectRatio / 10)" :src="item.items[0].thumbSrc || item.items[0].src" :cover="config.cover" :contain="!config.cover" :transition="false" class="primary--text align-end group-inside" style="cursor: pointer; max-height: 92vh; width: 100%;">
      <v-avatar :size="small ? 32 : 64" color="rgba(0,0,0,0.33)" v-if="item.type === 'video'" style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)">
        <v-progress-circular v-if="item.cfg.status === 'CONVERTING'" indeterminate color="white" size="48" width="6"></v-progress-circular>
        <v-icon :size="small ? 24: 48" color="white" v-else>mdi-play</v-icon>
      </v-avatar>
    </v-img>-->
  </template>
  <v-responsive v-else :aspect-ratio="noAspectRatio ? null : (config.aspectRatio / 10)" class="primary--text align-end" style="cursor: pointer;  position: relative">
    <slot name="empty">
      <v-img :src="getUrl('canvas.svg')" width="33%" style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);"></v-img>
    </slot>
  </v-responsive>
</div>
</template>

<style scoped>
</style>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
import { getCanvasItemMapAsync } from '@/libs/canvas.js'

export default {
  name: 'canvas-item-thumbnail',
  props: {
    value: {
      type: Object,
      default: () => null
    },
    config: {
      type: Object,
      default: () => ({  rounded: 0,  elevation: 1,  aspectRatio: 10, cover: true })
    },
    single: {
      type: Boolean,
      default: () => false
    },
    small: {
      type: Boolean,
      default: () => false
    },
    noAspectRatio: {
      type: Boolean,
      default: () => false
    },
  },
  data: () => ({
    linkedItem: null
  }),
  computed: {
    item: {
      get: function() { return this.value; },
      set: function(value) { this.$emit('input', value); }
    }
  },
  async mounted() {
    if (this.item && this.item.type === 'link' && this.item.cfg && this.item.cfg.cid) {
      const itemMap = await getCanvasItemMapAsync(this.$store.state, this.item.cfg.cid);
      if (itemMap && this.item.cfg.iid in itemMap)
        this.linkedItem = itemMap[this.item.cfg.iid];
    }      
  },
  methods: {
    getFirstImageSrc(item) {
      let current = item;
      // let's limit the max depth to 100
      for (let i = 0; i < 100; ++i) {
        if (current.thumbSrc || current.src) {
          return current.thumbSrc || current.src;
        }

        if (current.items && current.items.length > 0)
          current = current.items[0];
      }

      return null;
    },
    getSubtypeFromMime(mime) {
      if (!mime)
        return '';

      let parts = mime.split('/');
      if (parts.length < 2)
        return mime;

      return parts[1];
    },
    countMimeTypeInGroup(group, mime) {
      let count = 0;
      const traverseCountType = (item) => {
        if (!item.items || !item.items.length)
          return;
        for (let i = item.items.length -1; i >= 0; i--) {
          if (item.items[i].cfg.mime && item.items[i].cfg.mime.match(mime))
            count++;
          if (item.items[i].items && item.items[i].items.length)
            traverseCountType(item.items[i]);
        }
      }
      traverseCountType(group, null);
      return count;
    },
    getUrl(path) {
      return window.location.origin + '/' + path
    },
    isGroupType(type) {
      return ['canvas', 'gallery', 'list', 'map-list', 'map', 'file-table', 'casting-profile'].includes(type);
    },
    isFileGoogleViewerCompatible(item) {
      if (!item || !item.cfg || !item.cfg.path || !item.cfg.path.length)
        return false;

      const fileName = item.cfg.path.split("/").pop(); // get the file name
      let fileExtension = fileName.split(".").pop(); // get the extension
      if (!fileExtension || !fileExtension.length)
        return false;

      fileExtension = fileExtension.toLowerCase();
      
      return ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'svg', 'eps', 'ps', 'ttf', 'xps', 'mts'].includes(fileExtension);
    },
    click() {
      const item = this.item.type === 'link' ? this.linkedItem : this.item;
      let type = 'img';

      if (item.type === 'file' && !item.thumbSrc && !this.isFileGoogleViewerCompatible(item))
        type = 'new-tab';

      else if (this.isGroupType(item.type))
        type = 'group'
      
      this.$emit('view', type);
    }
  }
}
</script>