<template>
  <v-dialog v-model="show" @keydown.esc="show = false" scrollable persistent content-class="share-dialog" width="620">
    <v-card color="white" light>
      <v-card-title class="primary white--text pr-3">Share {{item && item.cfg ? item.cfg.name || 'project' : 'project'}}<v-spacer></v-spacer><v-btn @click="show = false" icon color="white"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
      <v-card-text class="primary--text pa-7 text-center">
        <share-widget :cid="cid" :iid="iid"></share-widget>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style>
.fill-height {
  height: 100%;
}
.share-dialog .v-input__append-inner {
  margin-top: 0px !important;
}
.share-dialog .v-input.pr-0 .v-input__slot {
  padding-right: 0px !important;
}
.share-dialog .v-input.pl-0 .v-input__slot {
  padding-left: 0px !important;
}
.ac-row .delete-ac {
  opacity: 0;
}
.ac-row:hover .delete-ac {
  opacity: 1;
}
</style>
<script>
/*eslint-disable no-unused-vars*/
import { mapActions, mapGetters } from 'vuex'
import * as api from '@/libs/api.js'
import * as tools from '@/libs/tools.js'
import { getCanvasItemMap } from '@/libs/canvas.js'
import { User } from '@/libs/user.js'
import { AccessControl } from '@/libs/access-control.js'
import ShareWidget from '@/components/widgets/share-widget'

export default {
  name: 'share-dialog',
  components: {
    'share-widget': ShareWidget
  },
  data: () => {
    return {
      email: null,
      role: 'Viewer',
      loading: false,
      downloading: false,
      publishing: false,
      copying: false,
      userRoles: ['Viewer', 'Editor', 'Client'],
    }
  },
  computed: {
    show: {
      get: function() { return this.isDialogShown()('shareWith'); },
      set: function(value) {  return value ? this.showDialog('shareWith') : this.hideDialog('shareWith'); }
    },
    published: {
      get: function() { return this.$store.state.dialogs.shareWith.public },
      set: function(value) { this.$store.state.dialogs.shareWith.public = value }
    },
    cid() { return this.$store.state.dialogs.shareWith.cid },
    iid() { return this.$store.state.dialogs.shareWith.iid },
    acUpdated() { return this.$store.state.ac.updated },
    item() {
      if (!this.iid || !(this.iid in getCanvasItemMap(this.$store.state, this.cid)))
        return null;

      return getCanvasItemMap(this.$store.state, this.cid)[this.iid];
    }
  },
  asyncComputed: {
    async acList() {
      this.downloading = true;
      let list = await this.getACList();
      this.downloading = false;
      return list;
    },
    async isPublicSection() {
      this.downloading = true;
      let list = await this.getACList('public');
      this.downloading = false;
      list = list.filter(ac => ac.iid === this.iid);
      if (list.length === 0)
        return false;

      if (list.length > 1)
        console.log('Multiple AC links in ' + this.iid + '!');

      return true;
    }
  },
  async mounted() {
    let userLib = new User(this.$store.state);
    try {
      let user = await userLib.getUser();
      this.userRoles = (user && user.userRoles && user.userRoles.length) ? user.userRoles : ['Viewer', 'Editor', 'Client'];
      this.role = this.userRoles[0];
    }
    catch (err) {
      console.info(err);
    }
  },
  methods: {
    ...mapGetters(['isDialogShown']),
    ...mapActions(['showDialog', 'hideDialog']),
    async share(uid) {
      if (!this.validEmail() && !uid)
        return;
      
      this.loading = true;
      try {
        let acLib = new AccessControl(this.$store);
        if (uid)
          await acLib.shareWith(this.cid, this.iid, '', 'Viewer', uid);
          
        else {
          await acLib.shareWith(this.cid, this.iid, this.email, this.role);
          this.email = null;
        }
        this.loading = false;
      }
      catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    async makePublic() {
      if (this.isPublicSection)
        return;
        
      await this.share('public');
    },
    async makePrivate() {
      if (!this.isPublicSection)
        return;

      this.loading = true;

      this.downloading = true;
      let list = await this.getACList('public');
      this.downloading = false;
      list = list.filter(ac => ac.iid === this.iid);
      if (!list || list.length === 0)
        return;

      for (let ac of list)
        await this.remove(ac.acid);
      
      this.loading = false;
    },
    validEmail() {
      if (!this.email || !this.email.length)
        return false;
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(this.email);
    },
    async remove(acid) {
      let acLib = new AccessControl(this.$store);
      if (!(await acLib.removeShare(this.cid, acid)))
        return;
    },
    getPublicURL() {
      return window.location.origin + '/canvas/' + this.cid + '/' + this.iid;
    },
    copyURL() {
      this.copying = true;
      if (navigator.clipboard)
        navigator.clipboard.writeText(this.getPublicURL());
      else
        this.fallbackCopyTextToClipboard();
        
      setTimeout(() => { this.copying = false }, 3000);
    },
    fallbackCopyTextToClipboard() {
      let el = document.getElementById('publicURL');
      el.focus();
      el.select();
      document.execCommand('copy');
    },
    async publish() {
      this.publishing = true;
      
      try {
        await api.publishCanvas(this.cid);
      }
      catch (err) {
        console.log(err);
      }

      this.publishing = false;
      this.published = true;
    },
    getColor(str) {
      return tools.seededRandomColor(str);
    },
    async getACList(uid = null) {
      let acLib = new AccessControl(this.$store);
      let item = (this.iid && (this.iid in getCanvasItemMap(this.$store.state, this.cid))) ? getCanvasItemMap(this.$store.state, this.cid)[this.iid] : null;
      return await acLib.listShares(this.cid, item, uid);
    },
    async updateRole(acid, role) {
      let acLib = new AccessControl(this.$store);
      return await acLib.updateShare(this.cid, acid, role);
    },
    async setAssignee(uid) {
      let previous = this.item.assignee;
      if (uid === this.item.assignee)
        uid = '';
        
      this.item.assignee = uid;

      if (await api.updateItem(this.cid, this.iid, { a: uid }) === null)
        this.item.assignee = previous;
    }
  }
}
</script>