import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { preset } from 'vue-cli-plugin-vuetify-preset-reply/preset'

Vue.use(Vuetify);

export default new Vuetify({
  preset,
  theme: { 
    dark: false,
    themes: {
      dark: {
        primary: '#4a6572',
        secondary: '#f9aa33'
      },
      light: {
        primary: '#344955',
        secondary: '#f9aa33'
      }
    }
  },
});
