<template>
<div :class="editing ? 'editing' : ''" class="file-table-widget">
  <template v-if="header">
    <div v-if="!editing" class="text-h4 px-3">{{item.cfg.name}}</div>
    <div v-else class="px-3 hoverable">
      <v-text-field :value="item.cfg.name" v-on:change="updateItemConfig(item, () => item.cfg.name = $event)" :placeholder="(parent && parent.items && parent.items.length > 1) && !item.cfg.name ? ('Untitled collection' + (index !== null ? ' ' + (index+1) : '')) : null" flat solo hide-details @mouseup.prevent @mousedown.prevent class="text-h4 float-left editing"></v-text-field>
      <v-menu :close-on-content-click="false" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon elevation="0" color="primary" v-bind="attrs" v-on="on" class="float-right" style="margin-top: 7px"><v-icon size="24">mdi-dots-vertical</v-icon></v-btn>
        </template>
        <v-card class="pa-5" >
          <div class="pb-3 text-center">
            <v-btn class="elevation-1 secondary primary--text mx-1" rounded @click="remove()"><v-icon left>mdi-delete</v-icon> {{$t('removeGallery')}}</v-btn>
          </div>
          <v-select v-model="item.type" v-on:change="updateItemType(item, $event)" :items="getGalleryTypeMenuItems()" item-text="t" item-value="v" menu-props="auto" hide-details dense single-line class="py-3"></v-select>
        </v-card>
      </v-menu>
      <div class="primary--text float-right mt-3 mx-2" style="font-size: 16px; cursor: pointer" v-if="editing && getShareCount() > 0" @click="$store.state.dialogs.shareWith = { show: true, cid: item.cid, iid: item.iid, public: false}"><v-icon :size="20" color="primary">mdi-account-multiple</v-icon>{{getShareCount()}}</div>
      <v-btn v-if="editing" class="elevation-1 secondary primary--text mx-1 float-right pr-2" rounded @click="addAssets(item)" style="margin-top: 6px">
        <v-icon left>mdi-plus</v-icon> 
        <span>{{$t('addAssets')}}</span> 
        <v-divider vertical class="ml-3 mr-2"></v-divider> 
        <v-menu  :close-on-content-click="false" :nudge-width="160" offset-y>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-chevron-down</v-icon>
          </template>
          <v-card class="pa-1">
            <v-list class="pa-0" rounded>
              <v-list-item color="success" @click="createSection()"><v-list-item-title>Add sub-section</v-list-item-title></v-list-item>
              <v-list-item @click="createKVItem(item, 'text')"><v-list-item-title>Add text</v-list-item-title></v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-btn>
      <v-btn v-if="item.items && item.items.length" icon elevation="0" color="primary" class="float-right" :class="item.items[0].checked ? null : 'show-on-hover'" style="margin-top: 7px" @click.stop.prevent="checkChildren"><v-icon size="24">{{item.items[0].checked ? `mdi-check-circle` : `mdi-checkbox-blank-circle-outline`}}</v-icon></v-btn>
      <div style="clear: both"></div>
      <v-snackbar :value="editing && childrenHasMaps() && showMapOffer" @input="showMapOffer = $event" :timeout="60000" max-width="800" color="primary">
        <span>{{$t('addMapQuestion')}}</span>
        <template v-slot:action="{ attrs }">
          <v-btn color="secondary" text v-bind="attrs" @click="updateItemType(item, 'map-list')">{{$t('yes')}}</v-btn>
          <v-btn color="secondary" text v-bind="attrs" @click="showMapOffer = false">{{$t('no')}}</v-btn>
        </template>
      </v-snackbar>
    </div>
  </template>
  <div class="gallery-kv">
    <v-container fluid grid-list-md :class="(!item.kv || !item.kv.length) ? 'pa-0' : ''">
      <draggable class="layout row wrap" :group="{ name: 'kv' }" :animation="200" v-model="item.kv" :disabled="!editing || $vuetify.breakpoint.mdAndDown">
        <v-flex v-for="(kv, k) in item.kv" :key="k" class="xs12 md12" :class="getKVSizeClass(item)">
          <key-value-item v-model="item.kv[k]" :editing="editing" class="mb-2" @remove="removeKVItem(item, k)" @update="updateKVItem(item, kv)" :rounded="0" full colorPrivate="rgba(255,192,192,0.4)" color="rgba(255,255,255,0.3)"></key-value-item>
        </v-flex>
      </draggable>
    </v-container>
  </div>
  <div class="gallery-content" @drop.prevent="dropFiles" @dragenter.prevent @dragover.prevent="dragFiles" @dragleave="dragLeave" :style="'padding: 2px'">
    <v-data-table :headers="headers" :items="files" :items-per-page="10" class="elevation-1 rounded-sm ma-3" item-key="iid" :hide-default-footer="!item || !item.items || item.items.length <= 10">
      <template v-slot:item="{ item }">
        <tr>
          <td style="cursor: pointer" @click.self.stop="viewItem(item.data, item.index)"><v-icon v-if="item.data && item.data.items && item.data.items.length > 0" size="24" color="primary" @click="expand(item.index)">{{expanded(item.index) ? `mdi-chevron-down` : `mdi-chevron-right`}}</v-icon> {{ item.name }}</td>
          <td>{{item.type}}</td>
          <td>{{item.formatedSize}}</td>
          <td><v-icon v-if="item.data && item.data.src" size="24" color="primary" @click="download(item.data)">mdi-download</v-icon></td>
          <td><v-icon v-if="editing" size="24" color="primary" @click="check(item.index)">{{checked(item.index) ? `mdi-check-circle` : `mdi-checkbox-blank-circle-outline`}}</v-icon></td>
        </tr>
        <tr v-if="expanded(item.index) && item.data && item.data.items && item.data.items.length > 0">
          <td :colspan="headers.length">
            <file-table-widget :value="item.data" :parent="value" :editing="editing" :preview="preview" :header="false"></file-table-widget>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</div>
</template>

<style>
.file-table-widget .text-h4 {
  font-weight: 300;
  white-space: nowrap;
}
.file-table-widget {
  background-color: rgba(255,255,255,0.4);
  transition: background-color 200ms linear;
}
.file-table-widget:hover {
  background-color: rgba(255,255,255,0.7);
}
</style>

<script>
/* eslint-disable no-unused-vars */
import draggable from 'vuedraggable'
import * as api from '@/libs/api.js'
import * as tools from '@/libs/tools.js'
import { EventBus } from '@/libs/eventBus.js'
import KeyValueItem from '@/components/widgets/key-value-item'
export default {
  name: 'file-table-widget',
  components: {
    'draggable': draggable,
    'key-value-item': KeyValueItem,
  },
  props: {
    value: {
      type: Object,
      default: () => null
    },
    parent: {
      type: Object, 
      default: () => null
    },
    index: {
      type: Number,
      default: () => null
    },
    preview: {
      type: Boolean, 
      default: () => false
    },
    editing: {
      type: Boolean, 
      default: () => false
    },
    header: {
      type: Boolean, 
      default: () => true
    }
  },
  data: () => ({
    render: 0,
    headers: [
      { text: 'Name', align: 'start', value: 'name', width: 'calc(100%-300px)' },
      { text: 'Type', align: 'start', value: 'type', width: 150},
      { text: 'Size', align: 'start', value: 'size', width: 120 },
      { text: '', align: 'start', value: 'download', width: 26, sortable: false },
      { text: '', align: 'start', value: 'actions', width: 26, sortable: false },
    ],
    items: [],
    expandedItems: [],
    showMapOffer: true,
    dragging: false
  }),
  computed: {
    item: {
      get: function() { return this.value; },
      set: function(value) { this.$emit('input', value); }
    },
    files() {
      if (!this.item || !this.item.items)
        return [];

      let files = [];
      for (let i in this.item.items) {
        const item = this.item.items[i];
        files.push({
          iid: item.iid,
          data: item,
          name: item.cfg.name,
          type: item.cfg.mime,
          size: item.cfg.size,
          formatedSize: item.cfg.size ? tools.humanFileSize(item.cfg.size, true) : '',
          index: i,
          url: item.src
        });
      }
      return files;
    }
  },
  methods: {
    async updateItemConfig(widget, functor) {
      if (functor)
        functor();
      if (widget.iid)
        await api.updateItem(widget.cid, widget.iid, { cfg: widget.cfg });
    },
    async updateItemType(widget, type) {
      if (!widget || !widget.iid || !type)
        return;

      widget.type = type;

      await api.updateItem(widget.cid, widget.iid, { t: type });
    },
    getColor (calories) {
      if (calories > 400) return 'red'
      else if (calories > 200) return 'orange'
      else return 'green'
    },
    addAssets(widget, files = null) {
      if (!widget)
        widget = this.item;

      this.$store.state.dialogs.addAssets = {
        show: true, 
        path: tools.getCanvasFullPath(this.$store.state.canvas.cfg.path, widget.cid),
        targetWidget: widget,
        files: files
      }
    },
    checkChildren() {
      if (!this.item.items || !this.item.items.length)
        return;

      const check = !this.item.items[0].checked;

      for (let item of this.item.items)
        item.checked = check;
    },
    check(index) {
      this.item.items[index].checked = !this.item.items[index].checked;
    },
    checked(index) {
      return this.item.items[index].checked;
    },
    expand(index) {
      if (this.expandedItems.includes(index)) {
        let i = this.expandedItems.indexOf(index);
        if (i !== -1)
          this.expandedItems.splice(i, 1);
      }
      else
        this.expandedItems.push(index);
    },
    expanded(index) {
      return (this.expandedItems.includes(index));
    },
    async remove() {
      let parent = this.parent;
      if (!parent || !parent.items)
        return;
      let index = parent.items.findIndex(item => item.iid == this.item.iid);
      parent.items.splice(index, 1);
      this.$emit('updateParent', parent);

      if (this.parent.iid && this.item.iid && this.parent.cid) {
        try {
          await api.removeItem(this.parent.cid, this.parent.iid, this.item.iid)
        }
        catch (err) {
          console.log(err);
        }
      }
    },
    childrenHasMaps() {
      if (!this.item || !this.item.items || !this.item.items.length)
        return false;

      for (let child of this.item.items) {
        if (!child.kv || !child.kv.length)
          continue;

        for (let kv of child.kv) {
          if (kv.type !== 'map' || !kv.value || !kv.value.lat || !kv.value.lng)
            continue;
          return true
        }
      }
      return false;
    },
    getGalleryTypeMenuItems() {
      let items = [{t:'Grid', v:'gallery'}, {t:'List', v:'list'}, {t:'Table', v:'file-table'}];
      if (this.childrenHasMaps())
        items.push({t:'Map', v:'map-list'});
      return items;
    },
    async createKVItem(item, type) {
      if (!item)
        return;

      const cid = item.cid;
      const iid = item.iid;
      const key = null;
      let value = null;
      if (!item.kv)
        item.kv = [];
      item.kv.push({ key: key, type: type, value: value, cfg: { hideCaption: true } });
      item.kv[item.kv.length-1].kvid = await api.createKeyValueItem(cid, iid, key, value, type);
      
    },
    async updateKVItem(item, kvItem) {
      if (!this.editing)
        return;

      if (item.iid && kvItem.kvid) {
        let updateItem = async () => await api.updateKeyValueItem(item.cid, item.iid, kvItem.kvid, { k: kvItem.key, v: kvItem.value, cfg: kvItem.cfg });
        EventBus.$emit('updateItem', {
          id: kvItem.kvid,
          funct: updateItem
        });
      }
    },
    async removeKVItem(item, index) {
      if (!item || !item.kv || index >= item.kv.length || index < 0)
        return;

      const kvid = item.kv[index].kvid;

      item.kv.splice(index, 1);

      await api.removeKeyValueItem(item.cid, item.iid, kvid);
    },
    getKVSizeClass(item) {
      if (!item.cfg || !item.cfg.kvSize) 
        return 'lg12';

      switch (item.cfg.kvSize) {
        case 1: return 'lg12';
        case 2: return 'lg6';
        case 3: return 'lg4';
        case 4: return 'lg3';
      }

      return 'lg12';
    },
    dragFiles(e) {
      e.stopPropagation();
      e.preventDefault();
      if (!e || !e.dataTransfer || !e.dataTransfer.files)
        return;

      e.dataTransfer.dropEffect = 'copy';
      this.dragging = true;
    },
    dropFiles(e) {
      if (!e || !e.dataTransfer || !e.dataTransfer.files || !e.dataTransfer.files.length)
        return;

      this.dragging = false;  
      this.addAssets(this.item, e.dataTransfer.files)
    },
    getShareCount() {
      return this.getShares().length;
    },
    getShares() {
      if (!this.item.cid || !(this.item.cid in this.$store.state.ac.canvases))
        return [];

      let list = this.$store.state.ac.canvases[this.item.cid].list;
      return list.filter((ac) => ac.iid == this.item.iid);
    },
    dragLeave() {
      this.dragging = false;
    },
    isFileGoogleViewerCompatible(item) {
      if (!item || !item.cfg || !item.cfg.path || !item.cfg.path.length)
        return false;

      const fileName = item.cfg.path.split("/").pop(); // get the file name
      let fileExtension = fileName.split(".").pop(); // get the extension
      if (!fileExtension || !fileExtension.length)
        return false;

      fileExtension = fileExtension.toLowerCase();
      
      return ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'svg', 'eps', 'ps', 'ttf', 'xps', 'mts'].includes(fileExtension);
    },
    isGroupType(type) {
      return ['canvas', 'gallery', 'list', 'map-list', 'map', 'file-table', 'casting-profile'].includes(type);
    },
    view(item, index) {
      this.$emit('view');
      console.log(item, index)
      if (index !== null && item)
        EventBus.$emit('showViewDialog', { items: this.item.items, index: index, widget: this.item, editing: this.editing });
    },
    viewGroup(item) {
      //this.view();
      EventBus.$emit('navigateTo', { item: item, parent: this.item });
    },
    viewItem(item, index) {
      let type = 'img';

      if (item.type === 'file' && !item.thumbSrc && !this.isFileGoogleViewerCompatible(item))
        type = 'new-tab';

      else if (this.isGroupType(item.type))
        type = 'group'

      if (!type)
        this.view(item, parseInt(index));

      else if (type === 'new-tab')
        return this.openInNewTab(item);

      else if (type === 'group')
        return this.viewGroup(item);

      this.view(item, parseInt(index));
    },
    download(item) {
      let url = item.src;
      let name = null;
      if (item.cfg && item.cfg.name)
        name = item.cfg.name;
      else if (item.cfg && item.cfg.path)
        name = item.cfg.path.split("/").pop();
      else
        name = 'untitled';
      
      let link = document.createElement("a");
      link.download = name;
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
</script>