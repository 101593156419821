<template>
<div class="login-page">
  <v-container fill-height class="signin pa-0">
    <v-layout row wrap align-center>
      <div class="mx-auto">
        <v-card @keydown.enter="continueStep()" :min-width="$vuetify.breakpoint.mobile ? 250 : 480" class="pa-3 elevation-1 rounded-xl">
          <template v-if="step === 'login'">
            <v-card-title primary-title class="login-dialog-title">
              <h4 class="headline ">What is your e-mail?</h4>
            </v-card-title>
            <v-card-text>
              <v-text-field v-if="!resetPassword" :readonly="verifyingEmail" autofocus outlined v-model="email" class="email-field"></v-text-field>
              <div class="text-center email-button" :class="validEmail ? 'valid-email' : 'valid-email'">
                <v-btn class="white--text elevation-1 rounded-pill pr-4" x-large color="secondary primary--text" :disabled="!validEmail || verifyingEmail" @click="verifyEmail()">{{verifyingEmail ? 'Working...' : 'Continue'}} <v-progress-circular v-if="verifyingEmail" indeterminate size="28" class="ml-2"></v-progress-circular><v-icon v-else class="ml-3">mdi-arrow-right</v-icon></v-btn>
              </div>
            </v-card-text>
          </template>
          <template v-else-if="step === 'password'">
            <v-card-title primary-title class="login-dialog-title">
              <h4 class="headline ">Use your password</h4>
            </v-card-title>
            <v-card-text>
              <v-text-field v-model="password" outlined :append-icon="hidePassword ? 'mdi-eye' : 'mdi-eye-off'" class="password-field" @click:append="() => (hidePassword = !hidePassword)"  :type="hidePassword ? 'password' : 'text'"></v-text-field>
              <div class="text-center password-button" :class="validPassword ? 'valid-password' : 'valid-password'">
                <v-btn class="white--text elevation-1 rounded-pill pr-4" x-large color="secondary primary--text" :disabled="!validPassword || verifyingPassword" @click="verifyPassword()">{{verifyingPassword ? 'Working...' : 'Login'}} <v-progress-circular v-if="verifyingPassword" indeterminate size="28" class="ml-2"></v-progress-circular><v-icon v-else class="ml-3">mdi-arrow-right</v-icon></v-btn>
              </div>
            </v-card-text>
          </template>
          <template v-else-if="step === 'magic-link'">
            <v-card-title primary-title class="login-dialog-title pb-0">
              <h4 class="headline ">Please use the PIN we just sent you</h4>
            </v-card-title>
            <v-card-text>
              <div class="text-subtitle-1 font-weight-light pb-6">We've just sent you the PIN to <span class="font-weight-regular">{{email}}</span>.<br />Please check your mailbox.</div>
              <v-text-field v-model="pin" outlined class="pin-field" type="number" hide-spin-buttons :readonly="verifyingPin"></v-text-field>
              <div class="text-center pin-button" :class="validPin ? 'valid-pin' : 'valid-pin'">
                <v-btn class="white--text elevation-1 rounded-pill pr-4" x-large color="secondary primary--text" :disabled="!validPin || verifyingPin" @click="verifyPin()">{{verifyingPin ? 'Working...' : 'Login'}} <v-progress-circular v-if="verifyingPin" indeterminate size="28" class="ml-2"></v-progress-circular><v-icon v-else class="ml-3">mdi-arrow-right</v-icon></v-btn>
              </div>
            </v-card-text>
          </template>
          <template v-else-if="step === 'reset-password'">
            <v-card-title primary-title class="login-dialog-title">
              <h4 class="headline error-text">Please reset your password</h4>
            </v-card-title>
            <v-card-text>
              <v-text-field v-model="password" outlined :append-icon="hidePassword ? 'mdi-eye' : 'mdi-eye-off'" class="password-field" @click:append="() => (hidePassword = !hidePassword)"  :type="hidePassword ? 'password' : 'text'"></v-text-field>
              <div class="text-center password-button" :class="validPassword ? 'valid-password' : 'valid-password'">
                <v-btn class="white--text elevation-1 rounded-pill pr-4" x-large color="secondary primary--text" :disabled="!validPassword || verifyingPassword" @click="newPassword()">{{verifyingPassword ? 'Working...' : 'Login'}} <v-progress-circular v-if="verifyingPassword" indeterminate size="28" class="ml-2"></v-progress-circular><v-icon v-else class="ml-3">mdi-arrow-right</v-icon></v-btn>
              </div>
            </v-card-text>
          </template>
          <template v-else-if="step === 'register'">
            <v-card-title primary-title class="login-dialog-title">
              <h4 class="headline ">Nice to meet you!</h4>
            </v-card-title>
            <v-card-text>
              <div class="text-subtitle-1 font-weight-light pb-2">This is the first time you are entering as <span class="font-weight-regular">{{email}}</span>.</div>
              <div class="text-subtitle-1 font-weight-light pb-6">By clicking continue, you agree to our <a href="#">terms and conditions</a>.</div>
              <div class="text-center">
                <v-btn class="white--text elevation-1 rounded-pill pr-4" x-large color="secondary primary--text" :disabled="registering" @click="register()">{{registering ? 'Working...' : 'Continue'}} <v-progress-circular v-if="registering" indeterminate size="28" class="ml-2"></v-progress-circular><v-icon v-else class="ml-3">mdi-arrow-right</v-icon></v-btn>
              </div>
            </v-card-text>
          </template>
        </v-card>
        <v-alert color="primary white--text" transition="scale-transition" dark dismissible class="rounded-pill elevation-1 mt-4" v-model="showerr"><v-icon color="white">mdi-alert-circle-outline</v-icon> {{ $t(errmsg) }}</v-alert>
      </div>
    </v-layout>
  </v-container>
</div>
</template>
<style scoped>
.login-dialog-title {
  margin-bottom: 12px;
}
.login-page {
  /*background-color: #b4c1cc;*/
  width: 100%;
  height: 100%;
}
.pin-button,
.password-button,
.email-button {
  transition: height 250ms linear;
  height: 0px;
  /*overflow: hidden;*/
}
.pin-button.valid-pin,
.password-button.valid-password,
.email-button.valid-email {
  height: 58px;
}
.pin-field,
.password-field,
.email-field  {
  font-size: 24px !important;
  font-weight: 300;
}
</style>

<script>
import router from '@/router'
import * as api from '@/libs/api'
import { Auth } from "aws-amplify";

export default {
  name: 'signin-page',
  props: {
    redir: {
      type: String,
      default: () => null
    },
  },
  data() {
    return {       
      hidePassword: true,
      email: '',
      password: '',
      errmsg: null,
      showerr: false,
      step: 'login',
      loading: false,
      resetPassword: null,
      verifyingEmail: false,
      verifyingPassword: false,
      registering: false,
      pin: null,
      verifyingPin: false
    }
  },
  computed: {
    validEmail() {
      if (!this.email || !this.email.length)
        return false;
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (pattern.test(this.email.trim()))
        return true;

      return false;
    },
    validPassword() {
      if (!this.password || !this.password.length)
        return false;

      if (!/(?=.*[a-z])/.test(this.password))
        return false;

      if (!/(?=.*[A-Z])/.test(this.password))
        return false;

      if (!/(?=.*[0-9])/.test(this.password))
        return false;

      if (this.password.length <= 8)
        return false;

      return true;
    },
    validPin() {
      if (!this.pin || !this.pin.length)
        return false;
      if (!/(?=.*[0-9])/.test(this.pin.trim()))
        return false;

      if (this.pin.length < 6)
        return false;

      return true;
    },
  },
  methods: {
    async verifyEmail() {
      if (!this.validEmail)
        return;

      this.verifyingEmail = true;

      try {
        let userInfo = await api.getUserInfo(this.email)
        if (userInfo && userInfo.status && userInfo.status !== 'not-found') {
          if (userInfo.status === 'found') {
            if (userInfo.loginType === 'magic-link') {
              this.step = 'magic-link';
              this.password = userInfo.secret;
              await Auth.forgotPassword(this.email);
            }
            else
              this.step = 'password';
          }
          else if (userInfo.status === 'reset-password')
            this.step = 'password'; // first, enter old password, then change to reset-password step...
        }
        else {
          this.step = 'register';
        }
      }
      catch (err) {
        console.log(err);
      }

      this.verifyingEmail = false;
    },
    async verifyPassword() {
      if (!this.validPassword)
        return;

      this.verifyingPassword = true;
      try {
        let user = await Auth.signIn(this.email, this.password);
        await this.handleLoginSuccess(user);
      }
      catch (err) {
        this.handleLoginError(err);
      }
      this.verifyingPassword = false;
    },
    newPassword() {
      this.verifyingPassword = true;
      this.resetPassword.completeNewPasswordChallenge(this.password, this.resetPassword.userAttributes, {
        onSuccess: this.handleLoginSuccess,
        onFailure: this.handleLoginError
      });
    },
    async verifyPin() {
      this.verifyingPin = true;
      try {
        await Auth.forgotPasswordSubmit(this.email, this.pin, this.password);
        await this.verifyPassword();
      }
      catch (err) {
        console.log(err);
        this.errmsg = "tryAgain";
        this.showerr = true;
      }
      this.verifyingPin = false;
    },
    async register() {
      this.registering = true;
      let password = await api.register(this.email);
      if (!password) {
        this.errmsg = "tryAgain";
        this.showerr = true;
      }
      let result = await Auth.signIn(this.email, password);
      result.completeNewPasswordChallenge(password, result.userAttributes, {
        onSuccess: this.handleLoginSuccess,
        onFailure: this.handleLoginError
      });
      this.registering = false;
    },
    continueStep() {
      if (this.step === 'login' && this.validEmail)
        this.verifyEmail();
      else if (this.step === 'password' && this.validPassword)
        this.verifyPassword();
      else if (this.step === 'reset-password' && this.validPassword)
        this.newPassword();
      else if (this.step === 'magic-link' && this.validPin)
        this.verifyPin();
      /*else if (this.step === 'register')
        this.register();*/
    },
    handleLoginSuccess(result) {
      if (result.challengeName && result.challengeName == "NEW_PASSWORD_REQUIRED") {
        this.resetPassword = result;
        this.password = null;
        this.step = 'reset-password'
        this.verifyingPassword = false;
      }
      else {
        Auth.currentAuthenticatedUser().then(async () => {
          try {
            await api.getUser();
          }
          catch (err) {
            console.log(err);
          }
          this.verifyingPassword = false;
          
          let path = '/';
          if (this.redir)
            path = decodeURIComponent(this.redir);
          router.push(path);
          window.setTimeout(() => {
            window.location.reload();
          }, 300);
        }).catch(() => {
          this.errmsg = "Unknown error";
          this.showerr = true;
          this.verifyingPassword = false;
        });
      }
    },
    handleLoginError(err) {
      console.log(err)
      if (err.code == 'UnknownError') {
        setTimeout(() => {
          this.verifyingPassword = false;
          this.step = 'login';
        }, 2000);
        return;
      }

      if (err.code === "NotAuthorizedException" || err.code === "UserNotFoundException") 
        this.errmsg = 'invalidPassword'
      else
        this.errmsg = 'tryAgain'
      this.step = 'login';
      this.showerr = true;

      this.verifyingPassword = false;
    }
  }
}
</script>

<i18n>
{
  "en": {
    "signIn": "Sign in",
    "tryAgain": "Please, try again",
    "unknownError": "Unknown error",
    "resetPassword": "Please set up new password",
    "invalidPassword": "Invalid email or password"
  }
}
</i18n>