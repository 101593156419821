<template>
<div class="text-left map-widget" :class="editing ? 'editing' : ''">
  <div class="gallery-header hoverable">
    <div v-if="!editing" class="text-h4">{{item.cfg.name}}</div>
    <div v-else>
      <v-text-field :value="item.cfg.name" v-on:change="updateItemConfig(item, () => item.cfg.name = $event)" :placeholder="(parent && parent.items && parent.items.length > 1) && !item.cfg.name ? ('Untitled collection' + (index !== null ? ' ' + (index+1) : '')) : null" flat solo hide-details @mouseup.prevent @mousedown.prevent @click.stop.prevent="nullHandler" class="text-h4 float-left editing"></v-text-field>
      <v-menu :close-on-content-click="false" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon elevation="0" color="primary" v-bind="attrs" v-on="on" class="float-right" style="margin-top: 7px"><v-icon size="24">mdi-dots-vertical</v-icon></v-btn>
        </template>
        <v-card class="pa-5" >
          <div class="text-center">
            <v-btn class="elevation-1 secondary primary--text mx-1" rounded @click="remove()"><v-icon left>mdi-delete</v-icon> {{$t('removeGallery')}}</v-btn>
          </div>
        </v-card>
      </v-menu>
      <v-menu :close-on-content-click="false" offset-y v-if="editing">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="elevation-1 secondary primary--text mx-1 float-right" rounded style="margin-top: 6px"><v-icon left>mdi-plus</v-icon>{{$t('addAssets')}}</v-btn>
        </template>
        <v-card>
          <v-treeview hoverable activatable dense :items="items" item-key="iid" item-children="items" item-text="cfg.name" expand-icon="mdi-chevron-down" @update:active="mapMenu.item = ($event.length ? $event[0] : null)" return-object>    
            <template v-slot:label="{item}">
              <div style="cursor: pointer">{{item.cfg && item.cfg.name ? item.cfg.name : 'Untitled'}} <v-icon color="primary" @click="view(item)">mdi-magnify</v-icon></div>
            </template>
          </v-treeview>
        </v-card>
      </v-menu>
      <div style="clear: both"></div>
  </div>
  <div class="gallery-kv">
    <v-container fluid grid-list-md :class="(!item.kv || !item.kv.length) ? 'pa-0' : ''">
      <draggable class="layout row wrap" :group="{ name: 'kv' }" :animation="200" v-model="item.kv" :disabled="!editing || $vuetify.breakpoint.mdAndDown">
        <v-flex v-for="(kv, k) in item.kv" :key="k" class="xs12 md12" :class="getKVSizeClass(item) + ' ' + ('px-' + ((typeof item.cfg.spacing == 'undefined') ? 1 : item.cfg.spacing))">
          <key-value-item v-model="item.kv[k]" :editing="editing" class="mb-2" @remove="removeKVItem(item, k)" @update="updateKVItem(item, kv)" :rounded="item.cfg.rounded || 0" full colorPrivate="rgba(255,192,192,0.4)" color="rgba(255,255,255,0.3)"></key-value-item>
        </v-flex>
      </draggable>
    </v-container>
  </div>
  <div class="gallery-content" @drop.prevent="dropFiles" @dragenter.prevent @dragover.prevent="dragFiles" @dragleave="dragLeave" :style="'padding: 2px'">
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="12">
          <v-alert color="primary white--text" transition="scale-transition" dark dismissible @input="mapMenu.item = null" class="rounded-pill elevation-1 mt-4" :value="editing && mapMenu.item && !mapMenu.lat"><v-icon color="white">mdi-map-marker</v-icon> Click into the map to place <b>{{mapMenu.item ? (mapMenu.item.cfg.name || 'Untitled') : 'Untitled'}}</b>.</v-alert>
          <GmapAutocomplete v-if="editing" class="mb-2 d-block v-text-field" @place_changed="setPlace" style="width: 100%"></GmapAutocomplete>
          <GmapMap ref="mapRef" :center="center" :zoom="13" :options="{streetViewControl: true}" @click="placeMapItem($event)" @rightclick="mapRightClick" @center_changed="updateCenter($event)" style="width: 100%; height: 70vh">
            <template v-if="markers && markers.length">
              <GmapMarker v-for="(marker, i) in markers" :key="i" :position="marker" @click="opened = (opened === i ? null : i)">
                <GmapInfoWindow v-if="marker.item && opened===i"  :opened="opened === i">
                  <div style="width: 200px; height: 248px; position: relative;" class="columns-6 hoverable">
                    <v-avatar color="rgba(255,255,255,0.5)" size="24" style="position: absolute; top: 2px; right: 2px; z-index: 4"><v-icon @click="opened = null" color="primary" size="20">mdi-close</v-icon></v-avatar>
                    <v-avatar color="rgba(255,255,255,0.5)" size="24" style="position: absolute; top: 30px; right: 2px; z-index: 4" class="show-on-hover-ex"><v-icon @click="removeFromMap(marker.item)" color="primary" size="20">mdi-trash-can</v-icon></v-avatar>
                    <gallery-item v-model="marker.item" :parent="item" :index="0" :cfg="{ showNames: true, rounded: 0, elevation: 0, aspectRatio: 10, cover: true, showLikes: false, showComments: false, size: 2 }"></gallery-item>
                  </div>
                </GmapInfoWindow>
              </GmapMarker>
            </template>
          </GmapMap>
        </v-col>
      </v-row>
    </v-container>
    <v-menu v-model="mapMenu.show" :position-x="mapMenu.x" :position-y="mapMenu.y" absolute offset-y :max-width="400" style="z-index: 1000">
      <v-card class="pa-0" width="350">
        <v-treeview hoverable activatable dense :items="items" item-key="iid" item-children="items" item-text="cfg.name" expand-icon="mdi-chevron-down" @update:active="addMapItem($event)" return-object>    
          <template v-slot:label="{item}">
            <div style="cursor: pointer">{{item.cfg && item.cfg.name ? item.cfg.name : 'Untitled'}}</div>
          </template>
        </v-treeview>
      </v-card>
    </v-menu>
  </div>
</div>
</div>
</template>
<i18n>
{
  "en": {
    "smallThumbnails": "Small thumbnails"
  }
}
</i18n>
<style>
.add-gallery-asset {
  opacity: 0;
  transition: opacity 200ms linear;
}
.mobile .add-gallery-asset {
  opacity: 1 !important;
}
.map-widget:hover .add-gallery-asset {
  opacity: 0.5;
}
.map-widget .add-gallery-asset:hover {
  opacity: 1;
}
.plus-sign {
  margin-top: 0px; 
  position: absolute; 
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
}
.plus-sign.item-show-names {
  margin-top: 28px; 
}
.map-widget .text-h4 {
  font-weight: 300;
  white-space: nowrap;
}
.map-widget .gm-style-iw {
  padding-left: 0px;
  padding-top: 0px;
}
.map-widget .gm-style-iw-d {
  overflow: hidden !important;
}
.map-widget .gm-style-iw-d .v-card__actions {
  min-height: 48px !important;
}
.map-widget .gm-style-iw-t .gm-ui-hover-effect {
  display: none !important;
}
.map-widget {
  background-color: rgba(255,255,255,0.4);
  transition: background-color 200ms linear;
}
.map-widget:hover {
  background-color: rgba(255,255,255,0.7);
}
</style>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import draggable from 'vuedraggable'
import * as api from '@/libs/api.js'
import * as tools from '@/libs/tools.js'
import { EventBus } from '@/libs/eventBus.js'
import { getCanvasItemMap } from '@/libs/canvas.js'
import ListItemWidget from '@/components/canvas-items/list-item-widget'
import CanvasGalleryItem from '@/components/canvas-items/canvas-gallery-item'
import KeyValueItem from '@/components/widgets/key-value-item'
import LayoutList from '@/components/widgets/layout-list'
import { gmapApi } from 'vue2-google-maps'
export default {
  name: 'map-widget',
  components: {
    'draggable': draggable,
    'list-item-widget': ListItemWidget,
    'gallery-item': CanvasGalleryItem,
    'key-value-item': KeyValueItem,
    'layout-list': LayoutList,
  },
  props: {
    value: {
      type: Object,
      default: () => null
    },
    parent: {
      type: Object,
      default: () => null
    },
    index: {
      type: Number,
      default: () => null
    },
    preview: {
      type: Boolean, 
      default: () => false
    },
    editing: {
      type: Boolean, 
      default: () => false
    }
  },
  data: () => {
    return {
      opened: null,
      dragging: false,
      mapMenu: {
        show: false,
        x: null,
        y: null,
        lat: null,
        lng: null,
        item: null
      },
      reportedCenter: { lat: 50.08, lng: 14.45 },
      center: {lat: 50.08, lng: 14.45}
    }
  },
  mounted() {
    this.updateBounds();
  },
  watch: {
    markers() {
      this.updateBounds();
    }
  },
  computed: {
    item: {
      get: function() { return this.value; },
      set: function(value) { this.$emit('input', value); }
    },
    items() {
      if (!this.item || !this.item.iid || !this.$store.state.canvas.activeRoot || !(this.$store.state.canvas.activeRoot in getCanvasItemMap(this.$store.state, this.item.cid)))
        return [];

      return getCanvasItemMap(this.$store.state, this.item.cid)[this.$store.state.canvas.activeRoot].items;
    },
    markers() {
      if (!this.item || !this.item.items || !this.item.items.length)
        return [];

      let markers = [];
      for (let i in this.item.items) {
        let child = this.item.items[i];
        if (!child.kv || !child.kv.length)
          continue;

        for (let kv of child.kv) {
          if (kv.type !== 'map' || !kv.value || !kv.value.lat || !kv.value.lng)
            continue;
          markers.push({item: child, index: parseInt(i), lat: kv.value.lat, lng: kv.value.lng});
        }
      }
      return markers;
    },
  },
  methods: {
    async updateItemConfig(widget, functor) {
      if (functor)
        functor();
      if (widget.iid)
        await api.updateItem(widget.cid, widget.iid, { cfg: widget.cfg });
    },
    async updateItemType(widget, type) {
      if (!widget || !widget.iid || !type)
        return;

      widget.type = type;

      await api.updateItem(widget.cid, widget.iid, { t: type });
    },
    async updateItemSorting(widget) {
      let i = [];
      for (let item of widget.items)
        i.push(item.iid);

      await api.updateItem(widget.cid, widget.iid, { i: i })
    },
    addAssets(widget, files = null) {
      if (!widget)
        widget = this.item;

      this.$store.state.dialogs.addAssets = {
        show: true, 
        path: tools.getCanvasFullPath(this.$store.state.canvas.cfg.path, widget.cid),
        targetWidget: widget,
        files: files
      }
    },
    checkChildren() {
      if (!this.item.items || !this.item.items.length)
        return;

      const check = !this.item.items[0].checked;

      for (let item of this.item.items)
        item.checked = check;
    },
    async remove() {
      let parent = this.parent;
      if (!parent || !parent.items)
        return;
      let index = parent.items.findIndex(item => item.iid == this.item.iid);
      parent.items.splice(index, 1);
      this.$emit('updateParent', parent);

      if (this.parent.iid && this.item.iid && this.item.cid) {
        try {
          await api.removeItem(this.item.cid, this.parent.iid, this.item.iid, false);
        }
        catch (err) {
          console.log(err);
        }
      }
    },
    getListItemConfig() {
      return { 
        rounded: ('rounded' in this.item.cfg) ? this.item.cfg.rounded : 0, 
        elevation: ('elevation' in this.item.cfg) ? this.item.cfg.elevation : 1,
        aspectRatio: ('aspectRatio' in this.item.cfg) ? this.item.cfg.aspectRatio : 10, 
        showNames: ('showNames' in this.item.cfg) ? this.item.cfg.showNames : false,
        cover: true, 
        showLikes: true,
        showComments: true,
        size: ('size' in this.item.cfg) ? this.item.cfg.size : 2, 
      }
    },
    getColumnCount(widget) {
      if (!this.$vuetify.breakpoint.mdAndUp)
        return 1;

      const sizes = [4,4,6,6,12,12];
      let size = 4;
      if (widget.cfg.size !== null && widget.cfg.size >= 0 && widget.cfg.size < sizes.length)
        size = sizes[widget.cfg.size];

      if (this.preview && size < 6)
        size = 6;

      return Math.floor(12 / size);
    },
    getRoundedClass(widget) {
      if (!widget || !widget.cfg || !widget.cfg.rounded)
        return '';

      switch (widget.cfg.rounded) {
        case 1: return 'rounded-sm';
        case 2: return 'rounded';
        case 3: return 'rounded-lg';
        case 4: return 'rounded-xl';
        case 5: return 'rounded-pill';
      }

      return 'rounded';
    },
    getThumnailSize(widget) {
      if (!widget || !widget.cfg)
        return 6;
      if (widget.cfg.size <= 4)
        return 4;
      else if (widget.cfg.size <= 6)
        return 5;
      else 
        return 6;
    },
    childrenHasMaps() {
      if (!this.item || !this.item.items || !this.item.items.length)
        return false;

      for (let child of this.item.items) {
        if (!child.kv || !child.kv.length)
          continue;

        for (let kv of child.kv) {
          if (kv.type !== 'map' || !kv.value || !kv.value.lat || !kv.value.lng)
            continue;
          return true
        }
      }
      return false;
    },
    getGalleryTypeMenuItems() {
      return [{t:'Grid', v:'gallery'}, {t:'List', v:'list'}, {t:'Table', v:'file-table'}, {t:'Map', v:'map-list'}];
    },
    async createKVItem(item, type) {
      if (!item)
        return;

      const cid = item.cid;
      const iid = item.iid;
      const key = null;
      let value = null;
      if (!item.kv)
        item.kv = [];
      item.kv.push({ key: key, type: type, value: value, cfg: { hideCaption: true } });
      item.kv[item.kv.length-1].kvid = await api.createKeyValueItem(cid, iid, key, value, type);
      
    },
    async updateKVItem(item, kvItem) {
      if (!this.editing)
        return;

      if (item.iid && kvItem.kvid) {
        let updateItem = async () => await api.updateKeyValueItem(this.$store.state.canvas.cid, item.iid, kvItem.kvid, { k: kvItem.key, v: kvItem.value, cfg: kvItem.cfg });
        EventBus.$emit('updateItem', {
          id: kvItem.kvid,
          funct: updateItem
        });
      }
    },
    async removeKVItem(item, index) {
      if (!item || !item.kv || index >= item.kv.length || index < 0)
        return;

      const kvid = item.kv[index].kvid;

      item.kv.splice(index, 1);

      await api.removeKeyValueItem(item.cid, item.iid, kvid);
    },
    getKVSizeClass(item) {
      if (!item.cfg || !item.cfg.kvSize) 
        return 'lg12';

      switch (item.cfg.kvSize) {
        case 1: return 'lg12';
        case 2: return 'lg6';
        case 3: return 'lg4';
        case 4: return 'lg3';
      }

      return 'lg12';
    }, 
    dragFiles(e) {
      e.stopPropagation();
      e.preventDefault();
      if (!e || !e.dataTransfer || !e.dataTransfer.files)
        return;

      e.dataTransfer.dropEffect = 'copy';
      this.dragging = true;
    },
    dropFiles(e) {
      if (!e || !e.dataTransfer || !e.dataTransfer.files || !e.dataTransfer.files.length)
        return;

      this.dragging = false;  
      this.addAssets(this.item, e.dataTransfer.files)
    },
    dragLeave() {
      this.dragging = false;
    },
    mapRightClick(mouseArgs) {
      this.mapMenu.x = mouseArgs.domEvent.x;
      this.mapMenu.y = mouseArgs.domEvent.y;
      this.mapMenu.lat = mouseArgs.latLng.lat();
      this.mapMenu.lng = mouseArgs.latLng.lng();
      this.mapMenu.show = true;
    },
    async addMapItem(i) {
      let clear = () => {
        this.mapMenu.item = null;
        this.mapMenu.x = null;
        this.mapMenu.y = null;
        this.mapMenu.lat = null;
        this.mapMenu.lng = null;
        this.mapMenu.show = false;
      }

      if (!i.length) {
        clear();
        return;
      }

      let item = i[0];

      if (item.type === 'map' || (this.item.items && this.item.items.find(i => i.iid === item.iid))) {
        clear();
        return;
      }

      let value = { lat: this.mapMenu.lat, lng: this.mapMenu.lng, parent: this.item.iid};

      if (!item.kv)
        item.kv = [];
      item.kv.push({ key: null, type: 'map', value: value, cfg: {} });
      item.kv[item.kv.length-1].kvid = await api.createKeyValueItem(item.cid, item.iid, null, value, 'map');

      if (!this.item.items)
        this.item.items = [];
      this.item.items.push(item);
      await api.updateItem(this.item.cid, this.item.iid, { i: this.item.items.map(item => item.iid) });

      clear();
    },
    async placeMapItem(mouseArgs) {
      if (!this.mapMenu.item || this.mapMenu.lat)
        return;

      let clear = () => {
        this.mapMenu.item = null;
        this.mapMenu.x = null;
        this.mapMenu.y = null;
        this.mapMenu.lat = null;
        this.mapMenu.lng = null;
        this.mapMenu.show = false;
      }

      if (this.mapMenu.item.type === 'map' || (this.item.items && this.item.items.find(i => i.iid === this.mapMenu.item.iid))) {
        clear();
        return;
      }
      
      let value = { lat: mouseArgs.latLng.lat(), lng: mouseArgs.latLng.lng(), parent: this.item.iid};

      if (!this.mapMenu.item.kv)
        this.mapMenu.item.kv = [];
      this.mapMenu.item.kv.push({ key: null, type: 'map', value: value, cfg: {} });
      this.mapMenu.item.kv[this.mapMenu.item.kv.length-1].kvid = await api.createKeyValueItem(this.mapMenu.item.cid, this.mapMenu.item.iid, null, value, 'map');
      
      if (!this.item.items)
        this.item.items = [];
      this.item.items.push(this.mapMenu.item);
      await api.updateItem(this.item.cid, this.item.iid, { i: this.item.items.map(item => item.iid) });

      clear();
    },
    updateCenter(event) {
      this.reportedCenter = {
        lat: event.lat(),
        lng: event.lng(),
      };
    },
    updateBounds() {
      this.$refs.mapRef.$mapPromise.then((map) => {
        let google = gmapApi();
        if (google) {
          let bounds = new google.maps.LatLngBounds();
          if (this.markers.length)
            for (let marker of this.markers)
              bounds.extend(new google.maps.LatLng(marker.lat, marker.lng));
          else {
            bounds.extend(new google.maps.LatLng(50.103603, 14.3214146));
            bounds.extend(new google.maps.LatLng(50.02311, 14.5629771));
          }

          map.fitBounds(bounds);
        }
      });
    },
    setPlace(place) {
      if (!place.geometry)
        return;

      this.$refs.mapRef.$mapPromise.then((map) => {
        map.panTo({lat: place.geometry.location.lat(), lng: place.geometry.location.lng()})
      });
    },
    view(item) {
      if (!item.parent)
        return;

      let parent = getCanvasItemMap(this.$store.state, item.cid)[item.parent];
      let index = 0
      for (let child of parent.items) {
        if (child.iid === item.iid)
          break;
        index++;
      }
      EventBus.$emit('showViewDialog', { items: parent.items, index: index, showDescription: true, widget: parent, editing: true });
    },
    async removeFromMap(item) {
      this.item.items = this.item.items.filter(i => i.iid === item.iid);
      await api.updateItem(this.item.cid, this.item.iid, { i: this.item.items.map(i => i.iid) });
    },
    nullHandler() {

    }
  }
}
</script>