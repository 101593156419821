<template>
<div style="height: 100%">
<div class="text-left casting-roles" :class="editing ? 'editing' : ''">
  <div class="gallery-header">
    <v-btn v-if="editing" class="elevation-1 secondary primary--text mx-1 float-right" rounded @click="addRole()" style="margin-top: 6px">
      <v-icon left>mdi-plus</v-icon> 
      <span>Add role</span>
    </v-btn>
    <div style="clear: both"></div>
  </div>
  <div class="gallery-content">
    <v-container fluid class="pt-0">
      <template v-if="item.items && item.items.length">
        <draggable class="row no-gutters" :class="(item.cfg.centered ? 'justify-center' : '') + (item.cfg.layoutClass ? item.cfg.layoutClass : '')" v-model="item.items" :animation="200" :group="{ name: 'items' }" @change="updateItemSorting(item)" :disabled="!editing || $vuetify.breakpoint.mdAndDown">
          <v-col v-for="(child, i) in item.items" :key="(child.iid && child.iid.length) ? child.iid : i" class="pa-1" cols="6" sm="3">
            <div style="height: 100%">
              <v-card class="role-list-item rounded pa-0 fill-height" :elevation="1" light>
                <v-card-title class="pa-0">
                  <h4 v-if="!editing" class="font-weight-light text-h5 pl-2 py-0">{{child.cfg.name}}</h4>
                  <v-text-field v-else flat solo hide-details v-model="child.cfg.name" :placeholder="!child.cfg.name ? 'Untitled role' : null" class="editing simple-list-item-caption text-h5 pl-2 py-0 font-weight-light" v-on:change="updateItemConfig(child, () => child.cfg.name = $event)" style="width: calc(100% - 50px)"></v-text-field>
                  <v-btn v-if="editing" icon elevation="0" color="primary" class="float-right" :class="itemsChecked ? null : 'show-on-hover'" style="margin-top: 7px" @click.stop.prevent="child.checked = !child.checked"><v-icon size="24">{{child.checked ? `mdi-check-circle` : `mdi-checkbox-blank-circle-outline`}}</v-icon></v-btn>
                  <div style="clear-both"></div>
                </v-card-title>
                <v-tabs v-model="tabs[child.iid]" bg-color="primary">
                  <v-tab>Cast</v-tab>
                  <v-tab>Brief</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tabs[child.iid]" style="min-height: 420px">
                  <v-tab-item>
                    <v-list v-if="child.items" class="simple-list-item-list pa-0">
                      <draggable :group="{ name: 'item' }" :animation="200" v-model="child.items" :disabled="!editing || $vuetify.breakpoint.mdAndDown" @change="updateItemSorting(child)">
                        <template v-if="child.items.length">
                          <template v-for="(subchild, i) in child.items">
                            <div :key="subchild.iid">
                              <v-divider></v-divider>
                              <v-list-item class="px-1 hoverable-ex" @click="viewItem(subchild, child, i)">
                                <v-list-item-avatar tile size="48px" class="my-1">
                                  <canvas-item-thumbnail single small v-model="child.items[i]" :config="{rounded: 0, elevation: 0, aspectRatio: 10, cover: true}" style="width: 100%; height: 100%;"></canvas-item-thumbnail>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title>{{ subchild.cfg.name || 'Untitled' }} <v-icon v-if="subchild.items && subchild.items.length" small>mdi-chevron-right</v-icon></v-list-item-title>
                                  <v-list-item-subtitle style="font-size: .9em"><span>{{subchild.status || 'Added'}}</span></v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action v-if="editing">
                                  <v-btn icon small elevation="0" color="primary" :class="itemsChecked ? null : 'show-on-hover-ex'" @click.stop.prevent="subchild.checked = !subchild.checked"><v-icon size="20">{{subchild.checked ? `mdi-check-circle` : `mdi-checkbox-blank-circle-outline`}}</v-icon></v-btn>
                                </v-list-item-action>
                              </v-list-item>
                            </div>
                          </template>
                        </template>
                      </draggable>
                    </v-list>
                  </v-tab-item>
                  <v-tab-item>
                    <vue-editor v-if="editing" v-model="briefs[child.iid]" @text-change="updateBrief(briefs[child.iid], child.iid)" :editorToolbar="[['bold', 'italic', 'underline', 'strike'],['clean']]" :editorOptions="{ theme: 'snow'}" class="role-brief"></vue-editor>
                    <div v-else v-html="briefs[child.iid]"></div>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </div>
          </v-col>
        </draggable>
      </template>
      <v-layout align-center v-else-if="editing && (!item.kv || !item.kv.length)">
        <v-flex xs12 sm12 md12 class="text-center primary--text">
          <p class="text-h5" style="font-weight: 300">{{$t('emptySection')}}</p>
          <v-btn class="mt-3 mb-8 elevation-1 secondary primary--text pr-4" x-large rounded @click="addAssets(item)"><v-icon left size="24" class="mr-3">mdi-plus</v-icon> <span>Add actors</span> </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</div>
</div>
</template>
<style>
.casting-roles {
  background-color: rgba(255,255,255,0.4);
  transition: background-color 200ms linear;
}
.casting-roles:hover {
  background-color: rgba(255,255,255,0.7);
}
.casting-roles .text-h4 {
  font-weight: 300;
  white-space: nowrap;
}
.casting-roles .v-tab {
  letter-spacing: initial;
  text-transform: initial;
  min-width: initial;
}
.casting-roles .v-tabs-bar {
  height: 36px;
}
.casting-roles .role-brief .ql-editor {
  min-height: 372px;
}
.casting-roles .ql-container {
  border: initial !important;
}
.casting-roles .ql-toolbar {
  border-left: none !important;
  border-right: none !important;
}
</style>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import draggable from 'vuedraggable'
import * as api from '@/libs/api.js'
import * as tools from '@/libs/tools.js'
import { EventBus } from '@/libs/eventBus.js'
import { getCanvasItemMap } from '@/libs/canvas.js'
import CanvasItemThumbnail from '@/components/canvas-items/canvas-thumbnail'
import { VueEditor } from "vue2-editor";

export default {
  name: 'gallery-widget',
  components: {
    'draggable': draggable,
    'canvas-item-thumbnail': CanvasItemThumbnail,
    'vue-editor': VueEditor,
  },
  props: {
    value: {
      type: Object,
      default: () => null
    },
    parent: {
      type: Object,
      default: () => null
    },
    index: {
      type: Number,
      default: () => null
    },
    preview: {
      type: Boolean, 
      default: () => false
    },
    editing: {
      type: Boolean, 
      default: () => false
    }
  },
  data: () => {
    return {
      creatingSection: false,
      tabs: {},
      briefs: {}
    }
  },
  computed: {
    item: {
      get: function() { return this.value; },
      set: function(value) { this.$emit('input', value); }
    },
    unassignedMaps() {
      if (!this.item || !this.item.items || !this.item.items.length || !(this.item.parent in getCanvasItemMap(this.$store.state, this.item.cid)))
        return [];
      
      let parentItems = getCanvasItemMap(this.$store.state, this.item.cid)[this.item.parent].items;
      if (!parentItems || !parentItems.length)
        return [];

      let unassignedMaps = [];
      for (let child of this.item.items) {
        if (!child.kv || !child.kv.length)
          continue;

        for (let kv of child.kv) {
          if (kv.type !== 'map' || !kv.value || !kv.value.lat || !kv.value.lng)
            continue;

          unassignedMaps.push(child);
          break;
        }
      }

      if (!unassignedMaps.length)
        return [];

      for (let sibling of parentItems) {
        if (sibling.type !== 'map' || !sibling.items || !sibling.items.length)
          continue;

        // found the map, let's check for any item with associated map that hasn't been added to this map
        for (let siblingChild of sibling.items) {
          let index = unassignedMaps.findIndex(i => i.iid === siblingChild.iid);
          if (index < 0)         
            continue;

          unassignedMaps.splice(index, 1);
        }
      }

      return unassignedMaps;
    },
    itemsChecked() {
      return this.$store.state.canvas.checkedCount > 0;
    },
  },
  methods: {
    async updateItemConfig(widget, functor) {
      if (functor)
        functor();
      if (widget.iid)
        await api.updateItem(widget.cid, widget.iid, { cfg: widget.cfg });
    },
    async updateItemSorting(widget) {
      let i = [];
      for (let item of widget.items)
        i.push(item.iid);

      await api.updateItem(widget.cid, widget.iid, { i: i });
    },
    addAssets(widget, files = null) {
      this.$store.state.dialogs.addAssets = {
        show: true, 
        path: tools.getCanvasFullPath(this.$store.state.canvas.cfg.path, widget.cid),
        targetWidget: widget,
        files: files
      }
    },
    checkChildren() {
      if (!this.item.items || !this.item.items.length)
        return;

      const check = !this.item.items[0].checked;

      for (let item of this.item.items)
        item.checked = check;
    },
    getGridSizeClass(widget) {
      const sizes = [1,2,3,4,6,12];
      let size = 4;
      if (widget.cfg.size !== null && widget.cfg.size >= 0 && widget.cfg.size < sizes.length)
        size = sizes[widget.cfg.size];

      if (this.preview && size < 4)
        size = 4;

      if (size >= 6)
        return 'xs12 sm' + size;

      if (size >= 3)
        return 'xs12 sm6 md' + size;
      
      return 'xs12 sm6 md3 lg' + size;
    },
    getKVSizeClass(item) {
      if (!item.cfg || !item.cfg.kvSize) 
        return 'lg12';

      switch (item.cfg.kvSize) {
        case 1: return 'lg12';
        case 2: return 'lg6';
        case 3: return 'lg4';
        case 4: return 'lg3';
      }

      return 'lg12';
    }, 
    getColumnCount(widget) {
      if (!this.$vuetify.breakpoint.smAndUp)
        return 1;

      const sizes = [1,2,3,4,6,12];
      let size = 4;
      if (widget.cfg.size !== null && widget.cfg.size >= 0 && widget.cfg.size < sizes.length)
        size = sizes[widget.cfg.size];

      if (this.preview && size < 4)
        size = 4;

      if (size >= 6)
        return Math.floor(12 / size);

      if (this.$vuetify.breakpoint.smOnly)
        return 2;

      if (size >= 3)
        return Math.floor(12 / size);

      if (this.$vuetify.breakpoint.mdOnly)
        return 4;

      return Math.floor(12 / size);
    },
    async remove() {
      let parent = this.parent;
      if (!parent || !parent.items)
        return;
      let index = parent.items.findIndex(item => item.iid == this.item.iid);
      parent.items.splice(index, 1);
      this.$emit('updateParent', parent);

      if (this.parent.iid && this.item.iid && this.item.cid) {
        try {
          await api.removeItem(this.item.cid, this.parent.iid, this.item.iid)
        }
        catch (err) {
          console.log(err);
        }
      }
    },
    childrenHasMaps() {
      if (!this.item || !this.item.items || !this.item.items.length)
        return false;

      for (let child of this.item.items) {
        if (!child.kv || !child.kv.length)
          continue;

        for (let kv of child.kv) {
          if (kv.type !== 'map' || !kv.value || !kv.value.lat || !kv.value.lng)
            continue;
          return true
        }
      }
      return false;
    },
    getGalleryTypeMenuItems() {
      let items = [{t:'Grid', v:'gallery'}, {t:'List', v:'list'}, {t:'Table', v:'file-table'}];
      return items;
    },
    getItemTypeMenuItems() {
      let items = [{t:'Big thumbnails', v:'big'}, {t:'Thumbnails with text', v:'text'}, {t:'List', v:'list'}];
      return items;
    },
    toggleMapOffer(value) {
      this.showMapOffer = value;
      if (value === false) {
        localStorage.setItem("mapOffer-" + this.item.iid, false);
      }
    },
    async addUnassignedToMap() {
      let parent = getCanvasItemMap(this.$store.state, this.item.cid)[this.item.parent];
      if (!parent.items || !parent.items.length)
        return;

      const addToMap = async (items, map) => {
        if (!map.items) map.items = [];
        map.items.push(...items);
        await api.updateItem(map.cid, map.iid, { i: map.items.map(item => item.iid) } );
      }

      for (let sibling of parent.items) {
        if (sibling.type !== 'map')
          continue;

        await addToMap(this.unassignedMaps, sibling);
        return;
      }

      
      // no map widget found amongst siblings
      try {
        let map = { type: 'map', parent: this.item.parent, cid: this.item.cid, cfg: { name: '', aspectRatio: 10, cover: true, outlined: false, rounded: false, elevation: 1, spacing: 1, showNames: true, size: 1}, kv: [], checked: false, comments: [] }
        map.iid = await api.createItem(map.cid, map.parent, map.type, map.cfg);
        getCanvasItemMap(this.$store.state, map.cid)[map.iid] = map;
        parent.items.push(map);

        await addToMap(this.unassignedMaps, map);
      }
      catch (err) {
        console.log(err);
      }
    },
    async createKVItem(item, type) {
      if (!item)
        return;

      const cid = item.cid;
      const iid = item.iid;
      const key = null;
      let value = null;
      if (!item.kv)
        item.kv = [];
      item.kv.push({ key: key, type: type, value: value, cfg: { hideCaption: true } });
      item.kv[item.kv.length-1].kvid = await api.createKeyValueItem(cid, iid, key, value, type);
      
    },
    async updateKVItem(item, kvItem) {
      if (!this.editing)
        return;

      if (item.iid && kvItem.kvid) {
        let updateItem = async () => await api.updateKeyValueItem(this.item.cid, item.iid, kvItem.kvid, { k: kvItem.key, v: kvItem.value, cfg: kvItem.cfg });
        EventBus.$emit('updateItem', {
          id: kvItem.kvid,
          funct: updateItem
        });
        //this.itemUpdateQueue[kvItem.kvid] = updateItem;
      }
    },
    async removeKVItem(item, index) {
      if (!item || !item.kv || index >= item.kv.length || index < 0)
        return;

      const kvid = item.kv[index].kvid;

      item.kv.splice(index, 1);

      await api.removeKeyValueItem(item.cid, item.iid, kvid);
    },
    async createSection() {
      this.creatingSection = true;
      try {
        let gallery = { type: 'gallery', parent: this.item.iid, cid: this.item.cid, cfg: { name: '', aspectRatio: 10, cover: true, outlined: false, rounded: false, elevation: 1, spacing: 1, showNames: true, size: 1}, kv: [], checked: false, comments: [] }
        gallery.iid = await api.createItem(gallery.cid, this.item.iid, gallery.type, gallery.cfg);
        getCanvasItemMap(this.$store.state, gallery.cid)[gallery.iid] = gallery;
        this.item.items.push(gallery);
      }
      catch (err) {
        console.log(err);
      }
      this.creatingSection = false;
    },
    isGroupType(type) {
      return ['canvas', 'gallery', 'list', 'map-list', 'map', 'file-table', 'casting-profile'/*, 'link'*/].includes(type);
    },
    viewItem(item, parent, index) {
      let type = 'img';

      if (item.type === 'file' && !item.thumbSrc)
        type = 'new-tab';

      else if (this.isGroupType(item.type))
        type = 'group'

      if (type === 'new-tab')
        return this.openInNewTab(item);

      else if (type === 'group')
        return this.viewGroup(item, parent);

      this.view(index, parent);
    },
    openInNewTab(item) {
      if (!item.src)
        return;
      window.open(item.src, '_blank').focus();
    },
    view(index, parent) {
      EventBus.$emit('showViewDialog', { items: parent.items, index: index, widget: parent, editing: this.editing });
    },
    viewGroup(item, parent) {
      EventBus.$emit('navigateTo', { item: item, parent: parent });
    },
    async addRole() {
      if (!this.item)
        return;

      let gallery = { type: 'gallery', parent: this.item.iid, cid: this.item.cid, items:[], cfg: { name: '', aspectRatio: 10, cover: true, outlined: false, rounded: false, elevation: 1, spacing: 1, showNames: true, size: 1}, kv: [], checked: false, comments: []};
      gallery.iid = await api.createItem(gallery.cid, this.item.iid, gallery.type, gallery.cfg);
      getCanvasItemMap(this.$store.state, gallery.cid)[gallery.iid] = gallery;
      this.item.items.push(gallery);
    },
    updateBrief(brief, iid) {

    },
    nullHandler() {

    }
  }
}
</script>