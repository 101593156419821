<template>
  <div>
    <v-expansion-panels v-model="fileGroupsPanel" multiple flat hover class="text-left">
      <v-expansion-panel v-for="(group, i) in files" :key="i">
        <v-expansion-panel-header color="#edf0f2"><div class="one-line">{{group.length > 1 ? (`${group.length} files (${getGroupSize(group)})`) : (group[0].name)}}</div> <v-progress-linear v-if="getGroupProgress(i) < 100" :value="getGroupProgress(i)" color="secondary" rounded height="5"></v-progress-linear></v-expansion-panel-header>
        <v-expansion-panel-content color="#edf0f2">
          <v-list color="transparent" dense>
            <v-list-item v-for="(file, f) in group" :key="f">
              <v-list-item-icon class="mr-2"><v-icon>{{getMimeTypeIcon(file.type)}}</v-icon></v-list-item-icon>
              <v-list-item-content><v-list-item-title>{{file.name}}</v-list-item-title></v-list-item-content>
              <v-list-item-action style="width: 48px" class="text-center">
                <v-icon v-if="file.error" color="error" class="mx-auto d-block">mdi-alert-circle-outline</v-icon>
                <v-progress-linear v-else-if="!progress[`${i}:${f}`] || progress[`${i}:${f}`] < 100" color="secondary" :value="progress[`${i}:${f}`] || 0" rounded height="5"></v-progress-linear>
                <v-icon v-else class="mx-auto d-block">mdi-check-circle-outline</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <input type="file" ref="inputfile" name="files" accept="*" style="display: none" @change="setFiles" multiple="multiple" />
    <input type="file" ref="inputfolder" name="files" accept="*" style="display: none" @change="setFiles" multiple webkitdirectory directory />
    <template v-if="!files.length">
      <v-btn x-large rounded elevation="1" class="mx-auto mt-6 d-block secondary primary--text" @click="pickFile" :loading="uploading" :disabled="uploading">{{ $t('selectFiles') }}</v-btn>
      <v-btn text rounded elevation="0" class="mx-auto mt-3 d-block primary--text" @click="pickFolder" :disabled="uploading">{{ $t('selectFolder') }}</v-btn>
    </template>
    <template v-else>
      <slot name="finish-button" v-bind:disabled="uploading"></slot>
      <v-divider class="mt-3"></v-divider>
      <div>
        <v-btn text rounded elevation="0" class="mx-auto mt-0 d-inline-block primary--text" :disabled="uploading" @click="pickFile">{{ $t('selectMoreFiles') }}</v-btn>
        <div class="d-inline-block"><span style="line-height: 32px">{{ $t('or') }}</span></div>
        <v-btn text rounded elevation="0" class="mx-auto mt-0 d-inline-block primary--text" :disabled="uploading" @click="pickFolder">{{ $t('anotherFolder') }}</v-btn>
      </div>
    </template>
  </div>
</template>

<style>
</style>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
import { EventBus } from '@/libs/eventBus.js'
import { Storage } from "aws-amplify"
import * as api from '@/libs/api.js'
import { getCanvasItemMap } from '@/libs/canvas.js'

export default {
  name: 'add-assets-widget',
  props: {
    value: {
      type: Object,
      default: () => null
    },
    createCanvas: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      render: 0,
      path: "",
      files: [],
      fileGroupsPanel: [],
      name: null,
      uploading: false,
      creatingCollect: false,
      progress: {}
    }
  },
  mounted() {
    EventBus.$on('uploadFileProgress', async (payload) => {
      let progress = payload.progress;
      let file = payload.file;
      let group = payload.group;
      if (group < 0 || group >= this.files.length)
        return;

      if (file < 0 || file >= this.files[group].length)
        return;

      this.files[group][file].progress = progress;

      this.$set(this.progress, `${group}:${file}`, progress);

      this.render++;
    });
  },
  computed: {
    targetWidget: {
      get: function() { return this.value; },
      set: function(value) { this.$emit('input', value); }
    }
  },
  methods: {
    normalizeString(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    clear() {
      this.files = [];
      this.fileGroupsPanel = [];
      this.uploading = false;
    },
    async setFiles(e) {
      if (!e || !e.target || !e.target.files)
        return;

      await this.uploadFiles(e.target.files);
    },
    async uploadFiles(files) {
      let targetWidget = this.targetWidget;

      this.uploading = true;

      if (!this.targetWidget && this.createCanvas) {
        let canvas = await this.createEmptyCanvas();
        if (!canvas) {
          this.uploading = false;
          return;
        }
        targetWidget = canvas.root;
        this.targetWidget = canvas.root;
      }

      if (targetWidget.type === 'root') {
        const aspectRatio = await this.getAverageAspectRatio(files);
        targetWidget = await this.createEmptyGallery(aspectRatio, files);
      }

      if (targetWidget && !targetWidget.items)
        targetWidget.items = [];
      
      let group = {};

      for (let i = 0; i < files.length; ++i) {
        let file = files.item(i)
        let key = '~';
        if (file.webkitRelativePath) {
          let i = file.webkitRelativePath.lastIndexOf('/');
          if (i >= 0) {
            key = file.webkitRelativePath.substring(0, i);
          }
        }
        key = key.replace('/', ' ');
        if (!(key in group))
          group[key] = [];

        group[key].push(file);
        const type = this.mimeToType(file.type);
        targetWidget.items.push({type: type, loading: true, iid: i, cfg: {name: ''}, parent: targetWidget.iid, cid: targetWidget.cid, checked: false});
      }

      let g = this.files.length;

      // first, populate the lists
      for (let key in group) {
        if (!group[key].length)
          continue;

        this.files.push(group[key]);
      }

      for (let key in group) {
        if (!group[key].length)
          continue;

        // Expand panel with currently uploaded group
        this.fileGroupsPanel = [g];

        for (let f in group[key]) {
          let file = group[key][f];
          if (!file || !file.name)
            continue;

          if (file.progress)
            continue;

          try {
            await Storage.vault.put(this.path + this.normalizeString(key) + '/' + this.normalizeString(file.name), file, {
              bucket: 'uploads.sharespot',
              contentType: file.type,
              progressCallback(progress) {
                EventBus.$emit('uploadFileProgress', {
                  group: g,
                  file: f,
                  progress: Math.min(90, Math.floor(100 * (progress.loaded / progress.total)))
                });
              },
            });
            
            await this.createItem(file, key, targetWidget, targetWidget.items.findIndex(i => i.loading === true));

            EventBus.$emit('uploadFileProgress', { group: g, file: f, progress: 100});
            
          }
          catch (err) {
            this.files[g][f].error = true;
            console.log(err);
          }
        }
        g++;
      }
      this.fileGroupsPanel = [g];

      this.droppedFiles = null;

      this.uploading = false;

      this.$emit('uploaded');
    },
    pickFile() {
      this.$refs.inputfile.click();
    },
    pickFolder() {
      this.$refs.inputfolder.click();
    },
    getMimeTypeIcon(mime) {
      if (!mime || !mime.length)
        return "mdi-file";

      if (mime === 'application/pdf')
        return 'mdi-file-document';

      else if (mime.match('image.*'))
        return 'mdi-file-image';

      if (mime.match('video.*'))
        return 'mdi-file-video';

      if (mime.match('audio.*'))
        return 'mdi-file-music';

      return 'mdi-file';
    },
    getGroupSize(group) {
      if (!group || !group.length)
        return 0;

      let size = 0;
      for (let file of group) {
        if (!file || !file.size)
          continue;

        size += file.size;
      }

      if (size < 1024)
        return size + ' B';

      else if (size < (1024 * 1024))
        return Math.floor(size / 1024) + ' kB';

      else 
        return Math.floor(size / (1024 * 1024)) + ' MB';
    },
    getGroupProgress(group) {
      if (group < 0 || group > this.files.length)
        return 0;

      let total = this.files[group].length * 100;
      let current = 0;
      for (let file of this.files[group]) {
        current += file.progress || 0;
      }

      return Math.floor(100  * (current / total));
    },
    async createEmptyCanvas() {
      this.loading = true;
      this.creatingCanvas = true;
      try {
        const canvas = await api.createCanvas(null, {  path: '', likes: true,  comments: true, });
        this.loading = false;
        this.creatingCanvas = false;

        return await api.getCanvas(canvas.cid);
      }
      catch (err) {
        console.log(err);
        this.loading = false;
        this.creatingCanvas = false;
      }

      return null;
    },
    async createEmptyGallery(aspectRatio, fileList) {
      const count = fileList.length;
      let widget = this.targetWidget;
      let getSizeFromCount = (count) => {
        if (!count)
          return 1; // 6 cols.

        if (count <= 6)
          return 3; // 3 cols

        if (count <= 8)
          return 2; // 4 cols.

        return 1; // 6 cols.
      }
      const type = await this.getContainerType(fileList);

      let gallery = {
        type: type,
        cid: widget.cid,
        cfg: {
          name: '',
          aspectRatio: aspectRatio ? Math.floor(aspectRatio * 10) : 8,
          cover: true,
          outlined: false,
          rounded: false,
          elevation: 1,
          spacing: 1,
          showNames: true,
          size: getSizeFromCount(count)
        },
        kv: []
      }
      gallery.iid = await api.createItem(widget.cid, widget.iid, gallery.type, gallery.cfg);
      getCanvasItemMap(this.$store.state, gallery.cid)[gallery.iid] = {type: gallery.type, cfg: gallery.cfg, parent: widget.iid, cid: this.targetWidget.cid, iid: gallery.iid, checked: false, comments: []}
      gallery.items = [];
      widget.items.push(gallery);

      return gallery;
    },
    async createItem(file, key, targetWidget, index) {
      if (!file || !file.name)
        return;

      const type = this.mimeToType(file.type);
      
      let i = file.name.lastIndexOf('.');
      const name = (i < 0 || targetWidget.type === 'file-table') ? file.name : file.name.substring(0, i);
      let path = this.path + this.normalizeString(key) + '/' + this.normalizeString(file.name);
      let cfg = {
        name: name,
        path: path,
        size: file.size,
        mime: file.type
      };

      if (type === 'video')
        cfg.status = 'CONVERTING';
        
      let iid = await api.createItem(targetWidget.cid, targetWidget.iid, type, cfg, null, true);
      getCanvasItemMap(this.$store.state, targetWidget.cid)[iid] = {type: type, cfg: cfg, parent: targetWidget.iid, cid: this.targetWidget.cid, iid: iid, checked: false, comments: []};

      if (file.type.match('video.*')) {
        await api.convertVideo(this.path + this.normalizeString(key) + '/' + this.normalizeString(file.name), this.targetWidget.cid, iid);
        EventBus.$emit('watchTranscodingJob', iid);
      }

      let item = { type: type, iid: iid, cfg: cfg, parent: targetWidget.iid, cid: this.targetWidget.cid, checked: false, comments: []};
      if (type === 'img' || type === 'file') {
        item = await api.getItem(targetWidget.cid, iid);
        item.parent = targetWidget.iid;
      }
        
      if (index >= 0 && index < targetWidget.items.length)
        targetWidget.items.splice(index, 1);

      targetWidget.items.push(item);
    },
    mimeToType(mime) {
      if (mime.match('audio.*'))
        return 'audio';
      if (mime.match('video.*'))
        return 'video';
      if (mime.match('image.*'))
        return 'img';

      return 'file';
    },
    async getImageDimensions(file) {
      if (!file)
        return null;
      return new Promise(
        (resolve, reject) => {
          let fr = new FileReader;
          fr.onload = function() {
            let img = new Image;
            img.onload = function() {
              resolve({
                width: img.width,
                height: img.height
              });
            };
            img.onerror = function() { reject(); };
            img.onabort = function() { reject(); };
            img.src = fr.result;
          };
          fr.onerror = function() { reject(); };
          fr.onabort = function() { reject(); };
          fr.readAsDataURL(file);
        }
      );
    },
    async getAverageAspectRatio(files) {
      if (!files)
        return null;
      let aspectRatios = [];
      for (let i = 0; i < files.length; ++i) {
        let file = files.item(i)
        const type = this.mimeToType(file.type);
        if (type === 'img') {
          try {
            const dimensions = await this.getImageDimensions(file);
            if (!dimensions || !dimensions.width || !dimensions.height)
              continue;
            aspectRatios.push(dimensions.width / dimensions.height);
          }
          catch (err) {
            console.log(err);
          }
        }
      }
      if (!aspectRatios.length)
        return null;

      return aspectRatios.reduce((partialSum, a) => partialSum + a, 0) / aspectRatios.length;
    },
    async getContainerType(files) {
      if (!files)
        return 'file-table';

      for (let i = 0; i < files.length; ++i) {
        let file = files.item(i)
        const type = this.mimeToType(file.type);
        if (type === 'img' || type === 'video')
          return 'gallery';
      }
      
      return 'file-table';
    },
  }
}
</script>

<i18n>
{
  "en": {
    "addAssetsTo": "Add assets to ",
    "and": " and ",
    "leaveItEmpty": "leave it empty",
    "upload": "Upload",
    "select": "Select",
    "uploadFilesFrom": "Upload files from your device",
    "collect": "Collect",
    "requestAssetsFrom": "Request assets from others via a simple link",
    "uploadFilesTo": "Upload files to the section",
    "selectFiles": "Select files",
    "selectFolder": "Select folder",
    "selectMoreFiles": "Select more files",
    "or": "or",
    "anotherFolder": "another folder",
    "writeYourInstructions": "Write your instructions",
    "tellOtherUsers": "Tell other users how they should contribute. They don't need an account to submit their assets and won't see any other content apart from theirs",
    "createALink": "Create a link",
    "hereIsTheLink": "Here's the link to collect assets",
    "shareItWith": "Share it with anyone who should add some files to this section. You can send this link to multiple contributors - they won't see your or each other's content."
  }
}
</i18n>