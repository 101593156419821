<template>
  <canvas-widget :parent="parent" :index="0" v-model="linkedItem" :editing="false" :preview="preview"></canvas-widget>
</template>

<script>
/* eslint-disable no-unused-vars */
import { getCanvasItemMapAsync } from '@/libs/canvas.js'
export default {
  name: 'canvas-link-item',
  props: {
    value: {
      type: Object,
      default: () => null
    },
    parent: {
      type: Object,
      default: () => null
    },
    preview: {
      type: Boolean, 
      default: () => false
    },
    editing: {
      type: Boolean, 
      default: () => false
    }
  },
  data: () => ({
    linkedItem: null
  }),
  computed: {
    item: {
      get: function() { return this.value; },
      set: function(value) { this.$emit('input', value); }
    }
  },
  async mounted() {
    if (!this.item.cfg || !this.item.cfg.cid || !this.item.cfg.iid)
      return;

    const itemMap = await getCanvasItemMapAsync(this.$store.state, this.item.cfg.cid);
    if (itemMap && this.item.cfg.iid in itemMap)
      this.linkedItem = itemMap[this.item.cfg.iid];
  }
}
</script>