<template>
  <v-hover v-slot="{ hover }">
    <li class="nav-tree-item py-1" :class="item.checked ? 'nav-tree-item-checked' : ''" style="position: relative">
      <template v-if="isGroup">
        <div :class="color + '--text nav-tree-group pl-1 py-1 ' + (isActive ? 'active-nav-tree-group' : '')" @click.prevent.stop="!item || (!item.cid && !item.iid) ? toggle() : navigateTo(item)">
          <v-btn class="mr-1" icon small dark @click.prevent.stop="toggle"><v-icon :color="color">{{open ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon></v-btn>
          <v-icon :size="12" class="mr-1 mb-1" :color="color">{{getItemIcon()}}</v-icon>
          <span :title="itemName">{{itemName}}</span>
          <span class="ml-1 secondary--text" style="font-size: 12px" v-if="getShareCount() > 0"><v-icon :size="13" color="secondary">mdi-account-multiple</v-icon>{{getShareCount()}}</span>
        </div>
        <ul v-if="open" class="pl-2">
          <draggable :list="item.items" :group="{ name: 'nav-items' }" :animation="200" :disabled="!editing || disableSort">
            <TreeItem class="item" v-for="(child, i) in item.items" :key="i" v-model="item.items[i]" :parent="item.iid" :editing="editing" :disableSort="disableSort"></TreeItem>
          </draggable>
        </ul>
      </template>
      <template v-else-if="isGroupType && item.needsUpdate && item.loader">
        <div :class="color + '--text nav-tree-group pl-1 py-1'" @click.prevent.stop="navigateTo(item)">
          <v-btn class="mr-1" icon small dark @click.prevent.stop="load()"><v-progress-circular size="19" :color="color" indeterminate v-if="loading"></v-progress-circular><v-icon :color="color" v-else>mdi-chevron-right</v-icon></v-btn>
          <v-icon :size="12" class="mr-1 mb-1" :color="color">{{getItemIcon()}}</v-icon>
          <span :title="itemName">{{itemName}}</span>
        </div>
      </template>
      <template v-else>
        <div :class="color + '--text '" class="nav-tree-item nav-tree-leaf pl-2 py-1 ml-2" @click="isGroupType ? navigateTo(item) : view(item)">
          <v-tooltip bottom :disabled="!item.thumbSrc" color="transparent" transition="scroll-y-transition">
            <template v-slot:activator="{on}">
              <v-icon :size="12" class="mr-1 mb-1" v-on="on" :color="color">{{getItemIcon()}}</v-icon>
            </template>
            <v-img v-if="item.thumbSrc" :src="item.thumbSrc" width="150px" ></v-img>
          </v-tooltip>
          <span :class="itemName.length > 25 ? 'caption' : null">{{itemName}}</span>
        </div>
      </template>
      <div v-if="checkbox" style="position: absolute; right: 2px; top: 8px;">
        <v-avatar color="rgba(0,0,0,0.5)" size="16" @click.stop.prevent="check()" :style="'cursor: pointer; opacity: ' + (hover || $store.state.canvas.checkedCount > 0 ? '1' : '0')">
          <v-icon :size="14" color="white">{{item.checked ? `mdi-check-circle` : `mdi-checkbox-blank-circle-outline`}}</v-icon>
        </v-avatar>
      </div>
    </li>
  </v-hover>
</template>

<style>
li {
  list-style-type: none;
}
.nav-tree-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nav-tree-group,
.nav-tree-leaf {
  cursor: pointer;
  transition: background-color .2s ease-in-out;
  border-radius: 30px 0px 0px 30px;
}

.nav-tree-item-checked > .nav-tree-leaf,
.nav-tree-item-checked > .nav-tree-group,
.nav-tree-leaf:hover,
.nav-tree-group:hover {
  background-color: rgba(255,255,255,0.1);
}
.nav-tree-item-checked > .nav-tree-leaf:hover,
.nav-tree-item-checked > .nav-tree-group:hover {
  background-color: rgba(255,255,255,0.2);
}

.nav-tree-item-checked .nav-tree-group.active-nav-tree-group
.nav-tree-group.active-nav-tree-group {
  background-color: rgba(249,170,51,0.1);
}
.nav-tree-group.active-nav-tree-group:hover {
  background-color: rgba(249,170,51,0.2);
}
.add-nav-tree-item {
  opacity: 0;
  transition: opacity .2s ease-in-out;
}

ul:hover > .add-nav-tree-item {
  opacity: 1;
}
</style>

<script>
import draggable from 'vuedraggable'
import { EventBus } from '@/libs/eventBus.js'
import { getCanvasItemMap } from '@/libs/canvas.js'

export default {
  name: 'TreeItem',
  components: {
    'draggable': draggable
  },
  props: {
    value: {
      type: Object,
      default: () => null
    },
    name: {
      type: String,
      default: () => null
    },
    editing: {
      type: Boolean,
      default: () => false
    },
    disableSort: {
      type: Boolean,
      default: () => false
    },
    checkbox: {
      type: Boolean,
      default: () => true
    },
    parent: {
      type: String,
      default: () => null
    }
  },
  mounted() {
    if (this.open || this.item.type === 'canvas')
      return;

    if (!this.isGroup) {
      this.open = false;
      return;
    }

    if (this.isActive) {
      this.open = true;
      return;
    }

    for (let item of this.item.items) {
      if (item.items && item.items.length) {
        this.open = true;
        return;
      }
    }

    this.open = false;
  },
  data() {
    return {
      open: false,
      loading: false
    }
  },
  computed: {
    item() {
      return this.value;
    },
    isGroup() {
      return this.value.items && this.value.items.length;
    },
    itemName() {
      return this.name || (this.item.cfg ? (this.item.cfg.name || 'Untitled') : 'Untitled');
    },
    isActive() {
      return (this.item.iid === this.$store.state.canvas.activeRoot);
    },
    isGroupType() {
      return (this.item.type === 'canvas' || this.item.type === 'gallery' || this.item.type === 'list' || this.item.type === 'map-list' || this.item.type === 'file-table' || this.item.type === 'map' || this.item.type === 'casting-profile');
    },
    color() {
      if (this.isActive)
        return 'secondary';

      return 'white';
    }
  },
  methods: {
    toggle() {
      if (this.isGroup)
        this.open = !this.open;
    },
    navigateTo(item) {
      if (!item.iid && !item.cid)
        return;

      if (item.cid && item.type === 'canvas')
        this.$router.push('/canvas/' + item.cid);
      else
        EventBus.$emit('navigateTo', { item: item });
    },
    view(item) {
      if (!item.parent)
        return;

      let parent = getCanvasItemMap(this.$store.state, item.cid)[item.parent];
      let index = 0
      for (let child of parent.items) {
        if (child.iid === item.iid)
          break;
        index++;
      }
      //this.navigateTo(parent);
      EventBus.$emit('showViewDialog', { items: parent.items, index: index, showDescription: true, widget: parent, editing: true });
    },
    getShareCount() {
      return this.getShares().length;
    },
    getShares() {
      if (!this.item.cid || !(this.item.cid in this.$store.state.ac.canvases))
        return [];

      let list = this.$store.state.ac.canvases[this.item.cid].list;
      return list.filter((ac) => ac.iid == this.item.iid);
    },
    getItemIcon() {
      const item = this.item;
      if (!item || !item.type || !item.type.length)
        return "";

      if (item.type === 'video')
        return 'mdi-play';

      else if (item.type === 'audio')
        return 'mdi-speaker-play';

      else if (item.type === 'canvas' || item.type === 'root')
        return 'mdi-view-grid';
      
      else if ((item.type === 'file' && item.cfg && item.cfg.mime === 'application/pdf'))
        return 'mdi-file-pdf-box';

      else if (item.thumbSrc && (!item.items || !item.items.length))
        return 'mdi-magnify';

      else if (item.type === 'file')
        return 'mdi-file-outline';

      else if (item.items && item.items.length || ['gallery', 'list', 'map-list', 'file-table', 'map', 'casting-profile'].includes(item.type))
        return 'mdi-folder-open-outline'

      return 'mdi-magnify';
    },
    async load() {
      if (!this.item.loader)
        return;
      this.loading = true;
      await this.item.loader();
      this.open = true;
      this.loading = false;
    },
    check() {
      this.item.checked = !this.item.checked
      if (this.item.cid && this.item.cid !== this.$store.state.canvas.cid)
        EventBus.$emit('checkExternal', this.item);
    }
  }
}
</script>