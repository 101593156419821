<template>
  <v-card class="elevation-0" style="width: 100%;" @click.prevent.stop="()=>{}" :ripple="false">
    <v-card-text class="pa-0">
      <v-container fluid class="pa-0">
        <v-layout column fill-height v-if="shown">
          <v-flex grow pa-3 :style="'overflow-y: auto; height: ' + height + 'px !important'" id="chatMessages">
            <div v-if="!messages.length" style="height: 100%;">
              <v-container fill-height>
                <v-layout row wrap align-center>
                  <v-flex xs12 sm12 md12 class="text-center">
                    <v-icon size="48" class="mb-4">far fa-comments</v-icon><br />
                    <span>{{$t('noMessages')}} <span style="font-size: 16px">🙂</span></span>
                  </v-flex>
                </v-layout>
              </v-container>
            </div>
            <div v-else v-for="(message, m) in messages" :key="m" class="comment elevation-2" :class="message.userName == userName ? 'sent' : (message.firstUnread ? 'received first-unread' : 'received')">
              <v-container>
                <v-layout row>
                  <v-flex grow>
                    <div>{{message.userName}} <span style="opacity: .5">{{getElapsedTime(message.time)}}</span></div>
                    <div class="text-subtitle-1 pt-3 pb-5 pl-2" style="max-width: 300px;">{{message.text}}</div>
                  </v-flex>
                  <v-flex shrink style="position: relative">
                    <v-avatar v-if="message.profileImage" size="32" class="elevation-1" style="border: 1px solid rgba(0,0,0,0.2); cursor: pointer"><v-img :src="message.profileImage" size="48"></v-img></v-avatar>
                    <v-avatar v-else :color="getColor(message.userName)" size="32" class="elevation-1" style="border: 1px solid rgba(0,0,0,0.2); font-size: 20px; font-weight: 300; color: rgba(0,0,0,0.6); cursor: pointer">{{message.userName[0].toUpperCase()}}</v-avatar>
                    <div style="position: absolute; bottom: 0px; right: 0px; font-size: 12px; opacity: 0.5; white-space: nowrap">{{getTime(message.time)}}</div>
                  </v-flex>
                </v-layout>
              </v-container>
            </div>
          </v-flex>
          <v-flex shrink pl-2 pr-1 py-1 style="background-color: #344955; position: relative">
            <v-progress-linear v-if="sending" indeterminate color="secondary" absolute height="6" style="top: 0px"></v-progress-linear>
            <v-container>
              <v-layout row align-center>
                <v-flex grow>
                  <v-text-field id="chatMessageText" class="my-0" dense :disabled="sending" prepend-inner-icon="mdi-emoticon-happy-outline" @click:prepend-inner="emojiMenu=true" light solo hide-details @keydown.enter="send" v-model="message"></v-text-field>
                  <v-menu v-model="emojiMenu" :close-on-content-click="false" activator=".v-input__prepend-inner" offset-x nudge-left="28" nudge-bottom="28">
                    <v-card flat max-width="300">
                      <span v-for="(emoji, e) in emojis" :key="e" v-on:click="addEmoji(emoji)" style="cursor: pointer; margin: 2px;">{{emoji}}</span>
                    </v-card>
                  </v-menu>
                </v-flex>
                <v-flex shrink class="pl-3">
                  <v-btn fab small @click="send" color="secondary"><v-icon color="#17262a" class="ml-1">mdi-send</v-icon></v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<i18n>
{
  "en": {
    "noMessages": "No one has added any comments yet, be the first one to break the ice",
    "elapsedHours": " ~ {hours} hours ago",
    "elapsedMinutes": " ~ {minutes} minutes ago"
  }
}
</i18n>
<style>
.comment {
  border-radius: 8px;
  background-color: white;
  margin: 8px 0px;
  padding: 12px;
}

.first-unread {
  padding-top: 12px;
  border-top: 1px solid #FF5252;
}
.message-box {
  border-radius: 0px 15px 15px 15px;
  background-color: white;
  /*border: 1px solid rgba(101, 188, 255, 0.4);*/
  padding: 12px 12px 4px 12px;
  color: black;
}
.sent .message-box {
  border-radius: 15px 0px 15px 15px;
  background-color: white;
  color: black;
}
.message-user-name {
  padding-left: 6px;
}
.sent .message-user-name {
  visibility: hidden;
}
.message {
  margin-bottom: 12px;
  width: calc(100% - 50px);
  padding: 0px 10px;
  float: right;
}
.sent .message {
  float: left;
}
.message-user {
  float: left;
}
.sent .message-user {
  float: right;
}
#chatConatiner,
#chatMessages {
  background-color: white/*#344955;*//*#edf0f2;*/
}

</style>

<script>
import { EventBus } from '@/libs/eventBus.js'
import * as tools from '@/libs/tools.js'

export default {
  name: 'chat-widget',
  props: {
    value: {
      type: Object,
      default: () => null
    },
    id: {
      type: String,
      default: () => null
    },
    userName: {
      type: String,
      default: () => null
    },
    getMessages: {
      type: Function,
      default: () => []
    },
    sendMessage: {
      type: Function,
      default: () => []
    },
    shown: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      emojiMenu: false,
      emojis: ["😀","😬","😁","😂","😃","😄","😅","😆","😇","😉","😊","🙂","🙃","😋","😌","😍","😘","😗","😙","😚","😜","😝","😛","🤑","🤓","😎","🤗","😏","😶","😐","😑","😒","🙄","🤔","😳","😞","😟","😠","😡","😔","😕","🙁","😣","😖","😫","😩","😤","😮","😱","😨","😰","😯","😦","😧","😢","😥","😪","😓","😭","😵","😲","🤐","😷","🤒","🤕","😴","💤","💩","😈","👿","👹","👺","💀","👻","👽","🤖","😺","😸","😹","😻","😼","😽","🙀","😿","😾","🙌","👏","👋","👍","👎","👊","✊","✌","👌","✋","👐","💪","🙏","☝","👆","👇","👈","👉","🖕","🖐","🤘","🖖","✍","💅","👄","👅","👂","👃","👁","👀","👤","👥","🗣","👶","👦","👧","👨","👩","👱","👴","👵","👲","👳","👮","👷","💂","🕵","🎅","👼","👸","👰","🚶","🏃","💃","🙇","💁","🙅","🙆","🙋","🙎","🙍","💇","💆","👪","👚","👕","👖","👔","👗","👙","👘","💄","💋","👣","👠","👡","👢","👞","👟","👒","🎩","🎓","👑","⛑","🎒","👝","👛","👜","💼","👓","🕶","💍","🌂"],
      message: '',
      messages: [],
      sending: false
    }
  },
  async mounted() {
    try {
      await this.updateMessages();
      setInterval(async () => {
        await this.updateMessages();
      }, 30000);
    }
    catch (err) {
      console.log(err);
    }
  },
  watch: {
    async shown(val) {
      if (val) {
        //if (!this.messages.length)
        await this.updateMessages(true);
        EventBus.$emit('chatMessagesRead');
      }
    }
  },
  computed: {
    height() {
      return 450;//window.innerHeight - 61;
    },
    item: {
      get() { return this.value; },
      set(value) { this.$emit('input', value); }
    }
  },
  methods: {
    rgbToHex(r, g, b) {
      let componentToHex = (c) => {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
      }
      return "" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    },
    hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : {r:0,g:0,b:0};
    },
    rgbToHsl(r, g, b) {
        r /= 255, g /= 255, b /= 255;
        var max = Math.max(r, g, b), min = Math.min(r, g, b);
        var h, s, l = (max + min) / 2;

        if (max == min)
            h = s = 0;
        else
        {
            var d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
            }
            h /= 6;
        }
        return [h, s, l];
    },
    hslToRgb(h, s, l) {
      var r, g, b;

      if (s == 0)
          r = g = b = l;
      else {
        let hue2rgb = (p, q, t) => {
          if (t < 0) t += 1;
          if (t > 1) t -= 1;
          if (t < 1/6) return p + (q - p) * 6 * t;
          if (t < 1/2) return q;
          if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
          return p;
        }

        var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        var p = 2 * l - q;
        r = hue2rgb(p, q, h + 1/3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1/3);
      }

      return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
    },
    stringToSeed(str) {
        for(var i = 0, h = 1779033703 ^ str.length; i < str.length; i++)
            h = Math.imul(h ^ str.charCodeAt(i), 3432918353),
            h = h << 13 | h >>> 19;
        return function() {
            h = Math.imul(h ^ h >>> 16, 2246822507);
            h = Math.imul(h ^ h >>> 13, 3266489909);
            return (h ^= h >>> 16) >>> 0;
        }
    },
    seededRandomColor(str) {
      if (!str)
        return '000000';
      let seed = this.stringToSeed(str);
      let LCG = (s) => (Math.imul(741103597, s) >>> 0) / 2 ** 32;
      const symbols = '0123456789ABCDEF';      
      let color = '';      
      for (let i = 0; i < 6; i++) {
        color += symbols[Math.round(LCG(seed() + i) * 16)] || '0';
      }
      let rgb = this.hexToRgb("#"+color);
      let [h, s, l] = this.rgbToHsl(rgb.r, rgb.g, rgb.b)

      // Tweak lightnes and saturation to match our colo palette
      l = Math.max(0.25, Math.min(0.55, l));
      s = Math.max(0.3, Math.min(0.75, s));
      let [r,g,b] = this.hslToRgb(h, s, l);

      color = this.rgbToHex(r,g,b);
      
      return color;
    },
    getTime(timestamp) {
      let date = new Date(timestamp);
      let time = date.getHours() + ':' + (date.getMinutes() < 10 == 1 ? '0' : '') + date.getMinutes();

      let todaysDate = new Date();

      // if not the same date, show the date as well
      if (date.setHours(0,0,0,0) != todaysDate.setHours(0,0,0,0))
        return date.getDate() + ". " + (date.getMonth() + 1) + ". " + time;

      return time;
    },
    getElapsedTime(timestamp) {
      let date = new Date(timestamp);
      let todaysDate = new Date();
      
      let elapsedHours = todaysDate.getHours() - date.getHours();
      let elapsedMinutes = todaysDate.getMinutes() - date.getMinutes();

      if (date.setHours(0,0,0,0) != todaysDate.setHours(0,0,0,0))
        return "";
      
      if (elapsedHours > 0)
        return this.$t('elapsedHours', {hours: elapsedHours});
      
      if (elapsedMinutes > 0)
        return this.$t('elapsedMinutes', {minutes: elapsedMinutes});

      return "";
    },
    addEmoji(emoji) {
      this.message += emoji;
      this.emojiMenu = false;
    },
    async updateMessages(clearPreviousUnread) {
      if (!this.shown || this._isDestroyed || document.hidden)
        return;

      let firstUnreadTimestamp = 0;

      if (this.messages && this.messages.length && !clearPreviousUnread) {
        let firstUnreadMessage = this.messages.find((message) => message.firstUnread == true);
        if (firstUnreadMessage)
          firstUnreadTimestamp = firstUnreadMessage.time;
      }

      let lastPrev = localStorage.getItem("lastMessage-" + this.id);
      let nextIsUnread = false;
      let prevCount = this.messages.length;
      this.messages = await this.getMessages(this.id);
      let latest = 0;

      if (this.messages) {
        for (let message of this.messages) {
          if (message.time > latest)
            latest = message.time;
          
          if (clearPreviousUnread && nextIsUnread && message.userName != this.userName) {
            message.firstUnread = true;
            nextIsUnread = false;
          }

          if (clearPreviousUnread && (message.time == lastPrev || !lastPrev)) {
            nextIsUnread = true;
            lastPrev = message.time;
          }

          if (!clearPreviousUnread && message.time == firstUnreadTimestamp)
            message.firstUnread = true;
        }
      }
      localStorage.setItem("lastMessage-" + this.id, latest);
      // scroll only upon receiving new messages
      if (this.messages.length != prevCount) {
        this.$nextTick(() => {
          let el = document.getElementById("chatMessages");
          el.scrollTop = el.scrollHeight;
        });
      }
    },
    async send() {
      if (!this.message || !this.message.length || this.sending)
        return;

      this.sending = true;

      if (this.item && this.item.comments)
        this.item.comments.push(this.message);

      await this.sendMessage(this.message, this.userName, this.id);

      this.sending = false;

      this.messages.push({
        text: this.message,
        userName: this.userName,
        time: + new Date()
      });
      this.message = '';

      this.$nextTick(() => {
        let el = document.getElementById("chatMessages");
        el.scrollTop = el.scrollHeight;
      });      
    },
    getInitials(name) {
      if (!name)
        return '';
      let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
      let initials = [...name.matchAll(rgx)] || [];
      initials = (
        (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
      ).toUpperCase();

      return initials;
    },
    getColor(str) {
      return tools.seededRandomColor(str);
    },
  }
}
</script>