import { Amplify } from "aws-amplify"
import config from './../config/aws'
import Vue from 'vue'
import AsyncComputed from 'vue-async-computed'
import VuePlyr from 'vue-plyr'
import * as VueGoogleMaps from 'vue2-google-maps'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import CanvasWidget from '@/components/canvas-items/canvas-widget'
import 'vue-plyr/dist/vue-plyr.css'
import i18n from './locales/i18n'
import VueMasonry from 'vue-masonry-css'
import LottieAnimation from 'lottie-web-vue'

router.beforeEach((to, from, next) => {
  if (to.name == 'signin' || to.name == 'signin-redirect' || to.name == 'share' || to.name == 'collect')
    next();
  else {
    Amplify.Auth.currentAuthenticatedUser().then(() => {
      next();
    })
    .catch(() => {
      next(`/signin${(to && to.name !== 'home') ? ('/' + encodeURIComponent(to.fullPath)) : ''}`);
    });
  }
});

Amplify.configure(config);

Vue.config.productionTip = true;
Vue.use(AsyncComputed);
Vue.use(VuePlyr, { plyr: {} });
Vue.use(VueGoogleMaps, { load: { key: 'AIzaSyDa0mGNOsJBEAVvYHhOvP0x0U_lwWo5was', libraries: 'places', region: 'US', language: 'en', } });
Vue.use(LottieAnimation);
Vue.use(VueMasonry);
Vue.component('canvas-widget', CanvasWidget);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');