<template>
<div class="collect-flow-page" style="height: 100%">
  <v-container v-if="loading" fill-height class="pa-0">
    <v-layout align-center>
      <v-progress-linear color="secondary" indeterminate rounded height="10" class="mx-auto" style="width: 25%"></v-progress-linear>
    </v-layout>
  </v-container>
  <v-container v-else-if="error" fill-height class="pa-0">
    <v-layout align-center>
      <div class="mx-auto">
        <v-card min-width="480" class="pa-3 elevation-1 rounded-xl">
          <v-card-title primary-title class="login-dialog-title">
            <h4 class="headline">{{error.header}}</h4>
          </v-card-title>
          <v-card-text>
            <p v-html="error.text"></p>
          </v-card-text>
        </v-card>
      </div>
    </v-layout>
  </v-container>
  <template v-else>
    <div v-if="collectFlow && collectFlow.length > 5">
      <v-progress-linear :value="100 * (currentStep / (collectFlow.length - 1))" color="secondary" height="6" class="elevation-0"></v-progress-linear>
    </div>
    <v-container fill-height class="pa-0" >
      <v-layout align-center>
        <div class="mx-auto">
          <v-card min-width="550" width="950" min-height="550" class="px-8 pt-16 pb-8 elevation-1 rounded-xl d-flex flex-column" v-if="collectFlow" >
            <v-card-text style="flex-grow: 1; position: relative;">
              <v-window v-model="currentStep" class="text-center" xstyle="width: 100%; height: 100%; position: absolute; left: 0px; right: 0px;">
                <v-window-item ref="window" v-for="(item, i) in collectFlow" :value="i" :key="item.cfid" class="pb-5" style="display: flex; flex-direction: column; height: 100%">
                  <template v-if="item.text && item.text.en">
                    <div class="main-message primary--text">{{ item.text.en.header }}</div>
                    <div class="sub-message" v-if="item.text.en.subheader" v-html="item.text.en.subheader"></div>
                  </template>
                  <div class="content" style="flex-grow: 1; position: relative">
                    <div xstyle="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);">
                      <div v-if="item.type === 'text'" style="width: 360px;" class="mx-auto small-field">
                        <v-text-field @keydown.enter="!nextDisabled ? next() : (()=>{})()" :value="item.value" @input="updateValue(item, $event)" class="mx-auto pa-0 collect-text-input" color="black" outlined :label="item.text && item.text.en && item.text.en.label ? item.text.en.label : null" :mask="item.mask ? item.mask : null" :suffix="item.unit ? item.unit : null" :counter="item.max ? item.max : null"></v-text-field>
                      </div>

                      <v-menu v-else-if="item.type === 'date'" v-model="dateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <div style="width: 360px;" class="mx-auto small-field">
                            <v-text-field :value="item.value" class="collect-text-input" @input="updateValue(item, $event)" :label="item.text && item.text.en && item.text.en.label ? item.text.en.label : null" readonly outlined v-bind="attrs" v-on="on"></v-text-field>
                          </div>
                        </template>
                        <v-date-picker :value="item.value" @input="updateValue(item, $event) && (dateMenu = false)" :ref="'datePicker' + currentStep" :active-picker.sync="activeDatePicker"></v-date-picker>
                      </v-menu>

                      <template v-else-if="item.type === 'select-one' || item.type === 'select-multiple'">
                        <template v-if="currentStep === i">
                          <template v-for="(option, o) in getOptions(item)">
                            <v-btn v-if="option.icon" :key="item.cfid + o" :color="isValueSelected(item, option.value) ? 'primary white--text' : 'white primary--text'" class="big-icon-btn px-1 ma-1 rounded-xl" large @click="selectItemValue(item, option.value)">
                              <v-responsive :aspect-ratio="1" class="ma-3">
                                <v-icon size="40" class="mt-2 mb-3">{{option.icon}}</v-icon>
                                <div>{{option.en || option.value}}</div>
                              </v-responsive>
                            </v-btn>
                            <v-btn v-else :key="item.cfid + o"  :color="isValueSelected(item, option.value) ? 'primary white--text' : 'white primary--text'" class="rounded-pill ma-1" large @click="selectItemValue(item, option.value)">{{option.en || option.value}}</v-btn>
                          </template>
                        </template>
                      </template>

                      <div v-else-if="item.type === 'dropdown'" style="width: 360px;" class="mx-auto small-field">
                        <v-combobox :value="item.value" @input="updateValue(item, $event.value)" item-text="en" item-value="value" :items="getOptions(item)" color="black" :label="item.text && item.text.en && item.text.en.label ? item.text.en.label : null">
                          <template slot="selection" slot-scope="data">
                            {{typeof data.item === 'string' ? data.item : (data.item.en || data.item.value)}}
                          </template>
                          <template slot="item" slot-scope="{ item }">
                            <v-list-item-content>
                              <v-list-item-title>{{item.en || item.value}}</v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-combobox>
                      </div>

                      <div v-else-if="item.type === 'number'" style="width: 320px;" class="mx-auto small-field">
                        <v-text-field :value="item.value" @input="updateValue(item, $event)" outlined @keydown.enter="!nextDisabled ? next() : (()=>{})()"  type="number" :label="item.text && item.text.en && item.text.en.label ? item.text.en.label : null" :suffix="item.unit ? item.unit : null" hide-spin-buttons class="number-text collect-text-input" background-color="transparent"></v-text-field>
                      </div>

                      <div v-else-if="item.type === 'photo' || item.type === 'files'">
                        <add-assets-widget v-model="item.item" @uploaded="item.value = true"></add-assets-widget>
                      </div>

                      <div v-else-if="item.type === 'item-list'" style="width: 450px;" class="mx-auto small-field">
                        <item-list-widget v-model="item.item" @input="item.value = ($event && $event.items && $event.items.length > 0)"></item-list-widget>
                      </div>

                      <div v-else-if="item.type === 'acs'" style="width: 100%; max-width: 600px" class="mx-auto">
                        <share-widget :cid="canvas.cid" :iid="canvas.root && canvas.root.iid ? canvas.root.iid : null" simple></share-widget>
                      </div>

                      <div v-else-if="item.type === 'instruction-list'" style="width: 100%; max-width: 600px" class="mx-auto">
                        <instruction-list-widget v-model="collectFlow[i]" @updateValue="render++"></instruction-list-widget>
                      </div>

                      <div v-else>
                        Unexpected error
                      </div>
                    </div>
                  </div>
                </v-window-item>
              </v-window>
            </v-card-text>
            <v-card-actions>
              <div class="d-block mx-auto" :key="currentStep + render">
                <v-btn class="next elevation-1 rounded-pill" color="secondary primary--text" x-large :key="currentStep + render" :disabled="nextDisabled" @click="currentStep === (collectFlow.length - 1) ? finish() : next()">{{currentStep === (collectFlow.length - 1) ? 'Finish' : 'Next'}} <v-icon right>mdi-chevron-right</v-icon></v-btn>
                <v-btn :style="'opacity: ' + (currentStep !== 0 ? '1' : '0')" class="back rounded-pill" text @click="prev()">Previous</v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </div>
      </v-layout>
    </v-container>
  </template>
</div>
</template>

<style>
.collect-flow-page .main-message {
  font-size: 40px;
  line-height: 44px;
  font-weight: 300;
  margin-bottom: 24px;
}
.collect-flow-page .sub-message {
  font-size: 18px;
  line-height: 20px;
  font-weight: 300;
  color: #8f969a;
  max-width: 640px;
  margin: 0px auto 24px auto;
}
.collect-flow-page .small-field .v-text-field {
  line-height: 48px !important;
}
.collect-flow-page .small-field .v-text-field input {
  line-height: 48px;
  max-height: 48px;
  font-size: 32px !important;
  font-weight: 300;
}
.collect-flow-page .v-btn.next {
  margin: 28px auto 4px auto;
  display: block;
}
.collect-flow-page .v-btn.back {
  margin: 20px auto 0px auto;
  font-weight: 300;
  display: block;
}
.collect-flow-page .big-icon-btn {
  height: initial !important;
  padding: 0px;
}
.collect-flow-page .big-icon-btn .v-btn__content {
  display: initial;
  min-width: 120px;
}

.collect-flow-page  .content {
  padding-top: 20px;
}
</style>

<script>
/* eslint-disable no-unused-vars */
import { CanvasList, getCanvasItemMap } from '@/libs/canvas.js'
import * as tools from '@/libs/tools.js'
import * as api from '@/libs/api.js'
import AddAssetsWidget from '@/components/widgets/add-assets'
import ItemListWidget from '@/components/widgets/item-list-widget'
import ShareWidget from '@/components/widgets/share-widget'
import InstructionListWidget from '@/components/widgets/instruction-list-widget'

export default {
  name: 'collect-flow-page',
  props: {
    id: {
      type: String,
      default: () => null
    },
    iid: {
      type: String,
      default: () => null
    },
  },
  components: {
    'add-assets-widget': AddAssetsWidget,
    'item-list-widget': ItemListWidget,
    'share-widget': ShareWidget,
    'instruction-list-widget': InstructionListWidget,
  },
  data: () => ({
    render: 0,
    loading: true,
    error: null,
    canvasList: null,
    canvas: null,
    collectFlow: null,
    currentStep: 0,
    dateMenu: false,
    activeDatePicker: null,
  }),
  computed: {
    nextDisabled() {
      if (this.render < 0) // reactivity hack
        return true;

      let currentItem = this.collectFlow[this.currentStep];
      if (currentItem.optional || currentItem.type === 'photo' || currentItem.type === 'files' || currentItem.type === 'acs')
        return false;

      if (Array.isArray(currentItem.value) && !currentItem.value.length)
        return true;

      return !currentItem.value;
    }
  },
  watch: {
    dateMenu (val) {
      if (!val)
        return;

      setTimeout(() => ( this.activeDatePicker = 'YEAR' ));
    },
  },
  async mounted() {
    this.loading = true;
    this.canvasList = new CanvasList(this.$store.state);

    let result = await this.canvasList.getCanvas(this.id);
    this.canvas = result.canvas;

    this.collectFlow = tools.getCollectFlow(this.canvas, this.$store.state);

    if (!this.collectFlow || !this.collectFlow.length) {
      this.$router.push({ name: 'canvas', params: {id: this.id} });
      return;
    }
    
    if (this.canvas.allowedRole && (this.canvas.allowedRole === 'Viewer' || this.canvas.allowedRole === 'Client')) {
      this.error = {
        header: 'Access Denied',
        text: 'As a ' + this.canvas.allowedRole + ' you do not have permission to access this page.'
      };
    }

    this.loading = false;
  },
  methods: {
    isValueSelected(item, value) {
      if (item.type === 'select-one')
        return item.value === value;
      else if (item.type === 'select-multiple')
        return item.value && item.value.includes(value);
      return false;
    },
    selectItemValue(item, value) {
      if (item.type === 'select-one') {
        if (item.value === value)
          item.value = null;
        else
          item.value = value;
      }
      else if (item.type === 'select-multiple') {
        if (!item.value)
          item.value = [];

        if (item.value.includes(value))
          item.value.splice(item.value.indexOf(value), 1);
        else
          item.value.push(value);
      }

      this.updateValue(item, item.value)
    },
    async updateValue(item, value) {
      item.value = value;
      if (item.target && item.target === 'canvas-name') {
        this.canvas.nm = value;
        await api.updateCanvas(this.canvas.cid, { nm: value });

        this.canvasList.updateCanvas(this.canvas.cid, this.canvas);
        
        return;
      }
      else if (!item.item && !item.kv)
        return;

      tools.updateCollectibleValue(getCanvasItemMap(this.$store.state, this.id), item, value);
    },
    getOptions(item) {
      if (item.options)
        return item.options;

      if (item.optionsDependency && item.optionsDependency.id) {
        let dependency = this.collectFlow.find(i => i.id === item.optionsDependency.id);
        if (dependency && dependency.value)
          return dependency.value.map(value => ({value: value}));
      }

      return [];
    },
    skipItem(item) {
      if (!item.dependency)
        return false;

      let dependency = this.collectFlow.find(i => i.id === item.dependency.id);
      if (!dependency)
        return true;

      if (item.dependency.valueIs)
        return dependency.value !== item.dependency.valueIs;
      else if (item.dependency.valueIsNot)
        return dependency.value === item.dependency.valueIsNot;
      else if (item.dependency.valueIsOneOf)
        return !item.dependency.valueIsOneOf.includes(dependency.value);
      else if (item.dependency.valueIsNotOneOf)
        return item.dependency.valueIsNotOneOf.includes(dependency.value);
      else if (item.dependency.valueIsNotEmpty)
        return !dependency.value || dependency.value.length === 0;
      else if (item.dependency.valueIsEmpty)
        return dependency.value && dependency.value.length > 0;
      else if (item.dependency.minLength)
        return dependency.value && dependency.value.length < item.dependency.minLength;
      else if (item.dependency.maxLength)
        return dependency.value && dependency.value.length > item.dependency.maxLength;
      else if (item.dependency.minValue)
        return dependency.value && dependency.value < item.dependency.minValue;
      else if (item.dependency.maxValue)
        return dependency.value && dependency.value > item.dependency.maxValue;
      else if (item.dependency.minDateIsToday)
        return dependency.value && dependency.value < new Date();
      else if (item.dependency.maxDateIsToday)
        return dependency.value && dependency.value > new Date();
      else if (item.dependency.valueIncludes)
        return dependency.value && !dependency.value.includes(item.dependency.valueIncludes);
      else if (item.dependency.valueExcludes)
        return dependency.value && dependency.value.includes(item.dependency.valueExcludes);
    },
    finish() {
      this.$router.push({ name: 'canvas', params: {id: this.id} });
    },
    next() {
      if (this.currentStep === this.collectFlow.length - 1)
        return;

      for (let i = this.currentStep + 1; i < this.collectFlow.length; i++) {
        if (!this.skipItem(this.collectFlow[i])) {
          this.currentStep = i;
          return;
        }
      }
    },
    prev() {
      if (this.currentStep === 0)
        return;

      for (let i = this.currentStep - 1; i >= 0; i--) {
        if (!this.skipItem(this.collectFlow[i])) {
          this.currentStep = i;
          return;
        }
      }
    },
  }
}
</script>
