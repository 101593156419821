export default {
  Auth: {
    mandatorySignIn: false,
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_lQ0AThQCJ',
    identityPoolId: 'eu-central-1:4eb0c044-0d30-46e7-a7cf-97a8f7544eee',
    userPoolWebClientId: '3nqpd0lsjt5ualpui3nkh2ubso'
  },
  Storage: {
    region: 'eu-central-1',
    bucket: /*'devstorage.sharespotcz',*/'storage.sharespotcz',
    identityPoolId: 'eu-central-1:4eb0c044-0d30-46e7-a7cf-97a8f7544eee'
  },
  API: {
    endpoints: [
      {
        name: "api",
        region: 'eu-central-1',
        endpoint: /*'https://8g8ltb5bsb.execute-api.eu-central-1.amazonaws.com'/**/'https://uuspw4s1df.execute-api.eu-central-1.amazonaws.com'
      }
    ]
  },
  WS: {
    endpoint: /*'wss://75kgdffsq5.execute-api.eu-central-1.amazonaws.com/dev'/**/'wss://ec837xx7hg.execute-api.eu-central-1.amazonaws.com/prod'
  }
}

