<template>
<div class="pa-1 layout-list">
  <v-treeview :items="getItems()" expand-icon="mdi-chevron-down" hoverable activatable rounded open-all @update:active="selectItem($event)" item-key="index" item-children="items" item-text="cfg.name" return-object>
    <template v-slot:label="{ item }">
      <span>{{(!item.cfg || !item.cfg.name || !item.cfg.name.length) ? ($t('untitledCollection') + ' ' + item.index) : item.cfg.name}}</span><v-icon v-if="item.type === 'root'" small class="ml-1">mdi-plus-circle</v-icon>
    </template>
  </v-treeview>
</div>
</template>

<style>
.layout-list .v-treeview-node__root:hover {
  cursor: pointer;
}
</style>

<script>
/* eslint-disable no-unused-vars */

import { getCanvasItemMapAsync, CanvasList } from '@/libs/canvas.js'
export default {
  name: 'layout-list',
  props: {
    showLeaves: {
      type: Boolean, 
      default: () => false
    },
  },
  data() {
    return {
    }
  },
  computed: {
    root() {
      return this.$store.state.canvas.root;
    }
  },
  methods: {
    getItems() {
      if (!this.root || !this.root.items || !this.root.items.length)
        return [];

      const allowedTypes = ['gallery', 'list', 'map-list', 'file-table', 'group', 'root', 'casting-roles', 'casting-events', 'casting', 'casting-profile'];

      const traverseTransform = (item, index) => {
        if (!allowedTypes.includes(item.type))
          return null;

        let transformedItems = [];
        if (item.items && item.items.length) {
          for (let i = item.items.length -1; i >= 0; i--) {
            //if (item.items[i].items && item.items[i].items.length) 
            {
              let transformed = traverseTransform(item.items[i], index !== null ? ('' + (i+1)) : (index + '.' + (i+1)));
              if (transformed)
                transformedItems.push(transformed);
            }
          }
        }

        return { cid: item.cid, iid: item.iid, type: item.type, cfg: item.cfg, index: index, items: transformedItems };
      }
      let transformedRoot = traverseTransform(this.root);
      if (!transformedRoot || !transformedRoot.items)
        return [];

      transformedRoot.items.push({ cid: transformedRoot.cid, iid: transformedRoot.iid, type: 'root', items: transformedRoot.transformedItems, cfg: {name: 'New collection'}});

      return transformedRoot.items;
    },
    async selectItem(item) {
      if (!item || !item.length)
        return;

      const items = await getCanvasItemMapAsync(this.$store.state, item[0].cid)
      const target = items && item[0].iid in items ? items[item[0].iid] : item[0];
      this.$emit('itemSelected', target);
    }
  }
}
</script>