<template>
<div class="share-page">
  <canvas-page :id="id" :iid="iid"></canvas-page>
</div>
</template>

<i18n>
{
  "en": {
    "pleaseEnterYourName": "Please enter your name",
    "yourName": "Your name"
  }
}
</i18n>

<style>
.share-page {
  height: 100%;
}
</style>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
import CanvasPage from '@/components/pages/canvas'

export default {
  name: 'share-page',
  props: {
    id: {
      type: String,
      default: () => null
    },
    iid: {
      type: String,
      default: () => null
    }
  },
  components: {
    'canvas-page': CanvasPage
  },
  data: () => ({
  }),
  async mounted() {
  },
  methods: {
  }
}
</script>
