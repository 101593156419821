<template>
<div>
  <div v-if="!item.items || !item.items.length" class="empty-root text-center" @drop.prevent="dropFiles" @dragenter.prevent @dragover.prevent="dragFiles" @dragleave="dragLeave">
    <p class="text-h5" style="font-weight: 300">{{$t('noContent')}}</p>
    <v-btn x-large elevation="1" class="rounded-pill" color="secondary primary--text" @click="addAssets(item)">{{$t('addContent')}}</v-btn>
    <v-btn text style="font-weight: 400" rounded elevation="0" color="white" class="mx-auto mt-3 d-block primary--text" :loading="creatingSection" @click="createSection()">Create empty section</v-btn>
  </div>
  <draggable v-else v-model="item.items" :animation="200" :group="{ name: 'items' }" :disabled="!editing || this.$vuetify.breakpoint.mdAndDown">
    <template v-for="(child, i) in item.items">
      <canvas-widget :key="i" :parent="item" :index="i" v-model="item.items[i]" :editing="editing" class="mb-5" :preview="preview"></canvas-widget>
    </template>
  </draggable>
</div>
</template>
<i18n>
{
  "en": {
    "noContent": "There's no content in this project yet",
    "letsAddSome": "Let's add some",
    "addContent": "Add content"
  }
}
</i18n>
<style>
  .empty-root {
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    padding-top: 64px;
    padding-bottom: 64px;
    padding-right: 32px;
    padding-left: 32px;
  }
</style>

<script>
import draggable from 'vuedraggable'
import * as tools from '@/libs/tools.js'
import * as api from '@/libs/api.js'
export default {
  name: 'root-widget',
  components: {
    'draggable': draggable
  },
  props: {
    value: {
      type: Object,
      default: () => null
    },
    preview: {
      type: Boolean, 
      default: () => false
    },
    editing: {
      type: Boolean, 
      default: () => false
    }
  },
  data: () => ({
    dragging: false,
    creatingSection: false
  }),
  computed: {
    item: {
      get: function() { return this.value; },
      set: function(value) { this.$emit('input', value); }
    }
  },
  methods: {
    addAssets(widget, files = null) {
      if (!widget)
        widget = this.item;

      this.$store.state.dialogs.addAssets = {
        show: true, 
        path: tools.getCanvasFullPath(this.$store.state.canvas.cfg.path, widget.cid),
        targetWidget: widget,
        files: files
      }
    },
    dragFiles(e) {
      e.stopPropagation();
      e.preventDefault();
      if (!e || !e.dataTransfer || !e.dataTransfer.files)
        return;

      e.dataTransfer.dropEffect = 'copy';
      this.dragging = true;
    },
    dropFiles(e) {
      if (!e || !e.dataTransfer || !e.dataTransfer.files || !e.dataTransfer.files.length)
        return;

      this.dragging = false;  
      this.addAssets(this.item, e.dataTransfer.files)
    },
    dragLeave() {
      this.dragging = false;
    },
    async createSection() {
      this.creatingSection = true;
      try {
        let gallery = { type: 'gallery', parent: this.item.iid, cid: this.item.cid, cfg: { name: '', aspectRatio: 10, cover: true, outlined: false, rounded: false, elevation: 1, spacing: 1, showNames: true, size: 1}, kv: [], checked: false, comments: [] }
        await api.addSubSection(this.item, gallery, this.$store);
      }
      catch (err) {
        console.log(err);
      }
      this.creatingSection = false;
    },
  }
}
</script>