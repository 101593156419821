<template>
  <v-dialog v-model="show" @keydown.esc="show = false" scrollable :fullscreen="$vuetify.breakpoint.mobile" persistent content-class="add-assets-dialog" width="950">
    <v-card color="#edf0f2" :key="render" xmin-height="650">
      <v-card-title class="primary white--text pa-3">{{ $t('addAssets') }}<v-spacer></v-spacer><v-btn icon color="white" @click="show = false"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
      <v-card-text class="pt-3">
        <v-window v-model="step" class="fill-height" style="overflow: visible;">
          <v-window-item class="fill-height">
            <v-container fill-height>
              <v-layout row wrap align-center>
                <v-flex xs12 sm12 md12>
                  <div style="max-width: 850px" class="mx-auto text-center">
                    <div class="text-h4 mb-6 primary--text" style="font-weight: 300">
                      <span v-if="targetWidget && (targetWidget.type === 'root')">{{ $t('create') }} </span>
                      <span v-else>{{ $t('addAssetsTo') }}</span>
                      <v-menu :close-on-content-click="true" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" style="border-bottom: 1px dashed; text-decoration: none; cursor: pointer">{{getTargetWidgetName()}}</span>
                        </template>
                        <v-card class="pa-0">
                          <layout-list @itemSelected="setTargetWidget($event)"></layout-list>
                        </v-card>
                      </v-menu>
                      <template v-if="targetWidget && (targetWidget.type === 'root')">
                        <span>{{ $t('and') }}</span><span @click="createEmptyGallery()" style="border-bottom: 1px solid; text-decoration: none; cursor: pointer">{{ $t('leaveItEmpty') }}</span>
                      </template>
                    </div>
                    <div v-if="targetWidget && (targetWidget.type === 'root')" style="width: 100%" class="text-center mb-2">or</div>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-card :class="`upload-select rounded-xl py-${$vuetify.breakpoint.mobile ? 3 : 10}`" elevation="1" @click="uploadStep" color="white">
                            <div class="px-10 pb-10" style="height: 175px; position: relative;" v-if="$vuetify.breakpoint.mdAndUp">
                              <v-icon :size="100" color="primary" style="position: absolute; left: 50%; bottom: 16px; transform: translateX(-50%)">mdi-tray-arrow-up</v-icon>
                            </div>
                            <div class="text-h4" style="font-weight: 300">{{ $t('upload') }}</div>
                            <div class="mt-2" style="max-width: 300px; margin: auto">{{ $t('uploadFilesFrom') }}<br />&nbsp;</div>
                            <v-btn rounded x-large :class="`add-assets-select text--primary elevation-1 ${$vuetify.breakpoint.mobile ? 'mt-3' : 'mt-10 mb-3'}`">{{ $t('select') }}</v-btn>
                          </v-card>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-card :class="`collect-select rounded-xl py-${$vuetify.breakpoint.mobile ? 3 : 10}`" elevation="1" @click="collectStep" color="white">
                            <div class="px-10 pb-10" style="height: 175px; position: relative;" v-if="$vuetify.breakpoint.mdAndUp">
                              <v-icon :size="100" color="primary" style="position: absolute; left: 50%; bottom: 16px; transform: translateX(-50%)">mdi-tray-arrow-down</v-icon>
                            </div>
                            <div class="text-h4" style="font-weight: 300">{{ $t('collect') }}</div>
                            <div class="mt-2" style="max-width: 300px; margin: auto">{{ $t('requestAssetsFrom') }}</div>
                            <v-btn rounded x-large :class="`add-assets-select text--primary elevation-1 ${$vuetify.breakpoint.mobile ? 'mt-3' : 'mt-10 mb-3'}`">{{ $t('select') }}</v-btn>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-window-item>
          <v-window-item class="fill-height">
            <template v-if="type == 'upload'">
              <v-btn fab large absolute left style="top: 50%; transform: translateY(-50%)" color="white" elevation="0" @click="step--"><v-icon x-large>mdi-chevron-left</v-icon></v-btn>
              <v-container fill-height>
                <v-layout row wrap align-center>
                  <v-flex xs12 sm12 md12 class="text-center">
                    <div style="max-width: 700px" class="mx-auto text-center pa-10 white rounded-xl elevation-1">
                      <div class="text-h4 pb-6" style="font-weight: 300; color: black">{{ $t('uploadFilesTo') }}</div>
                      <add-assets-widget v-model="targetWidget">
                        <template v-slot:finish-button>
                          <v-btn x-large rounded elevation="1" class="mx-auto mt-6 d-block secondary primary--text" @click="show = false">{{ $t('close') }}</v-btn>
                        </template>
                      </add-assets-widget>
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>
            </template>
            <template v-else-if="type == 'collect'">
              <v-btn fab large absolute left style="top: 50%; transform: translateY(-50%)" color="white" elevation="0" @click="step--"><v-icon x-large>mdi-chevron-left</v-icon></v-btn>
              <v-container fill-height>
                <v-layout row wrap align-center>
                  <v-flex xs12 sm12 md12>
                    <div style="max-width: 700px" class="mx-auto py-10 px-5 white text-center rounded-xl elevation-1">
                      <div class="text-h4" style="font-weight: 300; color: black">{{ $t('writeYourInstructions') }}</div>
                      <p class="text-subtitle-1 pb-6">{{ $t('tellOtherUsers') }}</p>
                      <v-combobox v-model="emails" label="Enter e-mail" :search-input.sync="currentEmail" outlined flat dense multiple chips hide-details class="mb-3" :delimiters="[' ', ',', ';']">
                        <template v-slot:append>&nbsp;</template>
                        <template v-slot:no-data>
                          <template v-if="isValidEmail(currentEmail)">
                            <v-list-item @click="() => {emails.push(currentEmail); currentEmail = null;}">
                              <v-list-item-content>
                                <v-list-item-title>Use <strong>{{ currentEmail }}</strong></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </template>
                        <template v-slot:selection="data">
                          <v-tooltip top :disabled="isValidEmail(data.item)">
                            <template v-slot:activator="{on}">
                              <v-chip class="my-1" label small v-on="on" v-bind="data.attrs" :input-value="data.selected" :color="isValidEmail(data.item) ? 'rgba(0,0,0,0.1)' : 'error'" close @click:close="data.parent.selectItem(data.item)">{{ data.item }}</v-chip>
                            </template>
                            <span>Not a valid email address</span>
                          </v-tooltip>
                        </template>
                      </v-combobox>
                      <div @contextmenu.prevent="showEditorContextMenu">
                        <vue-editor v-model="instructions" ref="editor" :editorToolbar="[[{ 'size': ['small', false, 'large', 'huge'] }],['bold', 'italic', 'underline'],['link'],[{ 'list': 'ordered'}, { 'list': 'bullet' }], [{ 'color': [] }, { 'background': [] }],['clean']]" :editorOptions="{ theme: 'bubble', placeholder: 'Instructions'}" class="text-left collect-message"></vue-editor>
                      </div>
                      <v-btn x-large rounded elevation="1" class="mx-auto mt-6 d-block secondary primary--text" :loading="creatingCollect" :disabled="!validEmails || !validEmails.length" @click="createShareToCollect">Send</v-btn>
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>
            </template>
            <template v-else>
              <div style="width: 100%; height: 100%;"></div>
            </template>
          </v-window-item>
          <v-window-item class="fill-height" v-if="type == 'collect'">
            <v-btn fab large absolute left style="top: 50%; transform: translateY(-50%)" color="white" elevation="0" @click="step--"><v-icon x-large>mdi-chevron-left</v-icon></v-btn>
            <v-container fill-height>
              <v-layout row wrap align-center>
                <v-flex xs12 sm12 md12 class="text-center">
                  <div style="max-width: 700px" class="mx-auto text-center pa-10 white rounded-xl elevation-1">
                    <div class="text-h4" style="font-weight: 300; color: black">You've successfully requested file contributions</div>
                    <p class="text-subtitle-1 pb-6">{{validEmails.length > 1 ? 'Emails' : 'An email'}} with instructions has been sent. Thank you!</p>
                    <v-btn x-large rounded elevation="1" class="mx-auto mt-6 d-block secondary primary--text" @click="show = false">{{ $t('close') }}</v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-window-item>
        </v-window>
      </v-card-text>
      <v-card-actions class="d-block">
        <v-alert color="primary white--text" transition="scale-transition" dark dismissible class="rounded-pill elevation-1 mt-4" v-model="error.show"><v-icon color="white">mdi-alert-circle-outline</v-icon> {{ error.message }}</v-alert>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
.fill-height {
  height: 100%;
}
.add-assets-dialog {
  overflow: visible !important;
}
.create-canvas-dialog .v-text-field {
  font-size: 32px;
}
.create-canvas-dialog .v-text-field input {
  max-height: 42px;
}
.create-canvas-dialog .one-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 160px;
}
.add-assets-select {
  transition: background-color 200ms linear, border-color 200ms linear;
}
.upload-select:hover .add-assets-select,
.collect-select:hover .add-assets-select {
  background-color: #f9aa33 !important;
  border-color: #f9aa33 !important;
}
</style>
<script>
import { VueEditor } from "vue2-editor";
import { mapActions, mapGetters } from 'vuex'
import { EventBus } from '@/libs/eventBus.js'
import { getCanvasItemMap } from '@/libs/canvas.js'
import LayoutList from '@/components/widgets/layout-list'
import AddAssetsWidget from '@/components/widgets/add-assets'
import * as api from '@/libs/api.js'
import { AccessControl } from '@/libs/access-control.js'

export default {
  name: 'add-assets',
  components: {
    'layout-list': LayoutList,
    'add-assets-widget': AddAssetsWidget,
    'vue-editor': VueEditor
  },
  data: () => {
    return {
      render: 0,
      step: 0,
      type: null,
      url: null,
      files: [],
      fileGroupsPanel: [],
      name: null,
      uploading: false,
      creatingCollect: false,
      instructions: null,
      copying: false,
      emails: [],
      currentEmail: null,
      error: {
        show: false,
        message: null
      }
    }
  },
  watch: {
    show(value) {
      if (value && this.droppedFiles) {
        this.uploadStep();
        this.uploadFiles(this.droppedFiles);
      }
    }
  },
  computed: {
    show: {
      get: function() { return this.isDialogShown()('addAssets'); },
      set: function(value) { 
        this.clear();
        value ? this.showDialog('addAssets') : this.hideDialog('addAssets'); 
      }
    },
    path() {
      return this.$store.state.dialogs.addAssets.path;
    },
    targetWidget: {
      get() { return this.$store.state.dialogs.addAssets.targetWidget; },
      set(value) { this.$store.state.dialogs.addAssets.targetWidget = value; }
    },
    root() {
      return this.$store.state.canvas.root;
    },
    droppedFiles: {
      get() { return this.$store.state.dialogs.addAssets.files; },
      set(value) { this.$store.state.dialogs.addAssets.files = value; }
    },
    validEmails() {
      return this.emails.filter(email => this.isValidEmail(email));
    }
  },
  mounted() {
    EventBus.$on('uploadFileProgress', async (payload) => {
      let progress = payload.progress;
      let file = payload.file;
      let group = payload.group;
      if (group < 0 || group >= this.files.length)
        return;

      if (file < 0 || file >= this.files[group].length)
        return;

      this.files[group][file].progress = progress;

      this.render++;
    });
  },
  methods: {
    ...mapGetters(['isDialogShown']),
    ...mapActions(['showDialog', 'hideDialog']),
    normalizeString(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    clear() {
      this.step = 0;
      this.instructions = null;
      this.type = null;
      this.url = null;
      this.files = [];
      this.fileGroupsPanel = [];
      this.uploading = false;
      this.copying = false;
      this.emails = [];
      this.currentEmail = null;
      this.error = {
        show: false,
        message: null
      }
    },
    pickFile() {
      this.$refs.inputfile.click();
    },
    pickFolder() {
      this.$refs.inputfolder.click();
    },
    getMimeTypeIcon(mime) {
      if (!mime || !mime.length)
        return "mdi-file";

      if (mime === 'application/pdf')
        return 'mdi-file-document';

      else if (mime.match('image.*'))
        return 'mdi-file-image';

      if (mime.match('video.*'))
        return 'mdi-file-video';

      if (mime.match('audio.*'))
        return 'mdi-file-music';

      return 'mdi-file';
    },
    getGroupSize(group) {
      if (!group || !group.length)
        return 0;

      let size = 0;
      for (let file of group) {
        if (!file || !file.size)
          continue;

        size += file.size;
      }

      if (size < 1024)
        return size + ' B';

      else if (size < (1024 * 1024))
        return Math.floor(size / 1024) + ' kB';

      else 
        return Math.floor(size / (1024 * 1024)) + ' MB';
    },
    getGroupProgress(group) {
      if (group < 0 || group > this.files.length)
        return 0;

      let total = this.files[group].length * 100;
      let current = 0;
      for (let file of this.files[group]) {
        current += file.progress || 0;
      }

      return Math.floor(100  * (current / total));
    },
    async createEmptyGallery(aspectRatio, count) {
      let widget = this.targetWidget;
      let getSizeFromCount = (count) => {
        if (!count)
          return 1; // 6 cols.

        if (count <= 6)
          return 3; // 3 cols

        if (count <= 8)
          return 2; // 4 cols.

        return 1; // 6 cols.
      }
      let gallery = {
        type: 'gallery',
        cid: widget.cid,
        cfg: {
          name: '',
          aspectRatio: aspectRatio ? Math.floor(aspectRatio * 10) : 8,
          cover: true,
          outlined: false,
          rounded: false,
          elevation: 1,
          spacing: 1,
          showNames: true,
          size: getSizeFromCount(count)
        },
        kv: []
      }
      gallery.iid = await api.createItem(widget.cid, widget.iid, gallery.type, gallery.cfg);
      getCanvasItemMap(this.$store.state, gallery.cid)[gallery.iid] = {type: gallery.type, cfg: gallery.cfg, parent: widget.iid, cid: this.targetWidget.cid, iid: gallery.iid, checked: false, comments: []}
      gallery.items = [];
      widget.items.push(gallery);

      return gallery;
    },
    uploadStep() {
      this.type = 'upload';
      this.step++;
    },
    collectStep() {
      this.type = 'collect';
      this.step++;
    },
    async createShareToCollect() {
      if (!this.validEmails || !this.validEmails.length)
        return;

      this.creatingCollect = true;

      let targetWidget = this.targetWidget;

      if (this.targetWidget.type === 'root') {
        const cfg = {
          name: '',
          aspectRatio: 8,
          collect: true,
          cover: true,
          outlined: false,
          rounded: false,
          elevation: 1,
          spacing: 1,
          showNames: true,
          expanded: [0],
          size: 2
        }
        const type = 'gallery';
        const iid = await api.createItem(this.targetWidget.cid, this.targetWidget.iid, type, cfg);
        getCanvasItemMap(this.$store.state, targetWidget.cid)[iid] = {type: type, cfg: cfg, parent: this.targetWidget.iid, cid: this.targetWidget.cid, iid: iid}
        
        targetWidget = { cid: this.targetWidget.cid, iid: iid, type: type, cfg: cfg, items: [] };
      }
      try {
        let acLib = new AccessControl(this.$store);

        for (let email of this.validEmails)
          await acLib.shareWith(targetWidget.cid, targetWidget.iid, email, 'Provider', null, this.instructions);
          
        targetWidget.cfg.collect = true;
        await api.updateItem(targetWidget.cid, targetWidget.iid, { cfg: targetWidget.cfg });
        this.step++;
      }
      catch (err) {
        this.error.message = err;
        this.error.show = true;
      }

      this.loading = false;
      this.creatingCollect = false;
    },
    copyURL() {
      this.copying = true;
      if (navigator.clipboard)
        navigator.clipboard.writeText(this.url);
      else
        this.fallbackCopyTextToClipboard();
        
      setInterval(()=>{this.copying = false}, 3000);
    },
    fallbackCopyTextToClipboard() {
      let el = document.getElementById('publicURL');
      el.focus();
      el.select();
      document.execCommand('copy');
    },
    mimeToType(mime) {
      if (mime.match('audio.*'))
        return 'audio';
      if (mime.match('video.*'))
        return 'video';
      if (mime.match('image.*'))
        return 'img';

      return 'file';
    },
    widgetCanContainType(widgetType, itemType) {
      if (widgetType === 'gallery' && (itemType === 'img' || itemType === 'video' || itemType === 'audio'))
        return true;

      else if (widgetType === 'file-table' || widgetType === 'grid')
        return true;

      return false;
    },
    getUrl(path) {
      return window.location.origin + '/' + path
    },
    getTargetWidgetName() {
      if (!this.targetWidget || this.targetWidget.type === 'root')
        return 'a new section';

      if (!this.targetWidget.cfg || !this.targetWidget.cfg.name || !this.targetWidget.cfg.name.length) {
        return 'Untitled section';
      }

      return this.targetWidget.cfg.name;
    },
    showEditorContextMenu() {
      this.$refs.editor.quill.theme.tooltip.edit();
      this.$refs.editor.quill.theme.tooltip.show();
    },
    setTargetWidget(widget) {
      this.targetWidget = widget;
    },
    async getImageDimensions(file) {
      if (!file)
        return null;
      return new Promise(
        (resolve, reject) => {
          let fr = new FileReader;
          fr.onload = function() {
            let img = new Image;
            img.onload = function() {
              resolve({
                width: img.width,
                height: img.height
              });
            };
            img.onerror = function() { reject(); };
            img.onabort = function() { reject(); };
            img.src = fr.result;
          };
          fr.onerror = function() { reject(); };
          fr.onabort = function() { reject(); };
          fr.readAsDataURL(file);
        }
      );
    },
    async getAverageAspectRatio(files) {
      if (!files)
        return null;
      let aspectRatios = [];
      for (let i = 0; i < files.length; ++i) {
        let file = files.item(i)
        const type = this.mimeToType(file.type);
        if (type === 'img') {
          try {
            const dimensions = await this.getImageDimensions(file);
            if (!dimensions || !dimensions.width || !dimensions.height)
              continue;
            aspectRatios.push(dimensions.width / dimensions.height);
          }
          catch (err) {
            console.log(err);
          }
        }
      }
      if (!aspectRatios.length)
        return null;

      return aspectRatios.reduce((partialSum, a) => partialSum + a, 0) / aspectRatios.length;
    },
    async pickSite() {
      await api.listImagesOnWebsite('sharespot.cz');
    },
    isValidEmail(email) {
      if (!email || !email.length)
        return false;
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(email);
    },
  }
}
</script>

<i18n>
{
  "en": {
    "addAssetsTo": "Add assets to ",
    "and": " and ",
    "leaveItEmpty": "leave it empty",
    "upload": "Upload",
    "select": "Select",
    "uploadFilesFrom": "Upload files from your device",
    "collect": "Collect",
    "requestAssetsFrom": "Request assets from others via a simple link",
    "uploadFilesTo": "Upload files to the section",
    "selectFiles": "Select files",
    "selectFolder": "Select folder",
    "selectMoreFiles": "Select more files",
    "or": "or",
    "anotherFolder": "another folder",
    "writeYourInstructions": "Write your instructions",
    "tellOtherUsers": "Tell other users how they should contribute. They don't need an account to submit their assets and won't see any other content apart from theirs",
    "createALink": "Create a link",
    "hereIsTheLink": "Here's the link to collect assets",
    "shareItWith": "Share it with anyone who should add some files to this section. You can send this link to multiple contributors - they won't see your or each other's content."
  }
}
</i18n>