<template>
<div class="item-list-widget">
<v-text-field class="my-2" dense outlined hide-details label="Enter name" v-model="newItem" :loading="loading" @keydown.enter="addNewItem()">
  <template v-slot:append>
    <div style="margin-right: 6px; margin-top: 0px; display: inline-flex; border-left: 1px solid silver; padding: 5px;" class="text-center">
      <v-btn class="elevation-0 rounded-pill" small @click="addNewItem()"><v-icon left>mdi-plus</v-icon> Add</v-btn>
    </div>
  </template>
</v-text-field>
<v-list>
  <v-list-item style="background-color: rgba(0,0,0,0.02); padding: 0px 8px;" class="rounded" v-for="(child) of item.items" :key="child.iid">
    <v-list-item-content class="pa-0">
      <v-list-item-title>
        <v-text-field class="my-2" dense solo flat hide-details v-model="child.cfg.name" v-on:change="updateItemConfig(child, () => child.cfg.name = $event)" label="Untitled" append-outer-icon="mdi-close" @click:append-outer="deleteItem(child)"></v-text-field>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</v-list>
</div>
</template>

<style>
.item-list-widget .v-input__append-inner {
  margin: 0px !important;
  align-self: auto !important;
  align-items: center !important;
}
.item-list-widget .v-input__slot {
  padding-right: 0px !important;
}
</style>

<script>
import * as api from '@/libs/api.js'
import { getCanvasItemMap } from '@/libs/canvas.js'
export default {
  name: 'ItemListWidget',
  props: {
    value: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      newItem: null,
      loading: false
    }
  },
  computed: {
    item: {
      get() { return this.value; },
      set(value) { this.$emit('input', value); }
    },
  },
  methods: {
    async addNewItem() {
      if (!this.item.items)
        this.item.items = [];

      this.loading = true;
      try {
        let gallery = { type: 'gallery', parent: this.item.iid, cid: this.item.cid, cfg: { name: this.newItem, aspectRatio: 10, cover: true, outlined: false, rounded: false, elevation: 1, spacing: 1, showNames: true, size: 1}, kv: [], checked: false, comments: [] };
        gallery.iid = await api.createItem(gallery.cid, this.item.iid, gallery.type, gallery.cfg);
        getCanvasItemMap(this.$store.state, gallery.cid)[gallery.iid] = gallery;
        this.item.items.push(gallery);
      }
      catch (err) {
        console.log(err);
      }

      this.loading = false;
      
      this.$emit('input', this.item);

      this.newItem = null;
    },
    async deleteItem(item) {
      this.item.items = this.item.items.filter(i => i.iid !== item.iid);
      this.$emit('input', this.item);
      this.loading = true;
      try {
        await api.removeItem(this.item.cid, this.item.iid, item.iid)
      }
      catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    async updateItemConfig(widget, functor) {
      if (functor)
        functor();
      if (widget.iid)
        await api.updateItem(widget.cid, widget.iid, { cfg: widget.cfg });
    },
  }
}
</script>