/* eslint-disable no-unused-vars */
import * as api from '@/libs/api.js'

export class Canvas {
  #store;

  constructor(store) {
    this.#store = store;
  }
}

export class CanvasList {
  #store;
  #updating = false;

  constructor(store) {
    // Singleton...
    if (CanvasList._instance)
      return CanvasList._instance;

    CanvasList._instance = this;
    this.#store = store;
  }

  async waitForUpdate() {
    if (this.#updating) {
      // Wait 20s max for update to finish
      let delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      let i = 0;
      while (this.#updating && ++i < 100) {
        await delay(200);
      }
    }
  }

  async #listStoreCanvases(updateIfNeeded = true) {
    await this.waitForUpdate();

    if ((!this.#store.canvases || !Object.keys(this.#store.canvases).length || Object.values(this.#store.canvases).findIndex(c => ('canvas' in c)) === -1) && updateIfNeeded) {
      this.#updating = true;
      let list = await api.listCanvases() || [];
      this.#store.canvases = {};
      list.forEach((c) => { 
        if (!(c.cid in this.#store.canvases) || this.#store.canvases[c.cid].needsUpdate)
          this.#store.canvases[c.cid] = {canvas: c, needsUpdate: true}; 
      });
      this.#updating = false;
    }

    if (!this.#store.canvases || !Object.keys(this.#store.canvases).length)
      return {};

    return this.#store.canvases;
  }

  #getCanvasItemMap(canvas) {
    let itemMap = {};
    if (!canvas || !canvas.root || !canvas.root.iid)
      return itemMap;

    let fi = (items) => {
      items.forEach((i) => {
        itemMap[i.iid] = i;
        if (i.items && i.items.length) 
          fi(i.items);
      });
    }

    itemMap[canvas.root.iid] = canvas.root;
    
    if (canvas.root.items && canvas.root.items.length)
      fi(canvas.root.items);

    return itemMap;
  }

  async #getSnapshot(cid, sid, updateIfNeeded = true) {
    if (cid in this.#store.canvases && this.#store.canvases[cid].snapshots && sid in this.#store.canvases[cid].snapshots && !this.#store.canvases[cid].snapshots[sid].needsUpdate) {
      return this.#store.canvases[cid].snapshots[sid];
    }

    await this.waitForUpdate();
    if (!(cid in this.#store.canvases))
      this.#store.canvases[cid] = { needsUpdate: true };

    if (!('snapshots' in this.#store.canvases[cid]))
      this.#store.canvases[cid].snapshots = {};

    if (!(sid in this.#store.canvases[cid].snapshots) || (this.#store.canvases[cid].snapshots[sid].needsUpdate && updateIfNeeded)) {
      this.#updating = true;

      let snapshot = await api.getSnapshot(cid, sid);
      if (snapshot)
        this.#store.canvases[cid].snapshots[sid] = { snapshot: snapshot, items: this.#getCanvasItemMap(snapshot), needsUpdate: false };

      this.#updating = false;
    }

    if (!(sid in this.#store.canvases[cid].snapshots))
      return null;

    return this.#store.canvases[cid].snapshots[sid];
  }

  async #getCanvas(cid, updateIfNeeded = true) {
    let canvases = await this.#listStoreCanvases(updateIfNeeded);
    if (cid in canvases && !canvases[cid].needsUpdate) {
      return canvases[cid];
    }

    await this.waitForUpdate();

    if ((!(cid in this.#store.canvases) || this.#store.canvases[cid].needsUpdate) && updateIfNeeded) {
      this.#updating = true;

      let canvas = await api.getCanvas(cid);
      if (canvas)
        this.#store.canvases[cid] = { canvas: canvas, items: this.#getCanvasItemMap(canvas), needsUpdate: false };

      this.#updating = false;
    }

    if (!(cid in this.#store.canvases))
      return null;

    return this.#store.canvases[cid];
  }

  updateCanvas(cid, canvas) {
    if (!(cid in this.#store.canvases))
      this.#store.canvases[cid] = { canvas: canvas, items: this.#getCanvasItemMap(canvas), needsUpdate: false };
    else {
      this.#store.canvases[cid].canvas = canvas;
      this.#store.canvases[cid].needsUpdate = false;
    }
  }

  async getCanvas(cid, updateIfNeeded = true) {
    return await this.#getCanvas(cid, updateIfNeeded);
  }

  async getSnapshot(cid, sid, updateIfNeeded = true) {
    return await this.#getSnapshot(cid, sid, updateIfNeeded);
  }

  async listCanvases() {
    return await this.#listStoreCanvases();
  }
}

export async function getCanvasItemMapAsync(state, cid) {
  try {
    const canvasList = new CanvasList(state);
    const canvas = await canvasList.getCanvas(cid);
    if (!canvas || !canvas.items)
      return {};

    return canvas.items;
  }
  catch (err) {
    console.log(err);
  }

  return {};
}

export function getCanvasItemMap(state, cid) {
  if (!state || !state.canvases || !state.canvases[cid] || !state.canvases[cid].items)
    return {};
    
  return state.canvases[cid].items;
}