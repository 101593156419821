import * as api from '@/libs/api.js'

export function humanFileSize(bytes, si=false, dp=1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si 
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}

export function getCanvasFullPath(canvasPath, cid) {
  let result = canvasPath;
  if (canvasPath && canvasPath.length && canvasPath[canvasPath.length-1] !== '/')
    result += '/';

  result += cid + '/';
  return result;
}

export function showConfirm(store, title, text, funct, type = null) {
  store.state.dialogs.confirm = {
    show: true,
    title: title,
    text: text,
    funct: funct,
    type: type
  }
}

export function stringToColor(str) {
  const hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).substr(-2);
  }

  let rgb = hexToRgb(colour);
  return 'rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ', 1)';
}

export function rgbToHex(r, g, b) {
  let componentToHex = (c) => {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }
  return "" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : {r:0,g:0,b:0};
}

export function rgbToHsl(r, g, b) {
    r /= 255, g /= 255, b /= 255;
    var max = Math.max(r, g, b), min = Math.min(r, g, b);
    var h, s, l = (max + min) / 2;

    if (max == min)
        h = s = 0;
    else
    {
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }
    return [h, s, l];
}

export function hslToRgb(h, s, l) {
  var r, g, b;

  if (s == 0)
      r = g = b = l;
  else {
    let hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1/6) return p + (q - p) * 6 * t;
      if (t < 1/2) return q;
      if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
      return p;
    }

    var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    var p = 2 * l - q;
    r = hue2rgb(p, q, h + 1/3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1/3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

export function stringToSeed(str) {
    for(var i = 0, h = 1779033703 ^ str.length; i < str.length; i++)
        h = Math.imul(h ^ str.charCodeAt(i), 3432918353),
        h = h << 13 | h >>> 19;
    return function() {
        h = Math.imul(h ^ h >>> 16, 2246822507);
        h = Math.imul(h ^ h >>> 13, 3266489909);
        return (h ^= h >>> 16) >>> 0;
    }
}

export function seededRandomColor(str) {
  if (!str)
    return '000000';
  let seed = stringToSeed(str);
  let LCG = (s) => (Math.imul(741103597, s) >>> 0) / 2 ** 32;
  const symbols = '0123456789ABCDEF';      
  let color = '';      
  for (let i = 0; i < 6; i++) {
    color += symbols[Math.round(LCG(seed() + i) * 16)] || '0';
  }
  let rgb = hexToRgb("#"+color);
  let [h, s, l] = rgbToHsl(rgb.r, rgb.g, rgb.b)

  // Tweak lightnes and saturation to match our color palette
  l = Math.max(0.8, Math.min(0.95, l));
  s = Math.max(0.5, Math.min(0.85, s));
  let [r,g,b] = hslToRgb(h, s, l);

  color = rgbToHex(r,g,b);
  
  return "#" + color;
}

// timestamp to date
export function timestampToDate(timestamp) {
  let date = new Date(timestamp);
  return date.toLocaleString();
}

export function prettyPrintJson(json, html = true) {
  if (!html)
    return JSON.stringify(json, undefined, 2);

  if (typeof json != 'string') {
    json = JSON.stringify(json, undefined, 2);
  }
  json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n/g, '<br/>').replace(/ /g, '&nbsp;');
  console.log(json)
  return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g, function (match) {
    var cls = 'json-number';
    if (/^"/.test(match)) {
      if (/:$/.test(match)) {
        cls = 'json-key';
      } else {
        cls = 'json-string';
      }
    } else if (/true|false/.test(match)) {
      cls = 'json-boolean';
    } else if (/null/.test(match)) {
      cls = 'json-null';
    }
    return '<span class="' + cls + '">' + match + '</span>';
  });
}

export function hideCollectItem(item, collectItemMap) {
  if (!item || !item.dependency)
    return false;

  if (!collectItemMap || !collectItemMap[item.dependency.cfid])
    return false;

  let dependency = collectItemMap[item.dependency.cfid];
  if (!dependency)
    return true;

  if (item.dependency.valueIs)
    return dependency.value !== item.dependency.valueIs;
  else if (item.dependency.valueIsNot)
    return dependency.value === item.dependency.valueIsNot;
  else if (item.dependency.valueIsOneOf)
    return !item.dependency.valueIsOneOf.includes(dependency.value);
  else if (item.dependency.valueIsNotOneOf)
    return item.dependency.valueIsNotOneOf.includes(dependency.value);
  else if (item.dependency.valueIsNotEmpty)
    return !dependency.value || dependency.value.length === 0;
  else if (item.dependency.valueIsEmpty)
    return dependency.value && dependency.value.length > 0;
  else if (item.dependency.minLength)
    return dependency.value && dependency.value.length < item.dependency.minLength;
  else if (item.dependency.maxLength)
    return dependency.value && dependency.value.length > item.dependency.maxLength;
  else if (item.dependency.minValue)
    return dependency.value && dependency.value < item.dependency.minValue;
  else if (item.dependency.maxValue)
    return dependency.value && dependency.value > item.dependency.maxValue;
  else if (item.dependency.minDateIsToday)
    return dependency.value && dependency.value < new Date();
  else if (item.dependency.maxDateIsToday)
    return dependency.value && dependency.value > new Date();
  else if (item.dependency.valueIncludes)
    return dependency.value && !dependency.value.includes(item.dependency.valueIncludes);
  else if (item.dependency.valueExcludes)
    return dependency.value && dependency.value.includes(item.dependency.valueExcludes);
}

function isCollectibleEmpty(item, collectibleItem) {
  if (!item || !item.cfids || !collectibleItem || !item.cfids.includes(collectibleItem.cfid) || !collectibleItem.target)
    return false;

  let target = collectibleItem.target;
  let configTarget = false;

  if (collectibleItem.target.indexOf('.') !== -1) {
    let targetParts = collectibleItem.target.split('.');
    if (targetParts.length === 2 && targetParts[0] === 'cfg') {
      target = targetParts[1];
      configTarget = true; 
    }
  }

  if (configTarget) {
    if (item.cfg && item.cfg[target] && item.cfg[target] !== null)
      return false;
  }
  else {
    if (item[target] && item[target] !== null)
      return false;
  }

  return true;
}

function isProviderCollectibleEmpty(item, uid) {
  if (!item)
    return false;

  if (!item.items || !item.items.length)
    return true;

  for (let child of item.items) {
    if (child.owner && child.owner === uid)
      return false;
  }

  return true;
}

export async function updateCollectibleValue(itemMap, item, value) {
  if (!item.item && !item.kv)
    return;

  if (!itemMap)
    return;

  if (item.item) {
    if (!item.target)
      return;

    let canvasItem = itemMap[item.item.iid];
    let target = item.target;
    let configTarget = false;

    if (item.target.indexOf('.') !== -1) {
      let targetParts = item.target.split('.');
      if (targetParts.length === 2 && targetParts[0] === 'cfg') {
        target = targetParts[1];
        configTarget = true; 
      }
    }

    if (configTarget) {
      if (!canvasItem.cfg)
        canvasItem.cfg = {};

      canvasItem.cfg[target] = value;
      await api.updateItem(canvasItem.cid, canvasItem.iid, { cfg: canvasItem.cfg });
    }
    else {
      canvasItem[target] = value;
      let params = {};
      params[target] = value;
      await api.updateItem(canvasItem.cid, canvasItem.iid, params);
    }

  }
  else if (item.kv) {
    let canvasItem = itemMap[item.kv.iid];
    if (canvasItem && canvasItem.kv) {
      let i = canvasItem.kv.findIndex(kv => kv.kvid === item.kv.kvid);
      if (i >= 0)
        canvasItem.kv[i].value = value;
    }

    await api.updateKeyValueItem(item.kv.cid, item.kv.iid, item.kv.kvid, { k: item.kv.key, v: value });
  }
}

export function getCollectFlow(canvas, state) {
  if (!canvas)
    return null;

  let collectFlow = canvas.collectFlow || [];
  let collectItems = canvas.collectItems || [];
  let providerCollectables = [];
  
  // Check, if the user is added as a Provider somewhere in the canvas, if so, add a collect flow items
  if (canvas.acs && canvas.acs.length) {
    canvas.acs.filter(ac => ac.role === 'Provider').forEach(ac => {
      collectFlow.push({ cfid: ac.iid + '-provider' });
      let collectItem = { cfid: ac.iid + '-provider', id: 'files', type: 'files'};
      if (ac.msg && ac.msg.length)
        collectItem.text = { en: {subheader: ac.msg} };
        
      collectItems.push(collectItem);
      providerCollectables.push(ac.iid);
    });
  }

  if (!canvas.root || !collectFlow.length || !collectItems.length)
    return null;

  let collectItemMap = {};
  for (let item of collectItems)
    collectItemMap[item.cfid] = item;

  let collectFlowMap = {};
  for (let item of collectFlow) {
    let collectItem = collectItemMap[item.cfid];
    if (!collectItem)
      continue;

    collectFlowMap[item.cfid] = collectItem;
  }

  let emptyCollectItems = [];
  const findCollectables = (item) => {
    
    // Is the item itself a collectable?
    if (item.cfids && item.cfids.length) {
      for (let cfid of item.cfids) {
        if(!(cfid in collectItemMap))
          continue;

        let collectItem = collectItemMap[cfid];

        if (!item.collectItems)
          item.collectItems = [];

        item.collectItems.push(collectItem);
        collectItem.item = item;

        if (!collectItem.target || !collectItem.target.length)
          continue;

        if (isCollectibleEmpty(item, collectItem))
          emptyCollectItems.push(collectItem);
      }
    }

    if (providerCollectables.includes(item.iid)) {
      let collectItem = collectItemMap[item.iid + '-provider'];
      if (collectItem) {
        if (!item.collectItems)
          item.collectItems = [];

        item.collectItems.push(collectItem);
        collectItem.item = item;

        if (!collectItem.text && item.cfg && ((item.cfg.msg && item.cfg.msg.length) || (item.cfg.name && item.cfg.name.length)))
          collectItem.text = { en: {} };
        
        if (item.cfg && item.cfg.msg && !collectItem.text.en.subheader)
          collectItem.text.en.subheader = item.cfg.msg;
        
        if (item.cfg&& item.cfg.name && !collectItem.text.en.header)
          collectItem.text.en.header = item.cfg.name;

        if (isProviderCollectibleEmpty(item, state.user.uid))
          emptyCollectItems.push(collectItem);
      }
    }
    
    // Are the KVs collectables?
    if (!item.kv || !item.kv.length) 
      return;
    
    for (let kv of item.kv) {
      if (!kv.cfid)
        continue;

      // if kv does have cfid and we know it, put the collect item in kv and vice versa
      if (kv.cfid in collectItemMap) {
        kv.collectItem = collectItemMap[kv.cfid];
        collectItemMap[kv.cfid].kv = kv;
        collectItemMap[kv.cfid].value = kv.value;
      }

      // If kv has cfid that is in the collect flow and it still doesn't have a value, let's mark it (found) as we'll be instructing the caller that
      // there are still some required values that need to be filled in
      //
      //  - note that collect flow is a subset of collect items - it just describes what is the initial flow to fill in the template
      if (kv.cfid in collectFlowMap) {
        if (!kv.value) {
          let collectItem = collectFlowMap[kv.cfid];
          if (collectItem.optional)
            continue;

          emptyCollectItems.push(kv.collectItem);
        }
      }
    }
  }
  const traverseFindCollectItems = (item) => {
    if (!item.items || !item.items.length)
      return;
    for (let i = item.items.length -1; i >= 0; i--) {
      findCollectables(item.items[i]);

      if (item.items[i].items && item.items[i].items.length)
        traverseFindCollectItems(item.items[i]);
    }
  }
  findCollectables(canvas.root);
  traverseFindCollectItems(canvas.root);

  // If we found any empty values, it still might be ok, if they shouldn;t be displayed based on their dependencies
  for (let i = emptyCollectItems.length -1; i >= 0; i--) {
    if (hideCollectItem(emptyCollectItems[i], collectItemMap))
      emptyCollectItems.splice(i, 1);
  }

  if (!emptyCollectItems.length)
    return null;

  return emptyCollectItems;
}