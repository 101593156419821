<template>
<v-container class="casting-overview fill-height">
  <v-row no-gutters>
    <v-col cols="12" sm="4" class="pa-2 fill-height">
      <v-card class="elevation-1 rounded fill-height">
        <v-card-title>Active projects</v-card-title>
        <v-card-text>
          <v-list rounded>
            <v-list-item v-for="(project) of activeProjects" :key="project.cid" @click="open(project)" class="elevation-1 hoverable-ex pl-3" style="background-color: rgba(0,0,0,0.03)">
              <v-list-item-action class="mr-1" :class="!anyChecked ? 'show-on-hover-ex' : null" @click.prevent.stop="check(project)"><v-icon size="21">{{project.checked ? 'mdi-check-circle' : 'mdi-checkbox-blank-circle-outline'}}</v-icon></v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{project.nm}}</v-list-item-title>
                <v-list-item-subtitle>{{timestampToDate(project.updated || project.created)}}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action><v-icon>mdi-chevron-right</v-icon></v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions class="pb-6"><v-spacer></v-spacer><v-btn large class="rounded-pill elevation-1 px-4" color="secondary primary--text" @click="createCasting()">Create new project</v-btn><v-spacer></v-spacer></v-card-actions>
      </v-card>
      <v-expansion-panels flat hover class="text-left mt-5 elevation-1 rounded">
        <v-expansion-panel>
          <v-expansion-panel-header>Archive</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list rounded>
              <v-list-item v-for="(project) of archivedProjects" :key="project.cid" @click="open(project)" class="elevation-1 hoverable-ex pl-3" style="background-color: rgba(0,0,0,0.03)">
                <v-list-item-action class="mr-1" :class="!anyChecked ? 'show-on-hover-ex' : null" @click.prevent.stop="check(project)"><v-icon size="21">{{project.checked ? 'mdi-check-circle' : 'mdi-checkbox-blank-circle-outline'}}</v-icon></v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{project.nm}}<v-chip class="ml-2" small>{{project.status}}</v-chip></v-list-item-title>
                  <v-list-item-subtitle>{{timestampToDate(project.updated || project.created)}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action><v-icon>mdi-chevron-right</v-icon></v-list-item-action>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <v-col cols="12" sm="8" class="pa-2 fill-height">
      <v-card class="elevation-1 rounded fill-height">
        <v-card-title>Actor database</v-card-title>
        <v-card-text class="text-center">
          <div class="stats">
            <v-container>
              <v-row no-gutters>
                <v-col cols="6" sm="3" xl="2" style="border: 1px solid rgba(0,0,0,0.05); position: relative" class="pa-2">
                  <span>Actors</span>
                  <div class="vcenter hcenter">
                    <v-icon size="100" color="primary">mdi-account-group-outline</v-icon>
                    <div class="text-h4">{{actorsCount}}</div>
                  </div>
                </v-col>
                <template v-for="(stat, i) of stats">
                  <v-col cols="6" sm="3" xl="2" :key="i" v-if="stat.id !== 'ct'" style="border: 1px solid rgba(0,0,0,0.05)" class="pa-2">
                    <span>{{stat.id}}</span>
                    <pie-chart style="width: 100%; " :class="`pie-chart-${i}`" :id="`.pie-chart-${i}`" :value="stat.values"></pie-chart>
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </div>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn large class="rounded-pill elevation-1 px-4" color="secondary primary--text" @click="createActor()">Add new actor</v-btn>
          <v-btn large class="rounded-pill elevation-1 px-4" color="secondary primary--text">Browse database</v-btn>
          <v-spacer></v-spacer></v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<style>
.casting-overview .stats {
  overflow-y: auto;
  height: 580px;
}
.casting-overview .v-expansion-panel-content__wrap {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
</style>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import { CanvasList } from '@/libs/canvas.js'
import { EventBus } from '@/libs/eventBus.js'
import { User } from '@/libs/user.js'
import PieChart from '@/components/widgets/pie-chart'
import * as tools from '@/libs/tools.js'

export default {
  name: 'casting-overview-page',
  props: {
    id: {
      type: String,
      default: () => null
    }
  },
  components: {
    'pie-chart': PieChart,
  },
  data: () => {
    return {
      stats: null,
      loading: true,
      projects: [],
      actors: {},
      user: null
    }
  },
  computed: {
    anyChecked() {
      for (let project of this.projects) {
        if (project.checked)
          return true;
      }
      return false;
    },
    activeProjects() {
      let active = [];
      if (!this.projects.length)
        return active;

      for (let i = 0; i < this.projects.length; i++) {
        if (this.projects[i].status && (this.projects[i].status === 'Done' || this.projects[i].status === 'Canceled'))
          continue;

        active.push(this.projects[i]);
      }

      return active;
    },
    archivedProjects() {
      let archived = [];
      if (!this.projects.length)
        return archived;

      for (let i = 0; i < this.projects.length; i++) {
        if (this.projects[i].status && (this.projects[i].status === 'Done' || this.projects[i].status === 'Canceled'))
          archived.push(this.projects[i]);
      }

      return archived;
    },
    actorsCount() {
      if (!this.user)
        return 0;

      if (this.user.accountStats && this.user.accountStats.ct) {
        const castingProfilesStats = this.user.accountStats.ct.find((stat) => stat.k === 'casting-profile');
        if (castingProfilesStats && castingProfilesStats.v)
          return castingProfilesStats.v;
      }

      return 0;
    }
  },
  async mounted() {
    this.loading = true;
    try {
      const canvasList = new CanvasList(this.$store.state);
      const canvases = await canvasList.listCanvases();
      for (let cid in canvases) {
        const {canvas: canvas} = canvases[cid];

        if (canvas.type !== 'casting-profile')
          this.projects.push({...canvas, checked: false});
      }
      this.projects.sort((a, b) => {
        return b.created - a.created;
      });
      let userLib = new User(this.$store.state);
      this.user = await userLib.getUser();
      if (this.user && this.user.accountStats) {
        this.stats = [];

        for (let id in this.user.accountStats) {
          let stat = this.user.accountStats[id];
          // sort stat by value
          stat.sort((a, b) => {
            return b.v - a.v;
          });

          this.stats.push({id: id, values: stat});
        }
      }
    }
    catch (err) {
      console.error(err);
    }

    this.loading = false;

    EventBus.$on('orgChartUpdateSelection', (data) => {
      for (let project of this.projects)
        project.checked = false;

      if (data && data.length) {
        for (let item of data) {
          if (!item.id && !item.cid && !item.iid)
            continue;

          for (let project of this.projects) {
            if (project.cid === item.cid) {
              project.checked = true;
              break;
            }
          }
        }
      }
      EventBus.$emit('orgChartSelectionUpdated', data);
    });
  },
  methods: {
    open(item) {
      if (!item || !item.cid)
        return;
      this.$router.push('/canvas/' + item.cid)
    },
    createCasting() {
      // TEMP -- do not hardcode the template!!!
      EventBus.$emit('orgChartAddNode', {template: {cid: '18df7342-0bcc-42ef-8b0d-83a56dbdac6c', iid: 'f9497d54-2ea4-4a8e-a2e8-fdda1ce8ead3'}, action: 'add-canvas' });
    },
    createActor() {
      // TEMP -- do not hardcode the template!!!
      EventBus.$emit('orgChartAddNode', {template: {cid: '61976f1e-9b3d-42d1-a1b2-f4c386a36b6f', iid: '434e5807-61a9-4655-b6e2-6fe972f43e3c'}, action: 'add-canvas' });
    },
    check(item) {
      if (!item || !item.cid)
        return;

      item.checked = !item.checked;

      let selected = [];

      for (let project of this.projects) {
        if (project.checked)
          selected.push(project);
      }

      EventBus.$emit('orgChartSelectionUpdated', selected);
    },
    timestampToDate(timestamp) {
      return tools.timestampToDate(timestamp);
    }
  }
}
</script>