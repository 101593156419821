<template>
<v-card class="list-widget-item pa-0" :class="getRoundedClass()" :elevation="config.elevation" :outlined="config.outlined" light>
  <v-container fluid class="pa-0" :class="preview ? 'preview' : null">
    <v-row no-gutters>
      <v-col :cols="getThumnailSize()" style="overflow: hidden">
        <canvas-item-thumbnail v-model="item" :config="config" @view="viewItem($event)" :noAspectRatio="noAspectRatio"></canvas-item-thumbnail>
      </v-col>
      <v-col :cols="12 - getThumnailSize()">
        <div class="py-3 pl-2 pr-0" style="position: relative; height: 100%">
          <v-btn v-if="editing" absolute top right fab elevation="1" x-small @click.stop.prevent="check()" style="top: 4px; right: 4px;" color="rgba(255,255,255,0.7)" :class="itemsChecked ? null : 'show-on-hover'">
            <v-icon size="24" color="primary">{{item.checked ? `mdi-check-circle` : `mdi-checkbox-blank-circle-outline`}}</v-icon>
          </v-btn>
          <template v-if="!editing">
            <span v-if="!item.cfg.link || !item.cfg.link.length" class="text-h4 pr-6">{{item.cfg.name}}</span>
            <a v-else :href="item.cfg.link" target="_BLANK" class="text-h4 pr-6">{{item.cfg.name}}</a>
          </template>
          <v-text-field v-else flat solo hide-details  v-model="item.cfg.name" class="editing list-widget-item-caption text-h4 pr-6"  :class="(!item.cfg.link || !item.cfg.link.length) ? null : 'has-link'" v-on:change="updateItemConfig(item, () => item.cfg.name = $event)" ></v-text-field>
          <div v-if="item.kv && item.kv.length" class="list-item-description">
            <key-value-item v-for="(kv, k) in item.kv" :key="k" v-model="item.kv[k]" color="white" :editing="editing" :showCaption="k > 0" style="padding: 0px;"></key-value-item>
          </div>
          <div v-if="linkedItem && linkedItem.kv && linkedItem.kv.length" class="list-item-description">
            <key-value-item v-for="(kv, k) in linkedItem.kv" :key="k" v-model="linkedItem.kv[k]" color="rgba(0,0,0,0.02)" class="mb-2 mr-2 px-3 pt-1 pb-2" :editing="false" :showCaption="k > 0" listItem style="padding: 0px;"></key-value-item>
          </div>
          <div v-else class="list-item-description new-description" style="position: relative">
            <div contenteditable="true" @input="editNewKV" style="width: 100%; height: 100%; display: block;">{{newKV}}</div>
          </div>
          <div class="like" style="position: absolute; bottom: 10px; right: 44px; z-index: 4">
            <emoji-widget v-model="item.emojis" :iid="item.iid" :cid="item.cid"></emoji-widget>
          </div>
          <v-badge v-if="config.showComments" :value="comments.length > 0" left x-small overlap color="secondary" class="like" style="position: absolute; bottom: 16px; right: 16px; z-index: 4">
            <span slot="badge" class="primary--text">{{ comments.length }}</span>
            <v-btn @click.stop.prevent="showComments($event)" absolute bottom right fab elevation="1" x-small style="bottom: -6px; right: -6px;" color="primary" class="comments-button" :class="item.comments.length > 0 ? 'has-comments' : ''"><v-icon color="white">{{comments.length > 0 ? 'mdi-comment' : 'mdi-comment-outline'}}</v-icon></v-btn>
          </v-badge>
        </div>
      </v-col>
    </v-row>
  </v-container>
</v-card>
</template>

<style>
.has-link .v-text-field__slot {
  text-decoration: underline;
}
.link-text-field .v-input__append-outer{
  margin-top: 12px;
}
.like .v-badge__badge {
 z-index: 1000;
}
.list-widget-item {
  overflow: hidden;
}
.list-widget-item .list-widget-item-caption {
  padding-right: 48px;
}
.mobile .list-widget-item .list-widget-item-caption {
  padding-right: 0px;
}
.list-widget-item .list-widget-item-caption input {
  line-height: 36px;
}
.list-widget-item .list-widget-item-caption input:hover,
.list-widget-item .list-widget-item-caption input:focus  {
  background-color: rgba(240,240,240,0.9);
}

.list-widget-item  .v-input__append-outer,
.list-widget-item  .v-input__append-inner {
  display: none;
}
.list-widget-item .v-input--is-focused .v-input__append-outer,
.list-widget-item .v-input--is-focused .v-input__append-inner {
  display: flex;
}
.list-widget-item .text-h4 {
  font-weight: 300;
  font-size: 1.4vw !important;
  white-space: nowrap;
}

.list-widget-item .list-widget-item-caption .v-input__slot { min-height: unset; }

.columns-3 .list-widget-item .list-widget-item-caption input { line-height: 1.6vw !important; }
.columns-2 .list-widget-item .list-widget-item-caption input { line-height: 2.2vw !important; }
.columns-1 .list-widget-item .list-widget-item-caption input { line-height: 3.3vw !important; }

.columns-3 .list-widget-item .text-h4 { font-size: 1.4vw !important; line-height: 1.6vw !important; }
.columns-2 .list-widget-item .text-h4 { font-size: 2vw !important; line-height: 2.2vw !important; }
.columns-1 .list-widget-item .text-h4 { font-size: 3vw !important; line-height: 3.3vw !important; }
.list-widget-item .preview .text-h4 { font-size: 1.5rem !important; }

.columns-3 .list-widget-item .ql-editor { font-size: calc(10px + 0.4vw); }
.columns-2 .list-widget-item .ql-editor { font-size: calc(12px + 0.6vw); }
.columns-1 .list-widget-item .ql-editor { font-size: calc(12px + 0.8vw); }

.list-item-description {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 108px) !important;
}
.list-item-description,
.list-item-description-editor {
  background-color: transparent;
  position: absolute;
  width: calc(100% - 32px);
  height: calc(100% - 128px);
}
.list-widget-item .ql-editor {
  padding: 0px;
}
.desktop .comments-button {
  opacity: 0;
  transition: opacity 200ms linear;
}

.desktop .comments-button.has-comments,
.desktop .gallery-item:hover .comments-button,
.desktop .list-widget-item:hover .comments-button {
  opacity: 1;
}
.desktop .new-description.notoolbar .quillWrapper {
  height: calc(100% - 10px) !important;
}
.desktop .new-description.toolbar .quillWrapper {
  height: calc(100% - 56px) !important;
}
.desktop .new-description .ql-editor {
  height: 100% !important;
  min-height: initial !important;
}
.desktop .new-description.notoolbar .ql-toolbar {
  height: 0;
  overflow: hidden;
  padding: 0 !important;
  border-bottom: none;
}
</style>

<script>
import KeyValueItem from '@/components/widgets/key-value-item'
import * as api from '@/libs/api.js'
import { EventBus } from '@/libs/eventBus.js'
import { getCanvasItemMapAsync } from '@/libs/canvas.js'
import EmojiWidget from '@/components/widgets/emoji-widget'
import CanvasItemThumbnail from '@/components/canvas-items/canvas-thumbnail'
export default {
  name: 'list-item-widget',
  components: {
    'key-value-item': KeyValueItem,
    'emoji-widget': EmojiWidget,
    'canvas-item-thumbnail': CanvasItemThumbnail
  },
  props: {
    value: {
      type: Object,
      default: () => null
    },
    parent: {
      type: Object, 
      default: () => null
    },
    index: {
      type: Number,
      default: () => null
    },
    preview: {
      type: Boolean, 
      default: () => false
    },
    editing: {
      type: Boolean,
      default: () => false
    },
    noAspectRatio: {
      type: Boolean,
      default: () => false
    },
  },
  data: () => {
    return {
      linkedItem: null,
      newKV: null
    }
  },
  computed: {
    item() {
      let item = this.value;
      if (!item.cfg)
        item.cfg = {};
      return item;
    },
    likes() {
      return this.item.likes || [];
    },
    comments() {
      return this.item.comments || [];
    },
    itemsChecked() {
      return this.$store.state.canvas.checkedCount > 0;
    },
    config() {
      let widget = this.parent;
      return { 
        rounded: (widget && ('rounded' in widget.cfg)) ? widget.cfg.rounded : 0, 
        elevation: (widget && ('elevation' in widget.cfg)) ? widget.cfg.elevation : 1, 
        outlined: (widget && ('outlined' in widget.cfg)) ? widget.cfg.outlined : false, 
        aspectRatio: (widget && ('aspectRatio' in widget.cfg)) ? widget.cfg.aspectRatio : 10, 
        cover: (widget && ('cover' in widget.cfg)) ? widget.cfg.cover : true, 
        showLikes: !this.preview,
        showComments: !this.preview,
        showNames: (widget && ('showNames' in widget.cfg)) ? widget.cfg.showNames : false,
        size: (widget && ('size' in widget.cfg)) ? widget.cfg.size : 'medium', 
      }
    },
  },
  async mounted() {
    if (this.item && this.item.type === 'link' && this.item.cfg && this.item.cfg.cid) {
      const itemMap = await getCanvasItemMapAsync(this.$store.state, this.item.cfg.cid);
      if (itemMap && this.item.cfg.iid in itemMap)
        this.linkedItem = itemMap[this.item.cfg.iid];
    }
  },
  methods: {
    view() {
      this.$emit('view');
      if (this.index !== null && this.parent)
        EventBus.$emit('showViewDialog', { items: this.parent.items, index: this.index, widget: this.parent, editing: this.editing });
    },
    viewGroup() {
      //this.view();
      EventBus.$emit('navigateTo', { item: this.item, parent: this.parent });
    },
    viewDescription() {
      this.$emit('view');
      if (this.index !== null && this.parent)
        EventBus.$emit('showViewDialog', { items: this.parent.items, index: this.index, showDescription: true, widget: this.parent, editing: this.editing });
    },
    check() {
      this.item.checked = !this.item.checked;
    },
    async remove() {
      let parent = this.parent;
      parent.items.splice(this.index, 1);
      this.$emit('updateParent', parent);

      if (this.parent.iid && this.item.iid && this.item.cid) {
        try {
          await api.removeItem(this.item.cid, this.parent.iid, this.item.iid)
        }
        catch (err) {
          console.log(err);
        }
      }
    },
    async updateItemConfig(widget, functor, addToQueue) {
      EventBus.$emit('updateItemConfig', { widget: widget, functor: functor, addToQueue: addToQueue});
    },
    showComments(event) {
      if (!this.item || !this.item.iid)
        return;

      EventBus.$emit('showItemComments', { item: this.item, x: event.x, y: event.y });
    },
    getThumnailSize() {
      if (this.config.size <= 4)
        return 4;
      else if (this.config.size <= 6)
        return 5;
      else 
        return 6;
    },
    getRoundedClass() {
      if (!this.config || !this.config.rounded)
        return '';

      switch (this.config.rounded) {
        case 1: return 'rounded-sm';
        case 2: return 'rounded';
        case 3: return 'rounded-lg';
        case 4: return 'rounded-xl';
        case 5: return 'rounded-pill';
      }

      return 'rounded';
    },
    showEditorContextMenu() {
      this.$refs.editor.quill.theme.tooltip.edit();
      this.$refs.editor.quill.theme.tooltip.show();
    },
    openInNewTab(item) {
      if (!item.src)
        return;
      window.open(item.src, '_blank').focus();
    },
    getUrl(path) {
      return window.location.origin + '/' + path
    },
    viewItem(type) {
      if (!type)
        this.view();

      else if (type === 'new-tab')
        return this.openInNewTab(this.item);

      else if (type === 'group')
        return this.viewGroup();

      this.view();
    },
    editNewKV(e) {
      const re = /([-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?)/gi;
      let content = e.target.innerHTML+" ";
      let found = re.exec(content);
      console.log(found)
      if (found && found.length) {
        for (let f of found) {
          e.target.innerHTML = content.replace(/([-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?)/gi, "<a href=\""+f+"\" target=\"_BLANK\">"+f+"</a>");
          let range = document.createRange();
          let sel = window.getSelection();
          range.setStart(e.target.lastChild, 0);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
        }
      } 
    }
  }
}
</script>