/* eslint-disable no-unused-vars */
import * as api from '@/libs/api.js'
import { getCanvasItemMap } from '@/libs/canvas.js'

export class AccessControl {
  #store;
  #updating = false;

  constructor(store) {
    // Singleton...
    if (AccessControl._instance)
      return AccessControl._instance;

    AccessControl._instance = this;
    this.#store = store;
  }

  async #getACList(cid, path, uid = null) {
    if (this.#updating) {
      let delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      let i = 0;
      while (this.#updating && ++i < 100)
        await delay(200);
    }

    const UPDATE_DELAY = 1000 * 60 * 10; // do not update more often than once per 10 mins

    if (!(cid in this.#store.state.ac.canvases) || !this.#store.state.ac.canvases[cid].list || (Date.now() - this.#store.state.ac.updated > UPDATE_DELAY)) {
      this.#updating = true;
      this.#store.state.ac.updated = Date.now();
      this.#store.state.ac.canvases[cid] = {
        updated: Date.now(),
        list: await api.listShares(cid)
      }
      this.#updating = false;
    }

    let list = this.#store.state.ac.canvases[cid].list
    if (!list || !list.length)
      return [];

    return list.filter((ac) => path.includes(ac.iid) && (uid ? ac.uid === uid : true));
  }

  // path - array of iids from observed section iid through parents to the root iid
  //===========================================
  async listShares(cid, item, uid = null) {
  //===========================================
    let path = [];
    let parent = item;
    
    for (let i = 0; i < 20; ++i) {
      if (!parent)
        break;
      
      path.unshift(parent.iid);
      
      if (!parent.parent || !(parent.parent in getCanvasItemMap(this.#store.state, item.cid)))
        break;

      parent = getCanvasItemMap(this.#store.state, item.cid)[parent.parent];
    }
    return await this.#getACList(cid, path, uid);
  }

  //===========================================
  async shareWith(cid, iid, email, role, uid, message) {
  //===========================================
    if (!(cid in this.#store.state.ac.canvases) || !this.#store.state.ac.canvases[cid].list)
      await this.#getACList(cid, []);

    let ac = await api.shareWithUser(cid, iid, uid, email, role, false, message);
    this.#store.state.ac.canvases[cid].list.push(ac);
    this.#store.state.ac.updated = Date.now();

    return ac;
  }

  //===========================================
  async removeShare(cid, acid) {
  //===========================================
    if (!(await api.removeShare(cid, acid)))
      return false;

    if (!(cid in this.#store.state.ac.canvases) || !this.#store.state.ac.canvases[cid].list)
      await this.#getACList(cid, []);
      
    let index = this.#store.state.ac.canvases[cid].list.findIndex(item => item.acid === acid);
    if (index < 0)
      return false;

    this.#store.state.ac.canvases[cid].list.splice(index, 1);
    this.#store.state.ac.updated = Date.now();
    return true;
  }

  //===========================================
  async updateShare(cid, acid, role) {
  //===========================================
    if (!(await api.updateShare(cid, acid, role)))
      return false;

    if (!(cid in this.#store.state.ac.canvases) || !this.#store.state.ac.canvases[cid].list)
      await this.#getACList(cid, []);

    let index = this.#store.state.ac.canvases[cid].list.findIndex(item => item.acid === acid);
    if (index < 0)
      return false;

    this.#store.state.ac.canvases[cid].list[index].role = role;
    this.#store.state.ac.updated = Date.now();

    return true;
  }
}