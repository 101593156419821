<template>
  <div class="pa-0 fill-height" :key="render">
    <div v-if="loading" style="height: 100%">
      <v-container fill-height>
        <v-layout row wrap align-center>
          <v-flex xs12 sm12 md12 class="text-center">
            <v-progress-linear color="secondary" indeterminate rounded height="10" class="mx-auto" style="width: 25%"></v-progress-linear>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <div v-else-if="!showDashboard && (!canvases || !Object.keys(canvases).length)" style="height: 100%">
      <div style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); padding: 40px 80px; background-color: white;" class="text-center primary--text rounded-xl elevation-1">
        <p class="text-h5">{{ $t('itFeelsEmpty') }}</p>
        <p class="text-subtitle-1">{{ $t('letsCreateAGallery') }}</p>
        <v-btn class="mt-3" x-large rounded elevation="1" color="secondary primary--text" @click="createCanvas()" :loading="creatingCanvas">{{ $t('createGallery') }}</v-btn>
      </div>
    </div>
    <casting-dashboard v-else-if="showCastingOverview"></casting-dashboard>
    <simple-dashboard v-else-if="showDashboard"></simple-dashboard>
    <v-container v-else fluid grid-list-md class="folder-container pa-0">
      <v-row no-gutters class="pa-0">
        <v-col cols="12">
          <div style="position: absolute; top: 12px; right: 12px; width: 100%;">
            <v-btn icon color="primary" class="elevation-1 mx-1 white float-right" @click="swapOrgChart()"><v-icon>mdi-repeat-variant</v-icon></v-btn>
            <v-btn icon color="primary" class="elevation-1 mx-1 white float-right" @click="collapseOrgChart()"><v-icon>mdi-collapse-all-outline</v-icon></v-btn>
            <v-btn icon color="primary" class="elevation-1 mx-1 white float-right" @click="fitOrgChart()"><v-icon>mdi-fit-to-page-outline</v-icon></v-btn>
            <v-btn class="elevation-1 rounded-pill mx-1 float-right" :class="orgChartView === 'assignedToMe' ? 'secondary primary--text' : 'white'" @click="orgChartView = orgChartView === 'assignedToMe' ? null : 'assignedToMe'"><v-icon color="primary" left>mdi-progress-clock</v-icon> Assigned to me</v-btn>
            <div style="clear: both"></div>
          </div>
          <org-chart v-if="orgData" :data="orgData" :foundItems="foundItems" :view="orgChartView"></org-chart>
        </v-col>
      </v-row>
    </v-container>
    <v-alert :value="selection.length > 0" transition="fade-transition" color="#1a2629" dark class="text-center" height="80px" max-height="80px" style="position: fixed; border-radius: 0px; bottom: 0; left: 0px; padding: 8px; width: 100%; z-index: 20; margin: 0px;">
      <v-tooltip top color="#edf0f2">
        <template v-slot:activator="{on}"><v-btn v-on="on" :disabled="downloadingSelection" fab large elevation="0" color="primary" class="mx-3" @click="deleteSelected"><v-icon size="32">mdi-delete</v-icon></v-btn></template>
        <span class="primary--text">{{ $t('delete') }}</span>
      </v-tooltip>
      <v-tooltip top color="#edf0f2">
        <template v-slot:activator="{on}"><v-btn v-on="on" :loading="downloadingSelection" fab large elevation="0" color="primary" class="mx-3" @click="downloadSelected"><v-icon size="32">mdi-download</v-icon></v-btn></template>
        <span class="primary--text">{{ $t('download') }}</span>
      </v-tooltip>
      <v-tooltip top color="#edf0f2">
        <template v-slot:activator="{on}"><v-btn v-on="on" :disabled="downloadingSelection" fab large elevation="0" color="primary" class="mx-3" @click="cancelSelection"><v-icon size="32">mdi-close</v-icon></v-btn></template>
        <span class="primary--text">{{ $t('cancel') }}</span>
      </v-tooltip>
    </v-alert>
    <v-menu v-model="createMore.show" :position-x="createMore.x" :position-y="createMore.y" absolute offset-y :max-width="400" style="z-index: 1000">
      <v-list v-if="user.templates && user.templates.length">
        <v-list-item @click="createCanvas()">
          <v-list-item-title>Create empty project</v-list-item-title>
        </v-list-item>
        <v-list-item v-for="template in user.templates" :key="template.iid" @click="createCanvas(template)">
          <v-list-item-title>Create {{template.name.toLowerCase()}}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<i18n>
{
  "en": {
    "itFeelsEmpty": "It feels a bit empty doesn't it?",
    "letsCreateAGallery": "Let's create a project here!",
    "createGallery": "Create project",
    "loadGallery": "Load project",
    "createFolder": "Create folder",
    "pleaseTypeIn": "Please type in the name of the new folder",
    "confirmDeleteTitle": "Are your sure?",
    "confirmDeleteText": "Do you really want to delete selected?"
  }
}
</i18n>

<style scoped>
.profile-folder-card {
  cursor: pointer;
}
.folder-container {
  position: relative;
}
.top-bar {
  width: 100%;/*calc(100% - 256px);*/
  height: 96px;
  background-color: white;
  border-bottom: 1px solid rgba(0,0,0,0.12);
  position: absolute;
  top: 0px;
  left: 0px;/*256px;*/
  padding: 12px 12px 12px 24px;
}
.mobile .top-bar {
  width: 100%;
  left: 0px;
}
.folder-content {
  padding-top: 96px;
}
.filecard.file .decoration {
  width: 33%; 
  height: 25px; 
  margin-bottom: -15px;
  background-color: transparent;
}
.filecard.folder .decoration {
  width: 33%; 
  height: 25px; 
  margin-bottom: -15px;
  background-color: #f9aa33;
}
.filecard.folder .profile-folder-card {
  background-color: #f9aa33 !important;
}
.multicheck {
  opacity: 0;
  float: right;
  right: 4px; 
  top: 4px;
}
.mobile .multicheck,
.multicheck.checked,
.profile-folder-card:hover .multicheck {
  opacity: 1;
}
.white-gradient {
  background-image: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0.5));
}
.folder-caption {
  position: absolute; 
  left: 0px; 
  bottom: 0px; 
  width: 50%; 
  border-radius: 0px 8px 0px 0px;
}
.folder-caption.folder-canvas {
  background-color: rgba(255,255,255,0.9);
}
</style>

<script>
/* eslint-disable no-unused-vars */

import { Storage } from "aws-amplify"
import { mapActions } from 'vuex'
import { EventBus } from '@/libs/eventBus.js'
import { CanvasList } from '@/libs/canvas.js'
import * as api from '@/libs/api.js'
import { showConfirm } from '@/libs/tools.js'
import * as base64 from '@/libs/base64.js'
import { User } from '@/libs/user.js'
import OrgChart from '@/components/widgets/org-chart'
import CastingDashboard from '@/components/pages/casting-dashboard'
import SimpleDashboard from '@/components/pages/simple-dashboard'

export default {
  name: 'folders-page',
  props: {
    id: {
      type: String,
      default: () => null
    }
  },
  components: {
    'org-chart': OrgChart,
    'casting-dashboard': CastingDashboard,
    'simple-dashboard': SimpleDashboard
  },
  data: () => {
    return {
      render: 0,
      loading: true,
      canvases: {},
      user: { userName: null, account: null },
      selection: [],
      creatingCanvas: false,
      createMore: {
        show: false,
        x: null,
        y: null
      },
      downloadingSelection: false,
      orgData: null,
      foundItems: [],
      orgChartView: null,
      showCastingOverview: false,
      showDashboard: true
    }
  },
  async mounted() {
    this.loading = true;
    
    if (!this.user.userName) {
      let userLib = new User(this.$store.state);
      this.user = await userLib.getUser();
    }

    if (this.user.accountType === 'casting')
      this.showCastingOverview = true;
    
    const canvasList = new CanvasList(this.$store.state);

    this.canvases = await canvasList.listCanvases();

    this.updateOrgData();
    
    setTimeout(() => {
      EventBus.$emit('orgChartFit');
      setTimeout(() => {
        EventBus.$emit('orgChartZoomOut');
      }, 300);
    }, 30);
    this.loading = false;

    EventBus.$on('orgChartSelectionUpdated', (data) => {
      this.selection = data;
    });
    EventBus.$on('orgChartAddNode', (data) => {
      let template = data.template || null;
      if (data.action && data.action === 'add-canvas')
        this.createCanvas(template);
    });
    EventBus.$on('orgChartShowCreateMoreMenu', (data) => {
      this.createMore.x = data.x;
      this.createMore.y = data.y;
      this.createMore.show = true;
    });
  },
  watch: {
    search(val) {
      if (!val)
        return;

      this.updateOrgData();

      api.search(val).then((data) => {
        console.log(data)
      });
    },
    orgChartView() {
      this.updateOrgData();
    }
  },
  computed: {
    search() {
      return this.$store.state.search.items;
    },
  },
  methods: {
    ...mapActions(['showDialog']),
    searchedItem(item) {
      if (!this.search || !this.search.length)
        return false;

      if (!item)
        return false;

      for (let searchTerm of this.search) {
        const lcSearch = searchTerm.toLowerCase()

        if (item.nm && item.nm.toLowerCase().includes(lcSearch))
          return true;

        if (item.cfg && (item.cfg.name || 'untitled').toLowerCase().includes(lcSearch))
          return true;
      }
      
      return false;
    },
    async updateOrgData() {
      let orgData = [{name: this.user.account || 'Projects', id: 'root-0', type: 'global-root'}];
      const canvasList = new CanvasList(this.$store.state);

      try {
        this.canvases = await canvasList.listCanvases();
      }
      catch (e) {
        console.error(e);
      }

      let occurances = {};
      let foundIds = [];
      let acs = [{...this.user, name: this.user.userName}];
      for (let cid in this.canvases) {
        let canvas = this.canvases[cid].canvas;
        let canvasItems = this.canvases[cid].items;
        let needsUpdate = this.canvases[cid].needsUpdate;
        
        if (!canvas)
          continue;

        if (!(cid in occurances))
          occurances[cid] = true;
        else
          continue;

        let assignedToMe = (!canvas.root || !canvas.root.assignee) ? false : (canvas.root.assignee === this.user.uid || canvas.root.assignee === this.user.e);

        orgData.push({
          name: canvas.nm || 'Untitled',
          type: 'canvas',
          class: 'canvas-node',
          id: canvas.cid,
          cid: canvas.cid,
          parentId: 'root-0',
          assignedToMe: assignedToMe,
          acs: acs,
          item: canvas.root,
          showCheck: true
        });

        if (this.searchedItem(canvas) || (assignedToMe && this.orgChartView === 'assignedToMe')) 
          foundIds.push(canvas.cid);

        if (canvasItems && !needsUpdate) {
          for (let iid in canvasItems) {
            let item = canvasItems[iid];

            if (!item.parent || !(item.parent in canvasItems))
              continue;

            if (!(iid in occurances))
              occurances[iid] = true;
            else
              continue;

            let parentId = item.parent;
            if (parentId && !canvasItems[parentId].parent)
              parentId = item.cid;

            let assignedToMe = !item.assignee ? false : (item.assignee === this.user.uid || this.user.e === item.assignee);
            orgData.push({
              name: item.cfg ? (item.cfg.name || 'Untitled') : 'Untitled',
              imageUrl: item.type === 'video' ? item.src : (item.thumbSrc || null),
              type: item.type,
              id: item.iid,
              cid: item.cid,
              parent: parentId || item.cid,
              parentId: parentId || item.cid,
              assignedToMe: assignedToMe,
              acs: acs,
              item: item
            });

            if (this.searchedItem(item) || (assignedToMe && this.orgChartView === 'assignedToMe')) 
              foundIds.push(item.iid);
          }
        }
        else {
          orgData.push({
            id: cid + '-loading',
            parentId: cid,
            loading: true,
            loader: async () => { 
              let canvasObj = await canvasList.getCanvas(cid);
              let canvas = canvasObj ? canvasObj.canvas : null;
              this.updateOrgData();

              let id = (canvas && canvas.root && canvas.root.items && canvas.root.items.length) ? canvas.root.items[0].iid : null;
              if (id)
                setTimeout(() => {
                  EventBus.$emit('orgChartExpandItem', id);
                }, 500);
            }
          });
        }
      }

      let addMore = this.user && this.user.templates && this.user.templates.length;
      let template = this.getDefaultProjectTemplate();

      orgData.push({
        name: 'Create ' + (template ? template.name.toLowerCase() : 'project'),
        type: 'add',
        class: 'add-node',
        action: 'add-canvas',
        id: 'root-0-add',
        parentId: 'root-0',
        addMore: addMore,
        template: this.getDefaultProjectTemplate()
      });

      this.foundItems = foundIds;
      this.orgData = orgData;
    },
    cancelSelection() {
      EventBus.$emit('orgChartUpdateSelection', []);
    },
    async deleteSelected() {
      showConfirm(this.$store, this.$t('confirmDeleteTitle'), this.$t('confirmDeleteText'), async () => {
        this.loading = true;
        for (let item of this.selection) {
          if (!item.cid)
            continue;

          await api.removeCanvas(item.cid);
        }
        this.selection = [];
        location.reload();
      }, 'yes-no');
    },
    async downloadSelected() {
      if (!this.selection || !this.selection.length)
        return;

      this.downloadingSelection = true;

      try {
        let items = [];
        for (let item of this.selection) {
          if (!item.iid || !item.cid)
            continue;
          items.push({iid: item.iid, cid: item.cid});
        }
        const archiveFileName = await api.zipItems(items);
        if (!archiveFileName) {
          this.downloadingSelection = false;
          return;
        }

        let response = await Storage.vault.get('archives/' + archiveFileName, { expires: 86400, download: true});

        const a = document.createElement("a");
        a.href = URL.createObjectURL(response.Body);
        a.download = archiveFileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
      catch (err) {
        console.log(err);
      }

      this.downloadingSelection = false;
    },
    async createCanvas(template) {
      if (this.creatingCanvas)
        return;
        
      this.loading = true;
      this.creatingCanvas = true;
      try {
        const canvas = await api.createCanvas(null, { 
          path: '',
          likes: true, 
          comments: true,
        }, template);
        if (!canvas || !canvas.cid)
          return;
        window.location.pathname = 'cf/' + canvas.cid;//'canvas/' + canvas.cid;
      }
      catch (err) {
        console.log(err);
      }
      //this.creatingCanvas = false;
      //this.loading = false;
    },
    navigateTo(path) {
      this.$router.push('/files/' + base64.encode(path))
    },
    view(item, index) {
      if(!item.parent || !(item.parent in this.itemMap) || !this.itemMap[item.parent].items)
        return;

      EventBus.$emit('showViewDialog', { items: this.itemMap[item.parent].items, index: index });
    },
    open(item) {
      if (!item || !item.cid)
        return;
      this.$router.push('/canvas/' + item.cid)
    },
    check(item) {
      item.checked = !item.checked;
      const index = this.selection.findIndex(i => i.iid ? (i.iid === item.iid) : (i.path === item.path));
      if (index >= 0)
        this.selection.splice(index, 1)
      else
        this.selection.push(item);
    },
    swapOrgChart() {
      EventBus.$emit('orgChartSwap');
    },
    expandOrgChart() {
      EventBus.$emit('orgChartExpand');
    },
    collapseOrgChart() {
      EventBus.$emit('orgChartCollapse');
    },
    fitOrgChart() {
      EventBus.$emit('orgChartFit');
    },
    getDefaultProjectTemplate() {
      if (!this.user || !this.user.defaultTemplate || !this.user.templates || !this.user.templates.length)
        return null;

      // find default template
      return this.user.templates.find(t => t.iid === this.user.defaultTemplate);
    }
  }
}
</script>