<template>
<div class="text-left map-list-widget" :class="editing ? 'editing' : ''">
  <div class="gallery-header hoverable">
    <div v-if="!editing" class="text-h4">{{item.cfg.name}}</div>
    <div v-else>
      <v-text-field :value="item.cfg.name" v-on:change="updateItemConfig(item, () => item.cfg.name = $event)" :placeholder="(parent && parent.items && parent.items.length > 1) && !item.cfg.name ? ('Untitled collection' + (index !== null ? ' ' + (index+1) : '')) : null" flat solo hide-details @mouseup.prevent @mousedown.prevent @click.stop.prevent="nullHandler" class="text-h4 float-left editing"></v-text-field>
      <v-menu :close-on-content-click="false" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon elevation="0" color="primary" v-bind="attrs" v-on="on" class="float-right" style="margin-top: 7px"><v-icon size="24">mdi-dots-vertical</v-icon></v-btn>
        </template>
        <v-card class="pa-5" >
          <div class="pb-3 text-center">
            <v-btn class="elevation-1 secondary primary--text mx-1" rounded @click="remove()"><v-icon left>mdi-delete</v-icon> {{$t('removeGallery')}}</v-btn>
          </div>
          <v-select v-model="item.type" v-on:change="updateItemType(item, $event)" :items="getGalleryTypeMenuItems()" item-text="t" item-value="v" menu-props="auto" hide-details dense single-line class="py-3"></v-select>
          <template v-if="item.items && item.items.length">
            <div class="mt-3">{{$t('aspectRatio')}}</div>
            <v-slider ticks hide-details class="mb-3" max="20" min="5" v-model="item.cfg.aspectRatio" v-on:change="updateItemConfig(item, () => item.cfg.aspectRatio = $event)"></v-slider>
          </template>
          <template v-if="item.kv && item.kv.length">
            <div class="mt-5">{{$t('textColumns')}}</div>
            <v-slider ticks hide-details class="mb-3" min="1" max="4" v-model="item.cfg.kvSize" v-on:change="updateItemConfig(item, () => item.cfg.kvSize = $event)"></v-slider>
          </template>
          <v-divider></v-divider>
          <v-expansion-panels flat hover class="text-left">
            <v-expansion-panel>
              <v-expansion-panel-header class="pa-0">{{$t('advanced')}}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <template v-if="item.items && item.items.length">
                  <v-switch :label="$t('smallThumbnails')" color="secondary" class="mt-0" v-model="item.cfg.smallThumbnails" v-on:change="updateItemConfig(item, () => item.cfg.smallThumbnails = $event)" hide-details></v-switch>
                  <v-switch :label="$t('showCaptions')" v-if="item.cfg.smallThumbnails" color="secondary" class="mt-0" v-model="item.cfg.showNames" v-on:change="updateItemConfig(item, () => item.cfg.showNames = $event)" hide-details></v-switch>
                </template>
                <v-slider :label="$t('rounded')" ticks hide-details class="my-3" min="0" max="4" v-model="item.cfg.rounded" v-on:change="updateItemConfig(item, () => item.cfg.rounded = $event)"></v-slider>
                <template v-if="item.items && item.items.length">
                  <v-slider :label="$t('shadow')" ticks hide-details class="my-3" min="0" max="5" v-model="item.cfg.elevation" v-on:change="updateItemConfig(item, () => item.cfg.elevation = $event)"></v-slider>
                </template>
                <v-slider :label="$t('spacing')" ticks hide-details class="my-3" min="0" max="5" v-model="item.cfg.spacing" v-on:change="updateItemConfig(item, () => item.cfg.spacing = $event)"></v-slider>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-menu>
      <v-btn v-if="editing && item.items && item.items.length" class="elevation-1 secondary primary--text mx-1 float-right pr-2" rounded @click="addAssets(item)" style="margin-top: 6px">
        <v-icon left>mdi-plus</v-icon> 
        <span>{{$t('addAssets')}}</span> 
        <v-divider vertical class="ml-3 mr-2"></v-divider> 
        <v-menu  :close-on-content-click="false" :nudge-width="160" offset-y>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-chevron-down</v-icon>
          </template>
          <v-card class="pa-1">
            <v-list class="pa-0" rounded>
              <v-list-item color="success" @click="createSection()"><v-list-item-title>Add sub-section</v-list-item-title></v-list-item>
              <v-list-item @click="createKVItem(item, 'text')"><v-list-item-title>Add text</v-list-item-title></v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-btn>
      <v-btn v-if="item.items && item.items.length" icon elevation="0" color="primary" class="float-right" :class="item.items[0].checked ? null : 'show-on-hover'" style="margin-top: 7px" @click.stop.prevent="checkChildren"><v-icon size="24">{{item.items[0].checked ? `mdi-check-circle` : `mdi-checkbox-blank-circle-outline`}}</v-icon></v-btn>
      <div style="clear: both"></div>
  </div>
  <div class="gallery-kv">
    <v-container fluid grid-list-md :class="(!item.kv || !item.kv.length) ? 'pa-0' : ''">
      <draggable class="layout row wrap" :group="{ name: 'kv' }" :animation="200" v-model="item.kv" :disabled="!editing || $vuetify.breakpoint.mdAndDown">
        <v-flex v-for="(kv, k) in item.kv" :key="k" class="xs12 md12" :class="getKVSizeClass(item) + ' ' + ('px-' + ((typeof item.cfg.spacing == 'undefined') ? 1 : item.cfg.spacing))">
          <key-value-item v-model="item.kv[k]" :editing="editing" class="mb-2" @remove="removeKVItem(item, k)" @update="updateKVItem(item, kv)" :rounded="item.cfg.rounded || 0" full colorPrivate="rgba(255,192,192,0.4)" color="rgba(255,255,255,0.3)"></key-value-item>
        </v-flex>
      </draggable>
    </v-container>
  </div>
  <div class="gallery-content" @drop.prevent="dropFiles" @dragenter.prevent @dragover.prevent="dragFiles" @dragleave="dragLeave" :style="'padding: 2px'">
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="12" :md="item.cfg.smallThumbnails ? 10 : 8">
          <GmapMap v-if="markers && markers.length" :center="{lat: center.lat, lng: center.lng}" :zoom="center.zoom" :options="{streetViewControl: false}" style="width: 100%; height: 70vh">
            <GmapMarker v-for="(marker, i) in markers" :key="i" :position="marker" @click="opened = (opened === i ? null : i)">
              <GmapInfoWindow v-if="marker.item" :opened="opened === i">
                <div style="width: 200px; height: 248px;" class="columns-6">
                  <gallery-item v-model="marker.item" :parent="item" :index="marker.index" :cfg="{ showNames: true, rounded: 0, elevation: 0, aspectRatio: 10, cover: true, showLikes: false, showComments: false, size: 2 }"></gallery-item>
                </div>
              </GmapInfoWindow>
            </GmapMarker>
          </GmapMap>
        </v-col>
        <v-col cols="12" :md="item.cfg.smallThumbnails ? 2 : 4" v-if="$vuetify.breakpoint.lgAndUp">
          <v-container fluid grid-list-md class="columns-6 pt-0" style="height: 70vh; overflow-y: auto;">
            <draggable v-model="item.items" class="layout row wrap" :animation="200" :group="{ name: 'items' }" @change="updateItemSorting(item)" :disabled="!editing || $vuetify.breakpoint.mdAndDown">
              <v-flex v-for="(child, i) in item.items" :key="i" :class="'xs12 ' + ('pa-' + ((typeof item.cfg.spacing == 'undefined') ? 1 : item.cfg.spacing))">
                <list-item-widget v-if="item.cfg.smallThumbnails === false && $vuetify.breakpoint.smAndUp" :config="getListItemConfig()" :parent="item" @updateParent="item = $event" :index="i" v-model="item.items[i]" :editing="editing" :preview="preview"></list-item-widget>
                <gallery-item v-else :cfg="getListItemConfig()" :parent="item" @updateParent="item = $event" :index="i" v-model="item.items[i]" :editing="editing" :preview="preview"></gallery-item>
              </v-flex>
            </draggable>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</div>
</div>
</template>
<i18n>
{
  "en": {
    "smallThumbnails": "Small thumbnails"
  }
}
</i18n>
<style>
.add-gallery-asset {
  opacity: 0;
  transition: opacity 200ms linear;
}
.mobile .add-gallery-asset {
  opacity: 1 !important;
}
.map-list-widget:hover .add-gallery-asset {
  opacity: 0.5;
}
.map-list-widget .add-gallery-asset:hover {
  opacity: 1;
}
.plus-sign {
  margin-top: 0px; 
  position: absolute; 
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
}
.plus-sign.item-show-names {
  margin-top: 28px; 
}
.map-list-widget .text-h4 {
  font-weight: 300;
  white-space: nowrap;
}
.map-list-widget .gm-style-iw {
  padding-left: 0px;
  padding-top: 0px;
}
.map-list-widget .gm-style-iw-d {
  overflow: hidden !important;
}
.map-list-widget .gm-style-iw-d .v-card__actions {
  min-height: 48px !important;
}
.map-list-widget .gm-style-iw-t .gm-ui-hover-effect {
  display: none !important;
}
.map-list-widget {
  background-color: rgba(255,255,255,0.4);
  transition: background-color 200ms linear;
}
.map-list-widget:hover {
  background-color: rgba(255,255,255,0.7);
}
</style>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import draggable from 'vuedraggable'
import * as api from '@/libs/api.js'
import * as tools from '@/libs/tools.js'
import { EventBus } from '@/libs/eventBus.js'
import ListItemWidget from '@/components/canvas-items/list-item-widget'
import CanvasGalleryItem from '@/components/canvas-items/canvas-gallery-item'
import KeyValueItem from '@/components/widgets/key-value-item'
export default {
  name: 'map-list-widget',
  components: {
    'draggable': draggable,
    'list-item-widget': ListItemWidget,
    'gallery-item': CanvasGalleryItem,
    'key-value-item': KeyValueItem,
  },
  props: {
    value: {
      type: Object,
      default: () => null
    },
    parent: {
      type: Object,
      default: () => null
    },
    index: {
      type: Number,
      default: () => null
    },
    preview: {
      type: Boolean, 
      default: () => false
    },
    editing: {
      type: Boolean, 
      default: () => false
    }
  },
  data: () => {
    return {
      opened: null,
      dragging: false
    }
  },
  computed: {
    item: {
      get: function() { return this.value; },
      set: function(value) { this.$emit('input', value); }
    },
    markers() {
      if (!this.item || !this.item.items || !this.item.items.length)
        return [];

      let markers = [];
      for (let i in this.item.items) {
        let child = this.item.items[i];
        if (!child.kv || !child.kv.length)
          continue;

        for (let kv of child.kv) {
          if (kv.type !== 'map' || !kv.value || !kv.value.lat || !kv.value.lng)
            continue;
          markers.push({item: child, index: parseInt(i), lat: kv.value.lat, lng: kv.value.lng});
        }
      }
      return markers;
    },
    center() {
      let sumLat = 0;
      let sumLng = 0;
      let minLat = null;
      let maxLat = null;
      let minLng = null;
      let maxLng = null;
      for (let marker of this.markers) {
        sumLat += marker.lat;
        sumLng += marker.lng;
        minLat = (minLat === null || marker.lat < minLat) ? marker.lat : minLat;
        maxLat = (maxLat === null || marker.lat > maxLat) ? marker.lat : maxLat;
        minLng = (minLng === null || marker.lng < minLng) ? marker.lng : minLng;
        maxLng = (maxLng === null || marker.lng > maxLng) ? marker.lng : maxLng;
      }

      const spread = Math.abs(Math.max(maxLat - minLat, maxLng - minLng));
      //const zoom = 20 - Math.min(18, 5  + Math.floor(spread * (17.0 / Math.PI)));
      let zoom = 15;
      if (spread > 180 ) zoom = 2;
      else if (spread > 83 ) zoom = 3;
      else if (spread > 40 ) zoom = 4;
      else if (spread > 25 ) zoom = 5;
      else if (spread > 15 ) zoom = 6;
      else if (spread > 5 ) zoom = 7;
      else if (spread > 2 ) zoom = 8;
      else if (spread > 1 ) zoom = 11;

      return { lat: sumLat / (1.0 * this.markers.length), lng: sumLng / (1.0 * this.markers.length), zoom: zoom};
    }
  },
  methods: {
    async updateItemConfig(widget, functor) {
      if (functor)
        functor();
      if (widget.iid)
        await api.updateItem(widget.cid, widget.iid, { cfg: widget.cfg });
    },
    async updateItemType(widget, type) {
      if (!widget || !widget.iid || !type)
        return;

      widget.type = type;

      await api.updateItem(widget.cid, widget.iid, { t: type });
    },
    async updateItemSorting(widget) {
      let i = [];
      for (let item of widget.items)
        i.push(item.iid);

      await api.updateItem(widget.cid, widget.iid, { i: i })
    },
    addAssets(widget, files = null) {
      if (!widget)
        widget = this.item;

      this.$store.state.dialogs.addAssets = {
        show: true, 
        path: tools.getCanvasFullPath(this.$store.state.canvas.cfg.path, widget.cid),
        targetWidget: widget,
        files: files
      }
    },
    checkChildren() {
      if (!this.item.items || !this.item.items.length)
        return;

      const check = !this.item.items[0].checked;

      for (let item of this.item.items)
        item.checked = check;
    },
    async remove() {
      let parent = this.parent;
      if (!parent || !parent.items)
        return;
      let index = parent.items.findIndex(item => item.iid == this.item.iid);
      parent.items.splice(index, 1);
      this.$emit('updateParent', parent);

      if (this.parent.iid && this.item.iid && this.item.cid) {
        try {
          await api.removeItem(this.item.cid, this.parent.iid, this.item.iid)
        }
        catch (err) {
          console.log(err);
        }
      }
    },
    getListItemConfig() {
      return { 
        rounded: ('rounded' in this.item.cfg) ? this.item.cfg.rounded : 0, 
        elevation: ('elevation' in this.item.cfg) ? this.item.cfg.elevation : 1,
        aspectRatio: ('aspectRatio' in this.item.cfg) ? this.item.cfg.aspectRatio : 10, 
        showNames: ('showNames' in this.item.cfg) ? this.item.cfg.showNames : false,
        cover: true, 
        showLikes: true,
        showComments: true,
        size: ('size' in this.item.cfg) ? this.item.cfg.size : 2, 
      }
    },
    getColumnCount(widget) {
      if (!this.$vuetify.breakpoint.mdAndUp)
        return 1;

      const sizes = [4,4,6,6,12,12];
      let size = 4;
      if (widget.cfg.size !== null && widget.cfg.size >= 0 && widget.cfg.size < sizes.length)
        size = sizes[widget.cfg.size];

      if (this.preview && size < 6)
        size = 6;

      return Math.floor(12 / size);
    },
    getRoundedClass(widget) {
      if (!widget || !widget.cfg || !widget.cfg.rounded)
        return '';

      switch (widget.cfg.rounded) {
        case 1: return 'rounded-sm';
        case 2: return 'rounded';
        case 3: return 'rounded-lg';
        case 4: return 'rounded-xl';
        case 5: return 'rounded-pill';
      }

      return 'rounded';
    },
    getThumnailSize(widget) {
      if (!widget || !widget.cfg)
        return 6;
      if (widget.cfg.size <= 4)
        return 4;
      else if (widget.cfg.size <= 6)
        return 5;
      else 
        return 6;
    },
    childrenHasMaps() {
      if (!this.item || !this.item.items || !this.item.items.length)
        return false;

      for (let child of this.item.items) {
        if (!child.kv || !child.kv.length)
          continue;

        for (let kv of child.kv) {
          if (kv.type !== 'map' || !kv.value || !kv.value.lat || !kv.value.lng)
            continue;
          return true
        }
      }
      return false;
    },
    getGalleryTypeMenuItems() {
      return [{t:'Grid', v:'gallery'}, {t:'List', v:'list'}, {t:'Table', v:'file-table'}, {t:'Map', v:'map-list'}];
    },
    async createKVItem(item, type) {
      if (!item)
        return;

      const cid = item.cid;
      const iid = item.iid;
      const key = null;
      let value = null;
      if (!item.kv)
        item.kv = [];
      item.kv.push({ key: key, type: type, value: value, cfg: { hideCaption: true } });
      item.kv[item.kv.length-1].kvid = await api.createKeyValueItem(cid, iid, key, value, type);
      
    },
    async updateKVItem(item, kvItem) {
      if (!this.editing)
        return;

      if (item.iid && kvItem.kvid) {
        let updateItem = async () => await api.updateKeyValueItem(this.$store.state.canvas.cid, item.iid, kvItem.kvid, { k: kvItem.key, v: kvItem.value, cfg: kvItem.cfg });
        EventBus.$emit('updateItem', {
          id: kvItem.kvid,
          funct: updateItem
        });
      }
    },
    async removeKVItem(item, index) {
      if (!item || !item.kv || index >= item.kv.length || index < 0)
        return;

      const kvid = item.kv[index].kvid;

      item.kv.splice(index, 1);

      await api.removeKeyValueItem(item.cid, item.iid, kvid);
    },
    getKVSizeClass(item) {
      if (!item.cfg || !item.cfg.kvSize) 
        return 'lg12';

      switch (item.cfg.kvSize) {
        case 1: return 'lg12';
        case 2: return 'lg6';
        case 3: return 'lg4';
        case 4: return 'lg3';
      }

      return 'lg12';
    }, 
    dragFiles(e) {
      e.stopPropagation();
      e.preventDefault();
      if (!e || !e.dataTransfer || !e.dataTransfer.files)
        return;

      e.dataTransfer.dropEffect = 'copy';
      this.dragging = true;
    },
    dropFiles(e) {
      if (!e || !e.dataTransfer || !e.dataTransfer.files || !e.dataTransfer.files.length)
        return;

      this.dragging = false;  
      this.addAssets(this.item, e.dataTransfer.files)
    },
    dragLeave() {
      this.dragging = false;
    },
    nullHandler() {

    }
  }
}
</script>