<template>
<div class="canvas-page" :key="render" :class="(navPanelMinimized || accessDenied ? 'nav-minimized ' : '') + 'sub-section'/* + (subsection ? 'sub-section ' : '')*/" :style="$vuetify.breakpoint.mdAndDown ? '' : `padding-left: ${(accessDenied || navPanelMinimized) ? 56 : (navWidth + 6)}px;`">
  <v-container v-if="loading" fill-height class="pa-0">
    <v-layout align-center>
      <v-progress-linear color="secondary" indeterminate rounded height="10" class="mx-auto" style="width: 25%"></v-progress-linear>
    </v-layout>
  </v-container>
  <v-container v-else-if="accessDenied || error" fill-height class="pa-0">
    <v-layout align-center>
      <div class="mx-auto">
        <v-card min-width="480" class="pa-3 elevation-1 rounded-xl">
            <template v-if="accessDenied">
              <v-card-title primary-title class="login-dialog-title">
                <h4 class="headline ">Access {{accessRequested ? 'requested' : 'denied'}}</h4>
              </v-card-title>
              <v-card-text>
                <template v-if="!accessRequested">
                  <p>We apologize for the inconvenience, but access to this project has been denied.<br />
                  If you want, you can ask the owner of this project to give you permisions to access it.</p>

                  <v-btn x-large rounded elevation="1" class="mx-auto mt-8 d-block secondary primary--text" :disabled="requestingAccess" :loading="requestingAccess" @click="requestAccess()">Request access</v-btn>
                </template>
                <template v-else>
                  <p>We've asked the owner of this project to allow your access.<br />
                  Once this is done, you will be notified by email.</p>
                </template>
              </v-card-text>
            </template>
            <template v-else>
              <v-card-title primary-title class="login-dialog-title">
                <h4 class="headline">{{error.header}}</h4>
              </v-card-title>
              <v-card-text>
                <p v-html="error.text"></p>
              </v-card-text>
            </template>          
        </v-card>
      </div>
    </v-layout>
  </v-container>
  <div v-else class="canvas recursive" :style="canvas.style">
    <navigation-panel v-if="!$vuetify.breakpoint.mdAndDown" v-model="canvas" @minimized="navigationPanelMinimized" :editing="editing"></navigation-panel>
    <template xv-if="!hideControls">
      <v-btn v-if="canvas.root && canvas.root.items && canvas.root.items.length" light elevation="1" fixed bottom right fab color="secondary" class="download-section-btn" style="right: 72px; z-index: 5;" @click="downloadSection()" :loading="downloadingSection" :disabled="downloadingSelection">
        <v-icon color="primary" size="28">mdi-download</v-icon>
      </v-btn>
      <v-badge color="primary" class="canvas-comments-badge" top left overlap :value="commentCount > 0">
        <template v-slot:badge><b class="white--text">{{commentCount}}</b></template>
        <v-speed-dial v-model="fab" fixed bottom right direction="top" transition="slide-x-reverse-transition" style="right: 12px; z-index: 5">
          <template v-slot:activator>
            <v-btn v-if="canvas.root && canvas.root.items && canvas.root.items.length" dark elevation="1" fab color="secondary">
              <v-icon color="primary" size="28">{{fab ? 'mdi-comment' : 'mdi-comment-outline'}}</v-icon>
            </v-btn>
          </template>
          <div style="width: 100%; max-height: calc(100vh - 74px); overflow-y: auto;padding: 4px">
            <v-expansion-panels class="chat-expansion-panel mt-2" :value="0">
              <v-expansion-panel v-if="root && root.cfg && root.cfg.name" class="rounded-xl mt-2" :value="0">
                <v-expansion-panel-header @click.prevent.stop="fab = true">
                  <div>
                    <div class="d-inline-block float-left">
                      <template v-for="ac in acList">
                        <v-avatar v-if="ac.profileImage" :key="ac.e" size="24" class="elevation-3" style="border: 1px solid rgba(0,0,0,0.2); margin-left: -10px;"><v-img :src="ac.profileImage" size="24"></v-img></v-avatar>
                        <v-avatar v-else-if="ac.e" :key="ac.e+'e'" :color="getColor(ac.e)" size="24" class="elevation-3" style="border: 1px solid rgba(0,0,0,0.2); margin-left: -10px; font-size: 15px; font-weight: 300; color: rgba(0,0,0,0.6); cursor: pointer">{{ac.e[0].toUpperCase()}}</v-avatar>
                      </template>
                    </div>
                    <div class="d-inline-block float-left pl-3 pt-1">{{root.cfg.name}}</div>
                    <div class="d-inline-block float-right mr-2 pt-1"><v-icon small class="mr-2">mdi-comment-multiple-outline</v-icon><span>{{root.comments ? root.comments.length : ''}}</span></div>
                    <div style="clear: both"></div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <chat :id="root.iid" :userName="user.userName" :getMessages="getMessages" :sendMessage="sendMessage" :shown="fab"></chat>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-else class="rounded-xl mt-2">
                <v-expansion-panel-header @click.prevent.stop="fab = true">
                  <div>
                    <div class="d-inline-block">
                      <template v-for="ac in canvasAcList">
                        <v-avatar v-if="ac.profileImage" :key="ac.e" size="24" class="elevation-3" style="border: 1px solid rgba(0,0,0,0.2); margin-left: -10px; cursor: pointer"><v-img :src="ac.profileImage" size="24"></v-img></v-avatar>
                        <v-avatar v-else-if="ac.e" :key="ac.e+'e'" :color="getColor(ac.e)" size="24" class="elevation-3" style="border: 1px solid rgba(0,0,0,0.2); margin-left: -10px; font-size: 15px; font-weight: 300; color: rgba(0,0,0,0.6); cursor: pointer">{{ac.e[0].toUpperCase()}}</v-avatar>
                      </template>
                    </div>
                    <div class="d-inline-block pl-3">{{canvas.nm}}</div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <chat :id="canvas.root.iid" :userName="user.userName" :getMessages="getMessages" :sendMessage="sendMessage" :shown="fab"></chat>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <template v-if="root && root.items && root.items.length" >
                <template v-for="item in root.items">
                  <v-expansion-panel :key="item.iid" v-if="getCommentsCount(item)"  class="rounded-xl mt-2">
                    <v-expansion-panel-header @click.prevent.stop="(getCommentsCount(item) > (item.comments ? item.comments.length : 0)) ? navigateTo(item) : (fab = true)" class="pr-5">
                      <template v-slot:default="{ open }">
                        <div>
                          <div class="d-inline-block float-left">
                            <template v-for="ac in acList">
                              <v-avatar v-if="ac.profileImage" :key="ac.e" size="24" class="elevation-3" style="border: 1px solid rgba(0,0,0,0.2); margin-left: -10px;"><v-img :src="ac.profileImage" size="24"></v-img></v-avatar>
                              <v-avatar v-else-if="ac.e" :key="ac.e+'e'" :color="getColor(ac.e)" size="24" class="elevation-3" style="border: 1px solid rgba(0,0,0,0.2); margin-left: -10px; font-size: 15px; font-weight: 300; color: rgba(0,0,0,0.6); cursor: pointer">{{ac.e[0].toUpperCase()}}</v-avatar>
                            </template>
                          </div>
                          <div class="d-inline-block float-left pl-3 pt-1">{{item.cfg.name}}</div>
                          <v-icon class="d-inline-block float-right">{{ (getCommentsCount(item) > (item.comments ? item.comments.length : 0)) ? 'mdi-chevron-right' : (open ? 'mdi-chevron-up' : 'mdi-chevron-down') }}</v-icon>
                          <div class="d-inline-block float-right mr-2 pt-1"><v-icon small class="mr-2">mdi-comment-multiple-outline</v-icon><span>{{getCommentsCount(item)}}</span></div>
                          <div style="clear: both"></div>
                        </div>
                      </template>
                      <template v-slot:actions>&nbsp;</template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="text-center" v-if="getCommentsCount(item) > (item.comments ? item.comments.length : 0)">
                        <a href="#" class="primary--text" style="font-size: 14px">{{getCommentsCount(item) - (item.comments ? item.comments.length : 0)}} more comments inside</a><v-icon size="14">mdi-chevron-right</v-icon>
                      </div>
                      <chat :id="item.iid" :userName="user.userName" :getMessages="getMessages" :sendMessage="sendMessage" :shown="fab"></chat>
                    </v-expansion-panel-content>
                  </v-expansion-panel>              
                </template>
              </template>
            </v-expansion-panels>
          </div>
        </v-speed-dial>
      </v-badge>
      <v-fab-transition>
        <v-btn v-if="false && local && canvas.root && canvas.root.items && canvas.root.items.length" dark fixed elevation="1" top right fab style="right: 76px; top: 82px; z-index: 5" color="secondary" @click="toggleEdit">
          <v-icon color="primary">{{editing ? 'mdi-pencil-off' : 'mdi-pencil'}}</v-icon>
        </v-btn>
      </v-fab-transition>
    </template>
    <v-alert :value="checkedCount > 0 || downloadingSelection" transition="fade-transition" color="#1a2629" dark class="text-center" height="80px" max-height="80px" style="position: fixed; border-radius: 0px; bottom: 0; left: 0px; padding: 8px; width: 100%; z-index: 2000; margin: 0px;">
      <v-tooltip top color="#edf0f2">
        <template v-slot:activator="{on}"><div v-on="on" class="d-inline-flex"><v-btn v-on="on" :disabled="selectionLoading" fab large elevation="0" color="primary" class="mx-3" @click="viewSelection"><v-icon size="32">mdi-magnify</v-icon></v-btn></div></template>
        <span class="primary--text">{{ checkedCount > 1 ? $t('compare') : $t('show') }}</span>
      </v-tooltip>
      <v-tooltip top color="#edf0f2" v-if="countGroupsInSelected() > 0 && !checkedExternally">
        <template v-slot:activator="{on}"><div v-on="on" class="d-inline-flex"><v-btn v-on="on" fab :loading="explodingSelection" :disabled="selectionLoading" large elevation="0" color="primary" class="mx-3" @click="explodeSelected"><v-icon size="32">mdi-call-split</v-icon></v-btn></div></template>
        <span class="primary--text">{{ $t('splitGroup') }}</span>
      </v-tooltip>
      <v-tooltip top color="#edf0f2" v-if="checkedCount > 1 && !checkedExternally">
        <template v-slot:activator="{on}"><div v-on="on" class="d-inline-flex"><v-btn v-on="on" fab :loading="groupingSelection" :disabled="selectionLoading" large elevation="0" color="primary" class="mx-3" @click="groupSelected"><v-icon size="32">mdi-call-merge</v-icon></v-btn></div></template>
        <span class="primary--text">{{ $t('groupSelected') }}</span>
      </v-tooltip>
      <v-tooltip top color="#edf0f2" v-if="!checkedExternally || !checkedInternally">
        <template v-slot:activator="{on}">
          <div v-on="on" class="d-inline-flex">
            <v-menu :close-on-content-click="true" offset-y top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" fab :loading="movingSelection" :disabled="selectionLoading" large elevation="0" color="primary" class="mx-3" ><v-icon size="32">mdi-arrow-right-thick</v-icon></v-btn>
              </template>
              <v-card class="pa-0">
                <v-subheader>{{ !checkedExternally ? $t('moveTo') : 'Copy to...' }}</v-subheader>
                <layout-list @itemSelected="moveSelected($event)"></layout-list>
              </v-card>
            </v-menu>
          </div>
        </template>
        <span class="primary--text">{{ !checkedExternally ? $t('moveTo') : 'Copy to...' }}</span>
      </v-tooltip>
      <v-tooltip top color="#edf0f2">
        <template v-slot:activator="{on}"><div v-on="on" class="d-inline-flex"><v-btn fab :disabled="selectionLoading" large elevation="0" color="primary" class="mx-3" @click="deleteSelected"><v-icon size="32">mdi-delete</v-icon></v-btn></div></template>
        <span class="primary--text">{{ $t('delete') }}</span>
      </v-tooltip>
      <v-tooltip top color="#edf0f2">
        <template v-slot:activator="{on}"><div v-on="on" class="d-inline-flex"><v-btn v-on="on" :loading="downloadingSelection || downloadingSection" :disabled="selectionLoading || downloadingSection" fab large elevation="0" color="primary" class="mx-3" @click="downloadSelected"><v-icon size="32">mdi-download</v-icon></v-btn></div></template>
        <span class="primary--text">{{ $t('download') }}</span>
      </v-tooltip>
      <v-tooltip top color="#edf0f2">
        <template v-slot:activator="{on}"><div v-on="on" class="d-inline-flex"><v-btn v-on="on" :disabled="selectionLoading" fab large elevation="0" color="primary" class="mx-3" @click="cancelSelection"><v-icon size="32">mdi-close</v-icon></v-btn></div></template>
        <span class="primary--text">{{ $t('cancel') }}</span>
      </v-tooltip>
    </v-alert>
    <v-alert :value="searchSelection.length > 0" transition="fade-transition" color="#1a2629" dark class="text-center" height="80px" max-height="80px" style="position: fixed; border-radius: 0px; bottom: 0; left: 0px; padding: 8px; width: 100%; z-index: 2000; margin: 0px;">
      <v-tooltip top color="#edf0f2">
        <template v-slot:activator="{on}">
          <div v-on="on" class="d-inline-flex">
            <v-menu :close-on-content-click="true" offset-y top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" :disabled="downloadingSearchSelection" fab large elevation="0" color="primary" class="mx-3" ><v-icon size="32">mdi-content-copy</v-icon></v-btn>
              </template>
              <v-card class="pa-0">
                <v-subheader>{{ $t('copyTo') }}</v-subheader>
                <layout-list @itemSelected="copySearchSelected($event)"></layout-list>
              </v-card>
            </v-menu>
          </div>
        </template>
        <span class="primary--text">{{ $t('copyTo') }}</span>
      </v-tooltip>
      <v-tooltip top color="#edf0f2">
        <template v-slot:activator="{on}"><div v-on="on" class="d-inline-flex"><v-btn v-on="on" :loading="downloadingSearchSelection" fab large elevation="0" color="primary" class="mx-3" @click="downloadSearchSelection"><v-icon size="32">mdi-download</v-icon></v-btn></div></template>
        <span class="primary--text">{{ $t('download') }}</span>
      </v-tooltip>
      <v-tooltip top color="#edf0f2">
        <template v-slot:activator="{on}"><div v-on="on" class="d-inline-flex"><v-btn v-on="on" :disabled="downloadingSearchSelection" fab large elevation="0" color="primary" class="mx-3" @click="cancelSearchSelection"><v-icon size="32">mdi-close</v-icon></v-btn></div></template>
        <span class="primary--text">{{ $t('cancel') }}</span>
      </v-tooltip>
    </v-alert>
    <v-dialog v-model="publishDialog.show" persistent width="720">
      <v-card :color="publishDialog.published ? 'white' : 'secondary'" light>
        <v-card-title v-if="publishDialog.published" class="primary white--text pr-3">Public link<v-spacer></v-spacer><v-btn icon color="white"><v-icon @click="publishDialog.show = false">mdi-close</v-icon></v-btn></v-card-title>
        <v-card-text class="primary--text pa-7 text-center">
          <template v-if="!publishDialog.published">
            <span class="text-subtitle-1">{{ $t('creatingALink') }}</span>
            <v-progress-linear indeterminate height="7" color="primary" class="mb-0"></v-progress-linear>
          </template>
          <template v-else>
            <div class="text-h4 pb-3" style="font-weight: 300; color: black">{{ $t('hereIsTheLink') }}</div>
            <v-text-field class="mt-4 mb-2 public-url" outlined hide-details :value="getPublicURL()" id="publicURL">
              <v-icon slot="append" @click="copyURL">{{copying ? 'mdi-check' : 'mdi-content-copy'}}</v-icon>
            </v-text-field>
            <p class="mt-2 pb-3 text-left">{{ $t('anyoneWithThis') }}</p>
            <v-alert :value="!publishDialog.timestamp" class="elevation-1 text-left" border="left" colored-border color="secondary"><v-icon class="mr-1">mdi-alert-circle-outline</v-icon> <span style="font-size: 14px">{{ $t('changesWereNotPublished') }}</span></v-alert>
            <v-btn v-if="!publishDialog.timestamp" x-large rounded elevation="1" class="mx-auto mt-6 d-block secondary primary--text" :loading="creatingCollect" @click="publish(true)">{{ $t('publishChanges') }}</v-btn>
            <v-btn v-else x-large rounded elevation="1" class="mx-auto mt-6 d-block secondary primary--text" :loading="creatingCollect" @click="publishDialog.show = false">{{ $t('close') }}</v-btn>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="createSnapshotDialog.show" @keydown.esc="createSnapshotDialog.loading ? (()=>{})() : createSnapshotDialog.show = false" persistent width="650">
      <v-card light>
        <v-card-title class="primary white--text pr-3">Create a snapshot of {{root.cfg ? (root.cfg.name || 'this section') : (canvas.nm || 'this project')}}<v-spacer></v-spacer><v-btn icon color="white"><v-icon @click="createSnapshotDialog.loading ? (()=>{})() : createSnapshotDialog.show = false">mdi-close</v-icon></v-btn></v-card-title>
        <v-card-text class="primary--text pa-7 text-center">
          <div class="body-2 text-left pb-3">
            <span>Create a snapshot to conserve the state of the project in time. You will be able to access this snapshot anytime in the future.</span>
          </div>
          <v-text-field class="mt-4 mb-2 py-0 pr-0" outlined hide-details label="Please enter name of the snapshot" v-model="createSnapshotDialog.name" @keydown.enter="(createSnapshotDialog.name && createSnapshotDialog.name.length) ? createSnapshot(createSnapshotDialog.name) : (() => {})()" :loading="createSnapshotDialog.loading" autofocus></v-text-field>
          <v-expand-transition>
            <div v-if="createSnapshotDialog.advanced">
              <div><v-icon>mdi-alpha</v-icon> - under construction</div>
              <v-switch class="d-inline-block pb-2" :input-value="true" disabled hide-details label="Clear comments" style="margin-right: 16px"></v-switch>
              <v-switch class="d-inline-block pb-2" :input-value="true" disabled hide-details label="Clear emoji reactions" style="border-left: 1px solid rgba(0,0,0,0.12); padding-left: 16px"></v-switch>
            </div>
          </v-expand-transition>
          <div class="d-flex" style="cursor: pointer" @click="createSnapshotDialog.advanced = !createSnapshotDialog.advanced">
            <v-divider class="flex-grow-1 flex-shrink-0 mr-3" style="margin-top: 10px"></v-divider>
            <div class="caption flex-grow-0 flex-shrink-1">Advanced <v-icon small color="rgba(0, 0, 0, 0.24)">{{`mdi-chevron-${createSnapshotDialog.advanced ? 'up' : 'down'}`}}</v-icon></div>
          </div>
        </v-card-text>
        <v-card-actions style="background-color: #edf0f2">
          <v-spacer></v-spacer>
          <v-btn text rounded color="primary" :disabled="createSnapshotDialog.loading" @click="createSnapshotDialog.show = false">{{ $t('cancel') }}</v-btn>
          <v-btn class="elevation-1 rounded-pill secondary primary--text" :loading="createSnapshotDialog.loading" :disabled="createSnapshotDialog.loading || !createSnapshotDialog.name || !createSnapshotDialog.name.length" @click="createSnapshot(createSnapshotDialog.name)">Create</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <share-dialog></share-dialog>
    <div class="top-bar" :class="subsection ? 'sub-section' : ''" :style="`padding-left: ${(accessDenied || navPanelMinimized) ? 80 : (navWidth + 30)}px`">
      <div v-if="canvas.root && canvas.root.items && canvas.root.items.length && $vuetify.breakpoint.mdAndUp" :style="`position: absolute; right: 24px; z-index: 4; top: ${(canvas.projectFlow || user.projectFlow || 'none') === 'none' ? 32 : 18}px;`" class="text-right" :key="acUpdated">
        <template v-for="ac in acList">
          <v-tooltip bottom :key="ac.e" v-if="ac.e">
            <template v-slot:activator="{on}">
              <div style="position: relative; cursor: pointer" class="d-inline-block">
                <v-avatar v-if="ac.profileImage" v-on="on" size="48" class="elevation-3 user-main-avatar" :class="(root && (ac.uid === root.assignee || ac.e === root.assignee)) ? 'assignee' : ''" @click="share()"><v-img :src="ac.profileImage" size="48"></v-img></v-avatar>
                <v-avatar v-else v-on="on" :color="getColor(ac.e)" size="48" class="elevation-3 user-main-avatar" :class="(root && (ac.uid === root.assignee || ac.e === root.assignee)) ? 'assignee' : ''" @click="share()">{{(ac.name || ac.e)[0].toUpperCase()}}</v-avatar>
                <v-icon v-if="(root && (ac.uid === root.assignee || ac.e === root.assignee))" color="white" size="20" style="position: absolute; bottom: -8px; left: -16px; border-radius: 50%; background-color: #344955; width: 22px; height: 22px">mdi-progress-clock</v-icon>
              </div>
            </template>
            <span v-if="root && (ac.uid === root.assignee || ac.e === root.assignee)">{{(ac.name || ac.e)}}:<b> assignee</b></span>
            <span v-else>{{(ac.name || ac.e)}}</span>
          </v-tooltip>
        </template>
        <v-avatar v-if="editing" size="48" class="elevation-3 secondary" style="margin-left: -10px; cursor: pointer" @click="share()">
          <v-tooltip bottom>
            <template v-slot:activator="{on}">
              <v-icon v-on="on" color="primary">mdi-account-plus</v-icon>
            </template>
            <span>Share <b>{{topbarTitle}}</b> with others</span>
          </v-tooltip>
        </v-avatar>
        <project-flow-control :type="canvas.projectFlow || user.projectFlow || 'client'" :canvas="canvas" :root="root" :editing="editing" @set-status="setStatus" @snapshot-created="snapshotCreated" @delete-snapshot="deleteSnapshot"></project-flow-control>
      </div>
      <div class="py-1">
        <h2 class="text-h2 d-inline-block" :class="(editing || !topbarTitle)  ? 'editing' : ''">
          <template v-if="!editing && (topbarTitle || hideControls)">{{topbarTitle}}</template>
          <v-text-field v-else :value="topbarTitle" :autofocus="!topbarTitle" :placeholder="!topbarTitle ? 'Untitled' : null" v-on:change="topbarTitle = $event" flat solo hide-details class="text-h2 canvas-title"></v-text-field>
        </h2>
        <div>
          <v-breadcrumbs class="py-0 px-1 d-inline-flex" :items="getBreadcrumbs(root)">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item @click="item.allProjects ? $router.push('/files') : (item.iid === subsection ? nullHandler() : navigateTo(item))" :style="item.iid === subsection ? '' : 'cursor: pointer'">
                <v-icon size="14" class="mr-1" v-if="item.icon">{{item.icon}}</v-icon>
                <template v-else>{{item.text}}</template>
                <v-tooltip right v-if="item.snapshot">
                  <template v-slot:activator="{on}">
                    <v-chip v-on="on" small color="secondary primary--text" class="ml-2 mr-0 elevation-0"><v-icon left small>mdi-backup-restore</v-icon> {{item.snapshot.nm}} <v-icon v-if="userCanOpenIid(item.snapshot.sourceIid)" right small @click.prevent.stop="openCanvas()">mdi-close</v-icon></v-chip>
                  </template>
                  <span>This is a snapshot from {{timestampToDate(item.snapshot.created)}}.<template v-if="userCanOpenIid(item.snapshot.sourceIid)"><br />To access the most up-to-date version, click on <v-icon small color="white">mdi-close</v-icon> icon.</template></span>
                </v-tooltip>
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </div>
      </div>
    </div>
    <canvas-widget v-if="root" v-model="root" :editing="editing"></canvas-widget>
    <div v-if="editing && root && (root.type !== 'root' || (root.items && root.items.length))" class="text-center mt-5 pa-10 create-section">
      <v-divider></v-divider>
      <v-btn class="elevation-1 pr-2" style="margin-top: -30px" :loading="creatingSection" rounded @click="createSection()" stylea="margin-top: 6px">
        <v-icon left>mdi-plus</v-icon>
        <span>Add section</span>
        <v-divider vertical class="ml-2 mr-1"></v-divider> 
        <v-menu  :close-on-content-click="false" :nudge-width="160" offset-y>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" size="18">mdi-chevron-down</v-icon>
          </template>
          <v-card class="pa-1">
            <v-list class="pa-0" rounded>
              <v-list-item color="success" @click="createMap()"><v-list-item-title>Add a map</v-list-item-title></v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-btn>
    </div>
  </div>
  <v-menu v-model="itemCommentMenu.show" :position-x="itemCommentMenu.x" :position-y="itemCommentMenu.y" absolute offset-y :max-width="400" style="z-index: 1000">
    <chat v-if="itemCommentMenu.item" v-model="itemCommentMenu.item" :id="itemCommentMenu.item.iid" :userName="user.userName" :getMessages="getMessages" :sendMessage="sendMessage" :shown="itemCommentMenu.show"></chat>
  </v-menu>
</div>
</template>
<i18n>
{
  "en": {
    "show": "Show",
    "compare": "Compare",
    "splitGroup": "Split group",
    "groupSelected": "Group selected",
    "moveTo": "Move to...",
    "copyTo": "Copy to...",
    "publicLink": "Public link",
    "creatingALink": "Creating a link to share, please wait",
    "hereIsTheLink": "Here's the link to share",
    "anyoneWithThis": "Anyone with this link will see the content of this section. They will be able to leave comments and download files, but they won't be able to modify and edit the assets.",
    "changesWereNotPublished": "Changes were not published yet. Users will see the previous version.",
    "publishChanges": "Publish changes",
    "confirmDeleteTitle": "Are your sure?",
    "confirmDeleteText": "Do you really want to delete selected?"
  }
}
</i18n>

<style>
.canvas-page {
  padding-top: 96px;
  height: 100%;
  /*padding-left: 266px;*/
  transition: padding-left 150ms linear;
}
.canvas-page.sub-section {
  padding-top: 118px;
}
.mobile .canvas-page {
  padding-left: 0px !important;
}
.canvas-page.nav-minimized {
  padding-left: 56px;
}
.canvas-page > .container {
  max-width: 100%;
}
.canvas {
  width: 100%;
  height: 100%;
  padding: 24px;
}
.mobile .canvas {
  width: 100%;
  padding: 4px;
}
.canvas-tools {
  top: 64px !important;
  padding-bottom: 64px;
}
.canvas-title {
  line-height: 84px;
}
.canvas-page .canvas-title input {
  line-height: 84px;
  font-size: 3.6rem !important;
}
/*.editing {
  border: 1px dashed gray;
}*/
.editing input:hover,
.editing input:focus {
  background-color: rgba(250,250,250,0.9);
}
.editing input {
  padding: 0px !important;
  max-height: 64px !important;
}
.editing .v-input__slot {
  padding: 0px !important;
  background-color: transparent !important;
}
.canvas-page .v-treeview-node__level {
  width: 13px;
}
.canvas-page .v-expansion-panel-content__wrap {
  padding-left: 0px;
  padding-right: 0px;
}
.canvas-page .theme--light.v-expansion-panels .v-expansion-panel--disabled {
  color: initial;
}
.canvas-page .like .v-badge__wrapper{
  z-index: 20;
}
.canvas-page .top-bar {
  width: 100%;
  height: 96px;
  background-color: white;
  border-bottom: 1px solid rgba(0,0,0,0.12);
  position: absolute;
  top: 0px;
  left: 0px;
  padding-top: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
  /*padding: 12px 12px 12px 290px;*/
  transition: padding 150ms linear;
}
.canvas-page.sub-section .top-bar {
  height: 118px;
}
.mobile .canvas-page .top-bar/*,
.canvas-page.nav-minimized .top-bar*/ {
  padding: 12px 12px 12px 12px !important;
}
.mobile .canvas-page .top-bar {
  width: 100%;
  left: 0px;
}
.canvas-comments-badge {
  position: fixed;
  right: 26px;
  bottom: 26px;
  z-index: 7;
}
.canvas-comments-badge .v-badge__badge {
  z-index: 6;
}
.create-section {
  opacity: 0.4;
  transition: opacity 200ms linear;
}
.create-section:hover {
  opacity: 1;
}
.canvas-page .v-speed-dial--direction-top .v-speed-dial__list, .canvas-page .v-speed-dial--direction-bottom .v-speed-dial__list {
  width: 400px;
  margin-left: -346px;
}
.chat-expansion-panel .v-expansion-panel-content__wrap {
  padding-bottom: 0px;
}
.canvas-page .v-expansion-panel:not(:first-child)::after {
  content: inherit;
}
.canvas-page .user-main-avatar {
  border: 1px solid rgba(0,0,0,0.2); 
  margin-left: -18px; 
  font-size: 30px; 
  font-weight: 300; 
  color: rgba(0,0,0,0.6); 
  cursor: pointer;
}
.canvas-page .user-main-avatar.assignee {
  border: 2px solid white !important;
  box-shadow: 0 0 0 3px #344955 !important;
}
.section-labels .v-chip .v-text-field {
  width: 60px !important;
  font-size: 12px !important;
  margin: 0px !important;
}
.section-labels .v-chip .v-text-field .v-input__slot {
  padding: 0px !important;
}
.section-labels .v-chip .v-text-field input {
  padding: 0px !important;
}
.add-status {
  opacity: 0.3;
}
.top-bar:hover .add-status {
  opacity: 0.6;
}
.snapshots {
  opacity: 0.3;
  transition: opacity 200ms linear;
}
.top-bar:hover .snapshots {
  opacity: 0.6;
}
.top-bar:hover .snapshots:hover {
  opacity: 1;
}
.canvas-page .error-output {
  padding: 12px;
  background-color: rgba(0,0,0,0.05);
  max-width: 600px;
  max-height: 600px;
  overflow: auto;
  font-style: italic;
}
.download-section-btn .v-progress-circular {
  color: #344955 !important;
}
</style>

<script>
/*eslint-disable no-unused-vars*/
import { mapActions } from 'vuex'
import { Storage } from "aws-amplify"
import CanvasWidget from '@/components/canvas-items/canvas-widget'
import NavigationPanel from '@/components/panels/navigation-panel'
import LayoutList from '@/components/widgets/layout-list'
import ShareDialog from '@/components/dialogs/share-dialog'
import ProjectFlowControl from '@/components/project-flows/project-flow-control'
import { EventBus } from '@/libs/eventBus.js'
import { showConfirm } from '@/libs/tools.js'
import { User } from '@/libs/user.js'
import { AccessControl } from '@/libs/access-control.js'
import { CanvasList, getCanvasItemMap } from '@/libs/canvas.js'
import chat from '@/components/widgets/chat-widget'
import * as api from '@/libs/api.js'
import * as tools from '@/libs/tools.js'

export default {
  name: 'canvas-page',
  components: {
    'canvas-widget': CanvasWidget,
    'layout-list': LayoutList,
    'share-dialog': ShareDialog,
    'navigation-panel': NavigationPanel,
    'chat': chat,
    'project-flow-control': ProjectFlowControl
  },
  props: {
    id: {
      type: String,
      default: () => null
    },
    iid: {
      type: String,
      default: () => null
    },
    sid: {
      type: String,
      default: () => null
    },
  },
  data: () => ({
    render: 0,
    loading: true,
    canvasList: null,
    canvas: null,
    root: null,
    subsection: null,
    user: { userName: null, statuses: [] },
    editing: true,
    hideControls: false,
    template: 'Casting',
    selectedCount: 0,
    selectedExternal: [],
    copying: false,
    checked: {},
    likes: {},
    comments: {},
    emojis: {},
    commentsPanel: false,
    transcodingWatchers: {},
    publishDialog: {
      show: false,
      published: false,
      uuid: null,
      timestamp: 0
    },
    createSnapshotDialog: {
      show: false,
      name: null,
      loading: false,
      advanced: false
    },
    useInGalleryMenu: {
      show: false
    },
    lastHash: null,
    navigation: {
      width: 420,
      borderSize: 3
    },
    itemUpdateQueue: {},
    itemUpdateInterval: null,
    handlersInitialized: false,
    downloadingSelection: false,
    downloadingSection: false,
    downloadingSearchSelection: false,
    groupingSelection: false,
    movingSelection: false,
    explodingSelection: false,
    navPanelMinimized: false,
    creatingSection: false,
    speedDialTooltip: false,
    fab: false,
    itemCommentMenu: {
      x: null,
      y: null,
      item: null,
      show: false
    },
    accessDenied: false,
    requestingAccess: false,
    accessRequested: false,
    error: null,
    navWidth: 260
  }),
  async mounted() {
    try {
      this.loading = true;

      await this.updateCanvas();
      if (tools.getCollectFlow(this.canvas, this.$store.state)) {
        if (this.iid)
          this.$router.push({ name: 'collect-flow-section', params: { id: this.id, iid: this.iid } });
        else
          this.$router.push({ name: 'collect-flow', params: { id: this.id } });
        return;
      }

      this.loading = false;

      let userLib = new User(this.$store.state);
      this.user = await userLib.getUser();
      
      await this.startHandlers();
    } 
    catch (err) {
      if (err && err.response && err.response.data) {
        if (err.response.data.error === 403)
          this.accessDenied = true;
        else if (err.response.data.error === 404)
          this.error = { header: 'Project not found', text: 'We\'re sorry to say, but we couldn\'t find the requested project.' }
        else
          this.error = { header: 'Something went wrong', text: 'We\'re sorry to say, but something went wrong. Please try again later. <br /><br /><div class="error-output">' + tools.prettyPrintJson(err.response.data) + '</div>' }
      }
      else
        this.error = { header: 'Something went wrong', text: 'We\'re sorry to say, but something went wrong. Please try again later. <br /><br /><div class="error-output">' + tools.prettyPrintJson(err) + '</div>' }

      this.loading = false;
      this.render++;
    }
  },
  computed: {
    local() {
      if (typeof webpackHotUpdatesharespot === 'undefined')
        return false;
      return true;
    },
    canvasItems() {
      return getCanvasItemMap(this.$store.state, this.id);
    },
    pathText() {
      return tools.getCanvasFullPath('', this.id);
    },
    checkedCount() {
      if (!this.canvas || !this.canvas.root)
        return 0;

      let count = 0;
      const traverseCountSelection = (item) => {
        if (!item.items || !item.items.length)
          return;
        for (let i = item.items.length -1; i >= 0; i--) {
          if (item.items[i].checked) {
            count++;
          }
          if (item.items[i].items && item.items[i].items.length)
            traverseCountSelection(item.items[i]);
        }
      }
      traverseCountSelection(this.canvas.root);

      count += this.selectedExternal.length;

      return count;
    },
    checkedInternally() {
      return this.$store.state.canvas.checkedCount - this.checkedExternally;
    },
    checkedExternally() {
      return this.selectedExternal.length;
    },
    searchSelection: {
      get() { return this.$store.state.search.selection },
      set(value) {this.$store.state.search.selection = value;}
    },
    topbarTitle: {
      get() { 
        if (this.subsection && this.subsection !== this.canvas.root.iid && this.subsection in this.canvasItems) {
          let subsection = this.canvasItems[this.subsection];
          return (subsection.cfg && subsection.cfg.name) ? subsection.cfg.name : /*'Untitled'*/null;
        }
        return this.canvas.nm;
      },
      set(value) {
        if (this.subsection && this.subsection !== this.canvas.root.iid && this.subsection in this.canvasItems) {
          let subsection = this.canvasItems[this.subsection];
          if (!subsection.cfg)
            return;

          subsection.cfg.name = value;

          this.updateItemConfig(subsection, null, true);
          return;
        }

        this.updateCanvasConfig(() => this.canvas.nm = value);
      }
    },
    acUpdated() { return this.$store.state.ac.updated },
    commentCount() {
      if (!this.root)
        return 0;
      
      let rootComments = (this.root && this.root.comments && (this.canvas.root.iid !== this.root.iid) ? this.root.comments.length : 0)
      let itemCount = 0;
      if (this.root.items && this.root.items.length) {
        for (let item of this.root.items) {
          itemCount += this.getCommentsCount(item);
        }
      }

      return rootComments + itemCount;
    },
    selectionLoading() {
      return this.groupingSelection || this.downloadingSelection || this.movingSelection || this.explodingSelection;
    }
  },
  asyncComputed: {
    async acList() {
      if (!this.root)
        return [];
      let acLib = new AccessControl(this.$store);
      return await acLib.listShares(this.id, this.root);
    },
    async canvasAcList() {
      if (!this.canvas || !this.canvas.root)
        return [];
      let acLib = new AccessControl(this.$store);
      return await acLib.listShares(this.id, this.canvas.root);
    },
  },
  watch: {
    'canvas.cid': function(cid) {
      this.$store.state.canvas.cid = cid;
    },
    'canvas.root': function(root) {
      this.$store.state.canvas.root = root;
    },
    'canvas.cfg.path': function(path) {
      this.$store.state.canvas.cfg.path = path;
    },
    'canvas.cfg.comments': function(comments) {
      this.$store.state.canvas.cfg.comments = comments;
    },
    checkedCount(value) {
      this.$store.state.canvas.checkedCount = value;
    },
    root(item) {
      this.$store.state.canvas.activeRoot = item.iid;
    },
    fab(val) {
      setTimeout(() => { this.speedDialTooltip = val }, 300);
    },
  },
  methods: {
    ...mapActions(['showDialog']),
    async updateCanvas() {
      this.canvasList = new CanvasList(this.$store.state);

      let result = this.sid ? await this.canvasList.getSnapshot(this.id, this.sid) : await this.canvasList.getCanvas(this.id);
      this.canvas = this.sid ? result.snapshot : result.canvas;
      
      if (this.canvas.allowedRole && (this.canvas.allowedRole === 'Viewer' || this.canvas.allowedRole === 'Client')) {
        this.editing = false;
        this.hideControls = true;
      }
      if (this.iid && this.iid in this.canvasItems) {
        this.root = this.canvasItems[this.iid];
        this.subsection = this.iid;
      }
      else {
        this.root = this.canvas.root;
        this.subsection = null;
      }
    },
    getTypeIcon(type) {
      if (!type || !type.length)
        return "";

      if (type === 'application/pdf')
        return 'mdi-file-document';

      else if (type.match('image.*'))
        return 'mdi-file-image';

      if (type.match('video.*'))
        return 'mdi-file-video';

      if (type.match('audio.*'))
        return 'mdi-file-music';

      return '';
    },
    getHash(obj) {
      if (!obj)
        return '';
      let hashCode = s => s.split('').reduce((a,b)=>{a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);
      return hashCode(typeof obj === 'string' ? obj : JSON.stringify(obj));
    },
    async publish(forcePublish) {
      if (!this.canvas || !this.canvas.root.items || !this.canvas.root.items.length)
        return;

      if (!forcePublish && this.canvas.cfg.publishedCid) {
        this.publishDialog.published = true;
        this.publishDialog.show = true;
        return;
      }
      this.publishDialog.timestamp = Date.now();
      this.publishDialog.published = false;
      this.publishDialog.show = true;

      await api.publishCanvas(this.canvas.cid);
      
      this.publishDialog.published = true;
    },
    getPublicURL() {
      return window.location.origin + '/~/' + this.canvas.cid;
    },
    copyURL() {
      this.copying = true;
      if (navigator.clipboard)
        navigator.clipboard.writeText(this.getPublicURL());
      else
        this.fallbackCopyTextToClipboard();
        
      setInterval(()=>{this.copying = false}, 3000);
    },
    fallbackCopyTextToClipboard() {
      let el = document.getElementById('publicURL');
      el.focus();
      el.select();
      document.execCommand('copy');
    },
    getCommentsCount(root) {
      let count = root.comments ? root.comments.length : 0;
      const traverseCount = (item) => {
        if (!item.items || !item.items.length)
          return;
        for (let i = item.items.length -1; i >= 0; i--) {
          if (item.items[i].comments) {
            count += item.items[i].comments.length;
            continue;
          }
          if (item.items[i].items && item.items[i].items.length)
            traverseCount(item.items[i]);
        }
      }
      traverseCount(root);

      return count;
    },
    viewSelection() {
      let items = [];
      const traverseFind = (item) => {
        if (!item.items || !item.items.length)
          return;
        for (let i = item.items.length -1; i >= 0; i--) {
          if (item.items[i].checked) {
            let clone = {...item.items[i]};
            /*if (clone.thumbSrc)
              delete clone.thumbSrc;*/
            items.push(clone);
            continue;
          }
          if (item.items[i].items && item.items[i].items.length)
            traverseFind(item.items[i]);
        }
      }
      traverseFind(this.canvas.root);

      if (this.selectedExternal.length)
        this.selectedExternal.forEach(item => items.push(item));

      if (!items.length)
        return;

      let getSizeFromCount = (count) => {
        if (!count || count === 1)
          return 4;
        if (count === 2)
          return 4;
        if (count <= 6)
          return 3;
        if (count <= 8)
          return 2;
        return 1;
      }

      let item = items.length === 1 ? items[0] : { type: 'gallery', cfg: { name: '', masonry: items.length > 4, aspectRatio: 10, cover: false, layoutClass: 'justify-center align-center', elevation: 0, size: getSizeFromCount(items.length) }, cid: this.canvas.cid, checked: false, iid: null, items: items, kv: [] };
      EventBus.$emit('showViewDialog', { items: [item], index: 0 });
      this.cancelSelection();
    },
    cancelSelection() {
      const traverseCancelSelection = (item) => {
        if (!item.items || !item.items.length)
          return;
        for (let i = item.items.length -1; i >= 0; i--) {
          if (item.items[i].checked) {
            item.items[i].checked = false;
          }
          if (item.items[i].items && item.items[i].items.length)
            traverseCancelSelection(item.items[i]);
        }
      }
      traverseCancelSelection(this.canvas.root);

      this.checked = {};
      this.selectedCount = 0;
      if (this.selectedExternal.length) {
        this.selectedExternal.forEach(item => { item.checked = false; });
        this.selectedExternal = [];
      }
      
      EventBus.$emit('orgChartUpdateSelection', []);
    },
    cancelSearchSelection() {
      this.searchSelection = [];
    },
    async downloadItems(items) {
      try {
        const archiveFileName = await api.zipItems(items);
        if (!archiveFileName)
          return;

        let response = await Storage.vault.get('archives/' + archiveFileName, { expires: 86400, download: true});

        const a = document.createElement("a");
        a.href = URL.createObjectURL(response.Body);
        a.download = archiveFileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
      catch (err) {
        console.log(err);
      }
    },
    async downloadSearchSelection() {
      if (!this.searchSelection || !this.searchSelection.length)
        return;

      this.downloadingSearchSelection = true;

      let items = [];
      for (let item of this.searchSelection) {
        items.push({iid: item.iid, cid: item.cid});
      }

      await this.downloadItems(items);

      this.downloadingSearchSelection = false;
    },
    async deleteSelected() {
      showConfirm(this.$store, this.$t('confirmDeleteTitle'), this.$t('confirmDeleteText'), async () => {
        let trash = [];
        const traverseDelete = (item) => {
          if (!item.items || !item.items.length)
            return;
          for (let i = item.items.length -1; i >= 0; i--) {
            if (item.items[i].checked) {
              trash.push({iid: item.items[i].iid, parentIid: item.iid});
              item.items.splice(i,1);
              continue;
            }
            if (item.items[i].items && item.items[i].items.length)
              traverseDelete(item.items[i]);
          }
        }
        traverseDelete(this.canvas.root);

        for (let cfg of trash)
            await api.removeItem(this.canvas.cid, cfg.parentIid, cfg.iid);

        this.checked = {};
      }, 'yes-no');
    },
    async downloadSelected() {
      let items = [];
      this.downloadingSelection = true;
      try {
        // Traverse find all selected items
        const traverseSelected = (item) => {
          if (!item.items || !item.items.length)
            return;
          for (let i = item.items.length -1; i >= 0; i--) {
            if (item.items[i].checked) {
              items.push({iid: item.items[i].iid, cid: item.items[i].cid});
              item.items[i].checked = false;
              continue;
            }
            if (item.items[i].items && item.items[i].items.length)
              traverseSelected(item.items[i]);
          }
        }
        traverseSelected(this.canvas.root, null);

        if (items.length)
          await this.downloadItems(items);
      }
      catch (err) {
        console.log(err);
      }
      this.downloadingSelection = false;
    },
    async downloadSection() {
      if (!this.root || !this.root.items || !this.root.items.length)
        return;
      
      let items = [];
      this.downloadingSection = true;
      try {
        // Traverse find all files in current section
        const traverseSelected = (item) => {
          if (!item.items || !item.items.length)
            return;
          for (let i = item.items.length -1; i >= 0; i--) {
            if (item.items[i].src) {
              items.push({iid: item.items[i].iid, cid: item.items[i].cid});
              continue;
            }
            if (item.items[i].items && item.items[i].items.length)
              traverseSelected(item.items[i]);
          }
        }
        traverseSelected(this.root, null);

        await this.downloadItems(items);
      }
      catch (err) {
        console.log(err);
      }
      this.downloadingSection = false;
    },
    countGroupsInSelected() {
      let count = 0;
      const traverseCountGroups = (item) => {
        if (!item.items || !item.items.length)
          return;
        for (let i = item.items.length -1; i >= 0; i--) {            
          if (item.items[i].items && item.items[i].items.length) {
            if (item.items[i].checked)
              count++;

            traverseCountGroups(item.items[i]);
          }
        }
      }
      traverseCountGroups(this.canvas.root, null);
      return count;
    },
    async explodeSelected() {
      this.explodingSelection = true;
      let group = [];
      let lastParent = null;

      // Traverse find all selected groups
      const traverseGroup = (item) => {
        if (!item.items || !item.items.length)
          return;
        for (let i = item.items.length -1; i >= 0; i--) {
          
          if (item.items[i].items && item.items[i].items.length) {
            if (item.items[i].checked) {
              group.push(...item.items[i].items);
              lastParent = item;
            }
            traverseGroup(item.items[i]);
          }
        }
      }
      traverseGroup(this.canvas.root, null);

      if (!lastParent)
        console.error('Explode - parent not found');

      await this.moveItems(group, lastParent);
      
      this.selectedCount = 0;
      this.checked = {};
      this.cancelSelection();
      this.explodingSelection = false;
    },
    async groupSelected() {
      this.groupingSelection = true;
      let group = [];
      let last = {index: null, parent: null};
      // Traverse find all selected items
      const traverseGroup = (item) => {
        if (!item.items || !item.items.length)
          return;
        for (let i = item.items.length -1; i >= 0; i--) {
          if (item.items[i].checked) {
            //item.items[i].checked = false;
            group.push(item.items[i]);
            last = {index: i, parent: item};
            continue;
          }
          if (item.items[i].items && item.items[i].items.length)
            traverseGroup(item.items[i]);
        }
      }
      traverseGroup(this.canvas.root, null);

      // Now, add new group to the canvas and update the database
      if (last.parent && last.parent.items) {
        let gallery = {
          type: 'gallery',
          cfg: { name: '', aspectRatio: 8, cover: true, outlined: false, rounded: false, elevation: 1, spacing: 1, showNames: true, expanded: [0], size: 2 },
          cid: this.canvas.cid,
          parent: last.parent.iid,
          checked: false,
          iid: null,
          items: [],
          kv: [],
          comments: []
        };
        gallery.iid = await this.createItem(this.canvas.cid, last.parent.iid, 'gallery', { name: '', aspectRatio: 8, cover: true, outlined: false, rounded: false, elevation: 1, spacing: 1, showNames: true, expanded: [0], size: 2 }, last.index, group);
        gallery.loading = true;
        last.parent.items.splice(last.index, 0, gallery);
        
        await this.moveItems(group, gallery);
        gallery.loading = false;
      }
      this.selectedCount = 0;
      this.checked = {};
      this.cancelSelection();
      this.groupingSelection = false;
    },
    async moveSelected(target) {
      if (!target)
        return;

      this.movingSelection = true;

      let items = [];
      let trash = [];
      // Traverse find all selected items
      const traverseGroup = (item) => {
        if (!item.items || !item.items.length)
          return;
        for (let i = item.items.length -1; i >= 0; i--) {
          if (item.items[i].checked) {
            items.push(item.items[i]);
            trash.push({iid: item.items[i].iid, parentIid: item.iid});
            item.items.splice(i, 1);
            continue;
          }
          if (item.items[i].items && item.items[i].items.length)
            traverseGroup(item.items[i]);
        }
      }
      traverseGroup(this.canvas.root, null);

      if (this.selectedExternal && this.selectedExternal.length)
        this.selectedExternal.forEach(selectedItem => { items.push(selectedItem); });

      let iid = target.iid;

      // Create new gallery if needed
      if (target.type === 'root') {
        let gallery = {
          cid: this.canvas.cid,
          type: 'gallery',
          cfg: { name: '', aspectRatio: 8, cover: true, outlined: false, rounded: false, elevation: 1, spacing: 1, showNames: true, expanded: [0], size: 2 }
        }
        iid = await this.createItem(this.canvas.cid, this.canvas.root.iid, gallery.type, gallery.cfg);
        gallery.iid = iid;
        gallery.items = [];
        target = gallery;
        this.canvas.root.items.push(gallery);
      }

      for (let item of items) {
        if (item.cid === this.canvas.cid) {
          target.items.push(item);
          await this.createItem(this.canvas.cid, iid, item.type, item.cfg);
        }
        else if (item.iid) {
          let canvasLink = { cid: this.id, type: 'link', checked: false, cfg: { cid: item.cid, iid: item.iid, name: item.cfg ? item.cfg.name : null}};
          canvasLink.iid = await this.createItem(this.canvas.cid, iid, canvasLink.type, canvasLink.cfg);
          target.items.push(canvasLink);
        }
      }
      for (let cfg of trash) {
        await api.removeItem(this.canvas.cid, cfg.parentIid, cfg.iid);
      }

      this.selectedCount = 0;
      this.checked = {};
      this.cancelSelection();
      this.movingSelection = false;
    },
    async moveItems(items, target, position = 0, removeEmptyGroups = true) {
      let parents = {};
      let moveItems = [];
      for (let item of items) {
        moveItems.push(item.iid);

        if (item.parent in parents)
          parents[item.parent].push(item.iid);
        else
          parents[item.parent] = [item.iid];

        item.parent = target.iid;
      }

      for (let parentIid in parents) {
        if (!parentIid)
          continue;
        let parent = this.canvasItems[parentIid];
        const removeChildren = parents[parentIid];
        parent.items = (!parent.items || !parent.items.length) ? [] : parent.items.filter(item => !removeChildren.includes(item.iid));

        if (!parent.items.length && removeEmptyGroups) {
          await api.removeItem(this.canvas.cid, parent.parent, parentIid, false);
          
          let parentItems = this.canvasItems[parent.parent].items;
          parentItems.splice(parentItems.findIndex(p => p.iid === parentIid), 1);
        }
        else
          await api.updateItem(this.canvas.cid, parentIid, { i: parent.items.map(item => item.iid) });
      }

      target.items.splice(position, 0, ...items);
      await api.updateItem(this.canvas.cid, target.iid, { i: target.items.map(item => item.iid) });

      this.selectedCount = 0;
      this.checked = {};
    },
    addAssets(widget) {
      this.$store.state.dialogs.addAssets = {
        show: true, 
        path: this.pathText,
        targetWidget: widget
      }
    },
    async updateItemConfig(widget, functor, addToQueue) {
      if (functor)
        functor();

      if (widget.iid) {
        let updateItem = async () => {
          await api.updateItem(this.canvas.cid, widget.iid, { cfg: widget.cfg });
          this.canvasList.updateCanvas(this.canvas.cid, this.canvas);
        }

        if (!addToQueue)
          await updateItem();
        else 
          this.itemUpdateQueue[widget.iid] = updateItem;
      }
    },
    async updateCanvasConfig(functor) {
      functor();

      await api.updateCanvas(this.canvas.cid, {
        nm: this.canvas.nm,
        cfg: this.canvas.cfg
      });

      this.canvasList.updateCanvas(this.canvas.cid, this.canvas);
    },
    async onUpdateItemInterval() {
      if (!this.itemUpdateQueue)
        return;
      const queue = this.itemUpdateQueue;

      for (let key in queue) {
        const functor = queue[key];
        if (!functor)
          continue;

        await functor();
      }

      this.itemUpdateQueue = {};
    },
    async startItemUpdateInterval() {
      await this.stopItemUpdateInteval();
      this.itemUpdateInterval = window.setInterval(async () => await this.onUpdateItemInterval(), 1000);
    },
    async stopItemUpdateInteval() {
      if (!this.itemUpdateInterval) 
        return;
      
      clearInterval(this.itemUpdateInterval);
      this.itemUpdateInterval = null;

      await this.onUpdateItemInterval();
      this.itemUpdateQueue = {};
    },
    async getACForUID(uid) {
      let acLib = new AccessControl(this.$store);
      let acList = await acLib.listShares(this.id, this.canvas.root);
      if (!acList || !acList.length) 
        return null;

      let index = acList.findIndex(ac => ac.uid === uid);
      if (index >= 0)
        return acList[index];

      return null
    },
    async getMessages(iid) {
      let comments =  await api.getComments(this.canvas.cid);
      if (!comments || !comments.length)
        return [];

      comments = comments.filter(comment => comment.iid === iid);
      for (let comment of comments) {
        let ac = comment.uid ? (await this.getACForUID(comment.uid)) : null;
        if (ac)
          comment.profileImage = ac.profileImage;
      }
      return comments;
    },
    async sendMessage(text, userName, iid) {
      let comment = await api.createComment(this.canvas.cid, userName, iid, text);
      if (!comment)
        return;

      let ac = comment.uid ? (await this.getACForUID(comment.uid)) : null;
      if (ac)
        comment.profileImage = ac.profileImage;
        
      if (iid in this.comments)
        this.comments[iid].push(comment);
      else
        this.comments[iid] = [comment];
    },
    startTranscodingJobsHandler() {
      if (this.handlersInitialized)
        return;
      EventBus.$on('watchTranscodingJob', async (iid) => {
        if (!(iid in this.transcodingWatchers)) {
          this.transcodingWatchers[iid] = setInterval(async () => {
            const item = await api.getItem(this.canvas.cid, iid);
            if (!item || !item.cfg || !item.cfg.status || item.cfg.status === 'CONVERTING')
              return;
            clearInterval(this.transcodingWatchers[iid]);
            delete this.transcodingWatchers[iid];
            await this.updateCanvas();
          }, 3000);
        }
      });
    },
    startUpdateCanvasHandler() {
      if (this.handlersInitialized)
        return;
      EventBus.$on('updateCanvas', async (canvas) => {
        if (!canvas)
          return;

        this.canvas = canvas;
      });
    },
    startRefreshHandler() {
      if (this.handlersInitialized)
        return;
      EventBus.$on('refresh', () => { this.render++ });
    },
    startUpdateItemConfigHandler() {
      if (this.handlersInitialized)
        return;
      EventBus.$on('updateItemConfig', async (params) => {
        if (!params || !params.widget || !params.functor)
          return;
          
        await this.updateItemConfig(params.widget, params.functor, !!params.addToQueue);
      });
    },
    startUpdateItemHandler() {
      if (this.handlersInitialized)
        return;
      EventBus.$on('updateItem', (data) => {
        if (!data.id || !data.funct)
          return;
        this.itemUpdateQueue[data.id] = data.funct;
      });
    },
    startNavigateHandler() {
      if (this.handlersInitialized)
        return;
      EventBus.$on('navigateTo', (data) => {
        if (!data.item)
          return;

        this.navigateTo(data.item);
      });
    },
    startItemCommentsHandler() {
      if (this.handlersInitialized)
        return;
      EventBus.$on('showItemComments', (data) => {
        if (!data.item || !data.x || !data.y)
          return;

        this.itemCommentMenu.x = data.x;
        this.itemCommentMenu.y = data.y;
        this.itemCommentMenu.item = data.item;
        this.itemCommentMenu.show = true;
      });
    },
    startNavigationResizedHandler() {
      if (this.handlersInitialized)
        return;
      EventBus.$on('navigationResized', (width) => {
        this.navWidth = width;
      });
    },
    startOrgChartAddHandler() {
      if (this.handlersInitialized)
        return;
      EventBus.$on('orgChartAddNode', async (data) => {
        if (!data || !data.parentId || (data.action && data.action === 'add-canvas'))
          return;

        if (!(data.parentId in this.canvasItems))
          return;

        let parent = this.canvasItems[data.parentId];
        
        if (data.action && data.action === 'add-section' && !this.creatingSection) {
          this.creatingSection = true;
          try {
            let gallery = { type: 'gallery', parent: data.parentId, cid: this.id, cfg: { name: '', aspectRatio: 10, cover: true, outlined: false, rounded: false, elevation: 1, spacing: 1, showNames: true, size: 1}, kv: [], checked: false, comments: [] }
            gallery.iid = await api.createItem(gallery.cid,  data.parentId, gallery.type, gallery.cfg);
            this.canvasItems[gallery.iid] = gallery;
            parent.items.push(gallery);
          }
          catch (err) {
            console.log(err);
          }
          this.creatingSection = false;
        }
        else
          this.addAssets(parent)
      });
    },
    startCheckExternalHandler() {
      if (this.handlersInitialized)
        return;

      EventBus.$on('checkExternal', async (item) => {
        if (!item)
          return;

        let index = !this.selectedExternal.length ? -1 : this.selectedExternal.findIndex(i => i.iid === item.iid);
        if (item.checked && index < 0)
          this.selectedExternal.push(item);

        else if (!item.checked && index >= 0)
          this.selectedExternal.splice(index, 1);
      });
    },
    navigateTo(item) {
      if (!item)
        return;

      if (item.cid && item.cid !== this.id) {
        if (item.iid)
          this.$router.push('/canvas/' + item.cid + '/' + item.iid);
        else
          this.$router.push('/canvas/' + item.cid);
        
        return;
      }

      if (this.sid)
        history.pushState({}, null, '/snapshot/' + this.id + '/' + item.iid + '/' + this.sid);
      else
        history.pushState({}, null, '/canvas/' + this.id + '/' + item.iid);

      if (item.iid && item.iid in this.canvasItems) {
        this.root = this.canvasItems[item.iid];
        this.subsection = item.iid;
      }
      else {
        this.root = this.$store.state.canvas.root;
        this.subsection = null;
      }
    },
    toggleEdit() {
      this.editing = !this.editing;
      if (!this.editing)
        this.cancelSelection();
    },
    findItem(iid) {
      const traverseFind = (item) => {
        if (item.iid === iid)
          return item;

        if (item.items) {
          for (let i = item.items.length -1; i >= 0; i--) {
            let found = traverseFind(item.items[i]);
            if (found)
              return found;
          }
        }

        return null;
      }
      return traverseFind(this.canvas.root);
    },
    async copySearchSelected(widget) {
      if (!widget)
        return;
      let target = this.findItem(widget.iid)
      if (!target)
        target = widget;
      // quickly add locally
      for (let item of this.searchSelection)
        target.items.push(item);
      // and then slowly add to the db
      for (let item of this.searchSelection) 
        await api.copyItem(item.cid, target.cid, item.iid, target.iid);
    },
    async createItem(cid, parent, type, config, index, items) {
      let iid = await api.createItem(cid, parent, type, config, index);
      this.canvasItems[iid] = {type: type, cfg: config, parent: parent, cid: cid, iid: iid, checked: false}
      if (items && items.length)
        this.canvasItems[iid].items = items;
      return iid;
    },
    share() {
      if (!this.canvas.cid || !this.root || !this.root.iid || !this.editing)
        return;
      this.$store.state.dialogs.shareWith = {
        show: true,
        cid: this.canvas.cid,
        iid: this.root.iid,
        public: (this.canvas.cfg.publishedCid && this.canvas.cfg.publishedCid.length)
      }
    },
    navigationPanelMinimized(value) {
      this.navPanelMinimized = value;
    },
    // get snapshot from sid
    getSnapshot(sid) {
      if (!sid || !this.canvas.snapshots || !this.canvas.snapshots.length)
        return null;
      let snapshot = this.canvas.snapshots.find(s => s.sid === sid);
      return snapshot
    },
    getBreadcrumbs(item) {
      const skipTypes = ['root', 'casting', 'casting-profile'];
      let breadcrumbs = [];
      let parent = item;
      let snapshot = this.sid ? this.getSnapshot(this.sid) : null;
      // max depth 10
      for (let i = 0; i < 10; ++i) {
        if (!parent || !parent.cfg)
          break;

        if (!skipTypes.includes(parent.type))
          breadcrumbs.unshift({text: (parent.cfg.name || 'Untitled'), iid: parent.iid});

        if (!parent.parent || !(parent.parent in this.canvasItems))
          break;

        parent = this.canvasItems[parent.parent];
      }

      breadcrumbs.unshift({text: this.canvas.nm || 'Untitled', iid: '', snapshot: snapshot});

      breadcrumbs.unshift({text: 'Projects', iid: '', allProjects: true});


      return breadcrumbs;
    },
    async createSection() {
      this.creatingSection = true;
      try {
        let gallery = { type: 'gallery', parent: this.root.iid, cid: this.id, cfg: { name: '', aspectRatio: 10, cover: true, outlined: false, rounded: false, elevation: 1, spacing: 1, showNames: true, size: 1}, kv: [], checked: false, comments: []};
        if (this.root && this.root.cfg && this.root.cfg.flat) {
          gallery.iid = await api.createItem(gallery.cid, this.root.iid, gallery.type, gallery.cfg);
          this.canvasItems[gallery.iid] = gallery;
          this.root.items.push(gallery);
        }
        else {
          await api.addSubSection(this.root, gallery, this.$store);
        }
      }
      catch (err) {
        console.log(err);
      }
      this.creatingSection = false;
    },
    async createMap() {
      this.creatingSection = true;
      try {
        let map = { type: 'map', parent: this.root.iid, cid: this.id, cfg: { name: '', aspectRatio: 10, cover: true, outlined: false, rounded: false, elevation: 1, spacing: 1, showNames: true, size: 1}, kv: [], checked: false, comments: []};
        if (this.root && this.root.cfg && this.root.cfg.flat) {
          map.iid = await api.createItem(map.cid, this.root.iid, map.type, map.cfg);
          this.canvasItems[map.iid] = map;
          this.root.items.push(map);
        }
        else {
          await api.addSubSection(this.root, map, this.$store);
        }
      }
      catch (err) {
        console.log(err);
      }
      this.creatingSection = false;
    },
    getColor(str) {
      return tools.seededRandomColor(str);
    },
    async requestAccess() {
      this.requestingAccess = true;
      try {
        await api.requestAccess(this.id, this.iid)
        this.accessRequested = true;
      }
      catch (err) {
        console.log(err);
      }
      this.requestingAccess = false;
    },
    async setStatus(status) {
      if (!this.root)
        return;

      //let previous = this.root.status;
      if (status === this.root.status)
        status = '';

      this.root.status = status;
      await api.updateItem(this.canvas.cid, this.root.iid, { s: status })
      /*if (!await api.updateItem(this.canvas.cid, this.root.iid, { s: status }))
        this.root.status = previous;*/
    },
    snapshotCreated(snapshot) {
      if (!this.canvas.snapshots)
        this.canvas.snapshots = [];

      this.canvas.snapshots.push(snapshot);
    },
    async createSnapshot(name) {
      if (!name || !name.length)
        return;

      this.createSnapshotDialog.loading = true;
      try {
        let snapshot = await api.createSnapshot(this.canvas.cid, this.root.iid, name);

        if (!snapshot)
          return;

        if (!this.canvas.snapshots)
          this.canvas.snapshots = [];

        this.canvas.snapshots.push(snapshot);
      }
      catch (err) {
        console.log(err);
      }

      this.createSnapshotDialog.show = false;
      this.createSnapshotDialog.name = null;
      this.createSnapshotDialog.loading = false;
    },
    timestampToDate(timestamp) {
      return tools.timestampToDate(timestamp);
    },
    sortItemsByProperty(items, key) {
      return items.sort((a, b) => {
        if (a[key] < b[key])
          return -1;
        if (a[key] > b[key])
          return 1;
        return 0;
      });
    },
    deleteSnapshot(sid) {
      showConfirm(this.$store, this.$t('confirmDeleteTitle'), this.$t('confirmDeleteText'), async () => {
        await api.removeSnapshot(this.canvas.cid, sid);
        // remove snapshot from this.canvas.snapshot array by sid
        let index = this.canvas.snapshots.findIndex(s => s.sid === sid);
        if (index >= 0)
          this.canvas.snapshots.splice(index, 1);
      }, 'yes-no');      
    },
    openSnapshot(snapshot) {
      //window.open(window.location.origin + '/snapshot/' + this.canvas.cid + '/' + snapshot.iid + '/' + snapshot.sid, '_blank').focus();
      window.location.pathname = '/snapshot/' + this.canvas.cid + '/' + snapshot.iid + '/' + snapshot.sid;
    },
    openCanvas() {
      // Open canvas in the same tab
      window.location.pathname = '/canvas/' + this.canvas.cid + '/' + this.iid;
    },
    userCanOpenIid(iid) {
      if (!(this.id in this.$store.state.ac.canvases))
        return false;

      let list = this.$store.state.ac.canvases[this.id].list
      return list.findIndex(ac => ac.iid === iid && ac.uid === this.$store.state.user.uid) > -1;
    },
    async startHandlers() {
      this.startTranscodingJobsHandler();
      this.startUpdateCanvasHandler();
      this.startRefreshHandler();
      await this.startItemUpdateInterval();
      this.startUpdateItemConfigHandler();
      this.startUpdateItemHandler();
      this.startNavigateHandler();
      this.startItemCommentsHandler();
      this.startNavigationResizedHandler();
      this.startOrgChartAddHandler();
      this.startCheckExternalHandler();

      this.handlersInitialized = true;
    },
    nullHandler() {}
  }
}
</script>
