<template>
<div>
  <div>
    <div class="text-right pt-4 section-labels d-inline-block" v-if="root && user && user.statuses && user.statuses.length">
      <v-menu :close-on-content-click="true" offset-y bottom>
        <template v-slot:activator="{ on }">
          <v-chip v-on="on" small class="mx-1 elevation-0 add-status" v-if="!root.status"><v-icon small left>mdi-plus</v-icon>status</v-chip>  
          <v-chip v-on="on" small class="mx-1 elevation-0 pr-1" :color="root.status === 'Done' ? 'success' : (root.status === 'Canceled' ? 'error' : null)" v-else>Status:&nbsp;<b>{{root.status}}</b><v-icon x-small>mdi-chevron-down</v-icon></v-chip>  
        </template>
        <v-card class="pa-0">
          <v-list dense class="pa-0">
            <v-list-item class="pl-2" v-for="status in user.statuses" :key="status" @click="setStatus(status)">
              <v-list-item-action class="pa-0 ma-0" v-if="root.status"><v-icon small v-if="root.status === status">mdi-check</v-icon></v-list-item-action>
              <v-list-item-title :class="root.status === status ? 'font-weight-bold' : null">{{status}}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>
    <div class="d-inline-block snapshots" :class="canvas.snapshots && canvas.snapshots.length ? 'has-snapshots' : ''" :style="canvas.sid ? 'opacity: 1' : '' ">
      <v-menu :close-on-content-click="true" offset-y bottom>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-tooltip bottom>
              <template v-slot:activator="{on}">
                <div v-on="on" style="position: relative; cursor: pointer">
                  <v-icon color="primary">mdi-backup-restore</v-icon>
                  <v-icon color="primary" size="12" style="position: absolute; bottom: -4px; right: -4px;">mdi-chevron-down</v-icon>
                </div>
              </template>
              <span>Snapshots</span>
            </v-tooltip>
          </div>
        </template>
        <v-card class="pa-1">
          <v-list class="pa-0" dense two-line>
            <template v-if="canvas.snapshots && canvas.snapshots.length">
              <div class="text-center caption pt-3 pb-2 mx-auto" style="max-width: 220px">Create a snapshot to conserve the state of the project in time.</div>
              <v-divider></v-divider>
              <v-list-item class="pl-2 pr-0 hoverable-ex" v-for="(snapshot, i) in /*sortItemsByProperty(*/canvas.snapshots/*, 'created')*/" :key="i" @click.stop="snapshot.sid === canvas.sid ? openCanvas() : openSnapshot(snapshot)">
              <v-list-item-action class="pa-0 ma-0" v-if="canvas.sid"><v-icon small v-if="snapshot.sid === canvas.sid">mdi-check</v-icon></v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{snapshot.nm}}</v-list-item-title>
                  <v-list-item-subtitle>{{timestampToDate(snapshot.created)}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="editing" style="margin-left: 4px;"><v-icon @click.prevent.stop="deleteSnapshot(snapshot.sid)" :size="20" color="primary" class="show-on-hover-ex">mdi-trash-can</v-icon></v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
            </template>
            <div v-else class="text-center caption pt-3">
              <span>This project doesn't have any snapshots yet.<br/>Create a snapshot to conserve the state of the project in time.</span>
            </div>
            <v-list-item v-if="editing"><v-btn @click="createSnapshotDialog.show = true" rounded small class="elevation-1 mx-auto" color="secondary primary--text"><v-icon left>mdi-plus</v-icon> Create snapshot</v-btn></v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>
  </div>
  <v-dialog v-model="createSnapshotDialog.show" @keydown.esc="createSnapshotDialog.loading ? (()=>{})() : createSnapshotDialog.show = false" persistent width="650">
    <v-card light>
      <v-card-title class="primary white--text pr-3">Create a snapshot of {{root.cfg ? (root.cfg.name || 'this section') : (canvas.nm || 'this project')}}<v-spacer></v-spacer><v-btn icon color="white"><v-icon @click="createSnapshotDialog.loading ? (()=>{})() : createSnapshotDialog.show = false">mdi-close</v-icon></v-btn></v-card-title>
      <v-card-text class="primary--text pa-7 text-center">
        <div class="body-2 text-left pb-3">
          <span>Create a snapshot to conserve the state of the project in time. You will be able to access this snapshot anytime in the future.</span>
        </div>
        <v-text-field class="mt-4 mb-2 py-0 pr-0" outlined hide-details label="Please enter name of the snapshot" v-model="createSnapshotDialog.name" @keydown.enter="(createSnapshotDialog.name && createSnapshotDialog.name.length) ? createSnapshot(createSnapshotDialog.name) : (() => {})()" :loading="createSnapshotDialog.loading" autofocus></v-text-field>
        <v-expand-transition>
          <div v-if="createSnapshotDialog.advanced">
            <div><v-icon>mdi-alpha</v-icon> - under construction</div>
            <v-switch class="d-inline-block pb-2" :input-value="true" disabled hide-details label="Clear comments" style="margin-right: 16px"></v-switch>
            <v-switch class="d-inline-block pb-2" :input-value="true" disabled hide-details label="Clear emoji reactions" style="border-left: 1px solid rgba(0,0,0,0.12); padding-left: 16px"></v-switch>
          </div>
        </v-expand-transition>
        <div class="d-flex" style="cursor: pointer" @click="createSnapshotDialog.advanced = !createSnapshotDialog.advanced">
          <v-divider class="flex-grow-1 flex-shrink-0 mr-3" style="margin-top: 10px"></v-divider>
          <div class="caption flex-grow-0 flex-shrink-1">Advanced <v-icon small color="rgba(0, 0, 0, 0.24)">{{`mdi-chevron-${createSnapshotDialog.advanced ? 'up' : 'down'}`}}</v-icon></div>
        </div>
      </v-card-text>
      <v-card-actions style="background-color: #edf0f2">
        <v-spacer></v-spacer>
        <v-btn text rounded color="primary" :disabled="createSnapshotDialog.loading" @click="createSnapshotDialog.show = false">{{ $t('cancel') }}</v-btn>
        <v-btn class="elevation-1 rounded-pill secondary primary--text" :loading="createSnapshotDialog.loading" :disabled="createSnapshotDialog.loading || !createSnapshotDialog.name || !createSnapshotDialog.name.length" @click="createSnapshot(createSnapshotDialog.name)">Create</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<style>
</style>

<script>
/* eslint-disable no-unused-vars */
import { User } from '@/libs/user.js'
import * as api from '@/libs/api.js'
import * as tools from '@/libs/tools.js'
export default {
  name: 'project-flow-control',
  props: {
    canvas: {
      type: Object,
      default: () => null
    },
    root: {
      type: Object,
      default: () => null
    },
    editing: {
      type: Boolean, 
      default: () => false
    }
  },
  data() {
    return {
      user: null,
      createSnapshotDialog: {
        show: false,
        name: null,
        loading: false,
        advanced: false
      },
    }
  },
  async mounted() {
    let userLib = new User(this.$store.state);
    this.user = await userLib.getUser();
  },
  methods: {
    setStatus(status) {
      this.$emit('set-status', status);
    },
    timestampToDate(timestamp) {
      return tools.timestampToDate(timestamp);
    },
    openSnapshot(snapshot) {
      window.location.pathname = '/snapshot/' + this.canvas.cid + '/' + snapshot.iid + '/' + snapshot.sid;
    },
    openCanvas() {
      window.location.pathname = '/canvas/' + this.canvas.cid + '/' + this.iid;
    },
    async createSnapshot(name) {
      if (!name || !name.length)
        return;

      this.createSnapshotDialog.loading = true;
      try {
        let snapshot = await api.createSnapshot(this.canvas.cid, this.root.iid, name);

        if (!snapshot)
          return;

        this.$emit('snapshot-created', snapshot);
      }
      catch (err) {
        console.log(err);
      }

      this.createSnapshotDialog.show = false;
      this.createSnapshotDialog.name = null;
      this.createSnapshotDialog.loading = false;
    },
  }
}
</script>