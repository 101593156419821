<template>
  <v-dialog v-model="show" @keydown.esc="show = false" scrollable persistent content-class="admin-dialog" width="800">
    <v-card color="#edf0f2">
      <v-card-title class="primary white--text pa-3">Administration<v-spacer></v-spacer><v-btn icon color="white" @click="show = false"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
      <v-card-text class="pt-10" style="background-color: white" id="photo-upload-container">
        <template v-if="user">
          <v-text-field :value="user.account" v-on:change="updateAccountName($event)" placeholder="Name" flat solo hide-details style="margin: 20px 0px 30px 0px; font-weight: 300; font-size: 38px" background-color="rgba(0,0,0,0.02)" class="name-input"></v-text-field>
          <v-card class="elevation-1">
            <v-tabs v-model="tab" background-color="primary accent-1" dark centered>
              <v-tabs-slider></v-tabs-slider>
              <v-tab href="#team-members">Team members</v-tab>
              <v-tab href="#projects">Project flow</v-tab>
              <v-tab href="#notifications">Notifications</v-tab>
              <v-tab href="#templates" v-if="user.templates && user.templates.length">Templates</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item value="team-members">
                <v-card flat height="440px">
                  <v-card-text>
                    <v-simple-table v-if="user.teamMembers && user.teamMembers.length">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left" style="width: 160px;">User</th>
                            <th class="text-left" >Email</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="teamMember in user.teamMembers">
                            <tr :key="teamMember.e" v-if="teamMember.e" class="ac-row">
                              <td class="text-left">
                                <span>{{teamMember.userName}}</span>
                              </td>
                              <td class="text-left">
                                <span :class="teamMember.precreated ? 'font-italic' : ''">{{teamMember.e}}</span>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                  <v-card-actions class="text-center pt-5">
                    <v-btn class="rounded-pill elevation-1 mx-auto pr-4" color="secondary primary--text" @click="showInviteDialog = true"><v-icon color="primary" left>mdi-plus</v-icon>Add team members</v-btn>
                  </v-card-actions>
                </v-card>
              </v-tab-item>
              <v-tab-item value="projects">
                <v-card flat style="min-height: 440px;">
                  <v-card-text>
                    <v-container fluid :key="render">
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-card :class="user.projectFlow === 'client' ? 'active' : ''" class="rounded-xl project-flow-type" elevation="1" @click="setProjectFlow('client')">
                            <v-card-title class="text-h5 font-weight-light pt-3">Client approves</v-card-title>
                            <v-card-subtitle>You can share a project or a section to the client for approval.</v-card-subtitle>
                          </v-card>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-card :class="user.projectFlow === 'generic' ? 'active' : ''" class="rounded-xl project-flow-type" elevation="1" @click="setProjectFlow('generic')">
                            <v-card-title class="text-h5 font-weight-light pt-3">Generic flow</v-card-title>
                            <v-card-subtitle>You can manually manage project state and share snapshots of a project.</v-card-subtitle>
                          </v-card>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-card :class="user.projectFlow === 'none' ? 'active' : ''" class="rounded-xl project-flow-type" elevation="1" @click="setProjectFlow('none')">
                            <v-card-title class="text-h5 pt-3 font-weight-light">No project flow</v-card-title>
                            <v-card-subtitle>We won't bother you with project management whatsoever.</v-card-subtitle>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                    <div class="px-2" :key="render + 'c'">
                      <v-combobox :value="user.projectFlow === 'client' ? ['In progress', 'Waiting for review', 'Accepted', 'Rejected', 'Reviewed', 'Done', 'Canceled'] : user.statuses" v-if="user.projectFlow !== 'none'" :disabled="user.projectFlow === 'client'" v-on:change="updateStatuses($event)" :items="['To do', 'In progress', 'Done', 'Canceled', 'In QA', 'Pending review', 'Reviewed']" label="Project statuses" class="mt-6 mb-4" multiple chips small-chips dense outlined hide-details></v-combobox>
                      <v-combobox :value="user.userRoles" v-on:change="updateUserRoles($event)" :items="['Viewer', 'Editor', 'Client', 'QA']" label="User roles in a project" multiple chips small-chips dense outlined hide-details></v-combobox>
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item value="notifications">
                <v-card flat style="min-height: 440px;">
                  <v-card-text>
                    <div>Send notification, when</div>
                    <v-sheet class="px-5">
                      <v-switch :value="true" label="Project shared" hide-details></v-switch>
                      <v-switch :value="true" label="Section shared" hide-details></v-switch>
                      <v-switch label="Section assigned" hide-details></v-switch>
                      <v-switch label="Section state changed" hide-details></v-switch>
                      <v-switch label="Project has been deleted" hide-details></v-switch>
                      <v-switch label="Section has been deleted" hide-details></v-switch>
                    </v-sheet>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item value="templates">
                <v-card flat style="min-height: 440px;" :key="render">
                  <v-card-text>
                    <v-simple-table v-if="user.templates && user.templates.length">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Name</th>
                            <th class="text-center" style="width: 160px;">Default template</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-left">
                              <span>Empty project</span>
                            </td>
                            <td class="text-center">
                              <v-simple-checkbox color="primary" :ripple="false" hide-details :value="!user.defaultTemplate" @input="setDefaultTemplate(null)" class="my-0 mx-auto d-block" style="width: 24px"></v-simple-checkbox>
                            </td>
                          </tr>
                          <template v-for="template in user.templates">
                            <tr :key="template.iid">
                              <td class="text-left">
                                <span>{{template.name}}</span>
                              </td>
                              <td class="text-center">
                                <v-simple-checkbox color="primary" :ripple="false" :value="user.defaultTemplate === template.iid" @input="setDefaultTemplate(template.iid)" hide-details class="my-0 mx-auto d-block" style="width: 24px"></v-simple-checkbox>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </template>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions style="background-color: #edf0f2">
        <v-spacer></v-spacer>
        <v-btn class="elevation-1 secondary primary--text rounded-pill px-3" @click="show = false">Close</v-btn>
      </v-card-actions>
    </v-card>
    <invite-dialog v-model="showInviteDialog"></invite-dialog>
  </v-dialog>
</template>

<style>
.fill-height {
  height: 100%;
}
.name-input input {
  text-align: center;
}
.settings-dialog .v-select__selections {
  line-height: 24px;
  color: rgba(0,0,0,0.6) !important;
  text-align: center;
}
.settings-dialog .v-select__selections input {
  display: none;
}

.theme--light.v-card.project-flow-type > .v-card__subtitle,
.project-flow-type {
  color: #4a6572 !important;
  background-color: white !important;
}
.theme--light.v-card.project-flow-type.active > .v-card__subtitle,
.project-flow-type.active {
  color: white !important;
  background-color: #4a6572 !important;
}
</style>
<script>
/* eslint-disable vue/no-unused-components */
import 'vue-advanced-cropper/dist/style.css';
import { CircleStencil, Cropper } from 'vue-advanced-cropper'
import InviteTeamMember from '@/components/dialogs/invite-team-member'
import { User } from '@/libs/user.js'
import { EventBus } from '@/libs/eventBus.js'
import * as api from '@/libs/api.js'

export default {
  name: 'admin-dialog',
  props: {
    value: {
      type: Boolean,
      default: () => false
    },
    showInvite: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    Cropper, 
    CircleStencil,
    'invite-dialog': InviteTeamMember
  },
  data: () => {
    return {
      render: 0,
      user: null,
      selectingPhoto: false,
      image: {
        src: '',
        type: "image/jpg",
      },
      tab: null,
      showInviteDialog: false,
    }
  },
  async mounted() {
    let userLib = new User(this.$store.state);
    try {
      this.user = await userLib.getUser();
    }
    catch (err) {
      console.info(err);
    }
  },
  watch: {
    show(show) {
      this.selectingPhoto = false;
      this.$nextTick(()=>{
        this.showInviteDialog = show && this.showInvite;
      });
    }
  },
  computed: {
    show: {
      get: function() { return this.value; },
      set: function(value) { this.$emit('input', value); }
    },
  },
  methods: {
    async updateAccountName(name) {
      this.user.account = name;
      EventBus.$emit('refresh');
      await api.updateAccount({name: name});
    },
    async updateStatuses(statuses) {
      this.user.statuses = statuses;
      EventBus.$emit('refresh');
      await api.updateAccount({statuses: statuses});
    },
    async updateUserRoles(userRoles) {
      this.user.userRoles = userRoles;
      EventBus.$emit('refresh');
      await api.updateAccount({userRoles: userRoles});
    },
    async setProjectFlow(flow) {
      this.user.projectFlow = flow;
      EventBus.$emit('refresh');
      await api.updateAccount({projectFlow: flow});
      this.render++;
    },
    async setDefaultTemplate(iid) {
      if (this.user.defaultTemplate === iid)
        return;
        
      this.user.defaultTemplate = iid;
      this.render++;
      //EventBus.$emit('refresh');
      await api.updateAccount({defaultTemplate: iid});
    },
  }
}
</script>

<i18n>
{
  "en": {
  }
}
</i18n>