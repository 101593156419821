<template>
<div style="width: 100%; height: 100%;">
  <div style="width: 100%; height: 100%;" class="pie-chart"></div>
  <div style="clear: both"></div>
</div>
</template>

<style>
.legend {
  /*float: left;*/
  font-size: .7em;
  text-align: center;
}
.pie text {
  fill: #000;
}
.pie .name-text{
  font-size: 1em;
}
.pie .value-text{
  font-size: 3em;
}
</style>

<script>
/* eslint-disable no-unused-vars */
import * as d3 from 'd3';

export default {
  name: 'pie-chart',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    id: {
      type: String,
      default: () => null
    }
  },
  data() {
    return {
    }
  },
  mounted() {
    this.drawChart();
  },
  methods: {
    drawChart() {
      if (!this.value)
        return;

      var data = this.value;
      var text = "";

      const container = this.$el;
      var width = container.clientWidth;
      var height = width//container.clientHeight;
      
      var padding = 10;
      var opacity = .8;
      var opacityHover = 1;
      var otherOpacityOnHover = .8;

      var radius = Math.min(width-padding, height-padding) / 2;
      var color = d3.scaleOrdinal(d3.schemeCategory10);

      const selector = this.id + " .pie-chart"

      var svg = d3.select(selector)
      .append('svg')
      .attr('class', 'pie')
      .attr('width', width)
      .attr('height', height);

      var g = svg.append('g')
      .attr('transform', 'translate(' + (width/2) + ',' + (height/2) + ')');

      var arc = d3.arc()
      .innerRadius(radius * 0.5)
      .outerRadius(radius);

      var pie = d3.pie()
      .value(function(d) { return d.v; })
      .sort(null);

      var path = g.selectAll('path')
        .data(pie(data))
        .enter()
        .append("g")  
        .append('path')
        .attr('d', arc)
        .attr('fill', (d,i) => color(i))
        .style('opacity', opacity)
        .style('stroke', 'white')
        .on("mouseover", function(d) {
            d3.selectAll('path')
              .style("opacity", otherOpacityOnHover);
            d3.select(this) 
              .style("opacity", opacityHover);

            let g = d3.select("svg")
              .style("cursor", "pointer")
              .append("g")
              .attr("class", "tooltip")
              .style("opacity", 0);
      
            g.append("text")
              .attr("class", "name-text")
              .text(`${d && d.data ? d.data.name : ""} (${d && d.data ? d.data.value : ""})`)
              .attr('text-anchor', 'middle');
          
            let text = g.select("text");
            let bbox = text.node().getBBox();
            let padding = 2;
            g.insert("rect", "text")
              .attr("x", bbox.x - padding)
              .attr("y", bbox.y - padding)
              .attr("width", bbox.width + (padding*2))
              .attr("height", bbox.height + (padding*2))
              .style("fill", "white")
              .style("opacity", 0.75);
          })
        .each(function(d, i) { this._current = i; });

      let legend = d3.select(selector).append('div')
            .attr('class', 'legend');

      let keys = legend.selectAll('.key')
            .data(data)
            .enter().append('div')
            .attr('class', 'key')
            .style('margin', '0px 4px')
            .style('display', 'inline-block');

          keys.append('div')
            .attr('class', 'symbol')
            .style('height', '8px')
            .style('width', '8px')
            .style('margin-right', '2px')
            .style('display', 'inline-block')
            .style('background-color', (d, i) => color(i));

          keys.append('div')
            .attr('class', 'name')
            .style('display', 'inline-block')
            .text(d => `${d.k} (${d.v})`);

          keys.exit().remove();
    }
  }
}
</script>