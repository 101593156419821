<template>
<div class="key-value-item" :class="(required ? 'required-item ' : '') + 'elevation-' + elevation + ' ' + (item.cfg && item.cfg.private ? 'private' : 'public') + ' ' + getRoundedClass()" :style="kvStyle" v-if="show">
  <div style="position: absolute; right: 4px; top: 4px; z-index: 4" v-if="!required">
    <template v-if="item.cfg && item.cfg.private">
      <v-tooltip top>
        <template v-slot:activator="{on}"><v-icon v-on="on" color="red" size="14" class="mr-1">mdi-lock</v-icon><span v-on="on" class="d-inline-block text-caption red--text font-weight-bold">Private</span></template>
        <span><span v-html="$t('privateHint')"></span> <v-icon size="16">mdi-dots-vertical</v-icon>.</span>
      </v-tooltip>
    </template>
    <v-menu :close-on-content-click="false" offset-y v-if="editing && full">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon elevation="0" color="primary" v-bind="attrs" v-on="on"><v-icon size="24">mdi-dots-vertical</v-icon></v-btn>
      </template>
      <v-card class="pa-5">
        <v-btn class="elevation-1 secondary primary--text py-3" rounded @click="remove()"><v-icon left>mdi-delete</v-icon> {{$t('removeText')}}</v-btn>
        <v-select color="secondary" v-model="item.type" :items="[{t:'Text', v:'plain-text'}, {t:$t('number'), v:'number'}, {t:$t('labels'), v:'labels'}]" item-text="t" item-value="v" menu-props="auto" hide-details dense single-line class="py-3"></v-select>
        <v-switch v-if="item.cfg" :label="$t('private')" color="secondary" class="mt-5 ml-2" hide-details v-model="item.cfg.private"></v-switch>
        <v-switch v-if="item.cfg" :label="$t('hideCaption')" color="secondary" class="mt-5 ml-2" hide-details v-model="item.cfg.hideCaption"></v-switch>
      </v-card>
    </v-menu>
  </div>
  <template v-if="showCaption && (!item.cfg || !item.cfg.hideCaption)">
    <div v-if="!editing || required" :class="listItem ? 'text-subtitle-1 font-weight-bold' : 'text-body-2'">{{item.key}}</div>
    <v-text-field v-else :label="$t('enterCaption')" light hide-details v-model="item.key" solo flat class="d-block editing type-text" :class="listItem ? 'text-subtitle-1 font-weight-bold' : 'text-body-2'"></v-text-field>
  </template>
  <template v-if="item.collectItem && (item.collectItem.options || item.collectItem.optionsDependency) && editing">
    <v-select :items="getOptions(item.collectItem)" :value="item.value" @input="item.value = $event; item.collectItem.value = $event;" item-text="en" item-value="value" :multiple="item.collectItem.type !== 'select-one' && item.collectItem.type !== 'dropdown'" solo flat hide-details></v-select>
  </template>
  <template v-else-if="item.collectItem && item.collectItem.type === 'number'">
    <v-text-field :value="item.value" @input="item.value = $event; item.collectItem.value = $event;" type="number" :suffix="item.collectItem.unit ? item.collectItem.unit : null" solo flat hide-spin-buttons class="number-text" background-color="transparent"></v-text-field>
  </template>
  <template v-else-if="item.type === 'labels'">
    <v-combobox v-if="editing" v-model="item.value" light solo flat dense multiple chips hide-details background-color="transparent" :label="$t('enterNewLabel')" class="labels-combo">
      <template v-slot:append>&nbsp;</template>
      <template v-slot:selection="data">
        <v-chip class="my-1" label small v-bind="data.attrs" :input-value="data.selected" color="rgba(0,0,0,0.1)" :close="editing && full" @click:close="data.parent.selectItem(data.item)">{{ data.item }}</v-chip>
      </template>
    </v-combobox>
    <v-chip-group column v-else>
      <v-chip v-for="label in item.value" :key="label" class="my-1" light label small color="rgba(0,0,0,0.1)">{{ label }}</v-chip>
    </v-chip-group>
  </template>
  <template v-else-if="item.type === 'number'">
    <template v-if="editing">
      <v-text-field type="number" :label="$t('enterNumber')" light hide-details hide-spin-buttons v-model="item.value" solo flat class="d-inline-block number-text" style="width: 120px" background-color="transparent"></v-text-field>
      <v-text-field light hide-details :label="$t('unit')" hide-spin-buttons solo flat class="d-inline-block" background-color="transparent"></v-text-field>
    </template>
    <div v-else class="number-text">{{item.value}}</div>
  </template>
  <div v-else-if="item.type === 'map'">
    <GmapAutocomplete v-if="editing" class="mb-2 d-block v-text-field" @place_changed="setPlace" style="width: 100%"></GmapAutocomplete>
    <GmapMap v-if="item.value && item.value.lat && item.value.lng" :center="{lat: item.value.lat, lng: item.value.lng}" :zoom="15" :options="{mapTypeControl: false, streetViewControl: false}" :style="'width: 100%; height: ' + (full ? '450px' : '300px')">
      <GmapMarker :position="{lat: item.value.lat, lng: item.value.lng }" />
    </GmapMap>
  </div>
  <template v-else-if="item.type === 'plain-text'">
    <v-textarea v-if="editing" v-model="item.value" auto-grow solo hide-details flat :rows="1" row-height="15" :label="required && (!item.value || !item.value.length) ? 'Required' : null"></v-textarea>
    <div v-else v-text="item.value"></div>
  </template>
  <template v-else>
    <vue-editor v-if="editing" v-model="item.value" :editorToolbar="customToolbar" :editorOptions="{ theme: 'bubble', xplaceholder: $t('enterText') }"></vue-editor>
    <div v-else v-html="item.value"></div>
  </template>
</div>
</template>
<i18n>
{
  "en": {
    "privateHint": "This section is <b>private</b> and won't be visible when published.<br />You can change it to public section in the menu",
    "removeText": "Remove text",
    "number": "Number",
    "labels": "Labels",
    "private": "Private",
    "hideCaption": "Hide caption",
    "enterCaption": "Enter caption...",
    "enterNewLabel": "Enter new label...",
    "enterNumber": "Enter number",
    "enterText": "Enter text...",
    "unit": "Unit"
  }
}
</i18n>
<style>
.key-value-item {
  position: relative;
  padding: 8px 16px;
}
/*.key-value-item.private {
  border: 2px solid #e7aeae;
}*/
.key-value-item .ql-editor {
  padding: 0px;
  min-height: 64px;
}
.key-value-item .ql-editor ol, .key-value-item  .ql-editor ul {
  padding-left: 0px;
}
.key-value-item .ql-editor.ql-blank::before {
  left: 0px;
  font-style: normal;
}
.key-value-item p {
  margin-bottom: 0px;
}
.private .type-text {
  width: calc(100% - 96px);
}
.type-text {
  width: calc(100% - 24px);
}
.number-text .v-input__slot,
.number-text .v-input__control,
.type-text .v-input__slot,
.type-text .v-input__control {
  min-height: 24px !important;
}
.currency-combo {
  width: 44px;
}
.number-text {
  font-size: 1.4em;
}
.number-text .v-input__slot,
.currency-combo .v-input__slot,
.labels-combo .v-input__slot{
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.number-text input {
  text-align: right;
}
.currency-combo input {
  padding-bottom: 3px !important;
}
.key-value-item .v-textarea textarea {
  margin: 0px !important;
  min-height: 24px !important;
}
.key-value-item .v-text-field.v-text-field--solo .v-input__control,
.key-value-item .v-text-field--solo > .v-input__control > .v-input__slot {
  min-height: 28px !important;
}
.key-value-item.required-item .v-label {
  top: 0px !important;
  color: rgba(0,0,0);
  font-weight: bold;
}
.ql-bubble .ql-tooltip {
  z-index: 11;
  background-color: #344955 !important;
  border: 2px solid #273740 !important;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important
 }
 .ql-bubble .ql-tooltip .ql-tooltip-arrow {
  border-bottom-color: #273740 !important;
 }
 .labels-combo input {
  background-color: transparent !important;
 }
</style>

<script>
import { VueEditor } from "vue2-editor";
export default {
  name: 'key-value-item',
  components: {
    'vue-editor': VueEditor,
  },
  props: {
    value: {
      type: Object,
      default: () => null
    },
    editing: {
      type: Boolean, 
      default: () => false
    },
    elevation: {
      type: Number, 
      default: () => 0
    },
    color: {
      type: String, 
      default: () => '#edf0f2'
    },
    colorPrivate: {
      type: String, 
      default: () => '#fff0f0'
    },
    rounded: {
      type: Number, 
      default: () => 4
    },
    full: {
      type: Boolean, 
      default: () => false
    },
    showCaption: {
      type: Boolean, 
      default: () => true
    },
    listItem: {
      type: Boolean, 
      default: () => false
    },
    required: {
      type: Boolean, 
      default: () => false
    }
  },
  watch: {
    'item.cfg.hideCaption': function() { this.$emit('update'); },
    'item.cfg.private': function() { this.$emit('update'); },
    'item.key': function() { this.$emit('update'); },
    'item.value': function() { this.$emit('update'); },
    'item.type': function(type) { 
      if (type === 'labels') {
        this.item.value = null;
      }
      this.$emit('update'); 
    },
  },
  data: () => ({
    search: null,
    customToolbar: [['bold', 'italic', 'underline', 'strike'],['clean']],
    items: [
      { header: 'Select existing title or create one' },
      { key: 'Description', type: 'text'},
      { key: 'Labels', type: 'labels'},
      { key: 'Price', type: 'price', currency: 'EUR'},
      { key: 'Map', type: 'map' },
    ]
  }),
  computed: {
    item: {
      get: function() { return this.value; },
      set: function(value) { this.$emit('input', value); }
    },
    show() {
      if (!this.editing && !this.item.value)
        return false;
        
      if (this.full)
        return true;

      if (this.item.type === 'map')
        return false;

      if (this.item.cfg && this.item.cfg.private)
        return false;

      return true;
    },
    kvStyle() {
      let requiredEmpty = (this.required && (!this.item.value || !this.item.value.length));
      let backgroundColor = (this.item.cfg && this.item.cfg.private ? this.colorPrivate : this.color);
      if (requiredEmpty)
        backgroundColor = '#fef5e6';
      let border = (requiredEmpty ? '1px solid #fbc779;' : 'none');
      let style = `background-color: ${backgroundColor};` +` border: ${border}`;
      return style;
    }
  },
  methods: {
    setPlace(place) {
      if (!place.geometry)
        return;

      this.item.value = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()};
    },
    remove() {
      this.$emit('remove');
    },
    getRoundedClass() {
      if (!this.rounded)
        return 'rounded';

      switch (this.rounded) {
        case 1: return 'rounded-sm';
        case 2: return 'rounded';
        case 3: return 'rounded-lg';
        case 4: return 'rounded-xl';
        case 5: return 'rounded-pill';
      }

      return 'rounded';
    },
    // Parse string and put <a> tags around links
    parseLinks(text) {
      if (!text)
        return text;

      var exp = /((http|https|ftp):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-.,@?^=%&amp;:/~+#]*[\w\-@?^=%&amp;/~+#])?)/g;
      return text.replace(exp,"<a href='$1' target='_blank'>$1</a>"); 
    },
    getOptions(item) {
      if (item.options)
        return item.options;

      if (item.optionsDependency && item.optionsDependency.id) {
        let dependency = this.collectFlow.find(i => i.id === item.optionsDependency.id);
        if (dependency && dependency.value)
          return dependency.value.map(value => ({value: value}));
      }

      return [];
    },
  }
}
</script>