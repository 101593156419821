<template>
<div :key="render" :class="/*Object.keys(emojis).length ? */'has-emojis'/* : 'no-emojis'*/">
  <div class="d-inline-block emoji-picker">
    <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fab elevation="1" x-small color="primary" style="position: relative"><v-icon>mdi-emoticon-happy-outline</v-icon><div style="position: absolute; padding-left: 1px; top: 10px; right: 3px; border-radius: 50%; background-color: #344955; width: 10px; height: 10px; line-height: 10px; font-weight: bold">+</div></v-btn>
      </template>
      <v-card>
        <Picker :data="emojiIndex" set="google" @select="showEmoji" title="Pick your emoji…" emoji="slightly_smiling_face" :showPreview="true" :emojiTooltip="true" color="#4a6572" />
      </v-card>
    </v-menu>
  </div>
  <div class="d-inline-block pt-3">
    <template v-for="(key, k) of Object.keys(emojis)">
      <v-chip v-if="emojis[key].length > 0" class="elevation-1 ml-1 px-2" color="rgba(52,73,85,0.5)" text-color="white" :key="k" @click.prevent.stop="toggleEmoji(key)">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" style="display: flex; justify-content: center; align-content: center; flex-direction: row;">
              <Emoji :data="emojiIndex" set="google" :emoji="key" :size="24" style="display: inline-flex; padding: 4px 4px 4px 0px;" />
              <div class="d-inline-flex pl-1" style="padding: 6px 2px 0px 0px;">{{emojis[key].length}}</div>
            </div>
          </template>
          <div style="min-width: 70px">
            <div>
              <div class="float-left"><Emoji :data="emojiIndex" set="google" :emoji="key" :size="36" style="display: inline-flex; padding: 4px 4px 4px 0px;" /></div>
              <div class="float-right" style="padding-top: 10px">:{{key}}:</div>
              <div style="clear: both"></div>
            </div>
            <v-divider class="pb-2"></v-divider>
            <div v-for="(name, n) of emojis[key]" :key="'n'+n" class="text-center">{{name}}</div>
          </div>
        </v-tooltip>
      </v-chip>
    </template>
  </div>
</div>
</template>

<style>
@import '~emoji-mart-vue-fast/css/emoji-mart.css';

.desktop .has-emojis .emoji-picker {
  opacity: 0;
  transition: opacity 200ms linear;
}

.desktop .gallery-item:hover .has-emojis .emoji-picker,
.desktop .list-widget-item:hover .has-emojis .emoji-picker {
  opacity: 1;
}

</style>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
import data from "emoji-mart-vue-fast/data/facebook.json";
import { Emoji, Picker, EmojiIndex } from "emoji-mart-vue-fast";
import { toggleEmojiInCanvas } from '@/libs/api'
import { User } from '@/libs/user.js'

let emojiIndex = new EmojiIndex(data);

export default {
  name: 'emoji-widget',
  components: {
    Picker,
    Emoji
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    iid: {
      type: String,
      default: () => null
    },
    cid: {
      type: String,
      default: () => null
    }
  },
  data() {
    return {
      render: 0,
      menu: false,
      emojiIndex: emojiIndex,
    }
  },
  computed: {
    emojis: {
      get: function() { return this.value; },
      set: function(value) { this.$emit('input', value); }
    },
  },
  methods: {
    async showEmoji(emoji) {
      await this.toggleEmoji(emoji.short_name);
    },
    async getUser() {
      let userLib = new User(this.$store.state);
      let user = await userLib.getUser();
      if (!user.userName)
        user.userName = 'admin';

      return user;
    },
    async toggleEmoji(shortName) {
      let user = await this.getUser();
      let add = true;

      if (shortName in this.emojis) {
        if (!this.emojis[shortName].length)
          this.emojis[shortName] = [user.userName];
        else {
          let index = this.emojis[shortName].indexOf(user.userName);
          if (index < 0)
            this.emojis[shortName].push(user.userName);
          else {
            this.emojis[shortName].splice(index, 1);
            if (!this.emojis[shortName].length)
              delete this.emojis[shortName];

            add = false;
          }
        }
      }
      else { 
        this.emojis[shortName] = [user.userName];
      }
      this.menu = false;
      this.render++;

      await this.toggleEmojiInDB(user.userName, shortName, add);
    },
    async toggleEmojiInDB(user, emoji, add) {
      if (!this.cid || !this.iid)
        return;

      await toggleEmojiInCanvas(this.cid, user, this.iid, add, emoji)
    },
    async toggledByUser(key) {
      let names = this.emojis[key];
      if (!names || !names.length)
        return false;

      return names.indexOf(await this.getUser()) >= 0;
    }
  }
}
</script>