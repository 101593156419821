<template>
<div v-if="item">
  <template v-if="item.type === 'img' || item.type === 'video' || item.type === 'audio' || item.type === 'group'">
    <gallery-item :value="item" :parent="parent" :editing="editing" :index="index" :preview="preview"></gallery-item>
  </template>
  <template v-else-if="showSection(item)">
    <section-widget v-model="item" :parent="parent" :editing="editing" :index="index" :preview="preview"></section-widget>
  </template>
  <template v-else-if="item.type === 'gallery'">
    <gallery-widget :value="item" :parent="parent" :editing="editing" :index="index" :preview="preview"></gallery-widget>
  </template>
  <template v-else-if="item.type === 'list'">
    <list-widget :value="item" :parent="parent" :editing="editing" :index="index" :preview="preview"></list-widget>
  </template>
  <template v-else-if="item.type === 'map-list'">
    <map-list-widget :value="item" :parent="parent" :editing="editing" :index="index" :preview="preview"></map-list-widget>
  </template>
  <template v-else-if="item.type === 'map'">
    <map-widget :value="item" :parent="parent" :editing="editing" :index="index" :preview="preview"></map-widget>
  </template>
  <template v-else-if="item.type === 'file-table'">
    <file-table-widget :value="item" :parent="parent" :editing="editing" :index="index" :preview="preview"></file-table-widget>
  </template>
  <template v-else-if="item.type === 'casting-profile'">
    <profile-item :value="item" :parent="parent" :editing="editing" :index="index" :preview="preview"></profile-item>
  </template>
  <template v-else-if="item.type === 'root'">
    <root-widget v-model="item" :editing="editing" :index="index" :preview="preview"></root-widget>
  </template>
  <template v-else-if="item.type === 'link'">
    <canvas-link-item v-model="item" :editing="editing" :index="index" :preview="preview"></canvas-link-item>
  </template>
  <template v-else-if="item.type === 'casting'">
    <casting-item v-model="item" :editing="editing" :index="index" :preview="preview"></casting-item>
  </template>
  <template v-else-if="item.type === 'casting-roles'">
    <casting-roles-item v-model="item" :editing="editing" :index="index" :preview="preview"></casting-roles-item>
  </template>
  <template v-else>
    <draggable v-model="item.items" :group="{ name: 'items' }" :animation="200" :disabled="!editing || this.$vuetify.breakpoint.mdAndDown">
      <canvas-widget v-for="(child, i) in item.items" :key="i" :parent="item" :index="i" v-model="item.items[i]" :editing="editing" :preview="preview"></canvas-widget>
    </draggable>
  </template>
</div>
</template>

<style scoped>
</style>

<script>
/* eslint-disable vue/no-unused-components */
import draggable from 'vuedraggable'
import CanvasGalleryItem from '@/components/canvas-items/canvas-gallery-item'
import GalleryWidget from '@/components/canvas-items/gallery-widget'
import ListWidget from '@/components/canvas-items/list-widget'
import MapListWidget from '@/components/canvas-items/map-list-widget'
import MapWidget from '@/components/canvas-items/map-widget'
import FileTableWidget from '@/components/canvas-items/file-table-widget'
import ProfileItem from '@/components/canvas-items/casting/profile-item'
import RootWidget from '@/components/canvas-items/root-widget'
import CanvasLinkItem from '@/components/canvas-items/link-item'
import CastingItem from '@/components/canvas-items/casting/casting-root'
import CastingRolesItem from '@/components/canvas-items/casting/casting-roles'
import SectionWidget from '@/components/canvas-items/section-widget'

export default {
  name: 'canvas-widget',
  components: {
    'gallery-item': CanvasGalleryItem,
    'gallery-widget': GalleryWidget,
    'list-widget': ListWidget,
    'map-list-widget': MapListWidget,
    'map-widget': MapWidget,
    'file-table-widget': FileTableWidget,
    'root-widget': RootWidget,
    'section-widget': SectionWidget,
    'profile-item': ProfileItem,
    'canvas-link-item': CanvasLinkItem,
    'casting-item': CastingItem,
    'casting-roles-item': CastingRolesItem,
    'draggable': draggable
  },
  props: {
    value: {
      type: Object,
      default: () => null
    },
    parent: {
      type: Object, 
      default: () => null
    },
    index: {
      type: Number, 
      default: () => null
    },
    preview: {
      type: Boolean, 
      default: () => false
    },
    editing: {
      type: Boolean, 
      default: () => false
    }
  },
  data: () => ({
    render: 0
  }),
  computed: {
    item: {
      get: function() { return this.value; },
      set: function(value) { this.$emit('input', value); }
    }
  },
  methods: {
    showSection(item) {
      if (!item.cfg || !item.cfg.flat)
        return false;

      if (item.type === 'casting')
        return false;
      
      let parts = window.location.pathname.split('/');
      if (parts < 4)
        return false;

      if (parts[3] === item.iid)
        return true;

      return false;
    }
  }
}
</script>