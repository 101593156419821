<template>
<div>
  <template v-if="type === 'generic'">
    <generic-project-flow :canvas="canvas" :root="root" :editing="editing" v-on="$listeners"></generic-project-flow>
  </template>
  <template v-else-if="type === 'client'">
    <client-project-flow :canvas="canvas" :root="root" :editing="editing" v-on="$listeners"></client-project-flow>
  </template>
</div>
</template>

<style scoped>
</style>

<script>
/* eslint-disable vue/no-unused-components */
import GenericProjectFlow from '@/components/project-flows/generic-project-flow'
import ClientProjectFlow from '@/components/project-flows/client-project-flow'

export default {
  name: 'project-flow-control',
  components: {
    'generic-project-flow': GenericProjectFlow,
    'client-project-flow': ClientProjectFlow,
  },
  props: {
    type: {
      type: String,
      default: () => null
    },
    canvas: {
      type: Object,
      default: () => null
    },
    root: {
      type: Object,
      default: () => null
    },
    editing: {
      type: Boolean, 
      default: () => false
    }
  },
  data: () => ({
  }),
}
</script>