<template>
<v-card class="gallery-item" :class="getRoundedClass()" :elevation="config.elevation" :outlined="config.outlined" light style="width: 100%">
  <div style="position: relative">
    <canvas-item-thumbnail v-model="item" :config="config" @view="viewItem($event)" :noAspectRatio="noAspectRatio"></canvas-item-thumbnail>
    <v-btn v-if="editing" absolute top right fab elevation="1" x-small @click.stop.prevent="check()" style="top: 4px; right: 4px;" color="rgba(255,255,255,0.7)" :class="itemsChecked ? null : 'show-on-hover'">
      <v-icon size="24" color="primary">{{item.checked ? `mdi-check-circle` : `mdi-checkbox-blank-circle-outline`}}</v-icon>
    </v-btn>
    <div v-if="config.showLikes" class="like" style="position: absolute; bottom: 10px; right: 44px; z-index: 4">
      <emoji-widget v-model="item.emojis" :iid="item.iid" :cid="item.cid"></emoji-widget>
    </div>
    <v-badge v-if="config.showComments" :value="item.comments.length > 0" left x-small overlap color="secondary" class="like" style="position: absolute; bottom: 16px; right: 16px; z-index: 4">
      <span slot="badge" class="primary--text">{{ item.comments.length }}</span>
      <v-btn @click.stop.prevent="showComments($event)" absolute bottom right fab elevation="1" x-small style="bottom: -6px; right: -6px;" class="comments-button" :class="item.comments.length > 0 ? 'has-comments' : ''" color="primary"><v-icon color="white">{{item.comments.length > 0 ? 'mdi-comment' : 'mdi-comment-outline'}}</v-icon></v-btn>
    </v-badge>
  </div>
  <v-card-actions v-if="config.showNames" class="py-0" style="min-height: 64px; overflow: hidden;">
    <div class="item-name" :class="hasPublicKV ? 'has-kv' : ''">
      <template v-if="!editing">
        <span v-if="!item.cfg.link || !item.cfg.link.length" class="gallery-item-caption">{{item.cfg.name}}</span>
        <a v-else :href="item.cfg.link" target="_BLANK" class="gallery-item-caption">{{item.cfg.name}}</a>
      </template>
      <v-text-field v-else flat solo hide-details v-model="item.cfg.name" class="editing gallery-item-caption"  :class="(!item.cfg.link || !item.cfg.link.length) ? null : 'has-link'" v-on:change="updateItemConfig(item, () => item.cfg.name = $event)" ></v-text-field>
      <div v-if="hasPublicKV" style="position: absolute; right: 4px; bottom: 4px; text-decoration: underline; cursor: pointer;" class="text-caption" @click="viewDescription()">{{$t('more')}}</div>
    </div>
  </v-card-actions>
</v-card>
</template>
<i18n>
{
  "en": {
    "more": "more..."
  }
}
</i18n>
<style>
.has-link .v-text-field__slot {
  text-decoration: underline;
}
.link-text-field .v-input__append-outer{
  margin-top: 12px;
}
.like .v-badge__badge {
 z-index: 1000;
}
.gallery-item {
  overflow: hidden;
}
.columns-12 .gallery-item .gallery-item-caption  { font-size: calc(0.6rem + 0.2vw); }
.columns-6 .gallery-item .gallery-item-caption { font-size: calc(0.8rem + 0.2vw); }
.columns-1 .gallery-item .gallery-item-caption { font-size: calc(1.2rem + 0.4vw); }
.gallery-item .gallery-item-caption { font-size: calc(1rem + 0.3vw); }
.gallery-item .gallery-item-caption input {
  line-height: 36px;
}
.gallery-item .gallery-item-caption input:hover,
.gallery-item .gallery-item-caption input:focus  {
  background-color: rgba(240,240,240,0.9);
}

.gallery-item  .v-input__append-outer,
.gallery-item  .v-input__append-inner {
  display: none;
}
.gallery-item .v-input--is-focused .v-input__append-outer,
.gallery-item .v-input--is-focused .v-input__append-inner {
  display: flex;
}
.gallery-item .item-name {
  width: 100%; 
  height: 100%; 
  position: relative;
}
.gallery-item .item-name.has-kv {
  height: 64px; 
}
.desktop .comments-button {
  opacity: 0;
  transition: opacity 200ms linear;
}

.desktop .comments-button.has-comments,
.desktop .gallery-item:hover .comments-button,
.desktop .list-widget-item:hover .comments-button {
  opacity: 1;
}
</style>

<script>
import * as api from '@/libs/api.js'
import { EventBus } from '@/libs/eventBus.js'
import { getCanvasItemMap } from '@/libs/canvas.js'
import EmojiWidget from '@/components/widgets/emoji-widget'
import CanvasItemThumbnail from '@/components/canvas-items/canvas-thumbnail'
export default {
  name: 'canvas-gallery-item',
  components: {
    'emoji-widget': EmojiWidget,
    'canvas-item-thumbnail': CanvasItemThumbnail
  },
  props: {
    value: {
      type: Object,
      default: () => null
    },
    parent: {
      type: Object, 
      default: () => null
    },
    index: {
      type: Number,
      default: () => null
    },
    preview: {
      type: Boolean, 
      default: () => false
    },
    editing: {
      type: Boolean,
      default: () => false
    },
    cfg: {
      type: Object, 
      default: () => null
    },
    noAspectRatio: {
      type: Boolean,
      default: () => false
    },
  },
  data: () => {
    return {
      render: 0,
    }
  },
  computed: {
    item() {
      let item = this.value;
      if (!item.cfg)
        item.cfg = {};
      if (!item.emojis)
        item.emojis = {};
      if (!item.comments)
        item.comments = [];
      return item;
    },
    likes() {
      return this.item.likes || [];
    },
    comments() {
      return this.item.comments || [];
    },
    itemsChecked() {
      return this.$store.state.canvas.checkedCount > 0;
    },
    config() {
      if (this.cfg)
        return this.cfg;
      let widget = this.getFirstParentGallery();

      const rounded = (widget && widget.cfg && ('rounded' in widget.cfg)) ? widget.cfg.rounded : 0;
      const elevation = (widget && widget.cfg && ('elevation' in widget.cfg)) ? widget.cfg.elevation : 1;
      const aspectRatio = (widget && widget.cfg && ('aspectRatio' in widget.cfg)) ? widget.cfg.aspectRatio : 10;
      const cover = (widget && widget.cfg && ('cover' in widget.cfg)) ? widget.cfg.cover : true
      let showCaptions = false;
      if (widget && widget && widget.cfg)
        showCaptions = !!widget.cfg.showNames;
      const size = (widget && widget.cfg && ('size' in widget.cfg)) ? widget.cfg.size : 'medium';

      return {  rounded: rounded,  elevation: elevation,  outlined: false,  aspectRatio: aspectRatio,  cover: cover,  showLikes: !this.preview, showComments: !this.preview, showNames: showCaptions, size: size,  parent: widget };
    },
    hasPublicKV() {
      if (!this.item.kv || !this.item.kv.length)
        return false;

      for (let kvItem of this.item.kv) {
        if (kvItem.cfg && kvItem.cfg.private)
          continue;
        if (kvItem.value)
          return true;
      }
      return false;
    }
  },
  methods: {
    view() {
      this.$emit('view');
      if (this.index !== null && this.parent)
        EventBus.$emit('showViewDialog', { items: this.parent.items, index: this.index, widget: this.parent, editing: this.editing });
    },
    viewGroup() {
      //this.view();
      EventBus.$emit('navigateTo', { item: this.item, parent: this.parent });
    },
    viewDescription() {
      this.$emit('view');
      if (this.index !== null && this.parent)
        EventBus.$emit('showViewDialog', { items: this.parent.items, index: this.index, showDescription: true, widget: this.parent, editing: this.editing });
    },
    check() {
      this.item.checked = !this.item.checked;
    },
    async remove() {
      let parent = this.parent;
      parent.items.splice(this.index, 1);
      this.$emit('updateParent', parent);

      if (this.parent.iid && this.item.iid && this.item.cid) {
        try {
          await api.removeItem(this.item.cid, this.parent.iid, this.item.iid)
        }
        catch (err) {
          console.log(err);
        }
      }
    },
    countMimeTypeInGroup(group, mime) {
      let count = 0;
      const traverseCountType = (item) => {
        if (!item.items || !item.items.length)
          return;
        for (let i = item.items.length -1; i >= 0; i--) {
          if (item.items[i].cfg && item.items[i].cfg.mime && item.items[i].cfg.mime.match(mime))
            count++;
          if (item.items[i].items && item.items[i].items.length)
            traverseCountType(item.items[i]);
        }
      }
      traverseCountType(group, null);
      return count;
    },
    getTypeFromMime(mime) {
      if (!mime)
        return '';

      let parts = mime.split('/');
      if (parts.length < 2)
        return mime;

      return parts[0];
    },
    getSubtypeFromMime(mime) {
      if (!mime)
        return '';

      let parts = mime.split('/');
      if (parts.length < 2)
        return mime;

      return parts[1];
    },
    getMimeToText(mime) {
      let type = this.getTypeFromMime(mime);
      let subtype = this.getSubtypeFromMime(mime);
      if (type === 'image' || type === 'audio' || type === 'video')
        return type;

      return subtype;
    },
    async like() {
      let name = this.$store.state.user.userName || 'owner';
      try {
        let like = true;
        if (this.item.likes) {
          const i = this.item.likes.indexOf(name);
          if (i < 0)
            this.item.likes.push(name);
          else {
            like = false;
            this.item.likes.splice(i, 1);
          }
        }
        else
          this.item.likes = [name];

        this.render++;
        await api.likeInCanvas(this.item.cid, name, this.item.iid, like);
        
      }
      catch (err) {
        console.log(err);
      }
    },
    async updateItemConfig(widget, functor) {
      if (functor)
        functor();
      if (widget.iid)
        await api.updateItem(widget.cid, widget.iid, { cfg: widget.cfg });
    },
    showComments(event) {
      if (!this.item || !this.item.iid)
        return;
        
      EventBus.$emit('showItemComments', { item: this.item, x: event.x, y: event.y });
    },
    getRoundedClass() {
      if (!this.config || !this.config.rounded)
        return '';

      switch (this.config.rounded) {
        case 1: return 'rounded-sm';
        case 2: return 'rounded';
        case 3: return 'rounded-lg';
        case 4: return 'rounded-xl';
        case 5: return 'rounded-pill';
      }

      return 'rounded';
    },
    getFirstImageSrc(item) {
      let current = item;
      // let's limit the max depth to 100
      for (let i = 0; i < 100; ++i) {
        if (current.thumbSrc || current.src)
          return current.thumbSrc || current.src;

        if (current.items && current.items.length > 0)
          current = current.items[0];
      }

      return null;
    },
    getFirstParentGallery() {
      let getParent = (item) => {
        if (!item.parent || !(item.parent in getCanvasItemMap(this.$store.state, item.cid)))
          return null;

        return getCanvasItemMap(this.$store.state, item.cid)[item.parent];
      }
      let item = this.value
      for (let i = 0; i < 50; ++i) {
        item = getParent(item);
        if (item === null)
          return this.parent;

        if (['gallery', 'list', 'casting', 'casting-roles', 'casting-events'].includes(item.type)) {
          return item;
        }
      }

      return this.parent;
    },
    openInNewTab(item) {
      if (!item.src)
        return;
      window.open(item.src, '_blank').focus();
    },
    getUrl(path) {
      return window.location.origin + '/' + path
    },
    viewItem(type) {
      if (!type)
        this.view();

      else if (type === 'new-tab')
        return this.openInNewTab(this.item);

      else if (type === 'group')
        return this.viewGroup();

      this.view();
    }
  }
}
</script>