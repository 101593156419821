<template>
<div class="casting-item">
  <v-container fluid class="pa-0" :class="preview ? 'preview' : null">
    <v-row no-gutters>
      <v-col cols="12" sm="6" class="pa-1">
        <v-row no-gutters style="height: 100%">
          <v-container fluid class="pa-0 white rounded">
            <v-row no-gutters>
              <template v-for="(kv, k) of item.kv">
                <v-col v-if="kv.cfg.c !== 'description'" :key="kv.kvid" cols="6" sm="3">
                  <key-value-item v-model="item.kv[k]" :editing="editing" class="ma-1" listItem required @update="updateKVItem(item, kv)" :rounded="0" full colorPrivate="rgba(255,192,192,0.4)" color="rgba(255,255,255,0.3)"></key-value-item>
                </v-col>
                <v-divider v-if="kv.cfg.c !== 'description' && k !== item.kv.length - 1" vertical :key="kv.kvid + '-d'"></v-divider>
              </template>
              <v-col cols="12" v-if="description">
                <v-divider></v-divider>
                <key-value-item :value="description" @update="updateKVItem(item, description)" :editing="editing" class="ma-1" listItem required :rounded="0" full colorPrivate="rgba(255,192,192,0.4)" color="rgba(255,255,255,0.3)"></key-value-item>
              </v-col>
            </v-row>
          </v-container>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6" class="pa-1">
        <v-data-table :headers="roleStatusHeaders" :items="roleStatusRows" :dense="roleStatusRows.length > 7" hide-default-footer class="elevation-0" style="height: 100%">
          <template v-slot:body="items">
            <tbody v-if="items && items.items">
              <tr v-for="item in items.items" :key="item.name">
                <td :style="item.sum == true ? `font-weight: bold` : ``">{{ item.name }}</td>
                <td class="text-center" :style="item.sum == true ? `font-weight: bold` : ``">{{item.total ? item.total : 0 }}</td>
                <td class="text-center" :style="item.sum == true ? `font-weight: bold` : ``">{{item.addressed || 0}}</td>
                <td class="text-center" :style="item.sum == true ? `font-weight: bold` : ``">{{item.rejected || 0}}</td>
                <td class="text-center" :style="item.sum == true ? `font-weight: bold` : ``">{{item.accepted || 0}}</td>
              </tr>
            </tbody>
          </template>
          <template slot="no-data">&nbsp;</template>
        </v-data-table>
      </v-col>
      <v-col cols="12" :md="roles && roles.items.length > 5 ? 12 : 6" class="pa-1">
        <div style="height: 100%" class="px-0 pt-2 pb-2 white rounded">
          <h1 class="font-weight-light pl-4" @click="navigateTo(roles)" style="cursor: pointer">Roles<v-icon class="ml-1">mdi-chevron-right</v-icon></h1>
          <template v-for="(role, r) of roles.items">
            <div :key="role.iid" :style="`width: ${$vuetify.breakpoint.smAndDown ? 50 : $vuetify.breakpoint.lgAndUp ? 25 : ($vuetify.breakpoint.mdAndUp ? 33 : null)}%`" class="float-left pa-2">
              <v-card class="elevation-1 rounded">
                <canvas-item-thumbnail v-model="roles.items[r]" style="position: relative" @view="viewItem($event, role)">
                  <template v-slot:empty>
                    <v-icon color="primary" :size="$vuetify.breakpoint.smAndDown ? 50 : $vuetify.breakpoint.lgAndUp ? 55 : ($vuetify.breakpoint.mdAndUp ? 64 : null)" style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);">mdi-account-group-outline</v-icon>
                  </template>
                </canvas-item-thumbnail>
                <v-card-title class="py-1">{{role.cfg.name}}</v-card-title>
              </v-card>
            </div>
          </template>
          <div style="clear: both"></div>
        </div>
      </v-col>
      <v-col cols="12" :md="roles && roles.items.length > 5 ? 12 : 6" class="pa-1">
        <div style="height: 100%" class="px-0 pt-2 pb-0 white rounded">
          <h1 class="font-weight-light pl-4" @click="navigateTo(events)" style="cursor: pointer">Events<v-icon class="ml-1">mdi-chevron-right</v-icon></h1>
          
        </div>
      </v-col>

      <template v-for="(child, c) of item.items">
        <v-col cols="12" v-if="child.type !== 'casting-roles' && child.type !== 'casting-events'" :key="child.iid" class="pa-1">
          <canvas-widget :parent="item" :index="c" v-model="item.items[c]" :editing="editing" :preview="preview" class="rounded"></canvas-widget>
        </v-col>
      </template>
    </v-row>
  </v-container>
</div>
</template>

<style>
.casting-item .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: rgba(0,0,0,0.02);
}
</style>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import * as api from '@/libs/api.js'
import * as tools from '@/libs/tools.js'
import { EventBus } from '@/libs/eventBus.js'
import KeyValueItem from '@/components/widgets/key-value-item'
import GalleryWidget from '@/components/canvas-items/gallery-widget'
import CanvasItemThumbnail from '@/components/canvas-items/canvas-thumbnail'

export default {
  name: 'casting-item',
  components: {
    'key-value-item': KeyValueItem,
    'gallery-widget': GalleryWidget,
    'canvas-item-thumbnail': CanvasItemThumbnail
  },
  props: {
    value: {
      type: Object,
      default: () => null
    },
    parent: {
      type: Object,
      default: () => null
    },
    index: {
      type: Number,
      default: () => null
    },
    preview: {
      type: Boolean, 
      default: () => false
    },
    editing: {
      type: Boolean, 
      default: () => false
    }
  },
  data: () => {
    return {
      roleStatusHeaders: [
        { text: 'Role', align: 'left', sortable: false, value: 'role'},
        { text: 'Total', align: 'center', sortable: false, value: 'total'},
        { text: 'Addressed', align: 'center', sortable: false, value: 'addressed'},
        { text: 'Rejected', align: 'center', sortable: false, value: 'rejected'},
        { text: 'Accepted', align: 'center', sortable: false, value: 'accepted'},
      ]
    }
  },
  mounted() {
  },
  computed: {
    item: {
      get: function() { return this.value; },
      set: function(value) { this.$emit('input', value); }
    },
    description() {
      if (!this.item.kv || !this.item.kv.length)
        return null;

      return this.item.kv.find(kv => kv.cfg.c === 'description');
    },
    roles() {
      if (!this.item || !this.item.items || !this.item.items.length)
        return null;

      return this.item.items.find(item => item.type === 'casting-roles');
    },
    events() {
      if (!this.item || !this.item.items || !this.item.items.length)
        return null;

      return this.item.items.find(item => item.type === 'casting-events');
    },
    roleStatusRows() {
      if (!this.roles || !this.roles.items || !this.roles.items.length)
        return [];

      const incrementStatus = (row, status, count) => {
        if (!count)
          count = 1;
        if (!row[status])
          row[status] = count;
        else
          row[status] += count;
      }

      const roles = this.roles.items;
      let statusRows = []
      let totalRow = {
        name: 'Total',
        sum: true
      }
      for (let role of roles) {
        let statusRow = { 
          name: role.cfg.name 
        };
        for (let pick of role.items) {
          if (!pick.status)
            continue; // pick.status = 'Added';

          incrementStatus(statusRow, pick.status)
          incrementStatus(totalRow, pick.status)
        }
        statusRow.total = role.items.length;
        if (role.items.length)
          incrementStatus(totalRow, 'total', role.items.length)
        statusRows.push(statusRow);
      }

      statusRows.push(totalRow);

      console.log(statusRows)

      return statusRows;
    },
  },
  methods: {
    async updateKVItem(item, kvItem) {
      if (!this.editing)
        return;

      if (item.iid && kvItem.kvid) {
        let updateItem = async () => await api.updateKeyValueItem(item.cid, item.iid, kvItem.kvid, { k: kvItem.key, v: kvItem.value, cfg: kvItem.cfg, t: kvItem.type });
        EventBus.$emit('updateItem', {
          id: kvItem.kvid,
          funct: updateItem
        });
      }
    },
    async removeKVItem(item, kvItem) {
      if (!this.editing)
        return;

      if (!item || !item.iid || !kvItem || !kvItem.kvid)
        return;

      const index = item.kv.findIndex(kv => kv.kvid === kvItem.kvid);
      const kvid = item.kv[index].kvid;

      item.kv.splice(index, 1);

      await api.removeKeyValueItem(item.cid, item.iid, kvid);
    },
    navigateTo(item) {
      EventBus.$emit('navigateTo', { item: item });
    },
    viewItem(type, item) {
      if (!type)
        EventBus.$emit('showViewDialog', { items: item.items, index: 0, widget: item, editing: this.editing });

      else if (type === 'group')
        return this.navigateTo(item);

      EventBus.$emit('showViewDialog', { items: item.items, index: 0, widget: item, editing: this.editing });
    },
    nullHandler() {

    }
  }
}
</script>