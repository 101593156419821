<template>
<div class="fill-height">
  <v-container class="casting-overview fill-height" :fluid="$vuetify.breakpoint.lgAndDown" :class="showProjects ? 'show-projects' : 'hide-projects'">
    <v-row no-gutters justify="center" class="py-10">
      <v-col cols="12" sm="6" class="pa-2 fill-height text-right">
        <v-card :class="`upload-select rounded-xl share-select d-inline-block ${$vuetify.breakpoint.mobile ? 'pb-3' : 'pb-10 pt-5'}`" elevation="1" @click="$router.push('/share')" @mouseenter="startAnim('uploadAnim')" @mouseleave="stopAnim('uploadAnim')" max-width="600">
          <div :style="`width: 66%; margin-top: ${$vuetify.breakpoint.mobile ? '-25px' : '0px'}`" class="mx-auto">
            <lottie-animation ref="uploadAnim" :animationData="uploadAnim" :autoPlay="false" @complete="completeAnims['uploadAnim'] = true" />
          </div>
          <div :class="`text-center font-weight-thin primary--text ${$vuetify.breakpoint.mobile ? 'text-h4' : 'pb-10 text-h3'}`" :style="$vuetify.breakpoint.mobile ? 'margin-top: -55px' : 'margin-top: -80px'">Share your files</div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" class="pa-2 fill-height text-left">
        <v-card :class="`collect-select rounded-xl share-select d-inline-block ${$vuetify.breakpoint.mobile ? 'pb-3' : 'pb-10 pt-5'}`" elevation="1" @click="$router.push('/collect')" @mouseenter="startAnim('downloadAnim')" @mouseleave="stopAnim('downloadAnim')" max-width="600">
          <div :style="`width: 66%; margin-top: ${$vuetify.breakpoint.mobile ? '-25px' : '0px'}`" class="mx-auto">
            <lottie-animation ref="downloadAnim" :animationData="downloadAnim" :autoPlay="false" @complete="completeAnims['downloadAnim'] = true" />
          </div>
          <div :class="`text-center font-weight-thin primary--text ${$vuetify.breakpoint.mobile ? 'text-h4' : 'pb-10 text-h3'}`" :style="$vuetify.breakpoint.mobile ? 'margin-top: -55px' : 'margin-top: -80px'">Collect from others</div>
        </v-card>
      </v-col>
      <v-col cols="12" class="pa-2 fill-height text-center">
        <v-btn class="rounded-pill white elevation-1 font-weight-light text-h6" style="text-transform: initial; letter-spacing: initial" x-large :block="$vuetify.breakpoint.smAndDown" @click="createProject()"><v-icon left>mdi-tray-plus</v-icon> Create empty project</v-btn><br />
        <v-btn class="rounded-pill mt-2 primary--text font-weight-light text-h6" style="text-transform: initial; letter-spacing: initial" v-if="projects && projects.length" text x-large @click="showProjects = true">Previously shared ({{projects.length}}) <v-icon>mdi-chevron-down</v-icon></v-btn>
      </v-col>
    </v-row>
  </v-container>
  <div class="projects" :class="showProjects ? 'show-projects' : 'hide-projects'">
    <div class="text-center">
      <template v-if="$vuetify.breakpoint.mobile">
        <v-avatar color="white" class="elevation-1 mx-1" @click="$router.push('/share')"><v-icon color="primary">mdi-tray-arrow-up</v-icon></v-avatar>
        <v-avatar color="white" class="elevation-1 mx-1" @click="$router.push('/collect')"><v-icon color="primary">mdi-tray-arrow-down</v-icon></v-avatar>
        <v-avatar color="white" class="elevation-1 mx-1" @click="createProject()"><v-icon color="primary">mdi-tray-plus</v-icon></v-avatar>
      </template>
      <template v-else>
        <v-btn class="rounded-pill white elevation-1 mx-2 my-1 font-weight-light text-h6" style="text-transform: initial; letter-spacing: initial" large @click="$router.push('/share')"><v-icon left>mdi-tray-arrow-up</v-icon> Share your files</v-btn>
        <v-btn class="rounded-pill white elevation-1 mx-2 my-1 font-weight-light text-h6" style="text-transform: initial; letter-spacing: initial" large @click="$router.push('/collect')"><v-icon left>mdi-tray-arrow-down</v-icon> Collect from others</v-btn>
        <v-btn class="rounded-pill white elevation-1 mx-2 my-1 font-weight-light text-h6" style="text-transform: initial; letter-spacing: initial" large @click="createProject()"><v-icon left>mdi-tray-plus</v-icon> Create empty project</v-btn>
      </template>
    </div>
    <v-divider class="mt-6"></v-divider>
    <v-btn class="rounded-pill elevation-0 d-block mx-auto" style="margin-top: -18px;" color="#edf0f2" @click="showProjects = false">Hide projects <v-icon>mdi-chevron-up</v-icon></v-btn>
    <org-chart v-if="orgData" :data="orgData" :foundItems="foundItems" :view="orgChartView"></org-chart>    
  </div>
  <collect-dialog v-model="showCollectDialog" :create="createCollect"></collect-dialog>
  <share-assets-dialog v-model="showShareAssetsDialog"></share-assets-dialog>
</div>
</template>

<style>
.share-select {
  transition: background-color 500ms ease-in-out;
  background-color: white;
}

.share-select:hover {
  background-color: white;/*rgba(249,170,51,1);*/
}
.casting-overview {
  transition: height 300ms ease-in-out, opacity 300ms ease-in-out;
  overflow: hidden;
  height: 100%;
}
.casting-overview.show-projects {
  height: 0px;
  opacity: 0;
}
.projects {
  transition: height 300ms ease-in-out, opacity 300ms ease-in-out;
  height: 0px;
  overflow: hidden;
  opacity: 0;
}
.projects.show-projects {
  height: 100%;
  overflow: visible;
  opacity: 1;
}
.projects.hide-projects {
  transition: none !important;
  overflow: hidden !important;
  opacity: 0 !important;
}
</style>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import { CanvasList } from '@/libs/canvas.js'
import { EventBus } from '@/libs/eventBus.js'
import LottieAnimation from 'lottie-web-vue'
import downloadAnim from '@/assets/download.json'
import uploadAnim from '@/assets/upload.json'
import * as tools from '@/libs/tools.js'
import { User } from '@/libs/user.js'
import CollectDialog from '@/components/dialogs/collect-dialog'
import ShareAssetsDialog from '@/components/dialogs/share-assets-dialog.vue'
import * as api from '@/libs/api.js'
import { AccessControl } from '@/libs/access-control.js'
import OrgChart from '@/components/widgets/org-chart'

export default {
  name: 'simple-dashboard',
  props: {
    id: {
      type: String,
      default: () => null
    }
  },
  components: {
    'lottie-animation': LottieAnimation,
    'collect-dialog': CollectDialog,
    'share-assets-dialog': ShareAssetsDialog,
    'org-chart': OrgChart,
  },
  data: () => {
    return {
      loading: true,
      projects: [],
      canvases: {},
      completeAnims: {},
      user: { userName: null, account: null },
      showCollectDialog: false,
      showShareAssetsDialog: false,
      creatingCanvas: false,
      showProjects: false,
      orgData: null,
      orgChartView: null,
      foundItems: [],
    }
  },
  computed: {
    anyChecked() {
      for (let project of this.projects) {
        if (project.checked)
          return true;
      }
      return false;
    },
    downloadAnim() { return downloadAnim; },
    uploadAnim() { return uploadAnim; },
    search() {
      return this.$store.state.search.items;
    },
  },
  watch: {
    async showProjects(show) {
      localStorage.setItem("showProjects", show);
      if (show)
        await this.updateOrgData();
    },
    search(val) {
      if (!val)
        return;

      if (val.length)
        this.showProjects = true;

      this.updateOrgData();

      api.search(val).then((data) => {
        console.log(data)
      });
    },
  },
  async mounted() {
    this.loading = true;
    console.log(localStorage.getItem("showProjects"))
    this.showProjects = JSON.parse(localStorage.getItem("showProjects"));
    
    try {
      if (!this.user.userName) {
        let userLib = new User(this.$store.state);
        this.user = await userLib.getUser();
      }
      const canvasList = new CanvasList(this.$store.state);
      const canvases = await canvasList.listCanvases();
      for (let cid in canvases) {
        const {canvas: canvas} = canvases[cid];
        
        this.projects.push({...canvas, checked: false});
      }
      this.projects.sort((a, b) => {
        return b.created - a.created;
      });
    }
    catch (err) {
      console.error(err);
    }

    this.loading = false;

    this.$nextTick(() => {
      this.$refs.downloadAnim.play();
      this.$refs.uploadAnim.play();
    });

    EventBus.$on('orgChartUpdateSelection', (data) => {
      for (let project of this.projects)
        project.checked = false;

      if (data && data.length) {
        for (let item of data) {
          if (!item.id && !item.cid && !item.iid)
            continue;

          for (let project of this.projects) {
            if (project.cid === item.cid) {
              project.checked = true;
              break;
            }
          }
        }
      }
      EventBus.$emit('orgChartSelectionUpdated', data);
    });
  },
  methods: {
    open(item) {
      if (!item || !item.cid)
        return;
      this.$router.push('/canvas/' + item.cid)
    },
    check(item) {
      if (!item || !item.cid)
        return;

      item.checked = !item.checked;

      let selected = [];

      for (let project of this.projects) {
        if (project.checked)
          selected.push(project);
      }

      EventBus.$emit('orgChartSelectionUpdated', selected);
    },
    timestampToDate(timestamp) {
      return tools.timestampToDate(timestamp);
    },
    getUrl(path) {
      return window.location.origin + '/' + path
    },
    createProject() {
      EventBus.$emit('orgChartAddNode', { action: 'add-canvas'/*, template: { cid: 'cce140d5-a6c3-4100-929a-4e88260fbe29', iid: '9531ed28-e317-4c2d-b26c-9f006cf3ff8d' }*/ });
    },
    async createCollect(data) {
      if (!data || !data.emails || !data.emails.length || !data.message || !data.message.length) 
        return;

      if (this.creatingCanvas)
        return;
        
      this.loading = true;
      this.creatingCanvas = true;
      try {
        const canvas = await api.createCanvas(null, {  path: '', likes: true,  comments: true, });
        if (!canvas || !canvas.cid || !canvas.root)
          return;

        let gallery = { type: 'gallery', cid: canvas.cid, cfg: { name: '', /*msg: data.message, */collect: true, aspectRatio: 8, cover: true, elevation: 1, spacing: 1, showNames: true }, kv: [] };
        gallery.iid = await api.createItem(canvas.cid, canvas.root, gallery.type, gallery.cfg);
        
        try {
          let acLib = new AccessControl(this.$store);

          for (let email of data.emails) {
            await acLib.shareWith(canvas.cid, gallery.iid, email, 'Provider', null, data.message);
          }

          window.location.pathname = 'canvas/' + canvas.cid;
        }
        catch (err) {
          console.log(err);
          this.loading = false;
        }
      }
      catch (err) {
        console.log(err);
      }

      this.loading = false;
      this.creatingCanvas = false;
      
    },
    startAnim(animation) {
      if (this.$refs[animation]) {
        if ((!(animation in this.completeAnims) || this.completeAnims[animation])) {
          this.completeAnims[animation] = false;
          this.$refs[animation].stop();
        }
        this.$refs[animation].play();
      }
    },
    stopAnim(animation) {
      /*if (this.$refs[animation]) {
        this.$refs[animation].pause();
      }*/
    },
    searchedItem(item) {
      if (!this.search || !this.search.length)
        return false;

      if (!item)
        return false;

      for (let searchTerm of this.search) {
        const lcSearch = searchTerm.toLowerCase()

        if (item.nm && item.nm.toLowerCase().includes(lcSearch))
          return true;

        if (item.cfg && (item.cfg.name || 'untitled').toLowerCase().includes(lcSearch))
          return true;
      }
      
      return false;
    },
    async updateOrgData() {
      let orgData = [{name: this.user.account || 'Projects', id: 'root-0', type: 'global-root'}];
      const canvasList = new CanvasList(this.$store.state);

      try {
        this.canvases = await canvasList.listCanvases();
      }
      catch (e) {
        console.error(e);
      }

      let occurances = {};
      let foundIds = [];
      let acs = [{...this.user, name: this.user.userName}];

      // sort this.canvases
      let canvases = Object.values(this.canvases);
      canvases.sort((a, b) => {
        if (!a.canvas || !b.canvas)
          return 0;

        return b.canvas.created - a.canvas.created;
      });

      for (let cv of canvases) {
        let cid = cv.canvas.cid;
        let canvas = this.canvases[cid].canvas;
        let canvasItems = this.canvases[cid].items;
        let needsUpdate = this.canvases[cid].needsUpdate;
        
        if (!canvas)
          continue;

        if (!(cid in occurances))
          occurances[cid] = true;
        else
          continue;

        let assignedToMe = (!canvas.root || !canvas.root.assignee) ? false : (canvas.root.assignee === this.user.uid || canvas.root.assignee === this.user.e);
        
        orgData.push({
          name: canvas.nm || 'Untitled',
          sub: (canvas.created ? this.timestampToDate(canvas.created) : null),
          type: 'canvas',
          class: 'canvas-node',
          id: canvas.cid,
          cid: canvas.cid,
          parentId: 'root-0',
          assignedToMe: assignedToMe,
          acs: acs,
          item: canvas.root,
          showCheck: true
        });

        if (this.searchedItem(canvas) || (assignedToMe && this.orgChartView === 'assignedToMe')) 
          foundIds.push(canvas.cid);

        if (canvasItems && !needsUpdate) {
          for (let iid in canvasItems) {
            let item = canvasItems[iid];

            if (!item.parent || !(item.parent in canvasItems))
              continue;

            if (!(iid in occurances))
              occurances[iid] = true;
            else
              continue;

            let parentId = item.parent;
            if (parentId && !canvasItems[parentId].parent)
              parentId = item.cid;

            let assignedToMe = !item.assignee ? false : (item.assignee === this.user.uid || this.user.e === item.assignee);
            orgData.push({
              name: item.cfg ? (item.cfg.name || 'Untitled') : 'Untitled',
              imageUrl: item.type === 'video' ? item.src : (item.thumbSrc || null),
              type: item.type,
              id: item.iid,
              cid: item.cid,
              parent: parentId || item.cid,
              parentId: parentId || item.cid,
              assignedToMe: assignedToMe,
              acs: acs,
              item: item
            });

            if (this.searchedItem(item) || (assignedToMe && this.orgChartView === 'assignedToMe')) 
              foundIds.push(item.iid);
          }
        }
        else {
          orgData.push({
            id: cid + '-loading',
            parentId: cid,
            loading: true,
            loader: async () => { 
              let canvasObj = await canvasList.getCanvas(cid);
              let canvas = canvasObj ? canvasObj.canvas : null;
              this.updateOrgData();

              let id = (canvas && canvas.root && canvas.root.items && canvas.root.items.length) ? canvas.root.items[0].iid : null;
              if (id)
                setTimeout(() => {
                  EventBus.$emit('orgChartExpandItem', id);
                }, 500);
            }
          });
        }
      }

/*      let addMore = this.user && this.user.templates && this.user.templates.length;
      //let template = this.getDefaultProjectTemplate();

      orgData.push({
        name: 'Create project', //+ (template ? template.name.toLowerCase() : '',
        type: 'add',
        class: 'add-node',
        action: 'add-canvas',
        id: 'root-0-add',
        parentId: 'root-0',
        addMore: addMore,
        //template: this.getDefaultProjectTemplate()
      });*/

      this.foundItems = foundIds;
      this.orgData = orgData;
    },
  }
}
</script>

<i18n>
{
  "en": {
    "addAssetsTo": "Add assets to ",
    "and": " and ",
    "leaveItEmpty": "leave it empty",
    "upload": "Upload",
    "select": "Select",
    "uploadFilesFrom": "Upload files from your device",
    "collect": "Collect",
    "requestAssetsFrom": "Request assets from others via a simple link",
    "uploadFilesTo": "Upload files to the section",
    "selectFiles": "Select files",
    "selectFolder": "Select folder",
    "selectMoreFiles": "Select more files",
    "or": "or",
    "anotherFolder": "another folder",
    "writeYourInstructions": "Write your instructions",
    "tellOtherUsers": "Tell other users how they should contribute. They don't need an account to submit their assets and won't see any other content apart from theirs",
    "createALink": "Create a link",
    "hereIsTheLink": "Here's the link to collect assets",
    "shareItWith": "Share it with anyone who should add some files to this section. You can send this link to multiple contributors - they won't see your or each other's content."
  }
}
</i18n>