<template>
  <v-dialog v-model="show" @keydown.esc="show = false" scrollable persistent content-class="collect-dialog" width="620">
    <v-card color="white" light>
      <v-card-title class="primary white--text pr-3">Ask others to send you assets<v-spacer></v-spacer><v-btn @click="show = false" icon color="white"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
      <v-card-text class="primary--text px-7 pt-7" style="overflow:visible">
        <div class="mb-4 text-h6 font-weight-light">Please write your instructions for the other person to follow.</div>
        <v-combobox v-model="emails" label="Enter e-mail" :search-input.sync="currentEmail" outlined flat dense multiple chips hide-details class="mb-3" :delimiters="[' ', ',', ';']">
          <template v-slot:append>&nbsp;</template>
          <template v-slot:no-data>
            <template v-if="isValidEmail(currentEmail)">
              <v-list-item @click="() => {emails.push(currentEmail); currentEmail = null;}">
                <v-list-item-content>
                  <v-list-item-title>Use <strong>{{ currentEmail }}</strong></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </template>
          <template v-slot:selection="data">
            <v-tooltip top :disabled="isValidEmail(data.item)">
              <template v-slot:activator="{on}">
                <v-chip class="my-1" label small v-on="on" v-bind="data.attrs" :input-value="data.selected" :color="isValidEmail(data.item) ? 'rgba(0,0,0,0.1)' : 'error'" close @click:close="data.parent.selectItem(data.item)">{{ data.item }}</v-chip>
              </template>
              <span>Not a valid email address</span>
            </v-tooltip>
          </template>
        </v-combobox>
        <vue-editor v-model="message" :editorToolbar="[[{ 'size': ['small', false, 'large', 'huge'] }],['bold', 'italic', 'underline'],['link'],[{ 'list': 'ordered'}, { 'list': 'bullet' }], [{ 'color': [] }, { 'background': [] }],['clean']]" :editorOptions="{ theme: 'bubble', placeholder: 'Message'}" class="text-left collect-message"></vue-editor>
      </v-card-text>
      <v-card-actions class="px-7 pb-6">
        <v-spacer></v-spacer>
        <v-btn text class="rounded-pill px-3" @click="show = false" :disabled="loading">Cancel</v-btn>
        <v-btn :disabled="!validEmails || !validEmails.length || loading" class="elevation-1 secondary primary--text rounded-pill px-3" @click="createCollect()" :loading="loading">Send <v-icon right>mdi-chevron-right</v-icon></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
.collect-dialog {
  overflow: visible;
}
.collect-message {
  border: 1px solid rgba(0,0,0,0.38);
  border-radius: 4px;
}

.collect-message:focus {
  border: 2px solid rgba(0,0,0,0.38);
}

.collect-message .ql-editor:focus.ql-blank::before {
  opacity: 0;
}

.collect-message .ql-editor.ql-blank::before {
  font-style: normal;
}
</style>

<script>
/*eslint-disable no-unused-vars*/
import { VueEditor } from "vue2-editor";

export default {
  name: 'collect-dialog',
  components: {
    'vue-editor': VueEditor,
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    create: {
      type: Function,
      default: () => {}
    }
  },
  data: () => {
    return {
      message: null,
      emails: [],
      currentEmail: null,
      loading: false
    }
  },
  computed: {
    show: {
      get() { return this.value; },
      set(val) { this.$emit('input', val); }
    },
    validEmails() {
      return this.emails.filter(email => this.isValidEmail(email));
    }
  },
  methods: {
    isValidEmail(email) {
      if (!email || !email.length)
        return false;
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(email);
    },
    async createCollect() {
      this.loading = true;
      try {
        await this.create({emails: this.validEmails, message: this.message});
      } catch (e) {
        console.error(e);
      }
      this.show = false;
    }
  }
}
</script>