<template>
<div>
  <div v-if="root && user">
    <template v-if="canvas.snapshot && (root.assignee === user.uid || root.assignee === user.e)">
      <template v-if="!root.status || root.status === 'In progress' || root.status === 'Waiting for review'">
        <div class="pt-4">
          <div small class="d-inline-block mx-1 elevation-0 body-2 mr-3" color="white">Waiting for your review</div>
          <v-chip small class="mr-2 elevation-1 pl-4" color="success" @click="setStatus('Accepted')"><v-icon left small>mdi-thumb-up</v-icon><b>Accept</b></v-chip>
          <v-chip small class="elevation-1 pl-4" color="red" dark @click="setStatus('Rejected')"><v-icon left small>mdi-thumb-down</v-icon><b>Reject</b></v-chip>
        </div>
      </template>
      <template v-else-if="root.status === 'Accepted' || root.status === 'Rejected'">
        <div class="pt-4">
          <v-chip small class="mx-1 elevation-0 pl-4" :color="root.status === 'Accepted' ? 'success' : 'error'"><v-icon small left>{{root.status === 'Accepted' ? 'mdi-thumb-up' : 'mdi-thumb-down'}}</v-icon>{{status.text}}</v-chip>
          <v-chip small class="mx-1 elevation-1 pl-4" color="primary" @click="setStatus('Waiting for review')"><v-icon left small>mdi-undo</v-icon><b>Revert review</b></v-chip>
        </div>
      </template>
      <template v-else>
        <v-chip small class="mx-1 elevation-0 mt-4" :color="status.color">{{status.text}}</v-chip>  
      </template>
    </template>
    <template v-else>
      <div class="text-right pt-4 section-labels d-inline-block">
        <v-menu :close-on-content-click="true" offset-y bottom :disabled="!editing">
          <template v-slot:activator="{ on }">
            <v-chip v-on="on" small class="mx-1 elevation-0 pr-1" :color="status.color">{{status.text}}<v-icon x-small>mdi-chevron-down</v-icon></v-chip>  
          </template>
          <v-card class="pa-0">
            <v-list dense class="pa-0">
              <v-list-item class="pl-2" v-for="status in ['In progress', 'Canceled', 'Done']" :key="status" @click="setStatus(status)">
                <v-list-item-action class="pa-0 ma-0" v-if="root.status"><v-icon small v-if="root.status === status">mdi-check</v-icon></v-list-item-action>
                <v-list-item-title :class="root.status === status ? 'font-weight-bold' : null">{{status}}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </div>
      <template v-if="root.status !== 'Canceled' && root.status !== 'Done' || (canvas.snapshots && canvas.snapshots.length)">
        <div class="text-right pt-4 section-labels d-inline-block" v-if="editing && !canvas.snapshots || !canvas.snapshots.length">
          <v-chip small class="elevation-1 ml-1 pr-1" color="primary white--text" @click="showCreateSnapshotDialog()"><b>Send for a review</b> <v-icon small class="ml-1">mdi-chevron-right</v-icon></v-chip>
        </div>
        <div class="text-right pt-4 section-labels d-inline-block" v-else>
          <v-menu :close-on-content-click="true" offset-y bottom v-if="canvas.snapshots && canvas.snapshots.length">
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" small class="elevation-1 pr-1" color="primary white--text">{{canvas.snapshot && getCurrentSnapshot() ? getCurrentSnapshot().nm : (canvas.snapshots.length > 1 ? 'See reviews' : 'See the review')}}<v-icon x-small>mdi-chevron-down</v-icon></v-chip>
            </template>
            <v-card class="pa-1">
              <v-list class="pa-0" dense two-line>
                <template v-if="canvas.snapshots && canvas.snapshots.length">
                  <v-list-item class="pl-2 pr-0 hoverable-ex" v-for="(snapshot, i) in canvas.snapshots" :key="i" :href="snapshot.sid === canvas.sid ? getCanvasURL() : getSnapshotURL(snapshot)">
                    <v-list-item-action class="py-0 pl-0 pr-3 ma-0" v-if="snapshot.status">
                      <v-icon color="success" v-if="snapshot.status === 'Accepted'">mdi-thumb-up</v-icon>
                      <v-icon color="red darken-2" v-else-if="snapshot.status === 'Rejected'">mdi-thumb-down</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{snapshot.nm}}</v-list-item-title>
                      <v-list-item-subtitle>{{timestampToDate(snapshot.created)}}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action v-if="editing" style="margin-left: 4px;"><v-icon @click.prevent.stop="deleteSnapshot(snapshot.sid)" :size="20" color="primary" class="show-on-hover-ex">mdi-trash-can</v-icon></v-list-item-action>
                  </v-list-item>
                  <v-divider></v-divider>
                </template>
                <div v-else class="text-center caption pt-3">
                  <span>This project doesn't have any snapshots yet.<br/>Create a snapshot to conserve the state of the project in time.</span>
                </div>
                <v-list-item v-if="editing"><v-btn @click="showCreateSnapshotDialog()" rounded small class="elevation-1 mx-auto" color="secondary primary--text">Send for another review</v-btn></v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </div>
      </template>
    </template>
  </div>
  <v-dialog v-model="createSnapshotDialog.show" @keydown.esc="createSnapshotDialog.loading ? (()=>{})() : createSnapshotDialog.show = false" persistent width="650">
    <v-card light class="client-flow-snapshot-dialog">
      <v-card-title class="primary white--text pr-3">Send {{root.cfg ? (root.cfg.name || 'this section') : (canvas.nm || 'this project')}} for a review<v-spacer></v-spacer><v-btn icon color="white"><v-icon @click="createSnapshotDialog.loading ? (()=>{})() : createSnapshotDialog.show = false">mdi-close</v-icon></v-btn></v-card-title>
      <v-card-text class="primary--text pa-7 text-center">
        <v-window v-model="createSnapshotDialog.step">
          <v-window-item :value="1">
            <div class="body-2 text-left pb-3">
              <span>Conserve the state of <b>{{root.cfg ? (root.cfg.name || 'this section') : (canvas.nm || 'this project')}}</b> in time and send it for review. Optionally, you can also conserve comments and emoji reactions.</span>
            </div>
            <v-text-field class="mt-4 mb-2 py-0 pr-0" outlined hide-details label="Please enter name of the snapshot" v-model="createSnapshotDialog.name" @keydown.enter="(createSnapshotDialog.name && createSnapshotDialog.name.length) ? createSnapshotDialog.step++ : (() => {})()" :loading="createSnapshotDialog.loading" autofocus></v-text-field>
            <v-expand-transition>
              <div v-if="createSnapshotDialog.advanced">
                <div><v-icon>mdi-alpha</v-icon> - under construction</div>
                <v-switch class="d-inline-block pb-2" :input-value="true" disabled hide-details label="Clear comments" style="margin-right: 16px"></v-switch>
                <v-switch class="d-inline-block pb-2" :input-value="true" disabled hide-details label="Clear emoji reactions" style="border-left: 1px solid rgba(0,0,0,0.12); padding-left: 16px"></v-switch>
              </div>
            </v-expand-transition>
            <div class="d-flex" style="cursor: pointer" @click="createSnapshotDialog.advanced = !createSnapshotDialog.advanced">
              <v-divider class="flex-grow-1 flex-shrink-0 mr-3" style="margin-top: 10px"></v-divider>
              <div class="caption flex-grow-0 flex-shrink-1">Advanced <v-icon small color="rgba(0, 0, 0, 0.24)">{{`mdi-chevron-${createSnapshotDialog.advanced ? 'up' : 'down'}`}}</v-icon></div>
            </div>
          </v-window-item>
          <v-window-item :value="2">
            <div class="body-2 text-left pb-3">
              <h2 class="font-weight-light">Who will be reviewing {{root.cfg ? (root.cfg.name || 'this section') : (canvas.nm || 'this project')}}?</h2>
              <div class="body-2 mt-2">Please enter an email of the user that will be reviewing {{root.cfg ? (root.cfg.name || 'this section') : (canvas.nm || 'this project')}}.</div>
              <v-text-field class="mt-4 mb-2 py-0 pr-0" outlined hide-details label="Enter e-mail" v-model="createSnapshotDialog.email" @keydown.enter="(createSnapshotDialog.email && createSnapshotDialog.email.length) ? createSnapshot(createSnapshotDialog.name, createSnapshotDialog.email, createSnapshotDialog.role) : (() => {})()" :loading="createSnapshotDialog.loading" autofocus>
                <template v-slot:append>
                  <div style="width: 126px; height: 100%; overflow: hidden; margin-top: 4px; border-left: 1px solid silver; display: inline-flex;"><v-select background-color="transparent" :items="userRoles" class="pr-0" v-model="createSnapshotDialog.role" solo flat hide-details></v-select></div>
                </template>
              </v-text-field>
              <div class="caption mt-2"><v-icon left>mdi-alpha</v-icon> Currently only one user is able to approve / reject review. Please open created review to add other users besides you and the reviewer.</div>
            </div>
          </v-window-item>
        </v-window>
      </v-card-text>
      <v-card-actions style="background-color: #edf0f2">
        <v-spacer></v-spacer>
        <template v-if="createSnapshotDialog.step === 1">
          <v-btn text rounded color="primary" :disabled="createSnapshotDialog.loading" @click="createSnapshotDialog.show = false">{{ $t('cancel') }}</v-btn>
          <v-btn class="elevation-1 rounded-pill secondary primary--text" :loading="createSnapshotDialog.loading" :disabled="createSnapshotDialog.loading || !createSnapshotDialog.name || !createSnapshotDialog.name.length" @click="createSnapshotDialog.step++">Next</v-btn>
        </template>
        <template v-if="createSnapshotDialog.step === 2">
          <v-btn text rounded color="primary" :disabled="createSnapshotDialog.loading" @click="createSnapshotDialog.step--">Previous</v-btn>
          <v-btn class="elevation-1 rounded-pill secondary primary--text" :loading="createSnapshotDialog.loading" :disabled="createSnapshotDialog.loading || !validEmail(createSnapshotDialog.email) || !createSnapshotDialog.name || !createSnapshotDialog.name.length || !createSnapshotDialog.role || !createSnapshotDialog.role.length" @click="createSnapshot(createSnapshotDialog.name, createSnapshotDialog.email, createSnapshotDialog.role)">Send</v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<style>
.client-flow-snapshot-dialog .v-input__append-inner {
  margin-top: 0px !important;
}
.client-flow-snapshot-dialog .v-input.pr-0 .v-input__slot {
  padding-right: 0px !important;
}
.client-flow-snapshot-dialog .v-input.pl-0 .v-input__slot {
  padding-left: 0px !important;
}
</style>

<script>
/* eslint-disable no-unused-vars */
import { User } from '@/libs/user.js'
import * as api from '@/libs/api.js'
import * as tools from '@/libs/tools.js'
export default {
  name: 'project-flow-control',
  props: {
    canvas: {
      type: Object,
      default: () => null
    },
    root: {
      type: Object,
      default: () => null
    },
    editing: {
      type: Boolean, 
      default: () => false
    }
  },
  data() {
    return {
      user: null,
      createSnapshotDialog: {
        show: false,
        step: 2,
        name: null,
        role: 'Client',
        loading: false,
        advanced: false,
      },
      userRoles: ['Viewer', 'Editor', 'Client'],
    }
  },
  computed: {
    status() {
      const statusToIcon = (text) => {
        switch (text) {
          case 'Waiting for your review':
          case 'Waiting for review':
          case 'In progress':
            return 'mdi-progress-clock';
          case 'Accepted':
            return 'mdi-check-circle';
          case 'Rejected':
            return 'mdi-close-circle';
          case 'Done':
            return 'mdi-check-circle';
          case 'Canceled':
            return 'mdi-close-circle';

          default:
            return null;
        }
      };

      const statusToColor = (text) => {
        switch (text) {
          case 'Accepted':
          case 'Done':
            return 'success';
          case 'Rejected':
          case 'Canceled':
            return 'red darken-2 white--text';

          default:
            return null;
        }
      };

      const getStatusObject = (text) => {
        return {
          text: text,
          icon: statusToIcon(text),
          color: statusToColor(text)
        };
      }

      if (this.canvas.snapshot) {
        if (this.root.assignee === this.user.uid || this.root.assignee === this.user.e) {
          if (!this.root.status || this.root.status === 'In progress' || !(this.root.status === 'Accepted' || this.root.status === 'Rejected' || this.root.status === 'Done' || this.root.status === 'Canceled'))
            return getStatusObject('Waiting for your review');
        }
        return getStatusObject(this.root.status || 'Waiting for review');
      }

      if (this.root.status === 'Done' || this.root.status === 'Canceled')
        return getStatusObject(this.root.status);

      if (this.canvas.snapshots && this.canvas.snapshots.length) {
        let acceptedCount = 0;
        let rejectedCount = 0;

        for (let snapshot of this.canvas.snapshots) {
          if (snapshot.status === 'Accepted')
            acceptedCount++;
          else if (snapshot.status === 'Rejected')
            rejectedCount++;
        }

        if (acceptedCount === this.canvas.snapshots.length)
          return getStatusObject('Accepted');
        else if (rejectedCount === this.canvas.snapshots.length)
          return getStatusObject('Rejected');
        else if (acceptedCount + rejectedCount === this.canvas.snapshots.length)
          return getStatusObject('Reviewed');
        else
          return getStatusObject('Waiting for review');
      }

      return getStatusObject(this.root.status || 'In progress');
    }
  },
  async mounted() {
    let userLib = new User(this.$store.state);
    this.user = await userLib.getUser();
    this.userRoles = (this.user && this.user.userRoles && this.user.userRoles.length) ? this.user.userRoles : ['Viewer', 'Editor', 'Client'];
  },
  methods: {
    setStatus(status) {
      this.$emit('set-status', status);
    },
    deleteSnapshot(sid) {
      this.$emit('delete-snapshot', sid);
    },
    timestampToDate(timestamp) {
      return tools.timestampToDate(timestamp);
    },
    openSnapshot(snapshot) {
      window.location.pathname = '/snapshot/' + this.canvas.cid + '/' + snapshot.iid + '/' + snapshot.sid;
    },
    openCanvas() {
      window.location.pathname = '/canvas/' + this.canvas.cid + '/' + (this.iid || '');
    },
    getSnapshotURL(snapshot) {
      return window.location.origin + '/snapshot/' + this.canvas.cid + '/' + snapshot.iid + '/' + snapshot.sid;
    },
    getCanvasURL() {
      return window.location.origin + '/canvas/' + this.canvas.cid + '/' + (this.iid || '');
    },
    getCurrentSnapshot() {
      if (!this.canvas.sid || !this.canvas.snapshots || !this.canvas.snapshots.length)
        return null;
      
      return this.canvas.snapshots.find(s => s.sid === this.canvas.sid);
    },
    async createSnapshot(name, email, role) {
      if (!name || !name.length || !email || !email.length || !role || !role.length)
        return;

      this.createSnapshotDialog.loading = true;
      try {
        let snapshot = await api.createSnapshot(this.canvas.cid, this.root.iid, name, email, role);

        if (!snapshot)
          return;

        this.setStatus('Waiting for review');
        this.$emit('snapshot-created', snapshot);

        //open snapshot in new tab
        window.open(this.getSnapshotURL(snapshot), '_blank').focus();
      }
      catch (err) {
        console.log(err);
      }

      this.createSnapshotDialog.show = false;
      this.createSnapshotDialog.name = null;
      this.createSnapshotDialog.loading = false;
    },
    showCreateSnapshotDialog() {
      let name = (this.root.cfg ? ((this.root.cfg.name) || 'Section') : ((this.canvas.nm) || 'Project')) + ' - review';

      if (this.canvas.snapshots && this.canvas.snapshots.length) {
        let found = false;
        let highestNo = 0;
        for (let snapshot of this.canvas.snapshots) {
          if (!found && snapshot.nm.indexOf(name) >= 0)
            found = true;

          let regex = new RegExp('^' + name + ' \\(\\d+\\)$');
          if (regex.test(snapshot.nm)) {
            let number = parseInt(snapshot.nm.replace(name + ' (', '').replace(')', ''));
            if (number >= 1 && number > highestNo) {
              highestNo = number;
              /*this.createSnapshotDialog.name = name + ' (' + (number + 1) + ')';
              break;*/
            }
          }
        }

        if (found) {
          if (highestNo < 2)
            highestNo = 1;
          this.createSnapshotDialog.name = name + ' (' + (highestNo + 1) + ')';
        }
        else
          this.createSnapshotDialog.name = name;
      }
      else {
        this.createSnapshotDialog.name = name;
      }
      this.createSnapshotDialog.step = 1;
      this.createSnapshotDialog.show = true;
    },
    validEmail(email) {
      if (!email || !email.length)
        return false;
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(email);
    }
  }
}
</script>