<template>
<div class="instruction-list-widget">
  <vue-editor v-model="text" @text-change="updateValue(text)" :editorToolbar="[[{ 'size': ['small', false, 'large', 'huge'] }],['bold', 'italic', 'underline'],['link'],[{ 'list': 'ordered'}, { 'list': 'bullet' }], [{ 'color': [] }, { 'background': [] }],['clean']]" :editorOptions="{ theme: 'snow'}" class="text-left"></vue-editor>
</div>
</template>

<style>
</style>

<script>
import { VueEditor } from "vue2-editor";
export default {
  name: 'InstructionListWidget',
  components: {
    'vue-editor': VueEditor,
  },
  props: {
    value: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      newItem: null,
      loading: false,
      text: null
    }
  },
  computed: {
    item: {
      get() { return this.value; },
      set(value) { this.$emit('input', value); }
    },
  },
  methods: {
    async updateValue(value) {
      if (!this.item)
        return;
      this.item.value = value;

      this.$emit('updateValue', value);
    }
  }
}
</script>