<template>
  <v-dialog v-model="show" @keydown.esc="show = false" scrollable persistent content-class="settings-dialog" width="650">
    <v-card color="#edf0f2">
      <v-card-title class="primary white--text pa-3">My profile<v-spacer></v-spacer><v-btn icon color="white" @click="show = false"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
      <v-card-text class="pt-10" style="background-color: white" id="photo-upload-container">
        <input style="display: none" type="file" ref="file" @change="uploadImage($event)" accept="image/*" />
        <template v-if="selectingPhoto">
          <cropper class="cropper" ref="cropper" :stencil-component="$options.components.CircleStencil" :src="image.src" :stencil-props="{aspectRatio: 1}" :canvas="{minHeight: 0, minWidth: 0, maxHeight: 128, maxWidth: 128}"></cropper>
        </template>
        <template v-else-if="user">
          <center>
            <v-hover v-slot="{ hover }">
              <div style="position: relative; cursor: pointer; display: inline-block;" @click="selectPhoto()">
                <v-avatar v-if="user.profileImage" size="64" class="elevation-3 ml-2" style="border: 1px solid rgba(0,0,0,0.2)"><v-img :src="user.profileImage" size="64"></v-img></v-avatar>
                <v-avatar v-else :color="getColor(user.e)" size="64" class="elevation-3 ml-2" style="border: 1px solid rgba(0,0,0,0.2); font-size: 46px; font-weight: 300; color: rgba(0,0,0,0.6);">{{(user.userName || user.e)[0].toUpperCase()}}</v-avatar>
                <v-fade-transition>
                  <v-icon v-if="hover" color="primary" size="16" style="position: absolute; bottom: -4px; right: -2px; border-radius: 50%; background-color: rgba(255,255,255,0.7); width: 28px; height: 24px">mdi-pencil</v-icon>
                </v-fade-transition>
              </div>
            </v-hover>
            <v-text-field :value="user.userName" v-on:change="updateUserName($event)" placeholder="Name" flat solo hide-details  style="margin: 20px 0px 10px 0px; font-weight: 300; font-size: 38px;" class="name-input"></v-text-field>
            <span style="margin-top: 10px; font-weight: 300; font-size: 20px;">{{user.e}}</span>
          </center>
          <center class="mb-8">
            <v-select color="primary" outlined append-icon="mdi-chevron-down" style="width: 130px; color: rgba(0,0,0,0.6); font-weight: 300; font-size: 20px;" :value="user.lang || 'eng'" v-on:change="updateLanguage($event)" label="Language" :items="[{t:'English', v:'eng'}, {t:$t('Čeština'), v:'cze'}]" item-text="t" item-value="v" menu-props="auto" hide-details flat dense single-line class="py-3 d-inline-block"></v-select>
          </center>
        </template>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions style="background-color: #edf0f2">
        <v-spacer></v-spacer>
        <template v-if="selectingPhoto">
          <v-btn class="elevation-1 secondary primary--text rounded-pill px-3" @click="cropImage">Save</v-btn>
          <v-btn class="elevation-1 secondary primary--text rounded-pill px-3" @click="selectingPhoto = false">Cancel</v-btn>
        </template>
        <template v-else>
          <v-btn class="elevation-1 secondary primary--text rounded-pill px-3" @click="show = false">Close</v-btn>
        </template>
      </v-card-actions>
    </v-card>
    <invite-dialog v-model="showInviteDialog"></invite-dialog>
  </v-dialog>
</template>

<style>
.fill-height {
  height: 100%;
}
.name-input input {
  text-align: center;
}
.settings-dialog .v-select__selections {
  line-height: 24px;
  color: rgba(0,0,0,0.6) !important;
  text-align: center;
}
.settings-dialog .v-select__selections input {
  display: none;
}
</style>
<script>
/* eslint-disable vue/no-unused-components */
import 'vue-advanced-cropper/dist/style.css';
import { CircleStencil, Cropper } from 'vue-advanced-cropper'
import InviteTeamMember from '@/components/dialogs/invite-team-member'
import * as tools from '@/libs/tools.js'
import { User } from '@/libs/user.js'
import * as api from '@/libs/api.js'
//import * as base64 from '@/libs/base64.js'

export default {
  name: 'profile-dialog',
  props: {
    value: {
      type: Boolean,
      default: () => false
    },
    showInvite: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    Cropper, 
    CircleStencil,
    'invite-dialog': InviteTeamMember
  },
  data: () => {
    return {
      user: null,
      selectingPhoto: false,
      image: {
        src: '',
        type: "image/jpg",
      },
      tab: null,
      showInviteDialog: false
    }
  },
  async mounted() {
    let userLib = new User(this.$store.state);
    try {
      this.user = await userLib.getUser();
    }
    catch (err) {
      console.info(err);
    }
  },
  watch: {
    show(show) {
      this.selectingPhoto = false;
      this.$nextTick(()=>{
        this.showInviteDialog = show && this.showInvite;
      });
    }
  },
  computed: {
    show: {
      get: function() { return this.value; },
      set: function(value) { this.$emit('input', value); }
    },
  },
  methods: {
    getColor(str) {
      return tools.seededRandomColor(str);
    },
    selectPhoto() {
      this.selectingPhoto = true;
      this.$refs.file.click();
    },
    async cropImage() {
      const result = this.$refs.cropper.getResult();
      this.user.profileImage = result.canvas.toDataURL(this.image.type);
      
      this.selectingPhoto = false;

      try {
        let userLib = new User(this.$store.state);
        await userLib.updateUser(this.user);

        for (let cid in this.$store.state.ac.canvases) {
          let acCanvas = this.$store.state.ac.canvases[cid];
          if (!acCanvas.list || !acCanvas.list.length)
            continue;

          let index = acCanvas.list.findIndex(ac => ac.uid === this.user.uid);
          if (index < 0)
            continue;

          acCanvas.list[index].profileImage = this.user.profileImage;
          this.$store.state.ac.updated = Date.now();
        }
        
        await api.updateUser({profileImage: this.user.profileImage});
      }
      catch (err) {
        console.log(err);
      }
    },
    uploadImage(event) {
      const { files } = event.target;
      if (files && files[0]) {
        if (this.image.src)
          URL.revokeObjectURL(this.image.src);

        const blob = URL.createObjectURL(files[0]);

        this.image = {
          src: blob,
          type: files[0].type,
        };
      }
    },
    async updateUserName(name) {
      this.user.userName = name;
      await api.updateUser({userName: name});
    },
    async updateLanguage(lang) {
      this.user.lang = lang;
      await api.updateUser({lang: lang});
    },
    
  }
}
</script>

<i18n>
{
  "en": {
  }
}
</i18n>