<template>
<div class="gallery-view">
  <v-dialog v-model="show" @keydown.esc="show = false" @keydown.left="!expandText ? prevImage() : nullHandler()" @keydown.right="!expandText ? nextImage() : nullHandler()" fullscreen persistent no-click-animation content-class="elevation-0 ma-0">
    <v-window show-arrows v-model="index" style="height: 100%;" dark>
      <template v-slot:prev="{ on, attrs }">
        <v-btn color="primary" fab :small="!$vuetify.breakpoint.mdAndUp" v-bind="attrs" v-on="on"><v-icon :large="$vuetify.breakpoint.mdAndUp">mdi-chevron-left</v-icon></v-btn>
      </template>
      <template v-slot:next="{ on, attrs }">
        <v-btn color="primary" fab :small="!$vuetify.breakpoint.mdAndUp" v-bind="attrs" v-on="on"><v-icon :large="$vuetify.breakpoint.mdAndUp">mdi-chevron-right</v-icon></v-btn>
      </template>
      <template v-for="(item, i) in items">
        <v-window-item v-if="true" :key="i" style="height: 100%">
          <div id="gallery-container" @xwheel="onScroll(item, $event)">
            <div style="position: fixed; width: 100%; height: calc(100% - 80px); overflow-y: auto;">
              <div v-if="item.type === 'file'" style="position: relative; width: 100%; height: calc(100% - 80px)">
                <iframe :src="(item.cfg && item.cfg.path && item.cfg.path.split('.').pop() === 'pdf') ? (!localPdfSupported() ? `https://pdf.sharespot.cz/?url=${urlEncode(btoa(item.src))}` : item.src) : `https://docs.google.com/gview?url=${urlEncode(item.src)}&embedded=true`" style="width: 100%; height: 100%;"></iframe>
              </div>
              <div v-else-if="item.type === 'img'" style="position: relative; width: 100%; height: 100%">
                <v-img :src="getItemSrc(item)" :lazy-src="item.thumbSrc || null" contain class="primary--text align-end vcenter hcenter" style="width: 100%; height: 100%"></v-img>
              </div>
              <video-player v-else-if="item.type === 'video'" class="video-player-box" ref="videoPlayer" :options="getVideoOptions(item, i)"></video-player>
              <div v-else-if="item.type === 'audio' && i === index && show" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
                <vue-plyr :options="{autoplay: true}">
                  <audio controls crossorigin playsinline>
                    <source :src="item.src" type="audio/mp3" />
                  </audio>
                </vue-plyr>
              </div>
              <div v-else-if="item.type !== 'root' && item.items && item.items.length" style="height: 100%; padding-bottom: 80px;">
                <canvas-widget :value="item" :editing="editing" style="height: 100%"></canvas-widget>
              </div>
              <div v-else-if="item.type === 'root' && item.items && item.items.length" class="gallery-view-root">
                <root-widget :value="item" :editing="editing"></root-widget>
              </div>
              <div v-else style="width: 100%; height: 100%;">
                <canvas-widget :value="item" :editing="false"></canvas-widget>
              </div>
            </div>
            <div v-if="false && firstParentGallery && (firstParentGallery.type === 'list' || firstParentGallery.type === 'map-list' || (firstParentGallery.cfg && firstParentGallery.cfg.showNames))" class="item-text" :class="expandText === true ? 'item-text-expanded' : null">
              <v-container fluid class="pa-0">
                <v-row no-gutters style="flex-wrap: nowrap">
                  <v-col cols="1" class="flex-grow-0 flex-shrink-0"></v-col>
                  <v-col cols="10" style="min-width: 100px; max-width: 100%;" class="flex-grow-1 flex-shrink-0" @click="showKV(item) ? scrollDescription(true) : nullHandler()">
                    <v-text-field v-if="editing" :value="item.cfg.name" v-on:change="updateItemConfig(item, () => item.cfg.name = $event)" flat solo hide-details light class="item-text-title"></v-text-field>
                    <span v-else class="item-text-title">{{item.cfg.name}}</span>
                  </v-col>
                  <v-col cols="1" style="min-width: 50px;" class="flex-grow-0 flex-shrink-1">
                    <v-btn v-if="show && (editing || showKV(item))" @click="showKV(item) ? scrollDescription(): nullHandler()" dark fab color="#17262a" :style="!editing ? 'opacity: 1' : null" class="expand-text-button float-right elevation-0" :small="!$vuetify.breakpoint.mdAndUp"><v-icon size="32" color="white">{{descriptionExpanded() ? 'mdi-chevron-down' : 'mdi-chevron-up'}}</v-icon></v-btn>
                    <div style="clear: both"></div>
                  </v-col>
                </v-row>
              </v-container>
              <div style="width: 100%; background-color: rgba(255,255,255,0.5);">
                <div class="item-description pa-4 elevation-3 rounded-t-xl">
                  <div>
                    <draggable v-model="item.kv" :disabled="$vuetify.breakpoint.mdAndDown">
                      <key-value-item v-for="(kv, k) in item.kv" :key="k" v-model="item.kv[k]" :editing="editing" :animation="200" class="mb-2" @remove="removeKVItem(item, k)" @update="updateKVItem(item, kv)" full></key-value-item>
                    </draggable>
                    <div class="d-block text-center mt-10">
                      <v-btn v-if="editing" class="ma-1 d-inline-block rounded-pill elevation-1" @click="createKVItem(item, 'text')"><v-icon left>mdi-pencil-plus</v-icon> {{ $t('kv.text') }}</v-btn>
                      <v-btn v-if="editing" class="ma-1 d-inline-block rounded-pill elevation-1" @click="createKVItem(item, 'number')"><v-icon left>mdi-numeric-9-plus</v-icon> {{ $t('kv.number') }}</v-btn>
                      <v-btn v-if="editing" class="ma-1 d-inline-block rounded-pill elevation-1" @click="createKVItem(item, 'labels')"><v-icon left>mdi-tag-plus-outline</v-icon> {{ $t('kv.labels') }}</v-btn>
                      <v-btn v-if="editing" class="ma-1 d-inline-block rounded-pill elevation-1" @click="createKVItem(item, 'map')"><v-icon left>mdi-map-plus</v-icon> {{ $t('kv.map') }}</v-btn>
                    </div>
                    <div id="item-description-end"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-window-item>
        <v-window-item v-else :key="i" style="height: 100%">
          <div id="gallery-container" @wheel="onScroll(item, $event)">
            <div style="position: fixed; width: 100%; height: calc(100% - 80px); overflow-y: auto;">
              <div style="position: relative; width: 100%; height: 100%">
                <iframe :src="(item.cfg && item.cfg.path && item.cfg.path.split('.').pop() === 'pdf') ? `https://pdf.sharespot.cz/?url=${urlEncode(btoa(item.src))}` : `https://docs.google.com/gview?url=${urlEncode(item.src)}&embedded=true`" style="width: 100%; height: 100%;"></iframe>
              </div>
            </div>
          </div>
        </v-window-item>
      </template>
    </v-window>
  </v-dialog>
  <v-alert :value="show && $vuetify.breakpoint.mdAndUp" transition="fade-transition" color="#1a2629" dark height="80px" max-height="80px" style="position: fixed; border-radius: 0px; top: 0; left: 0px; padding: 8px; width: 100%; z-index: 999; margin: 0px;">
    <div style="position: absolute; left: 50%; transform: translateX(-50%); max-height: 64px; overflow-y: hidden;">
      <template v-for="(item, i) in items">
        <canvas-item-thumbnail :value="item" :key="item.iid" :config="{ aspectRatio: 10, cover: false }" class="thumbnail d-inline-block align-end" :class="i === index ? 'active' : ''" single small @view="index = i"></canvas-item-thumbnail>
      </template>
    </div>
    <v-btn fab elevation="0" color="primary" class="float-right mt-1 mr-1" @click="show = false"><v-icon size="32">mdi-close</v-icon></v-btn>
    <div style="clear: both"></div>
  </v-alert>
  <v-btn v-if="show && !$vuetify.breakpoint.mdAndUp" dark fixed top right fab color="#17262a" style="z-index: 997" @click="show = false"><v-icon size="32" color="white">mdi-close</v-icon></v-btn>
  <v-btn v-if="show && !expandText" @click="download(items[index])" dark fixed bottom left fab color="#17262a" style="z-index: 997; left: 16px" :small="!$vuetify.breakpoint.mdAndUp">
    <v-tooltip top color="#edf0f2" style="z-index: 997; left: 16px">
      <template v-slot:activator="{on}">
        <v-icon color="white" v-on="on">mdi-download</v-icon>
      </template>
      <span class="primary--text">{{ $t('download') }}</span>
    </v-tooltip>
  </v-btn>
  <v-badge :key="render" v-if="false && show && showLikes" :value="likes.length > 0" left small overlap color="secondary" class="like-main" style="position: fixed; bottom: 20px; right: 90px; z-index: 999">
    <span slot="badge" class="primary--text">{{ likes.length }}</span>
    <v-tooltip top color="transparent">
      <template v-slot:activator="{on}">
        <v-btn v-on="on" @click="likeInt(widget, items[index])" dark fixed bottom right fab color="#17262a" style="z-index: 998; right: 80px"><v-icon color="white">{{likes.length > 0 ? 'mdi-thumb-up' : 'mdi-thumb-up-outline'}}</v-icon></v-btn>
      </template>
      <span class="primary--text">
        <div v-for="(like, l) in likes" :key="l" class="white px-3 py-1 rounded" style="margin-top: 4px">{{like}}</div>
      </span>
    </v-tooltip>
  </v-badge>
</div>
</template>

<style>
#gallery-container {
  padding-top: 80px;
  margin-bottom: 84px; 
  background-color: rgba(237, 240, 242, 0.95); 
  height: 100%;
  overflow-y: auto;
  position: relative;
}
#gallery-container .video-js {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100% !important;
  height: calc(100% - 164px) !important;
}
.gallery-view .thumbnail {
  max-height: 64px;
  width: 64px;
  object-fit: cover;
  border: 2px solid transparent;
  background-color: rgba(255,255,255,0.06);
  margin: 0px 1px;
  cursor: pointer;
}
.gallery-view .thumbnail.active {
  border: 2px solid white;
}
.gallery-view .like-main .v-badge__wrapper {
  z-index: 1000 !important;
}
#gallery-container .item-text .item-text-title.v-input {
  margin: 10px auto;
}
.mobile #gallery-container .item-text .item-text-title.v-input {
  margin: 0px auto;
}
#gallery-container .item-text .item-text-title {
  line-height: 84px;
  font-size: 48px;
  font-weight: 300;
}
#gallery-container .item-text .item-text-title .v-input__slot {
  background-color: transparent;
}
#gallery-container .item-text .item-text-title input {
  text-align: center;
  line-height: 64px;
  max-height: 64px;
}
.mobile #gallery-container .item-text {
  height: 72px;
}
#gallery-container .item-text {
  position: absolute; 
  bottom: 0px; 
  left: 50%; 
  width: 100%;
  transform: translateX(-50%);
  height: 84px;  
  text-align: center !important;
  background-color: rgba(255,255,255,0.5);
  -webkit-transition: background-color 300ms linear, height 300ms ease-in-out, width 300ms ease-in-out;
  -ms-transition: background-color 300ms linear, height 300ms ease-in-out, width 300ms ease-in-out;
  transition: background-color 300ms linear, height 300ms ease-in-out, width 300ms ease-in-out;
}
#gallery-container .item-text:hover {
  background-color: rgba(255,255,255,0.5);
}
#gallery-container .item-text.item-text-expanded {
  z-index: 10000;
  width: 1100px;
  max-width: 95%;
  height: calc(100% - 80px);
  background-color: rgba(255,255,255,0.95);
}
.mobile #gallery-container .item-text.item-text-expanded {
  width: 100%;
}
#gallery-container .item-text .expand-text-button {
  z-index: 997; 
  right: 16px;
  margin-top: 12px;
  opacity: 0;
}
.mobile #gallery-container .item-text .expand-text-button {
  margin: 16px 8px 0px 0px;
}
#gallery-container .item-text.item-text-expanded .expand-text-button,
#gallery-container .item-text:hover .expand-text-button {
  opacity: 1;
}
#gallery-container .item-text .item-description {
  text-align: left;
  width: 1100px;
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  min-height: calc(100vh - 160px);
}
.item-description-editor {
  height: calc(100vh - 212px);
}
.gallery-view-root {
  padding-left: 40px;
  padding-right: 40px;
}
.mobile .gallery-view-root {
  padding-left: 0px;
  padding-right: 0px;
}
#gallery-container .v-tab.v-tab--active {
  border-top: 1px solid rgb(204, 204, 204);
  border-left: 1px solid rgb(204, 204, 204);
  border-right: 1px solid rgb(204, 204, 204);
}
#gallery-container .v-tab {
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}

.gallery-view .group-inside {
  box-shadow: 1px 1px #aaa, 2px 2px #555;
}
.gallery-view .thumbnail.active .group-inside {
  box-shadow: initial;
}
</style>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
import { Storage } from "aws-amplify"
import videoPlayer from 'vue-videojs7'
import * as api from '@/libs/api.js'
import { getCanvasItemMap } from '@/libs/canvas.js'
import draggable from 'vuedraggable'
import CanvasGalleryItem from '@/components/canvas-items/canvas-gallery-item'
import RootWidget from '@/components/canvas-items/root-widget'
import KeyValueItem from '@/components/widgets/key-value-item'
import CanvasItemThumbnail from '@/components/canvas-items/canvas-thumbnail'
export default {
  name: 'gallery-view',
  components: {
    'video-player': videoPlayer.videoPlayer,
    'gallery-item': CanvasGalleryItem,
    'root-widget': RootWidget,
    'key-value-item': KeyValueItem,
    'canvas-item-thumbnail': CanvasItemThumbnail,
    'draggable': draggable
  },
  props: {
    value: {
      type: Boolean,
      default: () => false
    },
    widget: {
      type: Object,
      default: () => null
    },
    items: {
      type: Array,
      default: () => []
    },
    pos: {
      type: Number,
      default: () => 0
    },
    showLikes: {
      type: Boolean,
      default: () => false
    },
    getLikes: {
      type: Function,
      default: () => null
    },
    like: {
      type: Function,
      default: () => null
    },
    editing: {
      type: Boolean,
      default: () => false
    },
    showDescription: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => {
    return {
      index: 0,
      expandText: false,
      textTab: 0,
      customToolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [ 'link' ],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'color': [] }, { 'background': [] }],
        ['clean']
      ],
      itemUpdateQueue: {},
      itemUpdateInterval: null,
      render: 0,
      kvitems: [{
        key: 'Description',
        type: 'text',
        value: null
      }],
    }
  },
  computed: {
    show: {
      get: function() { return this.value; },
      set: function(value) { this.$emit('input', value); }
    },
    likes() {
      if (!this.showLikes || !this.widget || !this.getLikes)
        return [];

      return this.getLikes(this.widget, this.items[this.index]);
    },
    firstParentGallery() {
      return this.getFirstParentGallery();
    },
  },
  /*mounted() {
    document.addEventListener('scroll', () => {
      console.log('s');
    })
  },*/
  watch: {
    pos(value) {
      this.index = value;
    },
    /*index(value) {
      if (!this.editing && this.expandText && value >= 0 && value < this.items.length) {
        const item = this.items[value];
        if (!item.cfg || !item.cfg.publicText || !item.cfg.publicText.length)
          this.expandText = false;
      }
    },*/
    async show(value) {
      if (!value) {
        this.expandText = false;
        this.textTab = 0;
        await this.stopItemUpdateInterval();
      }
      else {
        if (this.editing)
          await this.startItemUpdateInterval();
        /*if (this.showDescription) {
          this.scrollDescription(true);
        }*/
      }
    },
  },
  methods: {
    prevImage() {
      this.index = this.index - 1 < 0 ?  this.items.length - 1 : this.index - 1;
    },
    nextImage() {
      this.index = this.index + 1 === this.items.length ? 0 : this.index + 1
    },
    getItemSrc(item, thumb) {
      if (!item)
        return null;
      if (item.items && item.items.length)
        return this.getFirstImageSrc(item, thumb);
      if (thumb && item.thumbSrc)
        return item.thumbSrc;
      if (item.src)
        return item.src;
      return null;
    },
    likeInt(widget, item) {
      this.like(widget, item);
      this.render++;
    },
    getVideoOptions(item, index) {
      return {
        autoplay: this.show && index === this.index,
        controls: true,
        sources: [{ type: "video/mp4", src: item.video }],
        controlBar: {
          timeDivider: false,
          durationDisplay: false
        }
      }
    },
    toDataURL(url) {
      return fetch(url, {
        mode: 'cors',
        headers: {
          'Access-Control-Allow-Origin':'*'
        }
      }).then((response) => {
        return response.blob();
      }).then(blob => {
        return URL.createObjectURL(blob);
      });
    },
    async download(item) {
      let response = await Storage.vault.get(item.cfg.path, { expires: 86400, download: true});
      const a = document.createElement("a");
      a.href = URL.createObjectURL(response.Body);
      a.download = item.cfg.path.replace(/^.*[\\/]/, '');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      //console.log(base64.encode(await response.Body.text()));
    },
    getGroupSizeClass(itemCount) {
      if (itemCount >= 24)
        return 'xs12 sm4 md3 lg2 xl1';

      else if (itemCount >= 12)
        return 'xs12 sm6 md4 lg2 xl2';
      
      else if (itemCount >= 6)
        return 'xs12 sm6 md4 lg3 xl2';
      
      else if (itemCount >= 3)
        return 'xs12 sm6 md6 lg4 xl4';

      return 'xs12 sm6';
    },
    async updateItemConfig(widget, functor, addToQueue) {
      if (!this.editing)
        return;

      if (functor)
        functor();

      if (widget.iid) {
        let updateItem = async () => await api.updateItem(widget.cid, widget.iid, { cfg: widget.cfg });

        if (!addToQueue)
          await updateItem();
        else 
          this.itemUpdateQueue[widget.iid] = updateItem;
      }
    },
    async updateKVItem(item, kvItem) {
      if (!this.editing)
        return;

      if (item.iid && kvItem.kvid) {
        let updateItem = async () => await api.updateKeyValueItem(item.cid, item.iid, kvItem.kvid, { k: kvItem.key, v: kvItem.value });
        this.itemUpdateQueue[kvItem.kvid] = updateItem;
      }
    },
    async onUpdateItemInterval() {
      if (!this.itemUpdateQueue)  
        return;
      const queue = this.itemUpdateQueue;

      for (let key in queue) {
        const functor = queue[key];
        if (!functor)
          continue;

        await functor();
      }

      this.itemUpdateQueue = {};
    },
    async startItemUpdateInterval() {
      await this.stopItemUpdateInterval();
      this.itemUpdateInterval = window.setInterval(async () => await this.onUpdateItemInterval(), 1000);
    },
    async stopItemUpdateInterval() {
      if (!this.itemUpdateInterval) 
        return;
      
      clearInterval(this.itemUpdateInterval);
      this.itemUpdateInterval = null;

      await this.onUpdateItemInterval();
      this.itemUpdateQueue = {};
    },
    getFirstImageSrc(item, thumb) {
      let current = item;
      // let's limit the max depth to 100
      for (let i = 0; i < 100; ++i) {
        if (thumb && current.thumbSrc)
          return current.thumbSrc;
        if (current.src)
          return current.src;

        if (current.items && current.items.length > 0)
          current = current.items[0];
      }

      return null;
    },
    getFirstParentGallery() {
      let getParent = (item) => {
        if (!item.parent || !(item.parent in getCanvasItemMap(this.$store.state, item.cid)))
          return null;

        return getCanvasItemMap(this.$store.state, item.cid)[item.parent];
      }
      if (this.index < 0 || this.index >= this.items.length)
        return this.widget;

      let item = this.items[this.index];
      for (let i = 0; i < 50; ++i) {
        item = getParent(item);
        if (item === null)
          return this.widget;

        if (item.type === 'gallery' || item.type === 'list' || item.type === 'map-list')
          return item;
      }

      return this.widget;
    },
    async createKVItem(item, type) {
      if (!item)
        return;

      const cid = item.cid;
      const iid = item.iid;
      const key = null;
      let value = null;
      /*if (type === 'number')
        value = { value: null, unit: null}*/
      if (!item.kv)
        item.kv = [];
      item.kv.push({ key: key, type: type, value: value, cfg: {} });
      item.kv[item.kv.length-1].kvid = await api.createKeyValueItem(cid, iid, key, value, type);
      
    },
    async removeKVItem(item, index) {
      if (!item || !item.kv || index >= item.kv.length || index < 0)
        return;

      const kvid = item.kv[index].kvid;

      item.kv.splice(index, 1);

      await api.removeKeyValueItem(item.cid, item.iid, kvid);
    },
    scrollDescription(forceTop) {
      const element = document.querySelector('.v-window-item--active  #gallery-container');
      const position = (forceTop || !element.scrollTop) ? element.offsetHeight : 0;
      this.$vuetify.goTo(position, { container: '.v-window-item--active  #gallery-container', duration: 500, offset: 0, easing: 'easeInOutCubic' })
    },
    descriptionExpanded() {
      const element = document.querySelector('.v-window-item--active  #gallery-container');
      if (!element)
        return false;
      return element.scrollTop > 0;
    },
    onScroll(item, event) {
      if (!event || !event.deltaY)
        return;

      const showKV = this.showKV(item);

      if (event.path && event.path.length) {
        for (let element of event.path) {
          if (!element.classList)
            continue;
          const classList = Array.from(element.classList || []);
          if (classList.includes('vue-map') || (!showKV && classList.includes('item-text'))) {
            event.preventDefault();
            return;
          }
        }
      }
      
      if (showKV) {
        const element = document.querySelector('.v-window-item--active  #gallery-container');
        element.scrollTop += event.deltaY;
      }
    },
    showKV(item) {
      if (this.editing)
        return true;

      if (!item || !item.kv || !item.kv.length)
        return false;

      for (let kvItem of item.kv) {
        if (!kvItem.value || (kvItem.cfg && kvItem.cfg.private))
          continue;

        return true;
      }
      return false;
    },
    btoa(str) {
      return btoa(str);
    },
    urlEncode(str) {
      return encodeURIComponent(str);
    },
    localPdfSupported() {
      return ('PDF Viewer' in navigator.plugins);
    },
    nullHandler() {}
  }
}
</script>