<template>
  <v-app id="app" :class="$vuetify.breakpoint.mdAndDown ? 'mobile' : 'desktop'">
    <v-app-bar app color="#1a2629" dark class="pl-4" v-if="showNavBar" style="z-index: 12">
      <v-toolbar-title><router-link to="/"><a><img :src="getUrl('sharespot2.png')" style="margin: 8px 23px 0px 0px;" height="32" /></a></router-link></v-toolbar-title>
      <v-combobox v-if="$vuetify.breakpoint.mdAndUp && signedIn" multiple chips clearable hide-details background-color="rgba(0,0,0,0.15)" label="Search..." solo v-model="$store.state.search.items">
        <template v-slot:selection="data">
          <v-chip class="my-1" label v-bind="data.attrs" :input-value="data.selected" color="rgba(255,255,255,0.1)" close @click:close="data.parent.selectItem(data.item)">{{data.item}}</v-chip>
        </template>
        <template v-slot:append>&nbsp;</template>
      </v-combobox>
      <v-spacer v-else></v-spacer>
      <v-menu offset-y v-if="signedIn && user">
        <template v-slot:activator="{ on }">
          <div style="position: relative; cursor: pointer" v-on="on" :key="profileImageRender">
            <v-avatar v-if="user.profileImage" size="48" class="elevation-3 ml-2" style="border: 1px solid rgba(0,0,0,0.2)"><v-img :src="user.profileImage" size="48"></v-img></v-avatar>
            <v-avatar v-else :color="getColor(user.e)" size="48" class="elevation-3 ml-2" style="border: 1px solid rgba(0,0,0,0.2); font-size: 34px; font-weight: 300; color: rgba(0,0,0,0.6);">{{(user.userName || user.e)[0].toUpperCase()}}</v-avatar>
            <v-icon color="white" size="20" style="position: absolute; bottom: -4px; right: -2px; border-radius: 50%; background-color: rgba(26, 38, 41, 0.7); width: 16px; height: 16px">mdi-chevron-down</v-icon>
          </div>
        </template>
        <v-list class="py-0">
          <v-list-item @click="showProfile()" class="pl-0"><v-list-item-avatar class="ma-0"><v-icon color="primary">mdi-account-circle</v-icon></v-list-item-avatar><v-list-item-title>My profile</v-list-item-title></v-list-item>
          <v-list-item @click="showAdministration()" class="pl-0"><v-list-item-avatar class="ma-0"><v-icon color="primary">mdi-cog</v-icon></v-list-item-avatar><v-list-item-title>Administration</v-list-item-title></v-list-item>
          <v-list-item @click="showAdministration(true)" class="pl-0"><v-list-item-avatar class="ma-0"><v-icon color="primary">mdi-account-multiple-plus</v-icon></v-list-item-avatar><v-list-item-title>Invite people to<br />{{(!user.account || user.account === user.e) ? 'your account' : user.account}}</v-list-item-title></v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="signOut()" class="pl-0"><v-list-item-avatar class="ma-0"><v-icon color="red">mdi-logout-variant</v-icon></v-list-item-avatar><v-list-item-title class="red--text">Sign out</v-list-item-title></v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <router-view :key="$route.fullPath" />
    </v-main>
    <add-assets-dialog></add-assets-dialog>
    <gallery-view v-model="viewDialog.show" :items="viewDialog.items" :pos="viewDialog.index" :editing="viewDialog.editing" :showDescription="viewDialog.showDescription" :widget="viewDialog.widget"></gallery-view>
    <confirm-dialog></confirm-dialog>
    <profile-dialog v-model="profileDialog.show"></profile-dialog>
    <admin-dialog v-model="adminDialog.show" :showInvite="adminDialog.showInvite"></admin-dialog>
  </v-app>
</template>
<style>
@import "~vue2-editor/dist/vue2-editor.css";
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import '~quill/dist/quill.bubble.css';
#app .v-dialog {
  border-radius: initial;
}
html, #app {
  background-color: #edf0f2;
}
.tox-button {
  background-color: #344955 !important;
  color: white !important;
  border: none !important;
  font-family: "Work Sans", sans-serif !important;
  font-weight: normal !important;
  text-transform: uppercase !important;
}
.tox-button.tox-button--secondary {
  background-color: #b4c1cc !important;
  color: #344955 !important;
}
.tox-button--icon {
  border-radius: 50% !important;
}
.show-on-hover-ex,
.show-on-hover {
  opacity: 0;
}
.hoverable-ex:hover .show-on-hover-ex,
.hoverable:hover > .show-on-hover-ex,
.hoverable:hover .show-on-hover,
.v-expansion-panel-header:hover .show-on-hover,
.v-card:hover .show-on-hover {
  opacity: 1;
}
.vtop {
  position: absolute;
  top: 0px;
}
.vbottom {
  position: absolute;
  bottom: 0px;
}
.vcenter {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.hcenter {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.vcenter.hcenter {
  transform: translate(-50%, -50%);
}
.text-h2 {
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
</style>

<style lang="scss">
.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #DDDBDD;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#fff, 0.2) 20%, rgba(#fff, 0.5) 60%, rgba(#fff, 0));
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>

<script>
/*eslint-disable no-unused-vars*/
import { w3cwebsocket as W3CWebSocket } from "websocket"
import { Auth, Signer } from "aws-amplify";
import AddAssetsDialog from "@/components/dialogs/add-assets";
import GalleryView from '@/components/dialogs/gallery-view'
import ConfirmDialog from "@/components/dialogs/confirm-dialog";
import ProfileDialog from "@/components/dialogs/profile-dialog";
import AdminDialog from "@/components/dialogs/admin-dialog";
import { EventBus } from '@/libs/eventBus.js'
import { User } from '@/libs/user.js'
import config from './../config/aws'
import * as tools from '@/libs/tools.js'

export default {
  name: 'App',
  components: {
    'add-assets-dialog': AddAssetsDialog,
    'gallery-view': GalleryView,
    'confirm-dialog': ConfirmDialog,
    'profile-dialog': ProfileDialog,
    'admin-dialog': AdminDialog
  },
  data: () => ({
    viewDialog: {
      show: false,
      items: [],
      widget: null,
      index: 0,
      showDescription: false,
      editing: false,
    },
    profileDialog: { show: false },
    adminDialog: { show: false, showInvite: false },
    user: null,
    wsClient: null,
    search: null,
    profileImageRender: 0
  }),
  async mounted() {
    this.startViewDialogHandler();
    this.startUpdateProfileImageHandler();
    let userLib = new User(this.$store.state);
    try {
      this.user = await userLib.getUser();
      await this.setupWebSocket();
    }
    catch (err) {
      console.info(err);
    }
  },
  computed: {
    showNavBar() {
      const path = location.pathname.split('/')[1];
      if (/*path === '~' || */path === 'collect')
        return false;

      return true;
    },
    signedIn() {
      return (this.$store.state.user && this.$store.state.user.uid && this.$store.state.user.uid.length > 0);
    }
  },
  methods: {
    getUrl(path) {
      return window.location.origin + '/' + path
    },
    async signOut() {
      await Auth.signOut();
      window.location.pathname = "signin";
    },
    startViewDialogHandler() {
      if (this.handlersInitialized)
        return;
      EventBus.$on('showViewDialog', async (params) => {
        if (!params || !params.items || params.index === null)
          return;

        let showDescription = false;
        if (params.showDescription)
          showDescription = params.showDescription;
        let widget = null;
        if (params.widget)
          widget = params.widget;
        let editing = false;
        if (params.editing)
          editing = params.editing;
          
        this.showViewDialog(params.items, params.index, showDescription, widget, editing);
      });
    },
    showViewDialog(items, index, showDescription, widget, editing) {
      this.viewDialog = {
        show: true,
        items: items,
        index: index,
        editing: editing
      }
      if (typeof showDescription !== 'undefined' && showDescription !== null)
        this.viewDialog.showDescription = showDescription;
      if (typeof widget !== 'undefined' && widget !== null)
        this.viewDialog.widget = widget;
    },
    async setupWebSocket() {
      /*const credentials = await Auth.currentCredentials();

      const signedUrl = Signer.signUrl(config.WS.endpoint, {
        access_key: credentials.accessKeyId,
        secret_key: credentials.secretAccessKey,
        session_token: credentials.sessionToken,
      });*/

      //this.wsClient = new W3CWebSocket(config.WS.endpoint/*signedUrl*/)

      /*this.wsClient.onerror = function () {
        console.log("[client]: Connection Error")
      }

      this.wsClient.onopen = function () {
        console.log("[client]: WebSocket Client Connected")
      }

      this.wsClient.onclose = function () {
        console.log("[client]: Client Closed")
      }

      this.wsClient.onmessage = function (e) {
        if (typeof e.data === "string") {
          console.log("Received: '" + e.data + "'")
        }
      }*/
    },
    getColor(str) {
      return tools.seededRandomColor(str);
    },
    showProfile() {
      this.profileDialog.show = true;
    },
    showAdministration(showInvite = false) {
      this.adminDialog.show = true;
      this.adminDialog.showInvite = showInvite;
    },
    startUpdateProfileImageHandler() {
       if (this.handlersInitialized)
        return;
      EventBus.$on('updateProfileImage', async () => {
        try {
          /*let userLib = new User(this.$store.state);
          this.user = await userLib.getUser();*/
          this.user.profileImage = this.$store.user.profileImage;
        }
        catch (err) {
          console.log(err);
        }
        this.profileImageRender++;
      });
    }

  }
};
</script>
