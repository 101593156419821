<template>
  <v-dialog v-model="show" @keydown.esc="show = false" scrollable persistent content-class="invite-dialog" width="450">
    <v-card color="#edf0f2">
      <v-card-title class="primary white--text pa-3">Invite people to {{account || 'your account'}}<v-spacer></v-spacer><v-btn icon color="white" @click="show = false"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
      <v-card-text v-if="inviteSent" class="pt-3">
        <p>The invite has been successfully sent.</p>
      </v-card-text>
      <v-card-text v-else-if="error" class="pt-3">
        <p>We have encountered an error when sending the invite, please try again later.</p>
      </v-card-text>
      <v-card-text v-else class="pt-3" style="background-color: white">
        <v-text-field class="mt-4 mb-2 py-0 pr-0" outlined hide-details label="Enter e-mail" v-model="email" @keydown.enter="validEmail() ? sendInvite() : (() => {})()" :loading="loading" autofocus>
          <template v-slot:append>
            <v-fade-transition leave-absolute>
              <div v-if="validEmail()">
                <div style="margin-right: -6px; margin-top: 0px; display: inline-flex"><v-icon left color="success">mdi-check</v-icon></div>
              </div>
            </v-fade-transition>
          </template>
        </v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions style="background-color: #edf0f2">
        <v-spacer></v-spacer>
        <template v-if="!inviteSent && !error">
          <v-btn text color="primary" @click="show = false">{{ $t('cancel') }}</v-btn>
          <v-btn class="elevation-1 rounded-pill secondary primary--text" @click="sendInvite()">Send invite</v-btn>
        </template>
        <template v-else>
          <v-btn class="elevation-1 rounded-pill secondary primary--text" @click="show = false">Close</v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.fill-height {
  height: 100%;
}
</style>
<script>
import * as api from '@/libs/api.js'

export default {
  name: 'invite-dialog',
  props: {
    value: {
      type: Boolean,
      default: () => false
    },
    account: {
      type: String,
      default: () => null
    },
  },
  data: () => {
    return {
      email: null,
      loading: false,
      inviteSent: false,
      error: null
    }
  },
  computed: {
    show: {
      get: function() { return this.value; },
      set: function(value) { this.$emit('input', value); }
    },
  },
  watch: {
    show() {
      this.email = null;
      this.loading = false;
      this.inviteSent = false;
      this.error = null;
    }
  },
  methods: {
    validEmail() {
      if (!this.email || !this.email.length)
        return false;
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(this.email);
    },
    async sendInvite() {
      if (!this.validEmail())
        return;

      this.loading = true;
      try { 
        await api.inviteToMyAccount(this.email);
        if (!this.$store.state.user.teamMembers)
          this.$store.state.user.teamMembers = [];

        this.$store.state.user.teamMembers.push({
          e: this.email,
          userName: '',
          precreated: true
        });

        this.inviteSent = true;
      }
      catch {
        this.error = true;
      }
      finally { 
        this.loading = false;
      }
    }
  }
}
</script>

<i18n>
{
  "en": {
  }
}
</i18n>