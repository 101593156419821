import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      name: null,
      e: null,
      uid: null,
      userName: null,
      account: null,
      accountType: null,
      profileImage: null,
      userStats: null,
      accountStats: null,
      teamMembers: null,
      statuses: [],
      userRoles: [],
      projectFlow: null,
      templates: [],
      defaultTemplate: null,
    },
    canvas: {
      cid: null,
      cfg: {path: null, likes: false, comments: false},
      checkedCount: 0,
      items: {},
      activeRoot: null
    },
    ac: {
      canvases: {},
      updated: null
    },
    canvases: [],
    dialogs: {
      createCanvas: { show: false, path: null },
      addAssets: { show: false, path: null, targetWidget: null, root: null, files: null },
      confirm: { show: false, title: null, text: null, type: null, funct: null },
      shareWith: { show: false, cid: null, iid: null, public: false },
    },
    panels: {
      comments: { show: false, iid: null }
    },
    search: {
      items: [],
      selection: []
    }
  },
  getters: {
    isDialogShown: (state) => (dialog) => {
      if (!(dialog in state.dialogs))
        return false;
      return state.dialogs[dialog].show;
    }
  },
  mutations: {
    canvas(state, canvas) { state.canvas = canvas; },
    showDialog(state, dialog) { if (!(dialog in state.dialogs)) return; state.dialogs[dialog].show = true; },
    hideDialog(state, dialog) { if (!(dialog in state.dialogs)) return; state.dialogs[dialog].show = false; },
  },
  actions: {
    setCanvas: ({commit}, canvas) => { commit('canvas', canvas); },
    showDialog: ({commit}, dialog) => { commit('showDialog', dialog); },
    hideDialog: ({commit}, dialog) => { commit('hideDialog', dialog); },
  },
  modules: {
  }
})
