<template>
<div :style="mini ? 'cursor: pointer' : ''" :class="mindmap ? 'mindmap' : ''">
<v-navigation-drawer :mobile-breakpoint="0" fixed left :mini-variant.sync="mini" ref="drawer" id="navigation-tools" class="pt-0 px-0 navigation-tools" :width="mindmap ? '100%' : navigation.width">
  <v-list-item class="px-2">
    <v-list-item-avatar><v-icon :color="mindmap ? 'primary' : 'white'" class="ml-0">mdi-file-tree</v-icon></v-list-item-avatar>
    <v-list-item-title :class="mindmap ? 'primary--text' : 'white--text'">Navigation</v-list-item-title>
    <template v-if="mindmap">
      <v-btn v-if="anyAssignedToMe" class="elevation-1 rounded-pill mx-1" :class="orgChartView === 'assignedToMe' ? 'secondary primary--text' : 'white'" @click="orgChartView = orgChartView === 'assignedToMe' ? null : 'assignedToMe'"><v-icon color="primary" left>mdi-progress-clock</v-icon> Assigned to me</v-btn>
      <v-btn v-if="anyAssignees" class="elevation-1 mx-1 white" :class="orgChartView === 'assignees' ? 'secondary' : 'white'" icon @click="orgChartView = orgChartView === 'assignees' ? null : 'assignees'"><v-icon color="primary">mdi-progress-clock</v-icon></v-btn>
      <v-btn v-if="anyShares" class="elevation-1 mx-1 white" :class="orgChartView === 'shares' ? 'secondary' : 'white'" icon @click="orgChartView = orgChartView === 'shares' ? null : 'shares'"><v-icon color="primary">mdi-account-multiple</v-icon></v-btn>
      <v-btn v-if="anyComments" class="elevation-1 mx-1 white" :class="orgChartView === 'comments' ? 'secondary' : 'white'" icon @click="orgChartView = orgChartView === 'comments' ? null : 'comments'"><v-icon color="primary">mdi-comment-multiple-outline</v-icon></v-btn>
      <v-btn class="elevation-1 mx-1 white" icon @click="fitOrgChart()"><v-icon color="primary">mdi-fit-to-page-outline</v-icon></v-btn>
      <v-btn class="elevation-1 mx-1 white" icon @click="collapseOrgChart()"><v-icon color="primary">mdi-collapse-all-outline</v-icon></v-btn>
      <v-btn class="elevation-1 mx-1 white" icon @click="expandOrgChart()"><v-icon color="primary">mdi-expand-all-outline</v-icon></v-btn>
      <v-btn class="elevation-1 mx-1 white" icon @click="swapOrgChart()"><v-icon color="primary">mdi-repeat-variant</v-icon></v-btn>
    </template>
    <v-btn fab small @click.stop="mindmap ? mindmap = false : (mini = !mini)" class="ml-6" color="primary" elevation="0"><v-icon color="white">mdi-chevron-left</v-icon></v-btn>
  </v-list-item>
  <div v-if="!mini && !mindmap">
    <ul class="pl-0">
      <tree-item class="item" :name="canvas.nm" :editing="editing" :checkbox="false" v-model="canvas.root" root></tree-item>
    </ul>
    <v-divider class="my-3"></v-divider>
    <ul class="pl-0" v-if="canvasesTree">
      <tree-item class="item" name="Other projects" :editing="editing" disableSort :checkbox="false" v-model="canvasesTree"></tree-item>
    </ul>
  </div>
  <div v-else-if="!mini && mindmap">
    <org-chart v-if="orgData" :data="orgData" :foundItems="foundItems" :expandWhenEmpty="true" :editing="editing" :view="orgChartView"></org-chart>
  </div>
  <template v-slot:append>
    <div class="bottom-action text-center" v-if="!mini">
      <v-btn rounded color="d-inline-block mx-auto my-2 primary darken-1" @click="mindmap = !mindmap">{{mindmap ? 'Back to project' : 'Show mindmap'}}</v-btn>
    </div>
  </template>
</v-navigation-drawer>
</div>
</template>

<style>
.navigation-tools {
  top: 64px !important;
  background-color: #344955 !important;
  transition: background-color 200ms ease-in-out, width 200ms ease-in-out !important;
  z-index: 10 !important;
}
.mindmap .navigation-tools {
  top: 64px !important;
  background-color: #edf0f2 !important;
}
.mobile .navigation-tools {
  top: 56px !important;
}
.bottom-action {
  width: 100%;
  padding-bottom: 64px;
}
.mobile .bottom-action {
  padding-bottom: 56px;
}
.navigation-tools-panel {
  background-color: transparent !important;
}
.navigation-tools-panel .v-expansion-panel-content {
  overflow-y: auto;
  overflow-x: hidden;
}
.navigation-tools .v-treeview-node__root {
  margin-top: 1px;
  margin-bottom: 1px;
  min-height: 32px;
}
.navigation-container {
  background-color: #344955;
}
.navigation-tools .v-navigation-drawer__border:hover {
  background-color: rgb(26, 38, 41) !important;
}
.navigation-tools .v-divider {
    border-color: rgba(255,255,255,0.12) !important; 
}
</style>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import { EventBus } from '@/libs/eventBus.js'
import { CanvasList, getCanvasItemMap } from '@/libs/canvas.js'
import OrgChart from '@/components/widgets/org-chart'
import TreeItem from '@/components/widgets/tree-item'

export default {
  name: 'navigation-panel',
  props: {
    value: {
      type: Object,
      default: () => null
    },
    editing: {
      type: Boolean,
      default: () => false
    },
  },
  components: {
    'org-chart': OrgChart,
    'tree-item': TreeItem
  },
  data() {
    return {
      mini: false,
      selectedItems: [],
      mindmap: false,
      orgData: [],
      foundItems: [],
      navigation: {
        width: 260,
        borderSize: 5
      },
      orgChartView: null,
      anyComments: false,
      anyShares: false,
      anyAssignees: false,
      anyAssignedToMe: false,
      canvasesTree: {}
    }
  },
  async mounted() {
    if (!this.$vuetify.breakpoint.mobile) {
      await this.updateCanvasTree();
    }

    this.$nextTick(()=>{
      this.setBorderWidth();
      this.setEvents();
      if (this.$vuetify.breakpoint.mobile) {
        this.mini = true;
        this.$emit('minimized', true);
      }
    });
  },
  watch: {
    mini(val) {
      this.$emit('minimized', val);
      if (val) {
        this.mindmap = false;
      }
      else {
        this.navigation.width = 260;
        EventBus.$emit('navigationResized', this.navigation.width);
        this.$nextTick(()=>{
          this.setBorderWidth();
          this.setEvents();
        });
      }
    },
    orgChartView(value) {
      this.updateOrgData();
    },
    canvas: {
      // TODO: Ditch the deep watcher (poor performance on large canvases!) in favor of sending events on certain manipulations with items
      deep: true,
      handler() {
        if (this.mindmap) {
          this.updateOrgData();
          /*this.$nextTick(() => {
            EventBus.$emit('orgChartExpand');
          });*/
        }
      }
    },
    search(val) {
      if (!val)
        return;

      if (this.mindmap)
        this.updateOrgData();

      else if (val.length)
        this.mindmap = true;
    },
    mindmap(val) {
      if (val) {
        window.setTimeout(()=>{
          this.updateOrgData();
          this.$nextTick(() => {
            EventBus.$emit('[orgChartExpand]');
          });
        }, 200);
      }
      else {
        this.orgData = [];
        this.navigation.width = 260;
        EventBus.$emit('navigationResized', this.navigation.width);
      }
    }
  },
  computed: {
    items() {
      return [{
        projectroot: true,
        type: 'root',
        cfg: {
          name: this.canvas.nm
        },
        iid: this.canvas.root.iid,
        items: this.canvas.root.items
      }];
    },
    search() {
      return this.$store.state.search.items;
    },
    acUpdated() { return this.$store.state.ac.updated },
    activeItem: {
      get() {return [this.$store.state.canvas.activeRoot]},
      set() {}
    },
    canvas: {
      get() { 
        return this.value 
      },
      set: function(value) { 
        this.$emit('input', value); 
      }
    }
  },
  methods: {
    async updateCanvasTree() {
      const canvasList = new CanvasList(this.$store.state);
      const canvases = Object.values(await canvasList.listCanvases() || {}).filter(c => c.canvas.cid !== this.canvas.cid);
      if (canvases && canvases.length) {
        this.canvasesTree = { items: [] };
        for (let canvas of canvases) {
          if (!canvas || !canvas.canvas)
            continue;

          let loader = async () => {
            await canvasList.getCanvas(canvas.canvas.cid, true);
            await this.updateCanvasTree();
          };

          let iid = null;
          if (canvas.canvas) {
            if (typeof canvas.canvas.root === 'string')
              iid = canvas.canvas.root;
            else if (canvas.canvas.root && canvas.canvas.root.iid)
              iid = canvas.canvas.root.iid;
          }
          this.canvasesTree.items.push({
            type: 'canvas',
            cid: canvas.canvas.cid,
            iid: iid,
            checked: false,
            cfg: { name: canvas.canvas.nm || 'Untitled' },
            items: canvas.canvas && canvas.canvas.root && canvas.canvas.root.items ? canvas.canvas.root.items : [],
            needsUpdate: canvas.needsUpdate,
            loader: canvas.needsUpdate ? loader : null
          });
        }
      }
      else 
        this.canvasesTree = null;
    },
    searchedItem(item) {
      if (!this.search || !this.search.length)
        return false;

      if (!item)
        return false;

      for (let searchTerm of this.search) {
        const lcSearch = searchTerm.toLowerCase()

        if ((item.cfg.name || 'untitled').toLowerCase().includes(lcSearch))
          return true;
      }
      
      return false;
    },
    updateOrgData() {
      if (!this.canvas || !this.canvas.root)
        return [];

      this.anyComments = false;
      this.anyShares = false;
      this.anyAssignees = false;
      this.anyAssignedToMe = false;

      const containers = ['gallery', 'list', 'file-table', 'map-list', 'map', 'root', 'casting-profile'];
      let acs = (!this.canvas || !(this.canvas.cid in this.$store.state.ac.canvases)) ? [] : this.$store.state.ac.canvases[this.canvas.cid].list;
      let data = [{name: this.canvas.nm || 'Root', cid: this.canvas.cid, id: this.canvas.root.iid, shares: this.getShares(this.canvas.root.iid), acs: acs, assignedToMe: !this.canvas.root.assignee ? false : this.canvas.root.assignee === this.$store.state.user.uid, item: this.canvas.root, type: 'global-root', class: 'canvas-node'}];
      let parents = [this.canvas.root];
      let foundIds = [];
      
      const traverseFind = (item) => {
        if (!item.items || !item.items.length)
          return;
        for (let i = item.items.length -1; i >= 0; i--) {
          let assignedToMe = !item.items[i].assignee ? false : (item.items[i].assignee === this.$store.state.user.uid || item.items[i].assignee === this.$store.state.user.e);
          data.push({
            name: item.items[i].cfg ? (item.items[i].cfg.name || 'Untitled') : 'Untitled',
            imageUrl: item.items[i].type === 'video' ? item.items[i].src : (item.items[i].thumbSrc || null),
            type: item.items[i].type,
            id: item.items[i].iid,
            cid: item.items[i].cid,
            parent: item.items[i].parent || this.canvas.root.iid,
            parentId: item.items[i].parent || this.canvas.root.iid,
            item: item.items[i],
            shares: this.getShares(item.items[i].iid),
            assignedToMe: assignedToMe,
            acs: acs,
            showCheck: true
          });

          if (acs.length)
            this.anyShares = true;
          if (item.items[i].assignee)
            this.anyAssignees = true;
          if (assignedToMe)
            this.anyAssignedToMe = true;
          if (item.items[i].comments && item.items[i].comments.length)
            this.anyComments = true;

          // is it a container type? -> let's add to parents, in order to include add nodes later
          if (containers.includes(item.items[i].type))
            parents.push(item.items[i]);

          if (this.searchedItem(item.items[i]) || (this.orgChartView === 'assignedToMe' && assignedToMe))
            foundIds.push(item.items[i].iid);

          if (item.items[i].items && item.items[i].items.length)
            traverseFind(item.items[i]);
        }
      }
      traverseFind(this.canvas.root);

      if (this.editing) {
        for (let parent of parents) {
          let addSection = false;
          if (parent.items && parent.items.length)
            addSection = parent.items.findIndex((item) => !containers.includes(item.type)) < 0;
          data.push({
            name: addSection ? 'Add section' : 'Add assets',
            type: 'add',
            class: 'add-transparent-node',
            action: addSection ? 'add-section' : 'add-assets',
            id: parent.iid + '-add',
            parentId: parent.iid
          });
        }
      }
      this.foundItems = foundIds;
      this.orgData = data;
    }, 
    navigateTo(item) {
      EventBus.$emit('navigateTo', { item: item });
    },
    view(item) {
      if (!item.parent)
        return;

      let parent = getCanvasItemMap(this.$store.state, item.cid)[item.parent];
      let index = 0
      for (let child of parent.items) {
        if (child.iid === item.iid)
          break;
        index++;
      }
      this.navigateTo(parent);
      EventBus.$emit('showViewDialog', { items: parent.items, index: index, showDescription: true, widget: parent, editing: true });
    },
    getShareCount(iid) {
      return this.getShares(iid).length;
    },
    getShares(iid) {
      if (!this.canvas || !(this.canvas.cid in this.$store.state.ac.canvases))
        return [];

      let list = this.$store.state.ac.canvases[this.canvas.cid].list;
      return list.filter((ac) => ac.iid == iid);
    },
    swapOrgChart() {
      EventBus.$emit('orgChartSwap');
    },
    expandOrgChart() {
      EventBus.$emit('orgChartExpand');
    },
    collapseOrgChart() {
      EventBus.$emit('orgChartCollapse');
    },
    fitOrgChart() {
      EventBus.$emit('orgChartFit');
    },
    setBorderWidth() {
      let i = this.$refs.drawer.$el.querySelector(
        ".v-navigation-drawer__border"
      );
      i.style.width = this.navigation.borderSize + "px";
      i.style.cursor = "ew-resize";
      i.style.backgroundColor = "#4a6572;";
    },
    setEvents() {
      const minSize = this.navigation.borderSize;
      const el = this.$refs.drawer.$el;
      const drawerBorder = el.querySelector(".v-navigation-drawer__border");
      const vm = this;
      const direction = el.classList.contains("v-navigation-drawer--right")
        ? "right"
        : "left";

      function resize(e) {
        document.body.style.cursor = "ew-resize";
        let f =
          direction === "right"
            ? document.body.scrollWidth - e.clientX
            : e.clientX;
        el.style.width = f + "px";
      }

      drawerBorder.addEventListener(
        "mousedown",
        (e) => {
          if (e.offsetX < minSize) {
            el.style.transition = "initial";
            document.addEventListener("mousemove", resize, false);
          }
        },
        false
      );

      document.addEventListener(
        "mouseup",
        () => {
          el.style.transition = "";
          this.navigation.width = el.style.width;
          document.body.style.cursor = "";
          document.removeEventListener("mousemove", resize, false);
          /*localStorage.setItem("navWidth-" + this.canvas.cid, parseInt(this.navigation.width));*/
          EventBus.$emit('navigationResized', parseInt(this.navigation.width));
        },
        false
      );
    }
  }
}
</script>