import Vue from 'vue'
import VueRouter from 'vue-router'
import SigninPage from '@/components/pages/signin.vue'
import FoldersPage from '@/components/pages/folders.vue'
import CanvasPage from '@/components/pages/canvas.vue'
import SharePage from '@/components/pages/share.vue'
import ShareAssetsPage from '@/components/pages/share-assets.vue'
import CollectAssetsPage from '@/components/pages/collect-assets.vue'
import CollectFlowPage from '@/components/pages/collect-flow.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'home', component: FoldersPage },
  { path: '/signin', name: 'signin', component: SigninPage },
  { path: '/signin/:redir', name: 'signin-redirect', component: SigninPage, props: true },
  { path: '/files', name: 'folders', component: FoldersPage },
  { path: '/files/:id', name: 'subfolders', component: FoldersPage, props: true },
  { path: '/canvas/:id', name: 'canvas', component: CanvasPage, props: true },
  { path: '/canvas/:id/:iid', name: 'canvas-section', component: CanvasPage, props: true },
  { path: '/snapshot/:id/:iid/:sid', name: 'snapshot-section', component: CanvasPage, props: true },
  { path: '/~/:id', name: 'share', component: SharePage, props: true },
  { path: '/~/:id/:iid', name: 'share-section', component: SharePage, props: true },
  { path: '/share', name: 'share-assets', component: ShareAssetsPage },
  { path: '/collect', name: 'collect-assets', component: CollectAssetsPage },
  { path: '/cf/:id', name: 'collect-flow', component: CollectFlowPage, props: true },
  { path: '/cf/:id/:iid', name: 'collect-flow-section', component: CollectFlowPage, props: true },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
