<template>
<div style="overflow: hidden" class="orgChartContainer">
  <div ref="svgElementContainer"></div> 
</div>
</template>

<script>
/* eslint-disable no-unused-vars */
import * as d3 from 'd3';
import { OrgChart } from 'd3-org-chart';
import { EventBus } from '@/libs/eventBus.js'
import * as tools from '@/libs/tools.js'

let selected = {};

export default {
  name: 'org-chart',
  props: {
    data: {
      type: Array,
      default: () => null
    },
    foundItems: {
      type: Array,
      default: () => []
    },
    expandWhenEmpty: {
      type: Boolean,
      default: () => false
    },
    editing: {
      type: Boolean,
      default: () => false
    },
    view: {
      type: String,
      default: () => null
    },
  },
  data() {
    return {
      chartReference: null,
      swap: 0
    };
  },
  watch: {
    data(value) {
      this.renderChart(value, this.view);
    },
    foundItems(items) {
      if (!items)
        return;

      if (items.length) {
        for (let id of items) {
          this.chartReference.setHighlighted(id);
          this.chartReference.setExpanded(id).render();
        }
        this.chartReference.fit().render();
      }
      else if (this.expandWhenEmpty)
        this.chartReference.expandAll().fit({animate: false});
    },
    view(value) {
      this.renderChart(this.data, value);
    },
  },
  mounted() {
    this.renderChart(this.data, this.view);
    EventBus.$on('orgChartSwap', () => { this.chartReference.layout(["left","top"][this.swap++%2]).render().fit() })
    EventBus.$on('orgChartExpand', () => { this.chartReference.expandAll().fit({animate: false}) })
    EventBus.$on('orgChartCollapse', () => { this.chartReference.collapseAll().expandLevel(2).render().fit() })
    EventBus.$on('orgChartFit', () => { this.chartReference.fit() })
    EventBus.$on('orgChartZoomOut', () => { this.chartReference.zoomOut() })
    EventBus.$on('orgChartZoomIn', () => { this.chartReference.zoomIn() })
    EventBus.$on('orgChartExpandItem', (id) => { this.chartReference.setExpanded(id).render() })
    EventBus.$on('orgChartUpdateSelection', (data) => {
      selected = {};
      if (data && data.length) {
        for (let item of data) {
          if (!item.id && !item.cid && !item.iid)
            continue;

          selected[item.id || item.cid || item.iid] = item;
        }
      }
      EventBus.$emit('orgChartSelectionUpdated', data);
      this.chartReference.render();
    });
  },
  methods: {
    renderChart(data, view) {
      if (!this.chartReference)
        this.chartReference = new OrgChart();

      this.chartReference
        .container(this.$refs.svgElementContainer) // node or css selector
        .data(data)
        .nodeWidth((d) => (view === 'assignees') || (view === 'assignedToMe') ? 300 : 250)
        .initialZoom(1.5)
        .nodeHeight((d) => d.data && d.data.imageUrl ? 300 : 50)
        .childrenMargin((d) => 40)
        .compactMarginBetween((d) => 15)
        .compactMarginPair((d) => 80)
        .buttonContent(({ node, state }) => {
          return `<div class="rounded-xl node-expand ${node.data.type === 'canvas' ? 'elevation-1 canvas-expand' : ' elevation-2'} expand-botton-${node.data.id}">
            <div role="progressbar" aria-valuemin="0" aria-valuemax="100" class="expand-progress v-progress-circular v-progress-circular--visible v-progress-circular--indeterminate primary--text" style="height: 16px; width: 16px;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="22.06896551724138 22.06896551724138 44.13793103448276 44.13793103448276" style="transform: rotate(0deg);"><circle fill="transparent" cx="44.13793103448276" cy="44.13793103448276" r="20" stroke-width="4.137931034482759" stroke-dasharray="125.664" stroke-dashoffset="125.66370614359172px" class="v-progress-circular__overlay"></circle></svg><div class="v-progress-circular__info"></div></div>
            ${node.children
              ? `<i aria-hidden="true" class="v-icon expand-chevron notranslate mdi mdi-chevron-${state.layout === 'top' ? 'up' : 'left'}" style="font-size: 16px"></i>`
              : `<i aria-hidden="true" class="v-icon expand-chevron notranslate mdi mdi-chevron-${state.layout === 'top' ? 'down' : 'right'}"" style="font-size: 16px"></i>`
          }` +
            //( node.data._directSubordinates - 1 > 0 ? `<span style="line-height: 16px">${node.data._directSubordinates - 1}</span>` : ``) +
          `</div>`;
        })
        .nodeContent(function (d, i, arr, state) {
          let getAssignee = (shares, owner) => {
            if (!shares || !shares.length || !owner)
              return null;
            let index = shares.findIndex(share => (share.uid === owner || share.e === owner));
            if (index < 0)
              return null;

            return shares[index];
          };
          if (d.data.loading)
            return '';
          let content = `
          <div class="v-card v-card--link v-sheet theme--light elevation-1 rounded-xl node ${d.data.class || ''}" style="padding-top:0px;background-color:none;margin-left:1px;height:${d.height}px;overflow:hidden">
            <div style="padding-top:0px;">`;
          if (d.data.loading)
            content += `<div role="progressbar" aria-valuemin="0" aria-valuemax="100" class="v-progress-linear v-progress-linear--rounded v-progress-linear--visible theme--light" style="height: 10px; width: calc(100% - 40px); margin: 20px auto 0px auto"><div class="v-progress-linear__background secondary" style="opacity: 0.3; left: 0%; width: 100%;"></div><div class="v-progress-linear__buffer"></div><div class="v-progress-linear__indeterminate v-progress-linear__indeterminate--active"><div class="v-progress-linear__indeterminate long secondary"></div><div class="v-progress-linear__indeterminate short secondary"></div></div></div>`;
          else {
            content += (d.data.imageUrl ? `<img src=" ${d.data.imageUrl}" style="width:${d.width - 1}px;height:${d.width - 2}px; object-fit: cover;" />` : ``)+
                `<div class="v-card__actions pl-7 pr-2 ${d.data.sub ? 'py-0' : 'py-2'} text-h6 text-left" style="overflow: hidden; height: 45px; font-weight: 300; position: relative;">
                  <div style=" width: 100%; height: 100%;">`
                    + (!d.data.showCheck ? `` : 
                    `<div style="position: absolute; left: 0px; height: 100%; width: 32px;" class="node-check-click-area ${d.data.sub ? 'mt-2' : ''}">
                      <i aria-hidden="true" class="v-icon mr-1 ml-2 notranslate mdi ${d.data.id in selected ? 'mdi-check-circle' : 'mdi-checkbox-blank-circle-outline'} ${d.data.type === 'global-root' ? 'white' : 'primary'}--text mb-0 node-check" style="font-size: 15px;"></i>
                    </div>`) +
                    `<span>${d.data.name}</span>`;
            if (d.data.sub)
              content += `<div style="font-size: 9px; line-height: 9px;">${d.data.sub}</div>`;
            if (view === 'comments' && d.data.item && d.data.item.comments && d.data.item.comments.length)
              content += `<button type="button" class="float-right show-comments mr-2 v-btn v-btn--icon v-btn--round theme--light v-size--default"><span class="v-btn__content"><i aria-hidden="true" class="v-icon show-comments notranslate mdi primary--text mdi-comment"></i></span><span class="primary--text show-comments">${d.data.item.comments.length}</span></button>`;

            else if (view === 'shares' && d.data.shares && d.data.shares.length)
              content += `<button type="button" class="float-right mr-2 v-btn v-btn--icon v-btn--round theme--light v-size--default"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi primary--text mdi-account-multiple"></i></span><span class="primary--text">${d.data.shares.length}</span></button>`;

            else if ((view === 'assignees' && d.data.item && d.data.item.assignee) || (view === 'assignedToMe' && d.data.assignedToMe)) {
              let assignee = getAssignee(d.data.acs, d.data.item.assignee);
              if (assignee && assignee.profileImage)
                content += `<div class="v-avatar float-right elevation-1" style="height: 32px; min-width: 32px; width: 32px; border: 1px solid rgba(0, 0, 0, 0.2); cursor: pointer;"><div class="v-image v-responsive theme--light" size="48"><div class="v-responsive__sizer" style="padding-bottom: 100%;"></div><div class="v-image__image v-image__image--cover" style="background-image: url('${assignee.profileImage}'); background-position: center center;"></div><div class="v-responsive__content" style="width: 128px;"></div></div></div>`
              else if (assignee)
                content += `<div class="v-avatar float-right elevation-1 mr-1" style="height: 32px; min-width: 32px; width: 32px; background-color: ${tools.seededRandomColor(assignee.e)}; border: 1px solid rgba(0, 0, 0, 0.1);">${(assignee.name || assignee.e)[0].toUpperCase()}</div>`;

              if (d.data.item && d.data.item.status) {
                content += `<span class="mx-1 mt-1 elevation-0 float-right v-chip v-chip--clickable theme--light v-size--small" style="background-color: rgba(0,0,0,0.1); font-weight: 400"><span class="v-chip__content">${d.data.item.status}</span></span>`;
              }
            }

            if (d.data.type !== 'global-root') {
              if (d.data.type === 'add')
                content += d.data.addMore ? `<button type="button" class="float-right v-btn v-btn--icon v-btn--round theme--dark add-more-button v-size--default"><span class="v-btn__content add-more-button"><i aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-down"></i></span></button><hr role="separator" aria-orientation="vertical" style="margin-top: 3px" class="add-more-divider mr-1 float-right v-divider v-divider--vertical theme--dark">` : ``;
              else
                content += `<button type="button" class="go-button float-right v-btn v-btn--icon v-btn--round theme--light v-size--default"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mdi ${d.data.imageUrl ? 'mdi-magnify' : 'mdi-arrow-right'} theme--light primary--text"></i></span></button>`;
            }
          }
          content += `
                </div>
              </div>
            </div>
          </div>`;

          return content;
        })
        .onNodeClick((id, event) => {
          if (!this.data || !this.data.length)
            return;
          let found = this.data.find(item => item.id === id);
          if (!found || found.type === 'global-root')
            return;

          if (event && event.srcElement && event.srcElement.classList && (event.srcElement.classList.contains('node-check') || event.srcElement.classList.contains('node-check-click-area'))) {
            let nodeCheck = event.srcElement.classList.contains('node-check') ? event.srcElement : event.srcElement.getElementsByClassName('node-check')[0];
            let containers = document.getElementsByClassName("orgChartContainer");
            let container = containers && containers.length ? containers[0] : null;
            if (nodeCheck.classList.contains('mdi-checkbox-blank-circle-outline')) {
              nodeCheck.classList.remove("mdi-checkbox-blank-circle-outline");
              nodeCheck.classList.add("mdi-check-circle");              
              
              if (found.item && (typeof found.item === 'object' && 'checked' in found.item))
                found.item.checked = true;

              selected[id] = (found.item && typeof found.item === 'object') ? found.item : found;
              if (container && container.classList && !container.classList.contains('any-selected'))
                container.classList.add("any-selected");
            }
            else {
              nodeCheck.classList.remove("mdi-check-circle");
              nodeCheck.classList.add("mdi-checkbox-blank-circle-outline");

              if (found.item && (typeof found.item === 'object' && 'checked' in found.item))
                found.item.checked = false;
              
              if (id in selected) {
                delete selected[id];
                if (Object.keys(selected).length === 0 && container && container.classList && container.classList.contains('any-selected'))
                  container.classList.remove("any-selected");
              }
            }
            EventBus.$emit('orgChartSelectionUpdated', Object.values(selected));

            return;
          }

          if (event && event.srcElement && event.srcElement.classList && event.srcElement.classList.contains('show-comments')) {
            
            EventBus.$emit('showItemComments', { item: found.item, x: event.x, y: event.y });

            return;
          }

          if (found.type === 'canvas')
            this.$router.push('/canvas/' + found.id);

          else if (found.type === 'add') {
            if (event && event.srcElement && event.srcElement.classList && (event.srcElement.classList.contains('add-more-button') || event.srcElement.classList.contains('mdi-chevron-down'))) {
              EventBus.$emit('orgChartShowCreateMoreMenu', { x: event.x, y: event.y, item: found  });
            }
            else
              EventBus.$emit('orgChartAddNode', found);
          }

          else if (!found.imageUrl) {
            //this.$router.push('/canvas/' + found.cid + '/' + found.id);
            EventBus.$emit('navigateTo', { item: { iid: found.iid } });
          }
          
          else if (found.parent && found.item) {
            let parent = this.data.find(item => item.id  === found.parent);
            if (parent.item && parent.item.items && parent.item.items.length) {
              EventBus.$emit('showViewDialog', { items: parent.item.items, index: Math.max(0, parent.item.items.findIndex(item => item.iid === id)) });
            }
            else
              EventBus.$emit('showViewDialog', { items: [found.item], index: 0 });
              
            EventBus.$emit('navigateTo', { item: { iid: found.parent } });
            //this.$router.push('/canvas/' + found.cid + '/' + found.parent);
          }
        })
        .nodeUpdate(function (d, i, arr) {
          if (d.data && d.data.loading) {
            if (d.data.parentId) {
              let buttons = document.getElementsByClassName(`expand-botton-${d.data.parentId}`);
              if (buttons.length) {
                buttons[0].classList.add('loading');
              }
            }
            d.data.loader();
            return;
          }            

          d3.select(this)
          .select('.node-rect')
          .attr('stroke', (d) =>
            d.data._highlighted || d.data._upToTheRootHighlighted
              ? '#344955'
              : 'none'
          )
          .attr(
            'stroke-width',
            d.data._highlighted || d.data._upToTheRootHighlighted ? 8 : 1
          )
          .attr(
            'rx',
            d.data._highlighted || d.data._upToTheRootHighlighted ? 23 : 0
          );
        })
        .render();
    }
  },
}
</script>

<style>
.node {
  transition: background-color 200ms linear;
}
.node .go-button {
  opacity: 0;
  transition: opacity 200ms linear;
}
.node:hover .go-button {
  opacity: 1;
}
.node .node-check {
  opacity: 0;
  transition: opacity 200ms linear;
}
.any-selected .node-check,
.node:hover .node-check {
  opacity: 1;
}
.node:not(.root-node):hover {
  background-color:#f5f5f5;
}
/*.root-node {
  cursor: auto;
  background-color: #344955 !important;
  color: white !important;
}*/
.add-node {
  background-color: #344955 !important;
  color: white !important;
}
.add-node:hover {
  background-color: #1a2629 !important;
}
.add-transparent-node {
  transition: opacity 200ms linear;
  opacity: 0.5;
}
.add-transparent-node:hover {
  opacity: 1;
}
.canvas-node {
  background-color: #f9aa33 !important;
  color: #344955 !important;
}
.canvas-node:hover {
  background-color: #f6c764 !important
}
.canvas-node .mdi-plus,
.node .mdi-plus {
  color: #344955;
}
.add-node .mdi-plus {
  color: white !important;
}
.add-node .add-more-divider {
  border-color: rgba(255, 255, 255, 0.12) !important;
}
.add-node .add-more-button {
  color: rgba(255, 255, 255, 0.8) !important;
}
.node-expand {
  color:#344955;
  border-radius:5px;
  padding:3px;
  font-size:10px;
  margin:auto auto;
  background-color: white;
  transition: background-color 200ms linear;
}
.node-button-foreign-object:hover .node-expand.canvas-expand {
  background-color: #fae27d;
}
.node-expand.canvas-expand {
  background-color: #f6c764;
}
.node-expand.loading .expand-chevron {
  display: none;
}
.node-expand .expand-progress {
  display: none;
}
.node-expand.loading .expand-progress {
  display: block;
}
</style>