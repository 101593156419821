<template>
  <v-dialog v-model="show" @keydown.esc="show = false" scrollable persistent content-class="share-assets-dialog" width="950">
    <v-card color="#edf0f2" min-height="650">
      <v-card-title class="primary white--text pa-3">Share files<v-spacer></v-spacer><v-btn icon color="white" @click="show = false"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
      <v-card-text class="pt-3" style="overflow:visible">
        <v-window v-model="step" class="fill-height" style="overflow: visible;">
          <v-window-item class="fill-height">
            <v-container fill-height>
              <v-layout row wrap align-center>
                <v-flex xs12 sm12 md12 class="text-center">
                  <div style="max-width: 700px" class="mx-auto text-center pa-10 white rounded-xl elevation-1">
                    <div class="text-h4 pb-6" style="font-weight: 300; color: black">{{ $t('uploadFilesTo') }}</div>
                    <add-assets-widget v-model="targetWidget" createCanvas>
                      <template v-slot:finish-button>
                        <v-btn x-large rounded elevation="1" class="mx-auto mt-6 d-block secondary primary--text" @click="step++">{{ $t('next') }}</v-btn>
                      </template>
                    </add-assets-widget>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-window-item>
          <v-window-item class="fill-height">
            <v-btn fab large absolute left style="top: 50%; transform: translateY(-50%)" color="white" elevation="0" @click="step--"><v-icon x-large>mdi-chevron-left</v-icon></v-btn>
            <v-container fill-height>
              <v-layout row wrap align-center>
                <v-flex xs12 sm12 md12 class="text-center">
                  <div style="max-width: 700px" class="mx-auto text-center pa-10 white rounded-xl elevation-1">
                    <div class="mb-4 text-h6 font-weight-light">Please write your message for the other person.</div>
                    <v-combobox v-model="emails" label="Enter e-mail" :search-input.sync="currentEmail" outlined flat dense multiple chips hide-details class="mb-3" :delimiters="[' ', ',', ';']">
                      <template v-slot:append>&nbsp;</template>
                      <template v-slot:no-data>
                        <template v-if="isValidEmail(currentEmail)">
                          <v-list-item @click="() => {emails.push(currentEmail); currentEmail = null;}">
                            <v-list-item-content>
                              <v-list-item-title>Use <strong>{{ currentEmail }}</strong></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </template>
                      <template v-slot:selection="data">
                        <v-tooltip top :disabled="isValidEmail(data.item)">
                          <template v-slot:activator="{on}">
                            <v-chip class="my-1" label small v-on="on" v-bind="data.attrs" :input-value="data.selected" :color="isValidEmail(data.item) ? 'rgba(0,0,0,0.1)' : 'error'" close @click:close="data.parent.selectItem(data.item)">{{ data.item }}</v-chip>
                          </template>
                          <span>Not a valid email address</span>
                        </v-tooltip>
                      </template>
                    </v-combobox>
                    <vue-editor v-model="message" :editorToolbar="[[{ 'size': ['small', false, 'large', 'huge'] }],['bold', 'italic', 'underline'],['link'],[{ 'list': 'ordered'}, { 'list': 'bullet' }], [{ 'color': [] }, { 'background': [] }],['clean']]" :editorOptions="{ theme: 'bubble', placeholder: 'Message'}" class="text-left collect-message"></vue-editor>
                    <v-btn x-large rounded elevation="1" class="mx-auto mt-6 d-block secondary primary--text" :loading="loading" :disabled="!validEmails || !validEmails.length || loading" @click="shareAssets()">Share</v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-window-item>
          <v-window-item class="fill-height">
            <v-btn fab large absolute left style="top: 50%; transform: translateY(-50%)" color="white" elevation="0" @click="step--"><v-icon x-large>mdi-chevron-left</v-icon></v-btn>
            <v-container fill-height>
              <v-layout row wrap align-center>
                <v-flex xs12 sm12 md12 class="text-center">
                  <div style="max-width: 700px" class="mx-auto text-center pa-10 white rounded-xl elevation-1">
                    <div class="text-h4" style="font-weight: 300; color: black">You've successfully shared your files</div>
                    <p class="text-subtitle-1 pb-6">{{validEmails.length > 1 ? 'Emails' : 'An email'}} with your message has been sent. Thank you!</p>
                    <v-btn x-large rounded elevation="1" class="mx-auto mt-6 d-block secondary primary--text" @click="finish()">{{ $t('close') }}</v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-window-item>
        </v-window>
      </v-card-text>
      <v-card-actions class="d-block">
        <v-alert color="primary white--text" transition="scale-transition" dark dismissible class="rounded-pill elevation-1 mt-4" v-model="error.show"><v-icon color="white">mdi-alert-circle-outline</v-icon> {{ error.message }}</v-alert>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
.fill-height {
  height: 100%;
}
.share-assets-dialog {
  overflow: visible !important;
}
</style>
<script>
/* eslint-disable no-unused-vars */
import { VueEditor } from "vue2-editor";
import AddAssetsWidget from '@/components/widgets/add-assets'
import { AccessControl } from '@/libs/access-control.js'

export default {
  name: 'share-assets-dialog',
  components: {
    'add-assets-widget': AddAssetsWidget,
    'vue-editor': VueEditor
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      step: 0,
      url: null,
      copying: false,
      emails: [],
      currentEmail: null,
      error: {
        show: false,
        message: null
      },
      message: null,
      targetWidget: null,
      loading: false
    }
  },
  computed: {
    show: {
      get() { return this.value; },
      set(val) { this.$emit('input', val); }
    },
    validEmails() {
      return this.emails.filter(email => this.isValidEmail(email));
    }
  },
  watch: {
    show() {
      this.step = 0;
      this.url = null;
      this.copying = false;
      this.emails = [];
      this.currentEmail = null;
      this.error.show = false;
      this.error.message = null;
      this.message = null;
      this.targetWidget = null;
      this.loading = false;
    }
  },
  methods: {
    copyURL() {
      this.copying = true;
      if (navigator.clipboard)
        navigator.clipboard.writeText(this.url);
      else
        this.fallbackCopyTextToClipboard();
        
      setInterval(()=>{this.copying = false}, 3000);
    },
    fallbackCopyTextToClipboard() {
      let el = document.getElementById('publicURL');
      el.focus();
      el.select();
      document.execCommand('copy');
    },
    mimeToType(mime) {
      if (mime.match('audio.*'))
        return 'audio';
      if (mime.match('video.*'))
        return 'video';
      if (mime.match('image.*'))
        return 'img';

      return 'file';
    },
    isValidEmail(email) {
      if (!email || !email.length)
        return false;
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(email);
    },
    async shareAssets() {
      if (!this.targetWidget)
        return;
      this.loading = true;
      try {
        let acLib = new AccessControl(this.$store);

        for (let email of this.validEmails) {
          await acLib.shareWith(this.targetWidget.cid, this.targetWidget.iid, email, 'Editor', null, this.message);
        }

        this.step++;
      }
      catch (err) {
        console.log(err);
        this.error.message = err;
        this.error.show = true;
      }

      this.loading = false;
    },
    finish() {
      if (!this.targetWidget)
        return;

      window.location.pathname = 'canvas/' + this.targetWidget.cid;
      this.show = false;
    }
  }
}
</script>

<i18n>
{
  "en": {
    "uploadFilesTo": "Upload files to the section",
    "createALink": "Create a link",
    "hereIsTheLink": "Here's the link to collect assets"
  }
}
</i18n>