<template>
  <div class="share-widget" :key="render">
    <template v-if="!downloading">
      <template v-if="!simple">
        <div class="text-h4 pb-3" style="font-weight: 300; color: black">Share this {{item && item.type !== 'root' ? 'section' : 'project'}} with others</div>
        <div class="mt-6 mx-auto">
          <v-text-field class="mt-4 mb-2 pr-0 public-url" readonly dense outlined hide-details :label="isPublicSection ? 'Public link' : 'Private link'" :value="getPublicURL()" >
            <template v-slot:append>
              <v-icon class="mt-2 mr-1" @click="copyURL">{{copying ? 'mdi-check' : 'mdi-content-copy'}}</v-icon>
            </template>
          </v-text-field>
          <template v-if="!isPublicSection">
            <div class="mt-2">This {{item && item.type !== 'root' ? 'section':'project'}} is <v-icon small color="primary">mdi-lock</v-icon> <b>private</b>. Only users listed below can access it.</div>
            <div class="d-inline-block mr-1" style="width: 28px; height 28px;"></div>
            <v-btn class="elevation-1 primary rounded-pill mt-3" small :disabled="loading" @click="makePublic()">Make this {{item && item.type !== 'root' ? 'section':'project'}} <b class="ml-1">public</b></v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{on}">
                <v-btn v-on="on" icon small class="mt-3 ml-1" ><v-icon small color="primary">mdi-information-outline</v-icon></v-btn>
              </template>
              <span>By making this section <b>public</b> anyone with the link will be able to <b>view</b> the {{item && item.type !== 'root' ? 'section':'project'}}.</span>
            </v-tooltip>
          </template>
          <template v-else>
            <div class="mt-2">This {{item && item.type !== 'root' ? 'section':'project'}} is <v-icon small color="primary">mdi-earth</v-icon> <b>public</b>. Anyone with the link above can access it. You can see anyone who accessed this {{item && item.type !== 'root' ? 'section':'project'}} in the list below.</div>
            <div class="d-inline-block mr-1" style="width: 28px; height 28px;"></div>
            <v-btn class="elevation-1 primary rounded-pill mt-3" small :disabled="loading" @click="makePrivate()">Make this {{item && item.type !== 'root' ? 'section':'project'}} <b class="ml-1">private</b></v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{on}">
                <v-btn v-on="on" icon small class="mt-3 ml-1" ><v-icon small color="primary">mdi-information-outline</v-icon></v-btn>
              </template>
              <span>By making this section <b>private</b> only users listed below will be able to access the {{item && item.type !== 'root' ? 'section':'project'}}.</span>
            </v-tooltip>
          </template>
        </div>
        <v-divider class="mt-8 mb-3"></v-divider>
      </template>      
      <template v-else>
        <v-btn v-if="!isPublicSection" class="elevation-1 primary rounded-pill mt-3" :disabled="loading" @click="makePublic()">Share with a <b class="ml-1">link</b></v-btn>
        <template v-else>
          <v-text-field class="mt-4 mb-2 pr-0 public-url" readonly dense outlined hide-details :label="isPublicSection ? 'Public link' : 'Private link'" :value="getPublicURL()" >
            <template v-slot:append>
              <v-icon class="mt-2 mr-1" @click="copyURL">{{copying ? 'mdi-check' : 'mdi-content-copy'}}</v-icon>
            </template>
          </v-text-field>
        </template>
        <v-divider class="mt-8 mb-3"></v-divider>
        <div class="mx-auto" style="font-size: 14px; margin-top: -24px"><span style="background-color: white; padding: 12px">or</span></div>
        <v-btn v-if="isPublicSection && acList.length < 2" class="elevation-1 primary rounded-pill mt-3" :loading="loading" :disabled="loading" @click="makePrivate()">Enter <b class="ml-1">e-mail</b></v-btn>
      </template>

      <template v-if="!simple || !isPublicSection || acList.length > 1">
        <v-text-field class="mt-4 mb-2 py-0 pr-0" dense outlined hide-details label="Enter e-mail" v-model="email" @keydown.enter="share()" :loading="loading" autofocus>
          <template v-slot:append>
            <v-fade-transition leave-absolute>
              <div v-if="validEmail()">
                <div style="margin-right: 6px; margin-top: 6px; display: inline-flex"><v-btn class="elevation-0 " small @click="share()"><v-icon left>mdi-plus</v-icon> Add</v-btn></div>
              </div>
            </v-fade-transition>
            <div v-if="!simple" style="width: 100px; height: calc(100% - 2px); overflow: hidden; margin-top: 2px; border-left: 1px solid silver; display: inline-flex;"><v-select background-color="transparent" :items="userRoles" class="pr-0" v-model="role" solo flat dense hide-details></v-select></div>
          </template>
        </v-text-field>
        <v-simple-table v-if="acList && acList.length" :key="acUpdated">
          <template v-slot:default>
            <thead v-if="!simple">
              <tr>
                <th class="text-left" style="padding-left: 45px">User</th>
                <th class="text-left" style="width: 140px" v-if="!simple">Role</th>
                <th class="text-left" style="width: 45px" v-if="!simple">Assignee</th>
                <th style="width: 32px"></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="ac in acList">
                <tr :key="ac.e" v-if="ac.e" class="ac-row">
                  <td class="text-left">
                    <v-avatar v-if="ac.profileImage" size="24" :color="getColor(ac.e)" class="elevation-2 mr-1" style="border: 1px solid rgba(0,0,0,0.1)"><v-img :src="ac.profileImage" size="24"></v-img></v-avatar> 
                    <v-avatar v-else size="24" :color="getColor(ac.e)" class="elevation-2 mr-1" style="border: 1px solid rgba(0,0,0,0.1)">{{ac.e[0].toUpperCase()}}</v-avatar> 
                    <span :class="ac.nonExisting ? 'font-italic' : ''">{{ac.name || ac.e}}</span>
                  </td>
                  <td class="text-left" v-if="!simple"><v-select v-if="ac.role !== 'Owner'" :items="userRoles.includes(ac.role) ? userRoles : [...userRoles, ac.role]" background-color="transparent" class="pl-0" v-model="ac.role" v-on:change="updateRole(ac.acid, $event)" solo flat dense hide-details></v-select><strong v-else>Owner</strong></td>
                  <td class="text-center" v-if="!simple">
                    <v-simple-checkbox color="primary" :ripple="false" hide-details dense class="d-inline-block pa-0 ma-0" :value="ac.uid === item.assignee || ac.e === item.assignee" @input="setAssignee(ac.uid)"></v-simple-checkbox>
                  </td>
                  <td class="text-right"><v-icon v-if="ac.role !== 'Owner'" @click="remove(ac.acid)" class="delete-ac">mdi-delete</v-icon></td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </template>     
    </template>
    <div v-else style="height: 100%; min-height: 300px;">
      <v-container fill-height>
        <v-layout row wrap align-center>
          <v-flex xs12 sm12 md12 class="text-center">
            <v-progress-linear color="secondary" indeterminate rounded height="10" class="mx-auto" style="width: 50%"></v-progress-linear>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<style>
.fill-height {
  height: 100%;
}
.share-widget .v-input__append-inner {
  margin-top: 0px !important;
}
.share-widget .v-input.pr-0 .v-input__slot {
  padding-right: 0px !important;
}
.share-widget .v-input.pl-0 .v-input__slot {
  padding-left: 0px !important;
}
.ac-row .delete-ac {
  opacity: 0;
}
.ac-row:hover .delete-ac {
  opacity: 1;
}
</style>

<script>
/*eslint-disable no-unused-vars*/
import * as api from '@/libs/api.js'
import * as tools from '@/libs/tools.js'
import { getCanvasItemMap } from '@/libs/canvas.js'
import { User } from '@/libs/user.js'
import { AccessControl } from '@/libs/access-control.js'
export default {
  name: 'share-widget',
  props: {
    cid: {
      type: String,
      default: () => null
    },
    iid: {
      type: String,
      default: () => null
    },
    simple: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => {
    return {
      render: 0,
      email: null,
      role: 'Viewer',
      loading: false,
      downloading: false,
      publishing: false,
      copying: false,
      userRoles: ['Viewer', 'Editor', 'Client'],
      user: null
    }
  },
  computed: {
    acUpdated() { return this.$store.state.ac.updated },
    item() {
      if (!this.iid || !(this.iid in getCanvasItemMap(this.$store.state, this.cid)))
        return null;

      return getCanvasItemMap(this.$store.state, this.cid)[this.iid];
    }
  },
  asyncComputed: {
    async acList() {
      this.downloading = true;
      let list = await this.getACList();
      if (this.simple)
        list = list.filter(ac => ac.uid !== this.user.uid && ac.uid !== 'public');

      this.downloading = false;
      return list;
    },
    async isPublicSection() {
      this.downloading = true;
      let list = await this.getACList('public');
      this.downloading = false;
      list = list.filter(ac => ac.iid === this.iid);
      if (list.length === 0)
        return false;

      if (list.length > 1)
        console.log('Multiple AC links in ' + this.iid + '!');

      return true;
    }
  },
  async mounted() {
    let userLib = new User(this.$store.state);
    try {
      let user = await userLib.getUser();
      this.userRoles = (user && user.userRoles && user.userRoles.length) ? user.userRoles : ['Viewer', 'Editor', 'Client'];
      this.role = this.userRoles[0];
      this.user = user;
    }
    catch (err) {
      console.info(err);
    }
  },
  methods: {
    async share(uid) {
      if (!this.validEmail() && !uid)
        return;
      
      this.loading = true;
      try {
        let acLib = new AccessControl(this.$store);
        if (uid)
          await acLib.shareWith(this.cid, this.iid, '', 'Viewer', uid);
          
        else {
          await acLib.shareWith(this.cid, this.iid, this.email, this.role);
          this.email = null;
        }
        this.loading = false;
      }
      catch (err) {
        console.log(err);
        this.loading = false;
      }
      this.render++;
    },
    async makePublic() {
      if (this.isPublicSection)
        return;
        
      await this.share('public');
    },
    async makePrivate() {
      if (!this.isPublicSection)
        return;

      this.loading = true;

      this.downloading = true;
      let list = await this.getACList('public');
      this.downloading = false;
      list = list.filter(ac => ac.iid === this.iid);
      if (!list || list.length === 0)
        return;

      for (let ac of list)
        await this.remove(ac.acid);
      
      this.loading = false;
    },
    validEmail() {
      if (!this.email || !this.email.length)
        return false;
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(this.email);
    },
    async remove(acid) {
      let acLib = new AccessControl(this.$store);
      if (!(await acLib.removeShare(this.cid, acid))) {
        this.render++;
        return;
      }
      this.render++;
    },
    getPublicURL() {
      return window.location.origin + '/canvas/' + this.cid + '/' + this.iid;
    },
    copyURL() {
      this.copying = true;
      if (navigator.clipboard)
        navigator.clipboard.writeText(this.getPublicURL());
      else
        this.fallbackCopyTextToClipboard();
        
      setTimeout(() => { this.copying = false }, 3000);
    },
    fallbackCopyTextToClipboard() {
      let el = document.getElementById('publicURL');
      el.focus();
      el.select();
      document.execCommand('copy');
    },
    getColor(str) {
      return tools.seededRandomColor(str);
    },
    async getACList(uid = null) {
      let acLib = new AccessControl(this.$store);
      let item = (this.iid && (this.iid in getCanvasItemMap(this.$store.state, this.cid))) ? getCanvasItemMap(this.$store.state, this.cid)[this.iid] : null;
      return await acLib.listShares(this.cid, item, uid);
    },
    async updateRole(acid, role) {
      let acLib = new AccessControl(this.$store);
      return await acLib.updateShare(this.cid, acid, role);
    },
    async setAssignee(uid) {
      let previous = this.item.assignee;
      if (uid === this.item.assignee)
        uid = '';
        
      this.item.assignee = uid;

      if (await api.updateItem(this.cid, this.iid, { a: uid }) === null)
        this.item.assignee = previous;
    }
  }
}
</script>