<template>
<div>
<div class="text-left galery-widget profile-widget" :class="editing ? 'editing' : ''">
  <div class="gallery-content py-8" @drop.prevent="dropFiles" @dragenter.prevent @dragover.prevent="dragFiles" @dragleave="dragLeave" :xstyle="'padding: 2px'">
    <v-container>
      <v-row no-gutters>
        <v-col cols="12" sm="4" class="px-10">
          <v-responsive aspect-ratio="1" class="pa-1">
            <v-avatar size="100%" color="white" class="elevation-2" style="border: 10px solid rgba(0,0,0,0.1); cursor: pointer;" @click="item.items && item.items.length ? view({ items: item.items, index: 0, widget: item, editing: editing }) : addAssets(item)">
              <v-img v-if="item.items && item.items.length" :src="item.items[0].thumbSrc || item.items[0].src" aspect-ratio="1" size="100%"></v-img>
              <v-icon v-else :size="$vuetify.breakpoint.smAndDown ? '14vw' : '7vw'" color="primary">mdi-camera-plus</v-icon>
            </v-avatar> 
          </v-responsive>
        </v-col>
        <v-col cols="12" sm="8">
          <div style="position: relative">
            <v-text-field v-if="editing" :value="item.cfg.name" v-on:change="updateItemConfig(item, () => item.cfg.name = $event)" :placeholder="!item.cfg.name ? 'Untitled profile' : null" flat solo hide-details class="text-h2 font-weight-thin editing d-block"></v-text-field>
            <h2 v-else class="text-h2 font-weight-thin d-block">{{item.cfg.name || 'Untitled profile'}}</h2>
            <v-btn v-if="editing" class="elevation-1 secondary primary--text" rounded @click="addAssets(item)" style="position: absolute; top: 18px; right: 0px;">
              <v-icon left>mdi-plus</v-icon> 
              <span>{{$t('addAssets')}}</span> 
            </v-btn>
          </div>
          <v-divider class="mt-4"></v-divider>
          <v-container fluid grid-list-md :class="'columns-' + getColumnCount(item) + ' ' + (item.items && item.items.length ? 'masked-overflow pt-6' : '')">
            <draggable class="layout row wrap" v-model="item.items" :animation="200" :group="{ name: 'profile-items' }" @change="updateItemSorting(item)" :disabled="!editing || $vuetify.breakpoint.mdAndDown">
              <v-flex v-for="(child, i) in item.items" :key="(child.iid && child.iid.length) ? child.iid : i" :class="getGridSizeClass(item) + ' ' + ('pa-' + ((typeof item.cfg.spacing == 'undefined') ? 1 : item.cfg.spacing))">
                <gallery-item v-model="item.items[i]" :parent="item" :editing="editing" :index="i" :cfg="{ showNames: false, rounded: 1, elevation: 1, aspectRatio: 10, cover: true, showLikes: false, showComments: false, size: 2 }"></gallery-item>
              </v-flex>
            </draggable>
          </v-container>          
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row no-gutters>
        <v-col cols="12" class="px-1">
          <key-value-item v-for="(kv, k) in aboutItems" :key="k" v-model="aboutItems[k]" :editing="editing" :animation="200" color="transparent" listItem :required="kv.cfg && kv.cfg.r" class="mb-1" @remove="removeKVItem(item, kv)" @update="updateKVItem(item, kv)" full></key-value-item>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row no-gutters>
        <v-col cols="12" md="4" class="px-1">
          <v-card class="elevation-1 rounded" style="height: calc(100%)" light>
            <v-card-text :key="basicInfoRender">
              <h2 class="pb-4 primary--text"><v-progress-circular :size="29" :width="4" :value="basicInfoCompletion" class="mr-2" v-if="editing"></v-progress-circular>Basic information</h2>
              <draggable class="v-list py-0 v-sheet theme--light" role="list" v-model="basicInfoItems" :disabled="$vuetify.breakpoint.mdAndDown || !editing">
                <v-list-item v-for="(kv, k) in basicInfoItems" :key="kv.kvid" light>
                  <v-list-item-title><key-value-item v-model="basicInfoItems[k]" :editing="editing" :animation="200" color="rgba(0,0,0,0.02)" listItem :required="kv.cfg && kv.cfg.r" class="mb-1" @remove="removeKVItem(item, kv)" @update="updateKVItem(item, kv)" full></key-value-item></v-list-item-title>
                </v-list-item>
              </draggable>
              <div class="d-block text-center mt-10" v-if="editing">
                <v-btn v-if="editing" class="ma-1 d-inline-block rounded-pill elevation-0" @click="createKVItem(item, 'plain-text', 'basic-info')"><v-icon left>mdi-pencil-plus</v-icon> Add</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" class="px-1">
          <v-card class="elevation-1 rounded" style="height: calc(100%)" light>
            <v-card-text>
              <h2 class="pb-4 primary--text"><v-progress-circular :size="29" :width="4" :value="appearanceCompletion" class="mr-2" v-if="editing"></v-progress-circular>Appearance</h2>
              <draggable class="v-list py-0 v-sheet theme--light" role="list" v-model="appearanceItems" :disabled="$vuetify.breakpoint.mdAndDown || !editing">
                <v-list-item v-for="(kv, k) in appearanceItems" :key="kv.kvid" light>
                  <v-list-item-title><key-value-item v-model="appearanceItems[k]" :editing="editing" :animation="200" color="rgba(0,0,0,0.02)" listItem :required="kv.cfg && kv.cfg.r" class="mb-1" @remove="removeKVItem(item, kv)" @update="updateKVItem(item, kv)" full></key-value-item></v-list-item-title>
                </v-list-item>
              </draggable>
              <div class="d-block text-center mt-10" v-if="editing">
                <v-btn v-if="editing" class="ma-1 d-inline-block rounded-pill elevation-0" @click="createKVItem(item, 'plain-text', 'appearance')"><v-icon left>mdi-pencil-plus</v-icon> Add</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" class="px-1">
          <v-card class="elevation-1 rounded" style="height: calc(100%)" light>
            <v-card-text>
              <h2 class="pb-4 primary--text"><v-progress-circular :size="29" :width="4" :value="experienceCompletion" class="mr-2" v-if="editing"></v-progress-circular>Experience</h2>
              <draggable class="v-list py-0 v-sheet theme--light" role="list" v-model="experienceItems" :disabled="$vuetify.breakpoint.mdAndDown || !editing">
                <v-list-item v-for="(kv, k) in experienceItems" :key="kv.kvid" light>
                  <v-list-item-title><key-value-item v-model="experienceItems[k]" :editing="editing" :animation="200" color="rgba(0,0,0,0.02)" listItem :required="kv.cfg && kv.cfg.r" class="mb-1" @remove="removeKVItem(item, kv)" @update="updateKVItem(item, kv)" full></key-value-item></v-list-item-title>
                </v-list-item>
              </draggable>
              <div class="d-block text-center mt-10" v-if="editing">
                <v-btn v-if="editing" class="ma-1 d-inline-block rounded-pill elevation-0" @click="createKVItem(item, 'plain-text', 'experience')"><v-icon left>mdi-pencil-plus</v-icon> Add</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</div>
</div>
</template>
<style>
.gallery-header {
  padding: 8px;
}
.add-gallery-asset {
  opacity: 0;
  transition: opacity 200ms linear;
}
.mobile .add-gallery-asset {
  opacity: 1 !important;
}
.galery-widget:hover .add-gallery-asset {
  opacity: 0.5;
}
.galery-widget .add-gallery-asset:hover {
  opacity: 1;
}
.galery-widget {
  background-color: rgba(255,255,255,0.4);
  transition: background-color 200ms linear;
}
.galery-widget:hover {
  background-color: rgba(255,255,255,0.7);
}
.galery-widget .text-h4 {
  font-weight: 300;
  white-space: nowrap;
}
.plus-sign {
  margin-top: 0px; 
  position: absolute; 
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);
}
.item-show-names {
  margin-top: 28px; 
}
.masked-overflow {
    --scrollbar-width: 16px;
    --mask-height: 32px;
    overflow-y: auto;
    height: 435px;
    padding-bottom: var(--mask-height);
    padding-right: 20px;
    --mask-image-content: linear-gradient(
        to bottom,
        transparent,
        black var(--mask-height),
        black calc(100% - var(--mask-height)),
        transparent
    );
    --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
    --mask-image-scrollbar: linear-gradient(black, black);
    --mask-size-scrollbar: var(--scrollbar-width) 100%;
    mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
    mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
    mask-position: 0 0, 100% 0;
    mask-repeat: no-repeat, no-repeat;
}

.editing.profile-widget input:hover, .editing input.profile-widget:focus {
  background-color: rgba(0,0,0,0.03);
}
</style>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import draggable from 'vuedraggable'
import * as api from '@/libs/api.js'
import * as tools from '@/libs/tools.js'
import { EventBus } from '@/libs/eventBus.js'
import CanvasGalleryItem from '@/components/canvas-items/canvas-gallery-item'
import KeyValueItem from '@/components/widgets/key-value-item'

export default {
  name: 'profile-item',
  components: {
    'draggable': draggable,
    'gallery-item': CanvasGalleryItem,
    'key-value-item': KeyValueItem,
  },
  props: {
    value: {
      type: Object,
      default: () => null
    },
    parent: {
      type: Object,
      default: () => null
    },
    index: {
      type: Number,
      default: () => null
    },
    preview: {
      type: Boolean, 
      default: () => false
    },
    editing: {
      type: Boolean, 
      default: () => false
    }
  },
  data: () => {
    return {
      showMapOffer: true,
      dragging: false,
      creatingSection: false, 
      masonryRender: 0,
      basicInfoRender: 0,
      aboutItems: [],
      basicInfoItems: [],
      appearanceItems: [],
      experienceItems: []
    }
  },
  watch: {
    'item.kv': {
      deep: true,
      handler: function() {
        this.aboutItems = this.getKvItemsOfCategory('about');
        this.basicInfoItems = this.getKvItemsOfCategory('basic-info');
        this.appearanceItems = this.getKvItemsOfCategory('appearance');
        this.experienceItems = this.getKvItemsOfCategory('experience');
      }
    }
  },
  mounted() {
    this.aboutItems = this.getKvItemsOfCategory('about');
    this.basicInfoItems = this.getKvItemsOfCategory('basic-info');
    this.appearanceItems = this.getKvItemsOfCategory('appearance');
    this.experienceItems = this.getKvItemsOfCategory('experience');
  },
  computed: {
    item: {
      get: function() { return this.value; },
      set: function(value) { this.$emit('input', value); }
    },
    basicInfoCompletion() {
      const items = this.getKvItemsOfCategory('basic-info');
      const total = items.length;
      if (total === 0) return 0;

      const filled = items.filter(item => item.value && item.value.length).length;
      return total ? Math.round(filled / total * 100) : 0;
    },
    appearanceCompletion() {
      const items = this.getKvItemsOfCategory('appearance');
      const total = items.length;
      if (total === 0) return 0;

      const filled = items.filter(item => item.value && item.value.length).length;
      return total ? Math.round(filled / total * 100) : 0;
    },
    experienceCompletion() {
      const items = this.getKvItemsOfCategory('experience');
      const total = items.length;
      if (total === 0) return 0;

      const filled = items.filter(item => item.value && item.value.length).length;
      return total ? Math.round(filled / total * 100) : 0;
    },
  },
  methods: {
    getKvItemsOfCategory(category) {
      if (!this.item.kv)
        return [];

      let collectItemMap = {};
      for (let kv of this.item.kv) {
        if (kv.collectItem && kv.collectItem.cfid)
          collectItemMap[kv.collectItem.cfid] = kv.collectItem;
      }

      let items = [];

      for (let kv of this.item.kv) {
        if (kv.collectItem && kv.collectItem.dependency && tools.hideCollectItem(kv.collectItem, collectItemMap))
          continue;

        if (!this.editing && !kv.value)
          continue;

        if (!kv.cfg || !kv.cfg.c || kv.cfg.c != category)
          continue;

        items.push(kv);
      }

      items.sort((a, b) => {
        if (a.value && a.value.length && (!b.value || !b.value.length))
          return 1;
        if ((!a.value || !a.value.length) && b.value && b.value.length)
          return -1;
        return 0;
      });

      return items;
    },
    async updateItemConfig(widget, functor) {
      if (functor)
        functor();
      if (widget.iid)
        await api.updateItem(widget.cid, widget.iid, { cfg: widget.cfg });
    },
    async updateItemSorting(widget) {
      let i = [];
      for (let item of widget.items)
        i.push(item.iid);

      await api.updateItem(widget.cid, widget.iid, { i: i });
    },
    addAssets(widget, files = null) {
      this.$store.state.dialogs.addAssets = {
        show: true, 
        path: tools.getCanvasFullPath(this.$store.state.canvas.cfg.path, widget.cid),
        targetWidget: widget,
        files: files
      }
    },
    getGridSizeClass(widget) {
      const sizes = [1,2,3,4,6,12];
      let size = 4;
      if (widget.cfg.size !== null && widget.cfg.size >= 0 && widget.cfg.size < sizes.length)
        size = sizes[widget.cfg.size];

      if (this.preview && size < 4)
        size = 4;

      if (size >= 6)
        return 'xs12 sm' + size;

      if (size >= 3)
        return 'xs12 sm6 md' + size;
      
      return 'xs12 sm6 md3 lg' + size;
    },
    getColumnCount(widget) {
      if (!this.$vuetify.breakpoint.smAndUp)
        return 1;

      const sizes = [1,2,3,4,6,12];
      let size = 4;
      if (widget.cfg.size !== null && widget.cfg.size >= 0 && widget.cfg.size < sizes.length)
        size = sizes[widget.cfg.size];

      if (this.preview && size < 4)
        size = 4;

      if (size >= 6)
        return Math.floor(12 / size);

      if (this.$vuetify.breakpoint.smOnly)
        return 2;

      if (size >= 3)
        return Math.floor(12 / size);

      if (this.$vuetify.breakpoint.mdOnly)
        return 4;

      return Math.floor(12 / size);
    },
    getRoundedClass(widget) {
      if (!widget || !widget.cfg || !widget.cfg.rounded)
        return '';

      switch (widget.cfg.rounded) {
        case 1: return 'rounded-sm';
        case 2: return 'rounded';
        case 3: return 'rounded-lg';
        case 4: return 'rounded-xl';
        case 5: return 'rounded-pill';
      }

      return 'rounded';
    },
    async remove() {
      let parent = this.parent;
      if (!parent || !parent.items)
        return;
      let index = parent.items.findIndex(item => item.iid == this.item.iid);
      parent.items.splice(index, 1);
      this.$emit('updateParent', parent);

      if (this.parent.iid && this.item.iid && this.item.cid) {
        try {
          await api.removeItem(this.item.cid, this.parent.iid, this.item.iid)
        }
        catch (err) {
          console.log(err);
        }
      }
    },
    async createKVItem(item, type, category) {
      if (!item)
        return;

      const cid = item.cid;
      const iid = item.iid;
      const key = null;
      let value = null;
      if (!item.kv)
        item.kv = [];
      item.kv.push({ key: key, type: type, value: value, cfg: { /*r: true,*/ c: category } });
      item.kv[item.kv.length-1].kvid = await api.createKeyValueItem(cid, iid, key, value, type, null, { /*r: false,*/ c: category });
      
    },
    async updateKVItem(item, kvItem) {
      if (!this.editing)
        return;

      if (item.iid && kvItem.kvid) {
        let updateItem = async () => await api.updateKeyValueItem(this.item.cid, item.iid, kvItem.kvid, { k: kvItem.key, v: kvItem.value, t: kvItem.type, cfg: kvItem.cfg });
        EventBus.$emit('updateItem', {
          id: kvItem.kvid,
          funct: updateItem
        });
      }
    },
    async removeKVItem(item, kvItem) {
      if (!item || !item.kv || !kvItem)
        return;

      const index = item.kv.findIndex(k => k.kvid == kvItem.kvid);
      if (index < 0)
        return;

      item.kv.splice(index, 1);

      await api.removeKeyValueItem(item.cid, item.iid, kvItem.kvid);
    },
    dragFiles(e) {
      e.stopPropagation();
      e.preventDefault();
      if (!e || !e.dataTransfer || !e.dataTransfer.files)
        return;

      e.dataTransfer.dropEffect = 'copy';
      this.dragging = true;
    },
    dropFiles(e) {
      if (!e || !e.dataTransfer || !e.dataTransfer.files || !e.dataTransfer.files.length)
        return;

      this.dragging = false;  
      this.addAssets(this.item, e.dataTransfer.files)
    },
    dragLeave() {
      this.dragging = false;
    },
    view(params) {
      EventBus.$emit('showViewDialog', params);
    },
    nullHandler() {

    }
  }
}
</script>