<template>
  <v-dialog v-model="show" @keydown.esc="show = false" scrollable persistent content-class="confirm-dialog" width="650">
    <v-card color="#edf0f2">
      <v-card-title class="primary white--text pa-3">{{title}}<v-spacer></v-spacer><v-btn icon color="white" @click="show = false"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
      <v-card-text class="pt-3">{{text}}</v-card-text>
      <v-divider></v-divider>
      <v-card-actions style="background-color: #edf0f2">
        <v-spacer></v-spacer>
        <template v-if="type === 'ok-cancel' && funct">
          <v-btn text color="primary" @click="show = false">{{ $t('cancel') }}</v-btn>
          <v-btn class="elevation-0 secondary primary--text" @click="confirm()">OK</v-btn>
        </template>
        <template v-else-if="type === 'yes-no' && funct">
          <v-btn class="elevation-1 primary rounded-pill" @click="confirm()">{{ $t('yes') }}</v-btn>
          <v-btn class="elevation-1 primary rounded-pill" @click="show = false">{{ $t('no') }}</v-btn>
        </template>
        <template v-else>
          <v-btn class="elevation-0 secondary primary--text" @click="funct ? confirm() : (show = false)">OK</v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.fill-height {
  height: 100%;
}
</style>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'add-assets',
  data: () => {
    return {
    }
  },
  computed: {
    show: {
      get: function() { return this.isDialogShown()('confirm'); },
      set: function(value) {  return value ? this.showDialog('confirm') : this.hideDialog('confirm');  }
    },
    title() { return this.$store.state.dialogs.confirm.title },
    text() { return this.$store.state.dialogs.confirm.text },
    type() { return this.$store.state.dialogs.confirm.type },
    funct() { return this.$store.state.dialogs.confirm.funct },
  },
  methods: {
    ...mapGetters(['isDialogShown']),
    ...mapActions(['showDialog', 'hideDialog']),
    confirm() {
      this.show = false;
      this.funct();
    }
  }
}
</script>

<i18n>
{
  "en": {
  }
}
</i18n>