<template>
<v-card class="simple-list-item pa-0" :class="getRoundedClass()" :elevation="config.elevation" :outlined="config.outlined" light>
  <h4 v-if="!editing" class="font-weight-light text-h4 pl-4 pt-2 pb-1">{{item.cfg.name}}</h4>
  <v-text-field v-else flat solo hide-details v-model="item.cfg.name" class="editing simple-list-item-caption text-h4 pl-4 pt-2 pb-1 font-weight-light" v-on:change="updateItemConfig(item, () => item.cfg.name = $event)" style="width: calc(100% - 50px)"></v-text-field>
  <v-btn v-if="editing" absolute top right fab elevation="1" x-small @click.stop.prevent="item.checked = !item.checked" style="top: 4px; right: 4px;" color="rgba(255,255,255,0.7)" :class="$store.state.canvas.checkedCount ? null : 'show-on-hover'">
    <v-icon size="24" color="primary">{{item.checked ? `mdi-check-circle` : `mdi-checkbox-blank-circle-outline`}}</v-icon>
  </v-btn>
  <v-list v-if="item.items && item.items.length" class="simple-list-item-list">
    <draggable :group="{ name: 'item' }" :animation="200" v-model="item.items" :disabled="!editing || $vuetify.breakpoint.mdAndDown">
      <template v-for="(child, i) in item.items">
        <div :key="child.iid">
          <v-divider></v-divider>
          <v-list-item class="px-1 hoverable-ex" @click="viewItem(child, i)">
            <v-list-item-avatar tile size="48px" class="my-1">
              <canvas-item-thumbnail single v-model="item.items[i]" :config="config" style="width: 100%; height: 100%;"></canvas-item-thumbnail>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ child.cfg.name || 'Untitled' }} <v-icon v-if="child.items && child.items.length" small>mdi-chevron-right</v-icon></v-list-item-title>
              <v-list-item-subtitle v-if="item.status" style="font-size: .9em"><span>{{item.status}}</span></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="editing">
              <v-btn icon small elevation="0" color="primary" :class="$store.state.canvas.checkedCount > 0 ? null : 'show-on-hover-ex'" @click.stop.prevent="child.checked = !child.checked"><v-icon size="20">{{child.checked ? `mdi-check-circle` : `mdi-checkbox-blank-circle-outline`}}</v-icon></v-btn>
            </v-list-item-action>
          </v-list-item>
        </div>
      </template>
    </draggable>
  </v-list>
  <div v-else style="position: relative; height: 550px; width: 100%;">
    <div style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)">
      <v-btn v-if="editing" class="elevation-1 secondary primary--text mx-1 float-right" rounded @click="addAssets(item)" style="margin-top: 6px">
        <v-icon left>mdi-plus</v-icon> 
        <span>{{$t('addAssets')}}</span> 
      </v-btn>
    </div>
  </div>
</v-card>
</template>

<style>
.has-link .v-text-field__slot {
  text-decoration: underline;
}
.link-text-field .v-input__append-outer{
  margin-top: 12px;
}
.like .v-badge__badge {
 z-index: 1000;
}
.list-widget-item {
  overflow: hidden;
}
.list-widget-item .list-widget-item-caption {
  padding-right: 48px;
}
.mobile .list-widget-item .list-widget-item-caption {
  padding-right: 0px;
}
.list-widget-item .list-widget-item-caption input {
  line-height: 36px;
}
.list-widget-item .list-widget-item-caption input:hover,
.list-widget-item .list-widget-item-caption input:focus  {
  background-color: rgba(240,240,240,0.9);
}

.list-widget-item  .v-input__append-outer,
.list-widget-item  .v-input__append-inner {
  display: none;
}
.list-widget-item .v-input--is-focused .v-input__append-outer,
.list-widget-item .v-input--is-focused .v-input__append-inner {
  display: flex;
}
.list-widget-item .text-h4 {
  font-weight: 300;
  font-size: 1.4vw !important;
  white-space: nowrap;
}

.list-widget-item .list-widget-item-caption .v-input__slot { min-height: unset; }

.columns-3 .list-widget-item .list-widget-item-caption input { line-height: 1.6vw !important; }
.columns-2 .list-widget-item .list-widget-item-caption input { line-height: 2.2vw !important; }
.columns-1 .list-widget-item .list-widget-item-caption input { line-height: 3.3vw !important; }

.columns-3 .list-widget-item .text-h4 { font-size: 1.4vw !important; line-height: 1.6vw !important; }
.columns-2 .list-widget-item .text-h4 { font-size: 2vw !important; line-height: 2.2vw !important; }
.columns-1 .list-widget-item .text-h4 { font-size: 3vw !important; line-height: 3.3vw !important; }
.list-widget-item .preview .text-h4 { font-size: 1.5rem !important; }

.columns-3 .list-widget-item .ql-editor { font-size: calc(10px + 0.4vw); }
.columns-2 .list-widget-item .ql-editor { font-size: calc(12px + 0.6vw); }
.columns-1 .list-widget-item .ql-editor { font-size: calc(12px + 0.8vw); }

.list-item-description {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 108px) !important;
}
.list-item-description,
.list-item-description-editor {
  background-color: transparent;
  position: absolute;
  width: calc(100% - 32px);
  height: calc(100% - 128px);
}
.list-widget-item .ql-editor {
  padding: 0px;
}
.desktop .comments-button {
  opacity: 0;
  transition: opacity 200ms linear;
}

.desktop .comments-button.has-comments,
.desktop .gallery-item:hover .comments-button,
.desktop .list-widget-item:hover .comments-button {
  opacity: 1;
}
.simple-list-item-list {
  max-height: 550px; 
  overflow-y: auto;
  overflow-x: hidden;
}
/*.simple-list-item:hover .simple-list-item-list {
  overflow-y: auto;
}*/
</style>

<script>
/*eslint-disable vue/no-unused-components*/
import KeyValueItem from '@/components/widgets/key-value-item'
import * as api from '@/libs/api.js'
import * as tools from '@/libs/tools.js'
import { EventBus } from '@/libs/eventBus.js'
import { getCanvasItemMapAsync } from '@/libs/canvas.js'
import draggable from 'vuedraggable'
import EmojiWidget from '@/components/widgets/emoji-widget'
import CanvasItemThumbnail from '@/components/canvas-items/canvas-thumbnail'
export default {
  name: 'simple-list-item',
  components: {
    'key-value-item': KeyValueItem,
    'emoji-widget': EmojiWidget,
    'canvas-item-thumbnail': CanvasItemThumbnail,
    'draggable': draggable,
  },
  props: {
    value: {
      type: Object,
      default: () => null
    },
    parent: {
      type: Object, 
      default: () => null
    },
    index: {
      type: Number,
      default: () => null
    },
    preview: {
      type: Boolean, 
      default: () => false
    },
    editing: {
      type: Boolean,
      default: () => false
    },
  },
  data: () => {
    return {
      linkedItem: null,
    }
  },
  computed: {
    item() {
      let item = this.value;
      if (!item.cfg)
        item.cfg = {};
      return item;
    },
    comments() {
      return this.item.comments || [];
    },
    itemsChecked() {
      return this.$store.state.canvas.checkedCount > 0;
    },
    config() {
      let widget = this.parent;
      return { 
        rounded: (widget && ('rounded' in widget.cfg)) ? widget.cfg.rounded : 0, 
        elevation: (widget && ('elevation' in widget.cfg)) ? widget.cfg.elevation : 1, 
        outlined: (widget && ('outlined' in widget.cfg)) ? widget.cfg.outlined : false, 
        aspectRatio: (widget && ('aspectRatio' in widget.cfg)) ? widget.cfg.aspectRatio : 10, 
        cover: (widget && ('cover' in widget.cfg)) ? widget.cfg.cover : true, 
        showLikes: !this.preview,
        showComments: !this.preview,
        showNames: (widget && ('showNames' in widget.cfg)) ? widget.cfg.showNames : false,
        size: (widget && ('size' in widget.cfg)) ? widget.cfg.size : 'medium', 
      }
    },
  },
  async mounted() {
    if (this.item && this.item.type === 'link' && this.item.cfg && this.item.cfg.cid) {
      const itemMap = await getCanvasItemMapAsync(this.$store.state, this.item.cfg.cid);
      if (itemMap && this.item.cfg.iid in itemMap)
        this.linkedItem = itemMap[this.item.cfg.iid];
    }
  },
  methods: {
    view(index) {
      this.$emit('view');
      EventBus.$emit('showViewDialog', { items: this.item.items, index: index, widget: this.item, editing: this.editing });
    },
    viewGroup(item) {
      EventBus.$emit('navigateTo', { item: item, parent: this.item });
    },
    viewDescription() {
      this.$emit('view');
      if (this.index !== null && this.parent)
        EventBus.$emit('showViewDialog', { items: this.parent.items, index: this.index, showDescription: true, widget: this.parent, editing: this.editing });
    },
    check() {
      this.item.checked = !this.item.checked;
    },
    async remove() {
      let parent = this.parent;
      parent.items.splice(this.index, 1);
      this.$emit('updateParent', parent);

      if (this.parent.iid && this.item.iid && this.item.cid) {
        try {
          await api.removeItem(this.item.cid, this.parent.iid, this.item.iid)
        }
        catch (err) {
          console.log(err);
        }
      }
    },
    async updateItemConfig(widget, functor, addToQueue) {
      EventBus.$emit('updateItemConfig', { widget: widget, functor: functor, addToQueue: addToQueue});
    },
    showComments(event) {
      if (!this.item || !this.item.iid)
        return;

      EventBus.$emit('showItemComments', { item: this.item, x: event.x, y: event.y });
    },
    getThumnailSize() {
      if (this.config.size <= 4)
        return 4;
      else if (this.config.size <= 6)
        return 5;
      else 
        return 6;
    },
    getRoundedClass() {
      if (!this.config || !this.config.rounded)
        return '';

      switch (this.config.rounded) {
        case 1: return 'rounded-sm';
        case 2: return 'rounded';
        case 3: return 'rounded-lg';
        case 4: return 'rounded-xl';
        case 5: return 'rounded-pill';
      }

      return 'rounded';
    },
    showEditorContextMenu() {
      this.$refs.editor.quill.theme.tooltip.edit();
      this.$refs.editor.quill.theme.tooltip.show();
    },
    openInNewTab(item) {
      if (!item.src)
        return;
      window.open(item.src, '_blank').focus();
    },
    getUrl(path) {
      return window.location.origin + '/' + path
    },
    isGroupType(type) {
      return ['canvas', 'gallery', 'list', 'map-list', 'map', 'file-table', 'casting-profile'/*, 'link'*/].includes(type);
    },
    viewItem(item, index) {
      let type = 'img';

      if (item.type === 'file' && !item.thumbSrc)
        type = 'new-tab';
      else if (this.isGroupType(item.type))
        type = 'group'

      if (!type)
        this.view(index);

      else if (type === 'new-tab')
        return this.openInNewTab(item);

      else if (type === 'group')
        return this.viewGroup(item);

      this.view(index);
    },
    addAssets(widget, files = null) {
      this.$store.state.dialogs.addAssets = {
        show: true, 
        path: tools.getCanvasFullPath(this.$store.state.canvas.cfg.path, widget.cid),
        targetWidget: widget,
        files: files
      }
    },
  }
}
</script>