<template>
<div>
  <div v-if="!item.items || !item.items.length" class="empty-root text-center" @drop.prevent="dropFiles" @dragenter.prevent @dragover.prevent="dragFiles" @dragleave="dragLeave" >
    <p class="text-h5" style="font-weight: 300">{{$t('noContent')}}</p>
    <p class="text-subtitle-1 mb-5">{{$t('letsAddSome')}}</p>
    <v-btn x-large elevation="1" class="rounded-pill" color="secondary primary--text" @click="addAssets(item)">{{$t('addContent')}}</v-btn>
  </div>
  <draggable v-else v-model="item.items" :animation="200" :group="{ name: 'items' }" :disabled="!editing || this.$vuetify.breakpoint.mdAndDown">
    <template v-for="(child, i) in item.items">
      <canvas-widget v-if="validType(item.items[i])" :key="i" :parent="item" :index="i" v-model="item.items[i]" :editing="editing" class="mb-5" :preview="preview"></canvas-widget>
    </template>
    <template v-if="assets.length">
      <gallery-widget :value="getAssetsWidget()" :parent="item" :editing="editing" :preview="preview"></gallery-widget>
    </template>
  </draggable>
</div>
</template>
<i18n>
{
  "en": {
    "noContent": "There's no content in this section yet",
    "letsAddSome": "Let's add some",
    "addContent": "Add content"
  }
}
</i18n>
<style>
  .empty-root {
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    padding-top: 64px;
    padding-bottom: 64px;
    padding-right: 32px;
    padding-left: 32px;
  }
</style>

<script>
import draggable from 'vuedraggable'
import * as tools from '@/libs/tools.js'
import GalleryWidget from '@/components/canvas-items/gallery-widget'
export default {
  name: 'section-widget',
  components: {
    'draggable': draggable,
    'gallery-widget': GalleryWidget,
  },
  props: {
    value: {
      type: Object,
      default: () => null
    },
    preview: {
      type: Boolean, 
      default: () => false
    },
    editing: {
      type: Boolean, 
      default: () => false
    }
  },
  data: () => ({
    dragging: false
  }),
  computed: {
    item: {
      get: function() { return this.value; },
      set: function(value) { this.$emit('input', value); }
    },
    assets() {
      if (!this.item)
        return [];

      let items = this.item.items;
      if (!items)
        return [];

      return items.filter(item => !this.validType(item));
    }
  },
  methods: {
    addAssets(widget, files = null) {
      if (!widget)
        widget = this.item;

      this.$store.state.dialogs.addAssets = {
        show: true, 
        path: tools.getCanvasFullPath(this.$store.state.canvas.cfg.path, widget.cid),
        targetWidget: widget,
        files: files
      }
    },
    dragFiles(e) {
      e.stopPropagation();
      e.preventDefault();
      if (!e || !e.dataTransfer || !e.dataTransfer.files)
        return;

      e.dataTransfer.dropEffect = 'copy';
      this.dragging = true;
    },
    dropFiles(e) {
      if (!e || !e.dataTransfer || !e.dataTransfer.files || !e.dataTransfer.files.length)
        return;

      this.dragging = false;  
      this.addAssets(this.item, e.dataTransfer.files)
    },
    dragLeave() {
      this.dragging = false;
    },
    validType(item) {
      if (item.type === 'img' || item.type === 'video' || item.type === 'audio' || item.type === 'group')
        return false;

      return true;
    },
    getAssetsWidget() {
      return { type: 'gallery', parent: this.item.iid, cid: this.item.cid, cfg: { name: '', hideControls: true, aspectRatio: 10, cover: true, outlined: false, rounded: false, elevation: 1, spacing: 1, showNames: true, size: 1}, kv: [], items: this.assets }
    }
  }
}
</script>